import {IDLE_STATE_ACTION, PRELOAD_STATE_ACTION} from "../constants";
import {pipeCall, preloadCall} from "../utils/stateManagerUtilities";
import {idleCmp} from "../utilities/toLoad";
import {makePipeFlow, PipeCall} from "../utils/classes/PipeCall";

export const idleStateActionListeners = {
    [IDLE_STATE_ACTION]: {
        [PRELOAD_STATE_ACTION]: makePipeFlow()
            .setArgs({batchLoad: idleCmp})
            .preload(),
    }
};
