import React from "react";
import '../../assets/scss/avatar-chooser.scss';
import {AvatarChooserBackground} from "../partials/AvatarChooserBackground";
import {AvatarPartChooser} from "../partials/AvatarPartChooserBackground";
import {AvatarViewer} from "../partials/AvatarViewer";

export const AvatarChooser = () => {
    return <svg className="avatar-chooser" viewBox="0 0 1920 1080" width="100%" height="100%">
        <g height="872" transform="matrix(1 0 0 -1 960 540)" width="1540" x="-770" y="-436">
            <AvatarChooserBackground/>
        </g>
        <AvatarViewer/>
        <AvatarPartChooser/>
    </svg>
}
