import "../../assets/scss/welcome-screen.scss";
import {animated, useSpring} from "react-spring";
import {ComponentAssets} from "../AssetsManager/Proxy";
import React from "react";
import {StateChanger} from "../partials/StateChanger";
import {asState, ENTERING_STATE_ACTION, EXITING_STATE_ACTION, WELCOME_SCREEN_SCREEN_STATE} from "../../constants";
import {useInteractionCheck} from "../../hooks/useInteractionCheck";
import {useStateListener} from "../../hooks/useStateListener";

const WelcomeScreen = () => {
    const canInteract = useInteractionCheck();
    const [loadingScreenOpacity, loadingScreenOpacityApi] = useSpring(() => ({opacity: 0}));

    useStateListener(asState(WELCOME_SCREEN_SCREEN_STATE, ENTERING_STATE_ACTION), () => loadingScreenOpacityApi.start({opacity: 1}));
    useStateListener(asState(WELCOME_SCREEN_SCREEN_STATE, EXITING_STATE_ACTION), () => loadingScreenOpacityApi.start({opacity: 0}));

    return <animated.svg style={loadingScreenOpacity} className="welcome-screen" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1920 1080">
        <g width="1375.87" height="814.08" transform="translate(251 111.58)">
            <ComponentAssets.WelcomeFrame/>
        </g>
        <g width="770.57" height="349.81" transform="translate(578.74 238.19)">
            <ComponentAssets.PresentationSplashText/>
        </g>
        <g width="403.64" height="177.92" transform="translate(762.2 606.82)">
            <StateChanger toState={asState(WELCOME_SCREEN_SCREEN_STATE, EXITING_STATE_ACTION)} event="onPlay">
                <ComponentAssets.PlayButton disabled={!canInteract}/>
            </StateChanger>
        </g>
        <g width="579.7" height="645.41" transform="translate(1266.46 334)">
            <ComponentAssets.RollyRobot/>
        </g>
    </animated.svg>

}

export default WelcomeScreen;
