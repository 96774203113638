import '../../assets/scss/answer-selection-screen.scss';
import {GameBtn} from "../partials/GameBtn";
import ConfirmAnswerButton from "../partials/ConfirmAnswerButton";
import RepeatSoundButton from "../partials/RepeatSoundButton";
import QuestionFrame from "../partials/QuestionFrame";
import AnswersChooser from "../partials/AnswersChooser";
import {useGameState} from "../../hooks/useGameState";
import {OnGameStateCmp} from "../../context/GameStateContext";
import {useDispatch, useSelector} from "react-redux";
import {choose as chooseAnswer, listen} from "../../store/answerSelectionScreenStateSlice";
import {animated, useSpring, useSprings} from "react-spring";
import React from "react";
import {SplashLoader} from "../partials/SplashLoader";
import {CONFIRMED_STATE_ACTION, LISTEN_STATE_ACTION, SELECTED_STATE_ACTION} from "../../constants";
import {useGameActionSetter} from "../../hooks/useGameActionSetter";
import {useDebuggerR} from "../../hooks/useDebuggerR";

export const AnswerSelectionScreen = () => {
    useDebuggerR("AnswerSelectionScreen");

    const listenAction = useGameActionSetter(LISTEN_STATE_ACTION);
    const selectedAction = useGameActionSetter(SELECTED_STATE_ACTION);

    const dispatch = useDispatch();

    const {question, answers, listening: whatListening, listened, choosed} = useSelector(({answerSelectionScreenState}) => answerSelectionScreenState);

    const {action} = useGameState();
    const btnBarPos = [
        [1653.9, 67.19],
        [281.13, 68.11],
        [160.66, 67.19],
        [399.28, 68.11],
        [515.81, 67.19],
    ];

    const isEnded = action === 'back' || action === 'complete';
    useSprings(
        btnBarPos.length,
        btnBarPos.map(item => {
            return {delay: isEnded ? 0 : 500, to: {transform: `translate(${isEnded ? 160.66 : item[0]}px,${item[1]}px)`}, from: {transform: `translate(${isEnded ? item[0] : 160.66}px,${item[1]}px)`}};
        })
    );
    const questionSpring = useSpring({

        from: {
            transform: `translate(-1500px, 0px)`,
        },
        to: {
            transform: `translate(${listened[0] ? 0 : -1500}px, 0px)`,
        }
    })

    const inPresentation = action === 'presentation' || action === 'nextPresentation';

    const confirmAnswer = useGameActionSetter(CONFIRMED_STATE_ACTION);

    return <OnGameStateCmp group="answer-selection-screen">
        <svg className="answer-selection-screen" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1920 1080">
            {
                typeof choosed === 'number' && <g onClick={() => confirmAnswer()} width="140.58" height="129.36" transform="translate(1420.42 790.54)">
                    <GameBtn dropX={0}>
                        <ConfirmAnswerButton/>
                    </GameBtn>
                </g>
            }
            <animated.g style={questionSpring}>
                <g width="1074.17" height="111.28" transform="translate(420.15 241.32)">
                    <QuestionFrame/>
                </g>
                <foreignObject transform="translate(475 255)" width="974.17" height="100">
                    <div className="cls-34" xmlns="http://www.w3.org/1999/xhtml">{question}</div>
                </foreignObject>
                <g onClick={() => {
                    if (inPresentation) {
                        return
                    }
                    dispatch(listen(0));
                    listenAction();
                }} width="59.6" height="54.84" transform="translate(383.94 328.46)">
                    <RepeatSoundButton listening={action === 'listening' && whatListening === 0}/>
                </g>
            </animated.g>
            <AnswersChooser
                onChoose={n => {

                    if (inPresentation) {
                        return
                    }
                    dispatch(chooseAnswer(n));
                    if (Number.isInteger(n)) {
                        selectedAction({selected: n})
                    }
                }}
                disabled={inPresentation}
                entered={listened.slice(1)}
                speakers={[
                    () => {
                        if (inPresentation) {
                            return
                        }
                        dispatch(listen(1));
                        listenAction();
                    },
                    () => {
                        if (inPresentation) {
                            return
                        }
                        dispatch(listen(2));
                        listenAction();
                    },
                    () => {
                        if (inPresentation) {
                            return
                        }
                        dispatch(listen(3));
                        listenAction();
                    },
                ]}
                answers={answers}
                listening={action === 'listening' && whatListening > 0}
            />

            <OnGameStateCmp action="preload">
                <SplashLoader/>
            </OnGameStateCmp>
        </svg>
    </OnGameStateCmp>

}
