import React from "react";
import Accessory1 from "./AvatarAccessory/Accessory1";
import Accessory2 from "./AvatarAccessory/Accessory2";
import Accessory3 from "./AvatarAccessory/Accessory3";
import Accessory4 from "./AvatarAccessory/Accessory4";
import Accessory5 from "./AvatarAccessory/Accessory5";
import Accessory6 from "./AvatarAccessory/Accessory6";


export const AvatarAccessory = ({n = 0}) => {

    const els = [
        Accessory6,
        Accessory5,
        Accessory4,
        Accessory3,
        Accessory2,
        Accessory1,
    ]

    return <g>
        {(els[n] && React.createElement(els[n])) || null}
    </g>
};
