import {createSlice} from "@reduxjs/toolkit";
import {AUDIO_ARPA_2, AUDIO_CONGRATS, AUDIO_DROP_PADLOCK, AUDIO_GREAT_JOB, AUDIO_JINGLE, AUDIO_JUMP, AUDIO_KEEP_GOING, AUDIO_NICE_JOB, AUDIO_OK, AUDIO_SWITCH_SCENE, AUDIO_TERRIFIC, AUDIO_TRANSITION_1, AUDIO_TRANSITION_2, AUDIO_TRANSITION_3, AUDIO_TRANSITION_4, AUDIO_WELL_DONE, AUDIO_WIN, AUDIO_YOU_DID_IT} from "../constants";

export const loadingScreenCmp = [
    'components.LoadingBar',
    'components.PresentationSplashText',
    'components.RollyRobot',
    AUDIO_ARPA_2,
];


export const loadingScreenStateSlice = createSlice({
    name: 'loadingScreenState',
    initialState: {
        loading: false,
        progress: 0,
        toPreload: loadingScreenCmp
    },
    reducers: {
        startLoading: state => {
            state.loading = true;
            state.progress = 0;
        },
        progress: (state, action) => {
            state.progress = action.payload;
        }
    }
});

export const {progress, startLoading} = loadingScreenStateSlice.actions;

export default loadingScreenStateSlice.reducer;
