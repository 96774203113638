import React from "react";

export const AvatarPlaceholder = () => {
    return <g>
        <path d="M314.8,231.9c9.4,3.4,18.7,4,28.1,1.1c21.4-6.5,31.6-22.5,36.7-43c4.9-19.6-1.2-36-13.6-50.9c-7.8-9.3-18.5-12-29.9-12.8
		c-5.9-0.4-11.9-0.1-17.2-0.1c-4.1-15.2-9.2-30.1-11.9-45.4c-5.2-29.7-23.1-52.2-40.4-75.2c-3.1-4.1-6.6-8-9.7-12.1
		c-17.9-24.3-41.9-40.2-69.7-50.8c-14.9-5.7-30.1-10.8-45-16.5c-20.3-7.9-41.9-8.8-63.1-12.1c-24.4-3.9-48.8-4-73.3-3
		c-3.8,0.2-5.6-0.7-5.2-4.9c0.5-4.9-1.1-11.3,1.5-14.4c2.1-2.5,8.9-1.2,13.7-1.2c4.8,0.1,9.8,0.1,14.4,1.2
		c7.6,1.8,14.2,0.1,20.2-4.2c1.6-1.1,2.4-3.8,2.9-6c2.2-8.9,4.1-17.8,6.4-26.6c0.3-1.2,1.6-2.5,2.7-3.2c6.7-3.8,13.4-7.5,20.2-11
		c29.4-15.1,54.4-36.4,79.7-57.3c5.9-4.9,11.9-9.7,17.2-15.2c10.1-10.4,19.8-21,29.4-31.8c2.2-2.4,4.5-5.5,2.5-9.5
		c-2.2-4.4-6-5.4-9.7-2.3c-2,1.7-3.6,3.9-5.4,5.8c-21.6,24-45.8,45.1-71.2,64.8c-3.6,2.8-7.5,5.1-10.6,8.3
		c-14.2,14.2-31.2,24.3-48.3,34.3c-0.8,0.5-1.8,0.8-3.8,1.7c0.6-4,1-7.2,1.6-10.3c5.5-25.9,10.6-51.9,16.7-77.6
		c4.9-20.7,11.4-41,16.3-61.8c4.2-17.6,7-35.5,10.4-53.3c0-0.2,0-0.3,0-0.5c1.3-12-0.1-14.6-12.3-16.4c-14.8-2.2-29.8-3.2-44.7-4.8
		c-3.3-0.3-6.5-0.7-10.3-1c0.6-4.1,1.2-7.7,1.5-11.3c0.8-9.8,1.7-19.6,2.2-29.3c1.4-31.3,2.7-62.6,4-93.8c0.4-9,0.7-18,0.9-27
		c0.1-5.1-2.2-8.2-5.9-8.5c-3.8-0.3-6.6,2.7-6.8,8.1c-0.6,15-1.3,30-1.6,44.9C34-473,34-443.5,33.4-414c-0.2,8.9-1.5,17.8-2.2,26.3
		c-28.7,1.6-57.1,3.2-85.9,4.9c-0.2-1.9-0.4-3.8-0.5-5.8c-1.4-28.8-2.7-57.6-4.2-86.4c-1.4-27.4-3-54.9-4.6-82.3
		c-0.2-3.5-1-6.6-5.3-6.3c-4.1,0.3-4.3,3.5-4.2,6.7c1.6,33.1,3.1,66.2,4.9,99.3c1.1,19.8,2.7,39.5,4.2,59.3
		c0.4,5.4,1.4,10.8,2.2,16.4c-9.8,1.5-19.5,2.3-28.8,4.6c-11.6,2.9-23,6.7-34.3,10.6c-6.9,2.4-8.6,6.4-6.7,13.4
		c9.3,34.9,18.9,69.7,27.9,104.7c4.4,17.1,7.3,34.5,11.7,51.6c2.8,10.9,7,21.5,10.6,32.2c0.6,1.9,1.1,3.7,2,6.8
		c-2.2-1-3.5-1.4-4.7-2.2c-18.4-11.7-36.9-23.3-55.1-35.3c-16.8-11.1-33.7-22-49.7-34.3c-11.3-8.7-21-19.3-31.2-29.3
		c-3.1-3-5.3-6.9-8.4-9.9c-1.1-1.1-3.7-1.4-5.2-0.9c-1,0.3-1.8,3.1-1.5,4.5c0.5,2.5,1.4,5.3,3,7.2c13,15.5,27.4,29.5,44.4,40.7
		c5.3,3.5,10.1,7.6,15.3,11.2c11.3,7.9,22.6,15.9,34.2,23.4c15.9,10.3,32.1,20.2,48.2,30.2c1.8,1.1,3.8,2.5,5.8,2.9
		c7.4,1.2,10.1,6.2,12.1,12.6c2.5,7.6,5.8,14.9,8.7,22.3c2.1,5.4,6,8.3,11.3,6.2c10-3.8,19.9-2.5,30-1.1c1.1,0.2,2.3,0.2,3.5,0.1
		c7.3-0.4,7.4-0.4,7.6,6.8c0.5,14.2,0.5,14.3-13.5,16.2c-41.4,5.6-80.1,18.7-117.1,38.2c-29.9,15.7-57.7,34.5-84.8,54.5
		c-6.7,4.9-12.4,11.1-18.4,16.8c-6.9,6.7-14.3,13.1-20.4,20.6c-7.2,8.9-13.7,18.4-19.7,28.2c-7.1,11.6-13.8,23.6-19.9,35.8
		c-3.7,7.5-6,15.7-9.1,24.2c-1.3-0.7-3-1.5-4.7-2.4c-6.7-3.9-14-4.7-21.2-2.3c-11,3.6-20.5,9.6-26.4,20.1
		c-10.6,18.9-16.4,38.9-14.1,60.8c3.5,32.1,30,49.7,61.5,40.3c0.6,7.4,1.4,14.6,1.6,21.8c0.9,35.7,6.9,70.6,16.4,104.9
		c6.9,24.8,18,47.6,33.3,68.4c11.4,15.4,22.5,31,34.4,45.9c15,18.9,33.1,34.2,55.3,44.3c9.9,4.5,19.4,9.8,29.6,13.3
		c24.9,8.5,49.9,16,77,13.7c13.5-1.1,27.3,0.4,40.9,1.8c40.2,4.1,79.8,0.7,119-8.7c10.8-2.6,21.9-4.2,32.8-6.8
		c7.1-1.7,14.1-4,20.8-6.8c11.5-4.8,23-9.7,34.1-15.5c21.8-11.3,42.3-24.3,60.5-41.2c18.9-17.4,31.5-38.8,44.2-60.6
		c15.3-26.3,26.5-54,30.2-84c2.8-22.7,2.9-45.8,3.8-68.8c0.4-11.6,0-23.3-0.1-35C314.5,233.9,314.7,233.1,314.8,231.9z M17-73.4
		c15.6,1,31.2,1.9,46.8,3.1c7.3,0.6,14.5,2,21.8,2.9c19.3,2.4,38.6,4.9,56.9,12.4c12.9,5.3,26.4,9.4,39.5,14.3
		C204.3-32.2,224.1-20,239.2-1c6,7.6,12.4,14.8,18.2,22.5c5.1,6.8,10.4,13.6,14.3,21c5.8,11,10.1,22.8,15.6,33.9
		c9.6,19.2,17.3,38.6,14.1,60.7c-0.5,3.3,0.1,7,3.7,7.8c2.7,0.6,5.9-0.7,8.6-1.7c3.3-1.2,6.4-4,9.7-4.3c6.4-0.4,13-0.1,19.4,1.1
		c11.5,2.1,24,17,24,28.7c0,13.3-3.6,25.6-11.7,36.4c-6.1,8.1-14.6,11.3-24.2,12c-7.2,0.6-12.6-2.3-16-8.8c-2-3.9-4.3-8-9.8-6.5
		c-4,1.1-5.4,4.8-4.6,11.3c0.1,1,0.4,2,0.5,3c0.9,11.1,2.1,22.2,2.6,33.3c0.4,7.8,0.3,15.7-0.1,23.5c-1,19.3-0.8,38.8-3.7,57.8
		c-4.2,27-11.7,53.2-25.9,77.2c-13.5,22.8-26.3,45.6-47.1,63.3c-19.8,16.9-40.7,31.6-64.7,41.7c-5.7,2.4-11.6,4.4-16.9,7.4
		c-11.9,6.9-24.6,10.8-38,13.1c-5.7,1-11.6,1.9-17.1,3.6C62.2,546,33.6,549.3,4.7,548c-31.6-1.4-63-5.4-94.6-6.6
		c-21.9-0.8-41.6-8.9-61.4-16.1c-21.1-7.7-40.2-19.4-57.1-35c-23-21.2-43-44.3-57.4-72.3c-15.8-30.9-25.9-63.6-31.4-97.6
		c-4.5-28.1-7.4-56.5-10.8-84.7c-0.5-3.7-1.3-7-5.1-8.4c-3.8-1.3-6.8,0.4-9.4,3.2c-4.3,4.7-9.5,5.1-15.1,3c-3.3-1.2-6.4-2.7-9.7-4
		c-8.9-3.6-13.4-10.5-15-19.7c-3-18,0.8-35.1,6.4-52c3.1-9.4,10.1-15.1,18.8-18.9c4.5-2,9.2-2.4,13.5,0.2c4.2,2.6,8.2,5.6,11.9,8.9
		c4.9,4.2,9.7,3,11.1-3.2c0.5-1.9,0.6-4,0.6-6c0.3-8.9,3.3-17,7.7-24.5c10-16.9,20.1-33.8,30.3-50.5c2.1-3.4,4.8-6.4,7.5-9.3
		c5.8-6,11.8-11.7,17.8-17.5c5.6-5.5,10.7-11.6,17-16.2c26.1-19,51.5-39,80.8-53.2c1.9-0.9,3.8-2.1,5.7-3.1
		C-86.6-61.6-36.9-75.4,17-73.4z M19.6-373.7c6.5,0.6,14.7,1.3,22.8,2.2c14.9,1.6,29.8,3.4,44.7,5.1c3.6,0.4,5.4,1.5,4.7,5.6
		c-1.5,8.9-2,17.9-3.9,26.6c-4,17.9-8.9,35.5-12.9,53.4c-6.6,29.4-13,58.8-18.8,88.4c-4,20.2-6.7,40.6-10.3,60.9
		c-1.5,8.3-2,8.3-10.5,8.8c-11.6,0.7-23.3,1.2-34.9,1.8c-6,0.3-12.1-0.3-17.9,0.9c-11.4,2.4-22-1.5-33-2.7c-1-0.1-2.2-1.9-2.7-3.2
		c-15.6-38-30.3-76.3-38.1-117c-4.4-22.8-11.7-45.1-17.6-67.6c-3.3-12.7-6.3-25.5-9.2-38.4c-0.5-2.4-2-5.5,1.8-6.7
		c9.3-3,18.5-7.1,28.1-8.6c15.3-2.5,30.8-3.4,46.2-4.7C-22-370.6-2-372,19.6-373.7z"/>
        <path className="st8" d="M19.6-373.7C-2-372-22-370.6-41.9-369c-15.4,1.3-30.9,2.3-46.2,4.7c-9.6,1.6-18.8,5.6-28.1,8.6
		c-3.8,1.2-2.4,4.4-1.8,6.7c2.9,12.8,5.9,25.6,9.2,38.4c5.8,22.5,13.2,44.8,17.6,67.6c7.8,40.7,22.4,79,38.1,117
		c0.5,1.3,1.7,3.1,2.7,3.2c10.9,1.2,21.6,5.1,33,2.7c5.8-1.2,11.9-0.6,17.9-0.9c11.6-0.6,23.3-1.1,34.9-1.8c8.5-0.5,9-0.6,10.5-8.8
		c3.7-20.3,6.4-40.7,10.3-60.9c5.8-29.5,12.3-59,18.8-88.4c4-17.9,8.9-35.5,12.9-53.4c1.9-8.7,2.4-17.8,3.9-26.6
		c0.7-4.2-1.2-5.2-4.7-5.6c-14.9-1.6-29.8-3.4-44.7-5.1C34.2-372.3,26.1-373,19.6-373.7z"/>
    </g>
};
