import React, {useState} from "react";
import {GameBtn} from "./GameBtn";
import {AvatarPartChooserBtn} from "./AvatarPartChooserBtn";
import {AvatarPrevPart} from "./AvatarPrevPart";
import {AvatarNextPart} from "./AvatarNextPart";
import {AvatarFace} from "./AvatarFace";
import {AvatarSkin} from "./AvatarSkin";
import {AvatarHeart} from "./AvatarHeart";
import {AvatarHat} from "./AvatarHat";
import {AvatarHair} from "./AvatarHair";
import {setPart} from "../../store/avatarSlice";
import {useDispatch} from "react-redux";
import {useAssetManager} from "../../hooks/useAssetManager";
import {AUDIO_JUMP, AUDIO_TRANSITION_1} from "../../constants";
import {AvatarAccessory} from "./AvatarAccessory";
import CloseButton from "./CloseButton";

export const AvatarPartChooserBackground = () => {
    return <g>
        <g height="766.5" width="769" x="-384.5" y="-383.2">
            <rect className="st5" height="753" width="755" x="-384.5" y="-383.2"/>
            <rect className="st6" height="753" width="755" x="-370.5" y="-369.8"/>
        </g>
    </g>
};
export const AvatarPartChooser = () => {

    const FaceChooser = ({n, ...props}) => <g transform="matrix(0.25, 0, 0, -0.25, -250, 237)" {...props}><AvatarFace n={n}/></g>;
    const AccessoryChooser = ({n, ...props}) => <g transform="matrix(0.25, 0, 0, -0.25, -250, 237)" {...props}><AvatarAccessory n={n}/></g>;
    const HairChooser = ({n, ...props}) => <g transform="matrix(0.25, 0, 0, -0.25, -250, 237)" {...props}><AvatarHair n={n}/></g>;
    const SkinChooser = ({n, ...props}) => <g transform="matrix(0.25, 0, 0, -0.25, -250, 237)" {...props}><AvatarSkin n={n}/></g>
    const HatChooser = ({n, ...props}) => <g transform="matrix(0.2, 0, 0, -0.2, -197, 123)" {...props}><AvatarHat n={n}/></g>
    const HeartChooser = ({n, ...props}) => <g transform="scale(4)" {...props}><AvatarHeart n={n}/></g>

    const [choosing, setChoosing] = useState(0);

    const partsAvailable = [
        SkinChooser,
        FaceChooser,
        HairChooser,
        AccessoryChooser,
        HatChooser,
        HeartChooser,
    ];

    const partModifiers = [
        "skin",
        "face",
        "hair",
        "accessory",
        "hat",
        "heart"
    ];

    const PartChooser = partsAvailable[choosing];

    const partsProps = [
        // "skin",
        [],
        // "face",
        [],
        // "hair",
        [
            {
                transform: "matrix(0.2, 0, 0, -0.2, -197, 171)"
            },
            {
                transform: "matrix(0.17, 0, 0, -0.17, -177, 151)"
            },
            {
                transform: "matrix(0.2, 0, 0, -0.2, -197, 161)"
            },
            {
                transform: "matrix(0.18, 0, 0, -0.18, -173, 196)"
            },
            {
                transform: "matrix(0.2, 0, 0, -0.2, -197, 171)"
            },
            {
                transform: "matrix(0.15, 0, 0, -0.15, -151, 104)"
            },
        ],
        // "accessory",
        [],
        // "hat",
        [
            {
                transform: "matrix(0.2, 0, 0, -0.2, -197, 171)"
            },
            {
                transform: "matrix(0.17, 0, 0, -0.17, -177, 151)"
            },
            {
                transform: "matrix(0.2, 0, 0, -0.2, -197, 131)"
            },
            {
                transform: "matrix(0.2, 0, 0, -0.2, -197, 103)"
            },
            {
                transform: "matrix(0.2, 0, 0, -0.2, -197, 171)"
            },
            {
                transform: "matrix(0.15, 0, 0, -0.15, -151, 104)"
            },
        ],
        // "heart"
        [],
    ]

    const partsAvailableLength = partsAvailable.length;

    const dispatch = useDispatch();

    const {get} = useAssetManager();

    return <g>

        <clipPath id="part-chooser-clip">
            <rect className="st6" height="753" width="755" x="850" y="157"/>

        </clipPath>

        <g height="766.5" transform="matrix(1 0 0 -1 1220.52 539.8652)" width="769" x="-384.5" y="-383.2">
            <AvatarPartChooserBackground/>
        </g>
        <g clipPath="url(#part-chooser-clip)">
            <GameBtn dropY={10} dropX={-10} onClick={() => {
                get(AUDIO_JUMP).play();
                dispatch(setPart({part: partModifiers[choosing], value: 0}))
            }}>
                <g height="230.4" transform="matrix(1 0 0 -1 1050.2697 290.624)" width="250.3" x="-125.2" y="-115.2">
                    <AvatarPartChooserBtn choose={PartChooser} n={0} chooseProps={partsProps?.[choosing]?.[0] ?? {}}/>
                </g>
            </GameBtn>
            <GameBtn dropY={10} dropX={-10} onClick={() => {
                get(AUDIO_JUMP).play();
                dispatch(setPart({part: partModifiers[choosing], value: 1}))
            }}>
                <g height="230.4" transform="matrix(1 0 0 -1 1375.2697 290.624)" width="250.3" x="-125.2" y="-115.2">
                    <AvatarPartChooserBtn choose={PartChooser} n={1} chooseProps={partsProps?.[choosing]?.[1] ?? {}}/>
                </g>
            </GameBtn>
            <GameBtn dropY={10} dropX={-10} onClick={() => {
                get(AUDIO_JUMP).play();
                dispatch(setPart({part: partModifiers[choosing], value: 2}))
            }}>
                <g height="230.4" transform="matrix(1 0 0 -1 1078.1029 533.124)" width="250.3" x="-125.2" y="-115.2">
                    <AvatarPartChooserBtn choose={PartChooser} n={2} chooseProps={partsProps?.[choosing]?.[2] ?? {}}/>
                </g>
            </GameBtn>
            <GameBtn dropY={10} dropX={-10} onClick={() => {
                get(AUDIO_JUMP).play();
                dispatch(setPart({part: partModifiers[choosing], value: 3}))
            }}>
                <g height="230.4" transform="matrix(1 0 0 -1 1403.1029 533.124)" width="250.3" x="-125.2" y="-115.2">
                    <AvatarPartChooserBtn choose={PartChooser} n={3} chooseProps={partsProps?.[choosing]?.[3] ?? {}}/>
                </g>
            </GameBtn>
            <GameBtn dropY={10} dropX={-10} onClick={() => {
                get(AUDIO_JUMP).play();
                dispatch(setPart({part: partModifiers[choosing], value: 4}))
            }}>
                <g height="230.4" transform="matrix(1 0 0 -1 1050.2697 775.624)" width="250.3" x="-125.2" y="-115.2">
                    <AvatarPartChooserBtn choose={PartChooser} n={4} chooseProps={partsProps?.[choosing]?.[4] ?? {}}/>
                </g>
            </GameBtn>
            <GameBtn dropY={10} dropX={-10} onClick={() => {
                get(AUDIO_JUMP).play();
                dispatch(setPart({part: partModifiers[choosing], value: 5}))
            }}>
                <g height="230.4" transform="matrix(1 0 0 -1 1375.2697 775.624)" width="250.3" x="-125.2" y="-115.2">
                    <AvatarPartChooserBtn choose={PartChooser} n={5} chooseProps={partsProps?.[choosing]?.[5] ?? {}}/>
                </g>
            </GameBtn>
        </g>

        <g>
            <GameBtn>
                <g onClick={() => {
                    get(AUDIO_TRANSITION_1).play();
                    setChoosing((choosing + partsAvailableLength - 1) % partsAvailableLength);
                }} height="116.9" transform="matrix(1 0 0 -1 857.4824 532.6627)" width="127.1" x="-63.5" y="-58.5">
                    <AvatarPrevPart/>
                </g>

            </GameBtn>

            <GameBtn>
                <g onClick={() => {
                    get(AUDIO_TRANSITION_1).play();
                    setChoosing((choosing + partsAvailableLength + 1) % partsAvailableLength);
                }} height="116.9" transform="matrix(1 0 0 -1 1619.4823 532.6627)" width="127.1" x="-63.5" y="-58.5">
                    <AvatarNextPart/>
                </g>
            </GameBtn>
            <g
                onClick={() => {
                    dispatch(setPart({part: partModifiers[choosing], value: -1}))
                }
                }
                style={{
                    transform: 'matrix(1, 0, 0, 1, 1175.48, 872.663)'
                }}>
                <GameBtn>
                    <CloseButton/>
                </GameBtn>
            </g>

        </g>
    </g>;
};
