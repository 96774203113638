//region scenes audio
import scene_01_audio from './scene_01.mp3';
import scene_02_audio from './scene_02.mp3';
import scene_03_audio from './scene_03.mp3';
import scene_04_audio from './scene_04.mp3';
import scene_05_audio from './scene_05.mp3';
import scene_06_audio from './scene_06.mp3';
import scene_07_audio from './scene_07.mp3';
import scene_08_audio from './scene_08.mp3';
import scene_09_audio from './scene_09.mp3';
import scene_10_audio from './scene_10.mp3';
import scene_11_audio from './scene_11.mp3';
import scene_12_audio from './scene_12.mp3';
import scene_13_audio from './scene_13.mp3';
import scene_14_audio from './scene_14.mp3';
//endregion


const all_audio = {
    audio_01: scene_01_audio,
    audio_02: scene_02_audio,
    audio_03: scene_03_audio,
    audio_04: scene_04_audio,
    audio_05: scene_05_audio,
    audio_06: scene_06_audio,
    audio_07: scene_07_audio,
    audio_08: scene_08_audio,
    audio_09: scene_09_audio,
    audio_10: scene_10_audio,
    audio_11: scene_11_audio,
    audio_12: scene_12_audio,
    audio_13: scene_13_audio,
    audio_14: scene_14_audio,
};
export default all_audio;
