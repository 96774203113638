import {idleStateActionListeners} from "./idleStateActionListeners";
import {loadingScreenActionListeners} from "./loadingScreenActionListeners";
import {loginScreenListeners} from "./loginScreenListeners";
import {welcomeScreenListeners} from "./welcomeScreenListeners";
import {avatarScreenListeners} from "./avatarScreenListeners";
import {levelSelectionListeners} from "./levelSelectionListeners";
import {levelSceneListeners} from "./levelSceneListeners";
import {answerSelectionListeners} from "./answerSelectionListeners";
import {levelEndListeners} from "./levelEndListeners";
import {TO_FULLSCREEN_STATE_ACTION} from "../constants";
import {makeSinglePipeFlow} from "../utils/classes/PipeCall";

const goToFullScreen = () => {
    if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
    ) {
        if (document.exitFullscreen) {
            document.exitFullscreen().then(r => r);
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    } else {
        const element = document.getElementById("fullscreen-container");
        if (element.requestFullscreen) {
            element.requestFullscreen().then(r => r);
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    }
}


export const gameFlowListeners = {
    '*': {
        [TO_FULLSCREEN_STATE_ACTION]: makeSinglePipeFlow(({configs: {stateManager: {toPreviousAction}}}) => {
                goToFullScreen();
                toPreviousAction();
            }
        ),
        // [DONT_WANT_LEAVE_STATE_ACTION]: makeSinglePipeFlow(({configs: {stateManager: {toPreviousAction}}}) => {
        //         toPreviousAction();
        //     }
        // ),
        ['logout']: makeSinglePipeFlow(({configs: {stateManager: {logout, resetAvatar}}}) => {
                logout();
                resetAvatar();
            }
        )
    },
    ...idleStateActionListeners,
    ...loginScreenListeners,
    ...loadingScreenActionListeners,
    ...welcomeScreenListeners,
    ...avatarScreenListeners,
    ...levelSelectionListeners,
    ...levelSceneListeners,
    ...answerSelectionListeners,
    ...levelEndListeners,
};



