import {createSlice} from "@reduxjs/toolkit";
import {asState, ENTERING_STATE_ACTION, IDLE_STATE_ACTION} from "../constants";
import {gameStateFlow} from "../constants/gameFlow";


const storePrevState = state => {
    state.prevAction = state.action;
    state.prevGroup = state.group;
};

const convertPayload = (payload, key = 'state') => {
    const payloadType = typeof payload;
    if (payloadType === 'string') {
        return {[key]: payload};
    } else if (payloadType === 'object') {
        return payload;
    }
}

const storeState = (actState, {storePrev = true, state = null, action = null, group = null, options = {}}) => {
    storePrev && storePrevState(actState);

    if (action) {
        actState.action = action;
    }

    if (group) {
        actState.group = group;
    }

    if (state) {
        const [group, actionS] = state.split(".");
        actState.action = actionS;
        actState.group = group;
    }

    actState.options = {
        ...actState.options,
        ...options,
    }
};

export const gameStateFlowSlice = createSlice({
    name: 'gameStateFlow',
    initialState: {
        group: IDLE_STATE_ACTION,
        action: ENTERING_STATE_ACTION,
        prevGroup: IDLE_STATE_ACTION,
        prevAction: ENTERING_STATE_ACTION,
        options: {},
        flow: gameStateFlow
    },
    reducers: {
        toPreviousAction: state => storeState(state, {action: state.prevAction, group: state.prevGroup, storePrev: false}),
        changeAction: (state, action) => storeState(state, convertPayload(action.payload, 'action')),
        changeGroup: (state, action) => storeState(state, convertPayload(action.payload, 'group')),
        changeState: (state, action) => storeState(state, convertPayload(action.payload)),
        setOptions: (state, action) => {
            state.options = {
                ...state.options,
                ...action.payload
            }
        },
        toNextState: (state) => {

            const {action, flow, group} = state;

            let nextPossibleState = flow?.[group]?.[action] ?? flow?.['*']?.[action];

            const nextPossibleStateType = typeof nextPossibleState;

            if (nextPossibleStateType === "object") {
                nextPossibleState = asState(nextPossibleState?.group ?? group, nextPossibleState?.action ?? ENTERING_STATE_ACTION);
            } else if (nextPossibleStateType === "string") {
                nextPossibleState = asState(group, nextPossibleState);
            }

            if (nextPossibleState) {
                storeState(state, {
                    state: nextPossibleState
                })
            }

        }
    }
});

export const {changeAction, changeState, toPreviousAction, setOptions, changeGroup, toNextState} = gameStateFlowSlice.actions;


export default gameStateFlowSlice.reducer;
