import {useSubscribingStates} from "../hooks/useSubscribingState";
import {useEventSender} from "../hooks/useEventSender";
import {useEffect} from "react";
import {asState} from "../constants";
import {getGameUUID} from "../utils/getGameUUID";
import * as moment from "moment";
import {getEventDataFlow} from "../utils/getEventDataFlow";
import {getListenerRepo} from "../repositories/listenersRepository";
import {PipeCall} from "../utils/classes/PipeCall";
import {SubscribingStateContext} from "./SubscribingStateContext";
import {useDebuggerR} from "../hooks/useDebuggerR";

export const SubscribingStateProvider = ({children}) => {
    const {writeD} = useDebuggerR("init SubscribedStateManager");

    const sub = useSubscribingStates();
    const eventSender = useEventSender();
    const {stateManager: {group, action}} = sub;

    useEffect(() => {

        writeD(`${group} - ${action}`,'states');

        const {stateManager: {forwardState, player: {classCode}, gameStateFlow: {prevGroup, prevAction}}} = sub

        if (prevGroup !== group || prevAction !== action) {
            const event = {
                messageType: asState(group, action),
                gameUUID: getGameUUID(),
                date: moment.now().toString(),
                data: getEventDataFlow(group, action, sub),
            };

            eventSender.sendEvent({event, classCode});
        }

        const lRepo = getListenerRepo();
        const generals = lRepo.getActionGroup('*', action); //storedListeners?.['*']?.[action] ?? [];
        const regulars = lRepo.getActionGroup(group, action); // storedListeners?.[group]?.[action] ?? [];


        generals?.map(el => {
            if (el instanceof PipeCall) {
                return el.execute(sub);
            }

            return el(sub);
        });

        if (regulars.length > 0) {
            regulars?.map(el => {

                if (el instanceof PipeCall) {
                    return el.execute(sub);
                }

                return el(sub);
            })
        } else {
            forwardState();
        }

    }, [group, action])


    return <SubscribingStateContext.Provider value={sub}>
        {children}
    </SubscribingStateContext.Provider>;
}
