import React from "react";
import {AvatarSkin} from "./AvatarSkin";
import {AvatarFace} from "./AvatarFace";
import {AvatarHair} from "./AvatarHair";
import {AvatarHat} from "./AvatarHat";
import {useSelector} from "react-redux";
import {AvatarAccessory} from "./AvatarAccessory";

export default function AvatarGameFrame() {
    const config = useSelector(({avatar}) => avatar);
    return <g data-name="avatar-game-frame">
        <path className="avatar-frame-1" d="M189.13,104.5A84.63,84.63,0,1,1,104.5,19.87a84.63,84.63,0,0,1,84.63,84.63m19.87,0A104.5,104.5,0,1,0,104.5,209,104.5,104.5,0,0,0,209,104.5"/>
        <circle className="avatar-frame-2" cx="108.5" cy="101.5" r="95.34"/>
        <g style={{
            transform: "translate(-130px, -100px) scale(0.45)"
        }}>

            <g transform="matrix(0.5 0 0 0.5 0 0)">
                <AvatarSkin n={config['skin']}/>
            </g>
            <g transform="scale(.5)">
                <AvatarFace n={config['face']}/>
            </g>
            <g transform="scale(.5)">
                <AvatarAccessory n={config['accessory']}/>
            </g>

            <g style={{transform: `scale(.5)`}}>
                <AvatarHair n={config['hair']}/>
            </g>

            <g transform="scale(.5)">
                <AvatarHat n={config['hat']}/>
            </g>

        </g>

    </g>;
}
