import {useAssetManager} from "../../hooks/useAssetManager";
import React from "react";
import {shallowEqual} from "@babel/types";

const AssetManager = {};
const ComponentManager = {};
const groupHandler = {
    get: (target, prop, receiver) => {
        ComponentProxy.group = prop;
        return ComponentProxy;
    }
}
const componentHandler = {
    get: (target, prop, receiver) => (props) => {

        const {get} = useAssetManager();

        const Component = get(`${target.group}.${prop}`);

        if (Component.isLoaded) {

            const Asset = Component.asset;

            return <Asset {...props}/>;
        } else {

            Component.load(() => {
            });

            return <div>loading</div>
        }

    }
    , shallowEqual
}
const AssetProxy = new Proxy(AssetManager, groupHandler);
const ComponentProxy = new Proxy(ComponentManager, componentHandler);

export const ComponentAssets = AssetProxy.components;
