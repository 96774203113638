import {useSelector} from "react-redux";
import {setAnswer, setAsFinished, setBackSound, setInteractions, setProgress, setReplaying} from "../store/generalStateSlice";
import {progress, startLoading} from "../store/loadingScreenStateSlice";
import {choose as chooseScene, next, prev, setCompleted, setUnlockable, setUnlocked, tryUnlock} from "../store/levelSelectionStateSlice";
import {muteScene, pause as pauseScene, play as playScene, recordProgress, restart as restartScene, setLoaded, unload, unmuteScene} from "../store/levelSceneScreenStateSlice";
import {choose as chooseAnswer, initSelection, listen, setListened} from "../store/answerSelectionScreenStateSlice";
import {setToolbar} from "../store/toolbarStateSlice";
import {mute, pause as pauseAudio, play as playAudio, resume as resumeAudio, stop as stopAudio, toMaxVolume, toMinVolume, unMute} from "../store/audioMixerStateSlice";
import {changeAction, changeGroup, changeState, setOptions, toPreviousAction} from "../store/gameStateFlowSlice";
import {useGameStateFlow} from "./useGameStateFlow";
import {clearToken, login, loginPlayer, logout, readPlayerInfo} from "../store/playerSlice";
import {chooseAvatar, resetAvatar} from "../store/avatarSlice";
import {initPlayerStateSave, readPlayerStateSave, updatePlayerStateSave} from "../store/asyncThunk/savePlayerState";
import {writeDebug} from "../store/debuggerSlice";


export const useGameState = () => {
    const gameState = useSelector(({generalState}) => generalState);
    const avatar = useSelector(({avatar}) => avatar);
    const loadingScreenState = useSelector(({loadingScreenState}) => loadingScreenState);
    const levelSelectionScreenState = useSelector(({levelSelectionScreenState}) => levelSelectionScreenState);
    const levelSceneScreenState = useSelector(({levelSceneScreenState}) => levelSceneScreenState);
    const answerSelectionScreenState = useSelector(({answerSelectionScreenState}) => answerSelectionScreenState);
    const toolbarState = useSelector(({toolbarState}) => toolbarState);
    const audioMixerState = useSelector(({audioMixerState}) => audioMixerState);
    const player = useSelector(({player}) => player);
    const [gameStateFlow, gameStateFlowActions] = useGameStateFlow();

    const {group, action, options} = gameStateFlow;
    const generalState = [group, action].join(".");

    return {
        audioMixerState,
        gameState,
        avatar,
        generalState,
        gameStateFlow,
        gameStateFlowActions,
        loadingScreenState,
        levelSelectionScreenState,
        levelSceneScreenState,
        answerSelectionScreenState,
        toolbarState,
        player,
        group,
        action,
        options,
        actions: {
            debuggerR: {
                writeDebug
            },
            general: {
                changeAction,
                changeGroup,
                changeState,
                setOptions,
                setProgress,
                setAnswer,
                setBackSound,
                setInteractions,
                toPreviousAction,
                setAsFinished,
                setReplaying,
                logout,
                resetAvatar,
            },
            loadingScreen: {
                progress,
                startLoading
            },
            levelSelectionScreen: {
                next,
                prev,
                choose: chooseScene,
                tryUnlock,
                setUnlocked,
                setUnlockable,
                setCompleted,
            },
            levelEndScreen: {
                setCompleted,
            },
            levelSceneScreen: {
                setLoaded,
                pause: pauseScene,
                play: playScene,
                unload,
                restart: restartScene,
                muteScene,
                unmuteScene,
                recordProgress,
            },
            avatarSelectionScreen: {
                chooseAvatar,
            },
            answerSelectionScreen: {
                initSelection,
                setListened,
                listen,
                choose: chooseAnswer,
                recordProgress,
            },
            toolbarState: {
                setToolbar,
            },
            audioMixerState: {
                mute,
                unMute,
                toMinVolume,
                toMaxVolume,
                play: playAudio,
                pause: pauseAudio,
                resume: resumeAudio,
                stop: stopAudio,
            },
            loginScreen: {
                login,
                loginPlayer,
                readPlayerInfo,
                initPlayerStateSave,
                updatePlayerStateSave,
                readPlayerStateSave,
                clearToken,
            }
        }
    }
}


