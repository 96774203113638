import React from "react";
import Hat1 from "./AvatarHats/Hat1";
import Hat2 from "./AvatarHats/Hat2";
import Hat3 from "./AvatarHats/Hat3";
import Hat4 from "./AvatarHats/Hat4";
import Hat5 from "./AvatarHats/Hat5";
import Hat6 from "./AvatarHats/Hat6";


export const AvatarHat = ({n=0}) => {

    const els = [
        Hat6,
        Hat5,
        Hat4,
        Hat3,
        Hat2,
        Hat1,
    ];

    return <g>
        {(els[n] && React.createElement(els[n])) || null}
    </g>
};
