import {useEffect, useState} from "react";
import {animated, useSprings} from "react-spring";
import {CompleteAnswerBtn} from "./CompleteAnswerBtn";

export default function AnswersChooser({speakers, onChoose, answers, listening, disabled, entered = [false, false, false]}) {

    const [choosed, setChoosedOr] = useState(null);
    const [whatListening, setListening] = useState(null);

    const setChoosed = (c) => {
        if (disabled) {
            return;
        }
        setChoosedOr(c)
    }


    // useEffect(() => {
    //     !listening && setListening(null);
    // },[listening])

    const fromPos = 1300;

    const els = [1, 2, 3];

    useEffect(() => {
        onChoose(choosed)
    }, [choosed])

    const enterSpring = useSprings(3, els.map((el, index) => ({
        to: {
            transform: `translate(0px, ${entered[index] ? 0 : fromPos}px)`
        },
        from: {
            transform: `translate(0px, ${fromPos}px)`
        },
        immediate: !entered[index]
    })));


    return <>
        <animated.g style={enterSpring[0]}>
            <CompleteAnswerBtn
                choosed={choosed}
                answers={answers}
                listening={listening}
                whatListening={whatListening}
                speakers={speakers}
                setChoosed={setChoosed}
                setListening={setListening}
                answPos={0}
            />
        </animated.g>
        <animated.g style={enterSpring[1]}>
            <CompleteAnswerBtn
                choosed={choosed}
                answers={answers}
                listening={listening}
                whatListening={whatListening}
                speakers={speakers}
                setChoosed={setChoosed}
                setListening={setListening}
                answPos={1}
            />
        </animated.g>
        <animated.g style={enterSpring[2]}>
            <CompleteAnswerBtn
                choosed={choosed}
                answers={answers}
                listening={listening}
                whatListening={whatListening}
                speakers={speakers}
                setChoosed={setChoosed}
                setListening={setListening}
                answPos={2}
            />
        </animated.g>
    </>;
}
