import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {initPlayerStateSave} from "./asyncThunk/savePlayerState";

const interceptor = token => config => {
    config.headers = {
        ...config.headers,
        "Authorization": `Bearer ${token}`,
        "Accept": "application/json",
    }
    return config;
};

let actualInterceptor;

export const setAxiosAuth = (token = '') => {
    typeof actualInterceptor === "number" && axios.interceptors.request.eject(actualInterceptor);
    actualInterceptor = axios.interceptors.request.use(interceptor(token));
}

const LOGIN_PLAYER_ACTION = createAction("player/login");
const READ_ME = createAction('player/me');


export const readPlayerInfo = createAsyncThunk(READ_ME.toString(), async () => {
    const response = await axios.get(`${process.env.REACT_APP_WEB_SERVICE_URL}/student/me`);

    return response.data;
})

export const loginPlayer = createAsyncThunk(LOGIN_PLAYER_ACTION.toString(),
    async ({
               nickname,
               classCode: class_code
           }, thunkAPI) => {
        const response = await axios.post(`${process.env.REACT_APP_WEB_SERVICE_URL}/student/gameAuth`, {nickname, class_code});

        if (response.data.token) {
            setAxiosAuth(response.data.token);
        }


        thunkAPI.dispatch(login({...response.data, classCode: class_code}));
        // setTimeout(() => {
        //     thunkAPI.dispatch(readPlayerInfo());
        // }, 500);

        return response.data;
    }
)

const initialState = {
    logged: false,
    submitting: false,
    token: null,
    error: false,
    info: null,
    classCode: null,
    stateId: null,
};
export const playerSlice = createSlice({
    name: 'player',
    initialState: initialState,
    reducers: {
        login: (state, action) => {
            state.logged = true;
        },
        logout: state => {
            state.token = null;
            state.logged = false;
        },
        clearToken: state => {
            state.token = null;
        }
    },
    extraReducers: builder => {
        builder.addCase(loginPlayer.pending, state => ({
                ...initialState,
                submitting: true,
            }
        ))
        builder.addCase(loginPlayer.fulfilled, (state, action) => {


            const classCode = action.meta.arg.classCode;

            const stateId = action.payload?.game_states?.filter(el => el?.code === classCode)?.[0]?.id;


            return ({
                ...initialState,
                logged: true,
                token: action.payload.token,
                classCode: action.meta.arg.classCode,
                stateId
            });
        });
        builder.addCase(loginPlayer.rejected, state => ({
            ...initialState,
            error: true
        }));
        builder.addCase(readPlayerInfo.fulfilled, (state, action) => {
            state.info = action.payload;
        });
        builder.addCase(initPlayerStateSave.fulfilled, (state, action) => {
            state.stateId = action.payload.id;
        })
    }
});


export const {login, logout, clearToken} = playerSlice.actions;


export default playerSlice.reducer;
