import React from "react";

export const AvatarNextPart = () => {
    return <g>
        <path className="st10" d="M-63.5,51.2l29.7-108.7c0.2-0.7,1-1.1,1.7-0.9L62.6-29c0.6,0.2,1,0.8,0.9,1.5L50.9,57.4
		c-0.1,0.7-0.7,1.1-1.4,1.1l-111.8-5.6C-63.1,52.9-63.7,52-63.5,51.2z"/>
        <path className="st11" d="M27.2,11.3L0.8,30.7c-7.4,5.4-13.5,3.2-14.6-4.8v-51.1c1.1-8,7.2-10.3,14.6-4.8l26.4,19.4
		C35.4-4.6,35.4,5.3,27.2,11.3z"/>
        <path className="st8" d="M26.3,23.3L-0.1,42.7c-7.4,5.4-13.5,3.2-14.6-4.8v-51.1c1.1-8,7.2-10.3,14.6-4.8L26.3,1.4
		C34.5,7.4,34.5,17.2,26.3,23.3z"/>
    </g>;
};
