import {NotOnGameStateCmp, OnGameStateCmp} from "../../context/GameStateContext";
import {AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE, EXITING_STATE_ACTION, GAME_END_SCREEN_STATE, IDLE_STATE_ACTION, LEVEL_END_SCREEN_STATE, LEVEL_SELECTION_SCREEN_STATE, LOADING_SCREEN_SCREEN_STATE, LOGIN_SCREEN_STATE, PRELOAD_STATE_ACTION, QUESTION_INTRO_SCREEN_STATE, WANT_EXIT_SCENE_STATE_ACTION, WANT_LEAVE_STATE_ACTION, WELCOME_SCREEN_SCREEN_STATE} from "../../constants";
import {GameBackground} from "../partials/GameBackground";
import {LoginScreen} from "../screens/LoginScreen";
import {LoadingScreen} from "../screens/LoadingScreen";
import WelcomeScreen from "../screens/WelcomeScreen";
import {AvatarChooser} from "../screens/AvatartChooser";
import LevelSelectionScreen from "../screens/LevelSelectionScreen";
import {LevelSceneScreen} from "../screens/LevelSceneScreen";
import {LevelEndScreen} from "../screens/LevelEndScreen";
import {GameEndScreen} from "../screens/GameEndScreen";
import {SplashLoader} from "../partials/SplashLoader";
import {ToolbarSection} from "../partials/ToolbarSection";
import React, {useEffect, useRef, useState} from "react";
import lottie from "lottie-web";
import {useAssetManager} from "../../hooks/useAssetManager";
import {useGameActionSetter} from "../../hooks/useGameActionSetter";
import {WantLeaveDialog} from "../partials/WantLeave";
import {WantExitSceneDialog} from "../partials/WantExitDialog";

export const QuestionIntroScreen = () => {

    const introRef = useRef();

    const {get} = useAssetManager();

    const exit = useGameActionSetter(EXITING_STATE_ACTION);

    const [exiting, setExiting] = useState(null);


    useEffect(() => {
        const prepareScene = (sceneAsset) => {
            const params = {
                container: introRef.current,
                renderer: 'svg',
                loop: false,
                autoplay: true,
                animationData: sceneAsset.asset,
                assetsPath: sceneAsset.assetsPath ?? ''
            };
            introRef.current?.children?.[0]?.remove();
            return lottie.loadAnimation(params);
        }

        const questionsIntro = get('questions.intro');
        if (introRef && (questionsIntro?.isLoaded ?? false)) {
            const anim = prepareScene(questionsIntro);

            setTimeout(exit, 4000);

            return () => anim.destroy();
        }

        return () => {
        };
    }, [introRef])


    return <svg viewBox="0 0 3840 2160">
        <g ref={introRef}/>
    </svg>;
}

export const Game = () => {
    return <div id="fullscreen-container" style={{width: '100%', height: '100%'}}>
        <svg viewBox="0 0 1920 1080" width="100%" height="100%">
            <NotOnGameStateCmp group={IDLE_STATE_ACTION} action={PRELOAD_STATE_ACTION}>
                <GameBackground/>
            </NotOnGameStateCmp>
            <OnGameStateCmp group={LOGIN_SCREEN_STATE}>
                <LoginScreen/>
            </OnGameStateCmp>
            <OnGameStateCmp group={LOADING_SCREEN_SCREEN_STATE}>
                <LoadingScreen/>
            </OnGameStateCmp>
            <OnGameStateCmp group={WELCOME_SCREEN_SCREEN_STATE}>
                <WelcomeScreen/>
            </OnGameStateCmp>
            <OnGameStateCmp group={AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE}>
                <AvatarChooser/>
            </OnGameStateCmp>
            <OnGameStateCmp group={LEVEL_SELECTION_SCREEN_STATE}>
                <LevelSelectionScreen/>
            </OnGameStateCmp>
            <LevelSceneScreen/>
            <OnGameStateCmp group={QUESTION_INTRO_SCREEN_STATE}>
                <QuestionIntroScreen/>
            </OnGameStateCmp>
            <OnGameStateCmp group={LEVEL_END_SCREEN_STATE}>
                <LevelEndScreen/>
            </OnGameStateCmp>
            <OnGameStateCmp group={GAME_END_SCREEN_STATE}>
                <GameEndScreen/>
            </OnGameStateCmp>
            <OnGameStateCmp action={PRELOAD_STATE_ACTION} group={IDLE_STATE_ACTION}>
                <SplashLoader/>
            </OnGameStateCmp>
            <svg viewBox="0 0 1920 1080">
                <ToolbarSection/>
            </svg>
            <OnGameStateCmp action={WANT_LEAVE_STATE_ACTION}>
                <WantLeaveDialog/>
            </OnGameStateCmp>
            <OnGameStateCmp action={WANT_EXIT_SCENE_STATE_ACTION}>
                <WantExitSceneDialog/>
            </OnGameStateCmp>
        </svg>
    </div>

}
