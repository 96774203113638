import {argsCall, changeActionCall, changeStateCall, nextStateCall, singleCall} from "../stateManagerUtilities";
import {identity} from 'lodash';
import {PipeCallExecutor} from "./PipeCallExecutor";

export class PipeCall {
    _toCall = [];
    _toCallArgs = [];
    options = {
        forward: false,
        forwardArgs: {}
    };

    constructor(options) {
        this.options = {
            ...this.options,
            ...options
        };
        this._executor = new PipeCallExecutor();
    }

    static make(options) {
        return new PipeCall(options);
    }

    _addCall(call, ...args) {
        this._toCall.push(call);
        this._toCallArgs.push(args);
    }

    preload() {
        this._addCall('preloadCall');
        return this;
    }

    toNextState() {
        this._addCall('nextStateCall');
        return this;
    }

    changeState() {
        this._addCall('changeStateCall');
        return this;
    }

    changeAction() {
        this._addCall('changeActionCall');
        return this;
    }

    delayChangeActionCall(time) {
        this._addCall('delayChangeActionCall', time);
        return this;
    }

    conditionalCall(key, trueCall, falseCall, ...othersCall) {
        this._addCall('conditionalCall', key, trueCall, falseCall, ...othersCall);

        return this;
    }

    extractData(extractionConfigs, then = identity) {
        this._addCall('extractData', extractionConfigs, then);
        return this;
    }

    extractConfigs(extractionConfigs, then = identity) {
        this._addCall('extractConfigs', extractionConfigs, then);
        return this;
    }

    condition(key, extractionConfigs, dataExtraction = null) {

        if (dataExtraction) {
            this._addCall('extractData', dataExtraction);
        }

        this._addCall('condition', key, extractionConfigs);

        return this;
    }

    /**
     *
     * @param configurator
     * @return {PipeCall}
     */
    setArgs(configurator) {
        this._addCall('argsCall', configurator);
        return this;
    }

    playAudio(audio, options = null) {
        this._addCall('playAudio', audio, options);
        return this;
    }

    /**
     * @param {*} toCall
     * @param {Function} argsConfigurator
     * @return {PipeCall}
     */
    callFn(toCall, argsConfigurator = identity) {

        if (Array.isArray(toCall)) {
            toCall.map((el, pos) => this._addCall('singleCall', el, argsConfigurator?.[pos] ?? identity));
        } else {
            this._addCall('singleCall', toCall, argsConfigurator);
        }

        return this;
    }

    logCall(toLog) {
        this._addCall('logCall', toLog);
        return this;
    }

    forward() {
        this._addCall('forwardCall');

        return this;
    }

    add(call) {
        this._addCall('directCall', call);
        return this;
    }

    execute(configs) {


        this._executor.withConfigs(configs);

        this._toCall.map((call, pos) => {
            let result = {};


            const executorCall = this._executor?.[call]?.bind(this._executor);
            if (executorCall) {
                result = executorCall?.(...this._toCallArgs[pos]);
            } else {
                throw new Error(`${call} not found in executor`)
            }

        });


        if (this.options.forward) {
            this._executor.argsCall.bind(this._executor)({...this.options.forwardArgs});
            this._executor.forwardCall.bind(this._executor)();
        }
    }
}

export const makePipeFlow = (options) => PipeCall.make(options);
export const makeSinglePipeFlow = (call,options) => PipeCall.make(options).add(call);

export const DIRECT_FORWARD = {forward: true};
export const HALF_SEC_FORWARD = {forward: true, forwardArgs: {delay: 500}};
export const ONE_SEC_FORWARD = {forward: true, forwardArgs: {delay: 1000}};
