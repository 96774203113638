import {useDispatch, useSelector} from "react-redux";
import {animated, useSprings} from "react-spring";
import {GameBtn} from "./GameBtn";
import CloseButton from "./CloseButton";
import SoundSwitchButton from "./SoundSwitchButton";
import HomeButton from "./HomeButton";
import FullScreenButton from "./FullScreenButton";
import ReplayButton from "./ReplayButton";
import React, {useState} from "react";
import {useGameActionSetter} from "../../hooks/useGameActionSetter";
import {logout} from "../../store/playerSlice";
import LogoutButton from "./LogoutButton";
import {resetAvatar} from "../../store/avatarSlice";
import {WANT_EXIT_SCENE_STATE_ACTION, WANT_LEAVE_STATE_ACTION} from "../../constants";

export const ToolbarSection = ({exiting = false}) => {
    const goBack = useGameActionSetter(WANT_EXIT_SCENE_STATE_ACTION);
    const toFullscreen = useGameActionSetter('toFullscreen');
    const switchingSound = useGameActionSetter('switchingSound');
    const toHome = useGameActionSetter('toHome');
    const askReplay = useGameActionSetter('askReplay');
    const logoutAction = useGameActionSetter(WANT_LEAVE_STATE_ACTION);

    const {canInteract} = useSelector(({generalState}) => generalState);

    const btnBarPos = [
        [1653.9, 67.19, "close"],
        [281.13, 68.11, "sound"],
        [160.66, 67.19, "home"],
        [399.28, 68.11, "full"],
        [515.81, 67.19, "replay"],
        [515.81, 67.19, "logout"],
    ];


    const statT = useSelector(({toolbarState}) => toolbarState);

    const springs = useSprings(
        btnBarPos.length,
        btnBarPos.map(item => {
            return {
                delay: (exiting || !statT[item[2]]) ? 0 : 500,
                to: {
                    transform: `translate(${item[0]}px,${(exiting || !statT[item[2]]) ? -100 : item[1]}px)`
                },
                from: {
                    transform: `translate(${item[0]}px,${!statT[item[2]] ? -100 : (exiting && statT[item[2]]) ? item[1] : -100}px)`
                }
            };
        })
    );

    const [soundOn, setSoundOn] = useState(true);



    return <g>
        {
            <animated.g onClick={() => canInteract && goBack()} width="96" height="88.34" style={springs[0]}>
                <GameBtn>
                    <CloseButton/>
                </GameBtn>
            </animated.g>
        }
        {
            <GameBtn>
                <animated.g onClick={() => {
                    canInteract && setSoundOn(!soundOn);
                    return canInteract && switchingSound();
                }} width="94" height="86.5" style={springs[1]}>
                    <SoundSwitchButton soundOn={soundOn}/>
                </animated.g>
            </GameBtn>
        }
        {
            <animated.g onClick={() => canInteract && toHome()} width="96" height="88.34" style={springs[2]}>
                <GameBtn>
                    <HomeButton/>
                </GameBtn>
            </animated.g>
        }
        {
            <animated.g onClick={() => canInteract && toFullscreen()} width="94" height="86.5" style={springs[3]}>
                <GameBtn>
                    <FullScreenButton/>
                </GameBtn>
            </animated.g>
        }
        {
            <animated.g onClick={() => canInteract && askReplay()} width="96" height="88.34" style={springs[4]}>
                <GameBtn>
                    <ReplayButton/>
                </GameBtn>
            </animated.g>
        }
        {
            <animated.g onClick={() => {
                if (canInteract) {
                    logoutAction();
                }
            }} width="96" height="88.34" style={springs[5]}>
                <GameBtn>
                    <LogoutButton/>
                </GameBtn>
            </animated.g>
        }
    </g>

}
