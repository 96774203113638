import {EXITED_STATE_ACTION, LOADING_SCREEN_SCREEN_STATE, PRELOAD_STATE_ACTION, PRELOADING_STATE_ACTION} from "../constants";
import {loadingScreenCmp} from "../store/loadingScreenStateSlice";
import {welcomeScreenCmp} from "../utilities/toLoad";
import {DIRECT_FORWARD, makePipeFlow} from "../utils/classes/PipeCall";

export const loadingScreenActionListeners = {
    [LOADING_SCREEN_SCREEN_STATE]: {
        [PRELOAD_STATE_ACTION]: makePipeFlow()
            .add(() => console.log(PRELOAD_STATE_ACTION, {loadingScreenCmp}))
            .setArgs({batchLoad: loadingScreenCmp})
            .preload(),
        [PRELOADING_STATE_ACTION]: makePipeFlow()
            .add(() => console.log(PRELOADING_STATE_ACTION, {welcomeScreenCmp}))
            .extractConfigs({progress: 'stateManager'})
            .setArgs(({data: {progress}}) => ({handleProgress: progress, handleFinish: () => progress(100), batchLoad: welcomeScreenCmp}))
            .preload(),
        [EXITED_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD),
    }
};
