//region answers audio
import answer_1_1_audio from './1-1.mp3';
import answer_1_2_audio from './1-2.mp3';
import answer_1_3_audio from './1-3.mp3';
import answer_2_1_audio from './2-1.mp3';
import answer_2_2_audio from './2-2.mp3';
import answer_2_3_audio from './2-3.mp3';
import answer_3_1_audio from './3-1.mp3';
import answer_3_2_audio from './3-2.mp3';
import answer_3_3_audio from './3-3.mp3';
import answer_4_1_audio from './4-1.mp3';
import answer_4_2_audio from './4-2.mp3';
import answer_4_3_audio from './4-3.mp3';
import answer_5_1_audio from './5-1.mp3';
import answer_5_2_audio from './5-2.mp3';
import answer_5_3_audio from './5-3.mp3';
import answer_6_1_audio from './6-1.mp3';
import answer_6_2_audio from './6-2.mp3';
import answer_6_3_audio from './6-3.mp3';
import answer_7_1_audio from './7-1.mp3';
import answer_7_2_audio from './7-2.mp3';
import answer_7_3_audio from './7-3.mp3';
import answer_8_1_audio from './8-1.mp3';
import answer_8_2_audio from './8-2.mp3';
import answer_8_3_audio from './8-3.mp3';
import answer_9_1_audio from './9-1.mp3';
import answer_9_2_audio from './9-2.mp3';
import answer_9_3_audio from './9-3.mp3';
import answer_10_1_audio from './10-1.mp3';
import answer_10_2_audio from './10-2.mp3';
import answer_10_3_audio from './10-3.mp3';
import answer_11_1_audio from './11-1.mp3';
import answer_11_2_audio from './11-2.mp3';
import answer_11_3_audio from './11-3.mp3';
import answer_12_1_audio from './12-1.mp3';
import answer_12_2_audio from './12-2.mp3';
import answer_12_3_audio from './12-3.mp3';
import answer_13_1_audio from './13-1.mp3';
import answer_13_2_audio from './13-2.mp3';
import answer_13_3_audio from './13-3.mp3';
import answer_14_1_audio from './14-1.mp3';
import answer_14_2_audio from './14-2.mp3';
import answer_14_3_audio from './14-3.mp3';
//endregion


const all_audio = {
    audio_1_1: answer_1_1_audio,
    audio_1_2: answer_1_2_audio,
    audio_1_3: answer_1_3_audio,
    audio_2_1: answer_2_1_audio,
    audio_2_2: answer_2_2_audio,
    audio_2_3: answer_2_3_audio,
    audio_3_1: answer_3_1_audio,
    audio_3_2: answer_3_2_audio,
    audio_3_3: answer_3_3_audio,
    audio_4_1: answer_4_1_audio,
    audio_4_2: answer_4_2_audio,
    audio_4_3: answer_4_3_audio,
    audio_5_1: answer_5_1_audio,
    audio_5_2: answer_5_2_audio,
    audio_5_3: answer_5_3_audio,
    audio_6_1: answer_6_1_audio,
    audio_6_2: answer_6_2_audio,
    audio_6_3: answer_6_3_audio,
    audio_7_1: answer_7_1_audio,
    audio_7_2: answer_7_2_audio,
    audio_7_3: answer_7_3_audio,
    audio_8_1: answer_8_1_audio,
    audio_8_2: answer_8_2_audio,
    audio_8_3: answer_8_3_audio,
    audio_9_1: answer_9_1_audio,
    audio_9_2: answer_9_2_audio,
    audio_9_3: answer_9_3_audio,
    audio_10_1: answer_10_1_audio,
    audio_10_2: answer_10_2_audio,
    audio_10_3: answer_10_3_audio,
    audio_11_1: answer_11_1_audio,
    audio_11_2: answer_11_2_audio,
    audio_11_3: answer_11_3_audio,
    audio_12_1: answer_12_1_audio,
    audio_12_2: answer_12_2_audio,
    audio_12_3: answer_12_3_audio,
    audio_13_1: answer_13_1_audio,
    audio_13_2: answer_13_2_audio,
    audio_13_3: answer_13_3_audio,
    audio_14_1: answer_14_1_audio,
    audio_14_2: answer_14_2_audio,
    audio_14_3: answer_14_3_audio,
};
export default all_audio;
