import React from "react";
import {gameFlowListeners} from "../../gameFlow/gameFlowListeners";
import {SubscribingStateProvider} from "../../context/SubscribingStateProvider";
import {useDebuggerR} from "../../hooks/useDebuggerR";


export const SubscribedStateManager = ({children}) => {
    useDebuggerR("init SubscribedStateManager");

    return <SubscribingStateProvider>
        {React.cloneElement(children, {listeners: gameFlowListeners})}
    </SubscribingStateProvider>;
}
