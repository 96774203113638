import {createSlice} from "@reduxjs/toolkit";
import * as moment from "moment";

export const debuggerSlice = createSlice({
    name: "debugger",
    initialState: {
        general: []
    },
    reducers: {
        writeDebug: (state, action) => {
            const type = action.payload?.type ?? 'general';
            return {
                ...state,
                [type]: [...(state?.[type] ?? []), {
                    time: moment().format("HH:mm:ss.SSS"),
                    text: `${action.payload?.text}` ?? action.payload
                }].slice(-20)
            }
        }
    }
});


export const {writeDebug} = debuggerSlice.actions;


export default debuggerSlice.reducer;
