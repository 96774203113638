import React, {createRef, useEffect, useRef, useState} from "react";
import '../../assets/scss/finale.scss';
import {GameBtn} from "../partials/GameBtn";
import {useAssetManager} from "../../hooks/useAssetManager";
import lottie from "lottie-web";
import {EXIT_STATE_ACTION, PLAY_AGAIN_STATE_ACTION} from "../../constants";
import {animated, useSpring} from "react-spring";
import {useGameActionSetter} from "../../hooks/useGameActionSetter";


const PlayAgainBtn = () => {

    return <g transform="matrix(1 0 0 -1 0 0)">
        <g>
            <path className="finale-5" d="M-132.3,51.1l29.7-108.5c0.2-0.7,1-1.1,1.7-0.9l232.4,29.4c0.6,0.2,1,0.8,0.9,1.5l-12.6,84.7
			c-0.1,0.7-0.7,1.1-1.4,1.1l-249.5-5.6C-132,52.7-132.6,51.9-132.3,51.1z"/>
            <g>
                <path className="finale-6" d="M-82,7L-82,7c0-0.3,0-0.6,0-0.8s0-0.5,0-0.7c-0.2-1.8-0.6-3.4-1.3-4.9C-84.1-0.8-85-2.1-86-3.1
				s-2.2-1.8-3.5-2.2c-1.3-0.5-2.6-0.5-3.9-0.2c0-0.5,0-1,0-1.5c0-0.5,0-1.2,0-2.2s0-2.2,0-3.8c0-1.6,0-3.7,0.1-6.4v-0.1
				c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-0.4-0.1-0.9-0.2-1.5-0.3s-1.2-0.1-1.9-0.1c-0.7,0-1.3,0-1.9,0c-0.6,0-1.2,0.1-1.7,0.2
				c-0.1,2.7-0.1,5.6-0.1,8.8c0,3.2,0,6.4,0,9.7s0,6.7,0,10c0,3.4-0.1,6.6-0.1,9.6c1.1,0.4,2.4,0.7,3.7,0.9c1.3,0.2,2.7,0.2,4,0.1
				s2.7-0.5,3.9-1c1.3-0.5,2.4-1.2,3.4-2.2c1-1,1.8-2.2,2.4-3.7C-82.5,10.9-82.1,9.1-82,7z M-89.3,9.3c-0.7,0.7-1.5,1.2-2.4,1.4
				c-0.9,0.2-1.8,0.2-2.7-0.1c-0.1,0-0.1,0-0.1-0.1c0-0.7,0-1.4,0-2.1c0-0.7,0-1.5,0-2.2c0-0.7,0-1.4,0.1-2.1s0.1-1.3,0.3-1.9h0.1
				c0.4,0,0.9,0.1,1.4,0.1c0.5,0,1,0.1,1.5,0.1c0.5,0.1,1,0.2,1.4,0.4c0.5,0.2,0.9,0.5,1.2,1c0.2,0.4,0.4,0.9,0.5,1.4
				c0.1,0.5,0.1,1,0,1.6c-0.1,0.5-0.2,1-0.4,1.4C-88.7,8.7-89,9-89.3,9.3z"/>
                <path className="finale-6" d="M-58.7-15.2c0.1-1.3,0.1-2.3,0.1-3c0-0.7,0-1.2-0.1-1.5c-0.1-0.3-0.2-0.5-0.4-0.5s-0.4,0-0.7,0
				c-0.3,0-0.7,0-1.2,0c-0.5,0-1.3-0.1-2.5-0.1s-2.7-0.1-4.7-0.1c-2,0-4.7,0-8,0.1v1c0.1,2.8,0.2,5.3,0.2,7.5
				c0.1,2.2,0.1,4.3,0.1,6.3c0,1.9,0,3.8,0,5.6s0,3.7,0,5.5c0,1.9-0.1,3.9-0.1,6c0,2.1-0.1,4.5-0.1,7.1c0.3,0.1,0.7,0.3,1.3,0.4
				s1.2,0.1,1.9,0.1c0.7,0,1.3-0.1,2-0.2c0.6-0.1,1.2-0.3,1.5-0.5c-0.1-2.4-0.1-4.9-0.1-7.4c0-2.5,0-5.1,0.1-7.6
				c0-2.5,0-5.1,0.1-7.6c0-2.5,0-5,0-7.3v-0.1c0.2,0.1,0.6,0.1,1.1,0.1c0.5,0,1.2,0,1.9-0.1c0.7,0,1.5-0.1,2.3-0.1s1.6-0.1,2.3-0.1
				c0.7,0,1.4-0.1,2-0.1c0.6,0,1,0,1.2,0c0-0.4,0-0.8,0-1.2c0-0.4,0-0.7-0.1-1.1C-58.7-14.5-58.7-14.9-58.7-15.2z"/>
                <path className="finale-6" d="M-38.6-0.8l3.1-19.4v-0.1c-0.8-0.2-1.7-0.3-2.7-0.2c-1,0.1-1.9,0.2-2.8,0.5h0c-0.2,0.6-0.3,1.3-0.5,2.1
				c-0.2,0.8-0.3,1.6-0.5,2.5c-0.2,0.8-0.3,1.7-0.5,2.4c-0.2,0.8-0.3,1.4-0.5,2c-0.4,0.1-0.6,0.1-0.8,0.1c-0.2,0-0.4,0-0.5,0
				s-0.3,0-0.5,0c-0.2,0-0.4,0-0.7,0c-0.2-0.6-0.4-1.3-0.5-2.1c-0.1-0.8-0.2-1.6-0.4-2.5c-0.1-0.9-0.3-1.7-0.4-2.5s-0.3-1.5-0.6-2.2
				c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-0.2-0.1-0.8-0.1-1.7,0c-0.9,0.1-2.3,0.1-4.2,0.1c0,0-0.1,0-0.1,0.1c0.1,0.3,0.2,1,0.3,2.2
				s0.3,2.7,0.6,4.6c0.2,1.9,0.5,4.1,0.9,6.5c0.3,2.5,0.8,5.1,1.2,7.8c0.5,2.8,1,5.6,1.5,8.6s1.2,6,1.8,8.9c0.2,0,0.4,0.1,0.6,0.1
				s0.5,0,0.9,0s0.8,0,1.4,0c0.6,0,1.3,0.1,2.2,0.2c0.1-0.5,0.2-1.2,0.4-2.2c0.2-1,0.4-2.1,0.6-3.3s0.4-2.6,0.7-4
				c0.2-1.4,0.5-2.8,0.7-4s0.4-2.5,0.6-3.5C-38.8,0.7-38.7-0.1-38.6-0.8z M-42.8-4.4c-0.3,3.2-0.5,5.6-0.7,7.4s-0.4,3-0.5,3.6
				c-0.2,0.6-0.3,0.8-0.5,0.4C-44.7,6.7-44.8,6-45,5c-0.1-1.1-0.3-2.4-0.4-4c-0.2-1.6-0.3-3.4-0.5-5.3c0.5,0,1,0,1.5,0
				S-43.3-4.4-42.8-4.4z"/>
                <path className="finale-6" d="M-12,18.2L-12,18.2c-0.8-3.4-1.7-6.6-2.7-9.8c-1.1-3.2-2.1-6.3-3.3-9.5c-1.1-3.2-2.2-6.3-3.3-9.5
				c-1.1-3.2-2-6.4-2.9-9.6c-0.2,0-0.3,0.1-0.5,0.1s-0.3,0-0.5,0c-0.6,0-1.2,0-1.9-0.1c-0.7-0.1-1.4-0.1-2-0.1c-0.2,0-0.3,0-0.5,0
				c-0.2,0-0.3,0-0.5,0.1h-0.1c0.7,3,1.6,6.1,2.5,9.4s1.9,6.5,2.9,9.8V-1c-0.3,1.6-0.7,3.2-1.2,4.8c-0.5,1.6-1,3.2-1.5,4.8
				c-0.5,1.6-1,3.2-1.5,4.8c-0.5,1.6-0.8,3.2-1.1,4.7c0.6,0,1.1,0.1,1.7,0.2c0.6,0.1,1.2,0.2,1.8,0.2c0.3,0,0.7,0,1-0.1
				c0.3-0.1,0.7-0.2,1-0.4c0.1-0.8,0.3-1.8,0.6-2.9c0.3-1.1,0.5-2.2,0.8-3.4s0.6-2.2,0.9-3.3c0.3-1,0.6-1.9,0.9-2.6
				c0.3,0.7,0.6,1.5,0.9,2.6c0.3,1.1,0.6,2.2,0.9,3.3c0.3,1.1,0.6,2.3,0.9,3.4c0.3,1.1,0.5,2,0.8,2.8c0.5,0.1,1.2,0.2,2,0.3
				c0.8,0.1,1.6,0.1,2.3,0.1c0.3,0,0.6,0,0.9,0C-12.4,18.3-12.2,18.2-12,18.2z"/>
                <path className="finale-6" d="M19-0.8l3.1-19.4v-0.1c-0.8-0.2-1.7-0.3-2.7-0.2c-1,0.1-1.9,0.2-2.8,0.5h0c-0.2,0.6-0.3,1.3-0.5,2.1
				c-0.2,0.8-0.3,1.6-0.5,2.5c-0.2,0.8-0.3,1.7-0.5,2.4c-0.2,0.8-0.3,1.4-0.5,2c-0.4,0.1-0.6,0.1-0.8,0.1c-0.2,0-0.4,0-0.5,0
				s-0.3,0-0.5,0c-0.2,0-0.4,0-0.7,0c-0.2-0.6-0.4-1.3-0.5-2.1c-0.1-0.8-0.2-1.6-0.4-2.5c-0.1-0.9-0.3-1.7-0.4-2.5s-0.3-1.5-0.6-2.2
				c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-0.2-0.1-0.8-0.1-1.7,0c-0.9,0.1-2.3,0.1-4.2,0.1c0,0-0.1,0-0.1,0.1c0.1,0.3,0.2,1,0.3,2.2
				s0.3,2.7,0.6,4.6c0.2,1.9,0.5,4.1,0.9,6.5C6.2-4.4,6.7-1.7,7.1,1c0.5,2.8,1,5.6,1.5,8.6s1.2,6,1.8,8.9c0.2,0,0.4,0.1,0.6,0.1
				s0.5,0,0.9,0s0.8,0,1.4,0c0.6,0,1.3,0.1,2.2,0.2c0.1-0.5,0.2-1.2,0.4-2.2c0.2-1,0.4-2.1,0.6-3.3s0.4-2.6,0.7-4
				c0.2-1.4,0.5-2.8,0.7-4s0.4-2.5,0.6-3.5C18.8,0.7,18.9-0.1,19-0.8z M14.8-4.4c-0.3,3.2-0.5,5.6-0.7,7.4s-0.4,3-0.5,3.6
				c-0.2,0.6-0.3,0.8-0.5,0.4C12.9,6.7,12.8,6,12.6,5c-0.1-1.1-0.3-2.4-0.4-4c-0.2-1.6-0.3-3.4-0.5-5.3c0.5,0,1,0,1.5,0
				S14.3-4.4,14.8-4.4z"/>
                <path className="finale-6" d="M35.7-0.9c0.4,0.3,0.9,0.5,1.8,0.5c0.8,0.1,1.7,0.1,2.6,0c0.9,0,1.8-0.1,2.6-0.2c0.8-0.1,1.3-0.1,1.6-0.1
				h0.1l0-0.1c0.2-2,0.3-4,0.3-6.2c0.1-2.2-0.1-4.3-0.3-6.3c-0.1-1.9-0.6-3.3-1.3-4.3c-0.7-1-1.6-1.8-2.7-2.3
				c-0.7-0.3-1.4-0.5-2.2-0.7c-0.7-0.2-1.5-0.4-2.3-0.4c-0.8-0.1-1.5-0.1-2.3,0s-1.4,0.4-2.1,0.7c-1.1,0.6-2,1.5-2.5,2.5
				c-0.6,1.1-1,2.4-1.4,4c-0.1,0.4-0.2,1-0.3,1.7c-0.1,0.7-0.2,1.4-0.2,2.2C27.1-8.7,27-7.6,27-6.6c-0.1,1-0.1,1.9-0.1,2.9
				c0,0.9-0.1,1.9-0.1,3c0,1.1,0,2.3,0,3.6c0,1.2,0.1,2.4,0.1,3.6c0.1,1.2,0.2,2.4,0.3,3.6c0.1,1.2,0.4,2.4,0.7,3.5
				c0.3,1.1,0.9,2.1,1.7,2.8c0.6,0.6,1.2,1.1,1.9,1.4c0.7,0.3,1.4,0.5,2.2,0.7c1.5,0.3,3,0.2,4.4-0.2c1.4-0.4,2.6-1.1,3.6-2.1
				c1-1,1.7-2.3,2.2-4c0.5-1.6,0.7-3.6,0.5-5.8l0-0.3c-0.1-0.1-0.2-0.2-0.4-0.2s-0.5,0-1-0.1c-0.4,0-0.8,0-1.1,0s-0.6,0-0.9,0
				s-0.6,0-0.9,0.1c-0.3,0-0.7,0.1-1.1,0.2c-0.1,0.4-0.2,0.8-0.4,1.4c-0.2,0.5-0.3,1-0.6,1.5c-0.2,0.5-0.5,0.9-0.8,1.2
				c-0.3,0.4-0.7,0.5-1.1,0.5c-0.5,0-0.9,0.1-1.4,0.1S34,10.5,33.6,10c-0.1-0.1-0.1-0.3-0.2-0.6c-0.1-0.3-0.2-0.6-0.2-0.9
				c-0.2-0.9-0.4-2-0.5-3.2s-0.2-2.6-0.3-4c-0.1-1.4-0.1-2.9,0-4.4c0-1.5,0.1-3,0.2-4.3c0.1-0.8,0.2-1.7,0.3-2.7
				c0.2-1,0.5-1.7,1-2.3c0.2-0.2,0.6-0.4,1-0.5c0.4-0.1,0.9-0.1,1.3,0.1c0.5,0.1,0.9,0.3,1.3,0.4c0.3,0.2,0.6,0.4,0.8,0.7
				c0.2,0.3,0.4,0.7,0.5,1.2c0,0.1,0.1,0.4,0.1,0.7c0,0.4,0.1,0.7,0.1,1.1c0,0.4,0,0.7,0,1c0,0.3-0.1,0.5-0.1,0.5
				c-0.2,0-0.4,0-0.7,0c-0.3,0-0.6,0-0.9-0.1s-0.6,0-0.9,0S35.9-7,35.7-6.9c-0.1,0.6-0.1,1.1-0.1,1.6s0,0.9,0,1.3c0,0.4,0,0.9,0,1.3
				c0,0.4,0,1-0.1,1.7V-1C35.6-1,35.6-1,35.7-0.9L35.7-0.9z"/>
                <path className="finale-6" d="M64.6-0.8l3.1-19.4v-0.1c-0.8-0.2-1.7-0.3-2.7-0.2S63-20.2,62.1-20h0c-0.2,0.6-0.3,1.3-0.5,2.1
				c-0.2,0.8-0.3,1.6-0.5,2.5c-0.2,0.8-0.3,1.7-0.5,2.4c-0.2,0.8-0.3,1.4-0.5,2c-0.4,0.1-0.6,0.1-0.8,0.1c-0.2,0-0.4,0-0.5,0
				s-0.3,0-0.5,0c-0.2,0-0.4,0-0.7,0c-0.2-0.6-0.4-1.3-0.5-2.1c-0.1-0.8-0.2-1.6-0.4-2.5c-0.1-0.9-0.3-1.7-0.4-2.5
				c-0.1-0.8-0.3-1.5-0.6-2.2c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-0.2-0.1-0.8-0.1-1.7,0c-0.9,0.1-2.3,0.1-4.2,0.1c0,0-0.1,0-0.1,0.1
				c0.1,0.3,0.2,1,0.3,2.2c0.1,1.2,0.3,2.7,0.6,4.6c0.2,1.9,0.5,4.1,0.9,6.5c0.3,2.5,0.8,5.1,1.2,7.8c0.5,2.8,1,5.6,1.5,8.6
				c0.6,3,1.2,6,1.8,8.9c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5,0,0.9,0c0.4,0,0.8,0,1.4,0c0.6,0,1.3,0.1,2.2,0.2c0.1-0.5,0.2-1.2,0.4-2.2
				c0.2-1,0.4-2.1,0.6-3.3s0.4-2.6,0.7-4s0.5-2.8,0.7-4c0.2-1.3,0.4-2.5,0.6-3.5C64.3,0.7,64.4-0.1,64.6-0.8z M60.3-4.4
				c-0.3,3.2-0.5,5.6-0.7,7.4c-0.2,1.8-0.4,3-0.5,3.6c-0.2,0.6-0.3,0.8-0.5,0.4C58.4,6.7,58.3,6,58.2,5s-0.3-2.4-0.4-4
				s-0.3-3.4-0.5-5.3c0.5,0,1,0,1.5,0C59.3-4.4,59.8-4.4,60.3-4.4z"/>
                <path className="finale-6" d="M80,18.7h1v-0.2c0-3.2,0-6.4-0.1-9.6c-0.1-3.2-0.1-6.4-0.2-9.7c0-3.2,0-6.5,0-9.7s0.1-6.4,0.2-9.5
				c-0.2-0.3-0.7-0.5-1.4-0.5s-1.5-0.1-2.3,0c-0.8,0-1.6,0.1-2.3,0.1c-0.7,0.1-1.3,0-1.5-0.1c0.2,3.3,0.3,6.7,0.2,10
				c0,3.4-0.1,6.7-0.1,10c-0.1,3.3-0.1,6.6-0.1,9.8c0,3.2,0.1,6.4,0.4,9.5V19c0.1,0,0.4,0,0.8,0c0.4-0.1,0.7-0.1,0.9-0.1
				c0.5,0,1.1-0.1,1.7-0.1C77.8,18.7,78.8,18.7,80,18.7z"/>
                <path className="finale-6" d="M104-20.5c-0.6,0-1.2,0-1.9,0.1c-0.7,0.1-1.4,0.1-1.9,0.1c-0.9,2.7-1.8,5.4-2.7,7.9c-0.9,2.6-1.7,5-2.3,7.4
				l-0.4,1.7c-0.1,0.4-0.2,0.8-0.3,1.1c-0.1,0.4-0.2,0.5-0.2,0.5c0,0-0.1-0.4-0.1-1.3S94-4.9,94-6.2c0-1.4,0-2.9-0.1-4.5
				c0-1.7,0-3.3,0-4.8c0-0.8,0-1.7,0-2.5s0-1.5,0-2.1c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0-0.6-0.1-0.9-0.1c-0.7,0-1.3,0.1-2,0.2
				c-0.7,0.1-1.3,0.2-2,0.2v3.9c0,3.5,0,6.8-0.1,10c0,3.2-0.1,6.5-0.1,10c0,2.5,0,5.1,0.1,7.6s0.2,4.9,0.3,7.3
				c0.3,0.1,0.6,0.1,0.9,0.2c0.3,0,0.6,0.1,1,0.1c0.2,0,0.4,0,0.8,0c0.4,0,0.8-0.1,1.2-0.1s0.7-0.1,1-0.2c0.3-0.1,0.4-0.1,0.4-0.2
				c0.2-0.7,0.4-1.2,0.5-1.7s0.3-0.9,0.5-1.3c0.2-0.4,0.3-0.9,0.5-1.4c0.2-0.5,0.4-1.1,0.6-1.9c0.1-0.2,0.2-0.5,0.4-1.1
				c0.2-0.5,0.4-1.1,0.7-1.8c0.2-0.7,0.5-1.4,0.8-2.2s0.5-1.4,0.8-2c0.2-0.6,0.4-1.1,0.6-1.6c0.2-0.4,0.3-0.6,0.3-0.6
				c0.1,0.1,0.2,0.4,0.2,1.1c0.1,0.7,0.1,1.3,0.1,1.9c0,1.5,0,2.9-0.1,4.1c0,1.3-0.1,2.6-0.1,3.9c0,0.8,0,1.6,0,2.4
				c0,0.8,0,1.7,0.1,2.6c0.9,0.2,1.9,0.3,3,0.3c1,0,1.9-0.1,2.7-0.3V1c0-1.6,0-3.1,0-4.7c0-1.6,0-3.2,0-4.8c0-1.1,0-2.1,0-3.1
				c0-1,0-2,0-3c0-0.7,0-1.5,0-2.2c0-0.7,0-1.5,0-2.2v-1.1c-0.1,0-0.2-0.1-0.4-0.2C104.8-20.4,104.4-20.5,104-20.5z"/>
            </g>
        </g>
        <g>
            <path className="finale-0" d="M-82,13.6L-82,13.6c0-0.3,0-0.6,0-0.8s0-0.5,0-0.7c-0.2-1.8-0.6-3.4-1.3-4.9C-84.1,5.7-85,4.5-86,3.4
			s-2.2-1.8-3.5-2.2c-1.3-0.5-2.6-0.5-3.9-0.2c0-0.5,0-1,0-1.5c0-0.5,0-1.2,0-2.2s0-2.2,0-3.8c0-1.6,0-3.7,0.1-6.4V-13
			c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-0.4-0.1-0.9-0.2-1.5-0.3s-1.2-0.1-1.9-0.1c-0.7,0-1.3,0-1.9,0c-0.6,0-1.2,0.1-1.7,0.2
			c-0.1,2.7-0.1,5.6-0.1,8.8c0,3.2,0,6.4,0,9.7s0,6.7,0,10c0,3.4-0.1,6.6-0.1,9.6c1.1,0.4,2.4,0.7,3.7,0.9c1.3,0.2,2.7,0.2,4,0.1
			s2.7-0.5,3.9-1c1.3-0.5,2.4-1.2,3.4-2.2c1-1,1.8-2.2,2.4-3.7C-82.5,17.5-82.1,15.7-82,13.6z M-89.3,15.9c-0.7,0.7-1.5,1.2-2.4,1.4
			c-0.9,0.2-1.8,0.2-2.7-0.1c-0.1,0-0.1,0-0.1-0.1c0-0.7,0-1.4,0-2.1c0-0.7,0-1.5,0-2.2c0-0.7,0-1.4,0.1-2.1s0.1-1.3,0.3-1.9h0.1
			c0.4,0,0.9,0.1,1.4,0.1c0.5,0,1,0.1,1.5,0.1c0.5,0.1,1,0.2,1.4,0.4c0.5,0.2,0.9,0.5,1.2,1c0.2,0.4,0.4,0.9,0.5,1.4
			c0.1,0.5,0.1,1,0,1.6c-0.1,0.5-0.2,1-0.4,1.4C-88.7,15.2-89,15.6-89.3,15.9z"/>
            <path className="finale-0" d="M-58.7-8.6c0.1-1.3,0.1-2.3,0.1-3c0-0.7,0-1.2-0.1-1.5c-0.1-0.3-0.2-0.5-0.4-0.5s-0.4,0-0.7,0
			c-0.3,0-0.7,0-1.2,0c-0.5,0-1.3-0.1-2.5-0.1s-2.7-0.1-4.7-0.1c-2,0-4.7,0-8,0.1v1c0.1,2.8,0.2,5.3,0.2,7.5
			c0.1,2.2,0.1,4.3,0.1,6.3c0,1.9,0,3.8,0,5.6s0,3.7,0,5.5c0,1.9-0.1,3.9-0.1,6c0,2.1-0.1,4.5-0.1,7.1c0.3,0.1,0.7,0.3,1.3,0.4
			s1.2,0.1,1.9,0.1c0.7,0,1.3-0.1,2-0.2c0.6-0.1,1.2-0.3,1.5-0.5c-0.1-2.4-0.1-4.9-0.1-7.4c0-2.5,0-5.1,0.1-7.6c0-2.5,0-5.1,0.1-7.6
			c0-2.5,0-5,0-7.3v-0.1c0.2,0.1,0.6,0.1,1.1,0.1c0.5,0,1.2,0,1.9-0.1c0.7,0,1.5-0.1,2.3-0.1s1.6-0.1,2.3-0.1c0.7,0,1.4-0.1,2-0.1
			c0.6,0,1,0,1.2,0c0-0.4,0-0.8,0-1.2c0-0.4,0-0.7-0.1-1.1C-58.7-8-58.7-8.3-58.7-8.6z"/>
            <path className="finale-0" d="M-38.6,5.8l3.1-19.4v-0.1c-0.8-0.2-1.7-0.3-2.7-0.2c-1,0.1-1.9,0.2-2.8,0.5h0c-0.2,0.6-0.3,1.3-0.5,2.1
			c-0.2,0.8-0.3,1.6-0.5,2.5c-0.2,0.8-0.3,1.7-0.5,2.4c-0.2,0.8-0.3,1.4-0.5,2c-0.4,0.1-0.6,0.1-0.8,0.1c-0.2,0-0.4,0-0.5,0
			s-0.3,0-0.5,0c-0.2,0-0.4,0-0.7,0c-0.2-0.6-0.4-1.3-0.5-2.1c-0.1-0.8-0.2-1.6-0.4-2.5c-0.1-0.9-0.3-1.7-0.4-2.5s-0.3-1.5-0.6-2.2
			c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-0.2-0.1-0.8-0.1-1.7,0c-0.9,0.1-2.3,0.1-4.2,0.1c0,0-0.1,0-0.1,0.1c0.1,0.3,0.2,1,0.3,2.2
			s0.3,2.7,0.6,4.6c0.2,1.9,0.5,4.1,0.9,6.5c0.3,2.5,0.8,5.1,1.2,7.8c0.5,2.8,1,5.6,1.5,8.6s1.2,6,1.8,8.9c0.2,0,0.4,0.1,0.6,0.1
			s0.5,0,0.9,0s0.8,0,1.4,0c0.6,0,1.3,0.1,2.2,0.2c0.1-0.5,0.2-1.2,0.4-2.2c0.2-1,0.4-2.1,0.6-3.3s0.4-2.6,0.7-4
			c0.2-1.4,0.5-2.8,0.7-4s0.4-2.5,0.6-3.5C-38.8,7.3-38.7,6.4-38.6,5.8z M-42.8,2.2c-0.3,3.2-0.5,5.6-0.7,7.4s-0.4,3-0.5,3.6
			c-0.2,0.6-0.3,0.8-0.5,0.4c-0.2-0.4-0.3-1.1-0.4-2.1c-0.1-1.1-0.3-2.4-0.4-4c-0.2-1.6-0.3-3.4-0.5-5.3c0.5,0,1,0,1.5,0
			S-43.3,2.2-42.8,2.2z"/>
            <path className="finale-0" d="M-12,24.7L-12,24.7c-0.8-3.4-1.7-6.6-2.7-9.8c-1.1-3.2-2.1-6.3-3.3-9.5c-1.1-3.2-2.2-6.3-3.3-9.5
			c-1.1-3.2-2-6.4-2.9-9.6c-0.2,0-0.3,0.1-0.5,0.1s-0.3,0-0.5,0c-0.6,0-1.2,0-1.9-0.1c-0.7-0.1-1.4-0.1-2-0.1c-0.2,0-0.3,0-0.5,0
			c-0.2,0-0.3,0-0.5,0.1h-0.1c0.7,3,1.6,6.1,2.5,9.4s1.9,6.5,2.9,9.8v0.1c-0.3,1.6-0.7,3.2-1.2,4.8c-0.5,1.6-1,3.2-1.5,4.8
			c-0.5,1.6-1,3.2-1.5,4.8c-0.5,1.6-0.8,3.2-1.1,4.7c0.6,0,1.1,0.1,1.7,0.2c0.6,0.1,1.2,0.2,1.8,0.2c0.3,0,0.7,0,1-0.1
			c0.3-0.1,0.7-0.2,1-0.4c0.1-0.8,0.3-1.8,0.6-2.9c0.3-1.1,0.5-2.2,0.8-3.4s0.6-2.2,0.9-3.3c0.3-1,0.6-1.9,0.9-2.6
			c0.3,0.7,0.6,1.5,0.9,2.6c0.3,1.1,0.6,2.2,0.9,3.3c0.3,1.1,0.6,2.3,0.9,3.4c0.3,1.1,0.5,2,0.8,2.8c0.5,0.1,1.2,0.2,2,0.3
			c0.8,0.1,1.6,0.1,2.3,0.1c0.3,0,0.6,0,0.9,0C-12.4,24.8-12.2,24.8-12,24.7z"/>
            <path className="finale-0" d="M19,5.8l3.1-19.4v-0.1c-0.8-0.2-1.7-0.3-2.7-0.2c-1,0.1-1.9,0.2-2.8,0.5h0c-0.2,0.6-0.3,1.3-0.5,2.1
			c-0.2,0.8-0.3,1.6-0.5,2.5c-0.2,0.8-0.3,1.7-0.5,2.4c-0.2,0.8-0.3,1.4-0.5,2c-0.4,0.1-0.6,0.1-0.8,0.1c-0.2,0-0.4,0-0.5,0
			s-0.3,0-0.5,0c-0.2,0-0.4,0-0.7,0c-0.2-0.6-0.4-1.3-0.5-2.1c-0.1-0.8-0.2-1.6-0.4-2.5c-0.1-0.9-0.3-1.7-0.4-2.5s-0.3-1.5-0.6-2.2
			c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-0.2-0.1-0.8-0.1-1.7,0c-0.9,0.1-2.3,0.1-4.2,0.1c0,0-0.1,0-0.1,0.1c0.1,0.3,0.2,1,0.3,2.2
			S4.8-8.6,5-6.8c0.2,1.9,0.5,4.1,0.9,6.5c0.3,2.5,0.8,5.1,1.2,7.8c0.5,2.8,1,5.6,1.5,8.6s1.2,6,1.8,8.9c0.2,0,0.4,0.1,0.6,0.1
			s0.5,0,0.9,0s0.8,0,1.4,0c0.6,0,1.3,0.1,2.2,0.2c0.1-0.5,0.2-1.2,0.4-2.2c0.2-1,0.4-2.1,0.6-3.3s0.4-2.6,0.7-4
			c0.2-1.4,0.5-2.8,0.7-4s0.4-2.5,0.6-3.5C18.8,7.3,18.9,6.4,19,5.8z M14.8,2.2c-0.3,3.2-0.5,5.6-0.7,7.4s-0.4,3-0.5,3.6
			c-0.2,0.6-0.3,0.8-0.5,0.4c-0.2-0.4-0.3-1.1-0.4-2.1c-0.1-1.1-0.3-2.4-0.4-4c-0.2-1.6-0.3-3.4-0.5-5.3c0.5,0,1,0,1.5,0
			S14.3,2.2,14.8,2.2z"/>
            <path className="finale-0" d="M35.7,5.6c0.4,0.3,0.9,0.5,1.8,0.5c0.8,0.1,1.7,0.1,2.6,0c0.9,0,1.8-0.1,2.6-0.2c0.8-0.1,1.3-0.1,1.6-0.1h0.1
			l0-0.1c0.2-2,0.3-4,0.3-6.2c0.1-2.2-0.1-4.3-0.3-6.3C44.2-8.6,43.8-10,43-11c-0.7-1-1.6-1.8-2.7-2.3c-0.7-0.3-1.4-0.5-2.2-0.7
			c-0.7-0.2-1.5-0.4-2.3-0.4c-0.8-0.1-1.5-0.1-2.3,0s-1.4,0.4-2.1,0.7c-1.1,0.6-2,1.5-2.5,2.5c-0.6,1.1-1,2.4-1.4,4
			c-0.1,0.4-0.2,1-0.3,1.7c-0.1,0.7-0.2,1.4-0.2,2.2C27.1-2.1,27-1,27-0.1c-0.1,1-0.1,1.9-0.1,2.9c0,0.9-0.1,1.9-0.1,3
			c0,1.1,0,2.3,0,3.6c0,1.2,0.1,2.4,0.1,3.6c0.1,1.2,0.2,2.4,0.3,3.6c0.1,1.2,0.4,2.4,0.7,3.5c0.3,1.1,0.9,2.1,1.7,2.8
			c0.6,0.6,1.2,1.1,1.9,1.4c0.7,0.3,1.4,0.5,2.2,0.7c1.5,0.3,3,0.2,4.4-0.2c1.4-0.4,2.6-1.1,3.6-2.1c1-1,1.7-2.3,2.2-4
			c0.5-1.6,0.7-3.6,0.5-5.8l0-0.3c-0.1-0.1-0.2-0.2-0.4-0.2s-0.5,0-1-0.1c-0.4,0-0.8,0-1.1,0s-0.6,0-0.9,0s-0.6,0-0.9,0.1
			c-0.3,0-0.7,0.1-1.1,0.2c-0.1,0.4-0.2,0.8-0.4,1.4c-0.2,0.5-0.3,1-0.6,1.5c-0.2,0.5-0.5,0.9-0.8,1.2c-0.3,0.4-0.7,0.5-1.1,0.5
			c-0.5,0-0.9,0.1-1.4,0.1s-0.8-0.2-1.2-0.7c-0.1-0.1-0.1-0.3-0.2-0.6c-0.1-0.3-0.2-0.6-0.2-0.9c-0.2-0.9-0.4-2-0.5-3.2
			s-0.2-2.6-0.3-4c-0.1-1.4-0.1-2.9,0-4.4c0-1.5,0.1-3,0.2-4.3c0.1-0.8,0.2-1.7,0.3-2.7c0.2-1,0.5-1.7,1-2.3c0.2-0.2,0.6-0.4,1-0.5
			c0.4-0.1,0.9-0.1,1.3,0.1c0.5,0.1,0.9,0.3,1.3,0.4C37.8-5.6,38-5.3,38.3-5c0.2,0.3,0.4,0.7,0.5,1.2c0,0.1,0.1,0.4,0.1,0.7
			c0,0.4,0.1,0.7,0.1,1.1c0,0.4,0,0.7,0,1c0,0.3-0.1,0.5-0.1,0.5c-0.2,0-0.4,0-0.7,0c-0.3,0-0.6,0-0.9-0.1s-0.6,0-0.9,0
			s-0.5,0.1-0.6,0.1c-0.1,0.6-0.1,1.1-0.1,1.6s0,0.9,0,1.3c0,0.4,0,0.9,0,1.3c0,0.4,0,1-0.1,1.7v0.1C35.6,5.6,35.6,5.6,35.7,5.6
			L35.7,5.6z"/>
            <path className="finale-0" d="M64.6,5.8l3.1-19.4v-0.1c-0.8-0.2-1.7-0.3-2.7-0.2s-1.9,0.2-2.8,0.5h0c-0.2,0.6-0.3,1.3-0.5,2.1
			c-0.2,0.8-0.3,1.6-0.5,2.5C61-8,60.8-7.2,60.6-6.4c-0.2,0.8-0.3,1.4-0.5,2c-0.4,0.1-0.6,0.1-0.8,0.1c-0.2,0-0.4,0-0.5,0
			s-0.3,0-0.5,0c-0.2,0-0.4,0-0.7,0c-0.2-0.6-0.4-1.3-0.5-2.1c-0.1-0.8-0.2-1.6-0.4-2.5c-0.1-0.9-0.3-1.7-0.4-2.5
			c-0.1-0.8-0.3-1.5-0.6-2.2c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-0.2-0.1-0.8-0.1-1.7,0c-0.9,0.1-2.3,0.1-4.2,0.1c0,0-0.1,0-0.1,0.1
			c0.1,0.3,0.2,1,0.3,2.2c0.1,1.2,0.3,2.7,0.6,4.6c0.2,1.9,0.5,4.1,0.9,6.5c0.3,2.5,0.8,5.1,1.2,7.8c0.5,2.8,1,5.6,1.5,8.6
			c0.6,3,1.2,6,1.8,8.9c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5,0,0.9,0c0.4,0,0.8,0,1.4,0c0.6,0,1.3,0.1,2.2,0.2c0.1-0.5,0.2-1.2,0.4-2.2
			c0.2-1,0.4-2.1,0.6-3.3s0.4-2.6,0.7-4s0.5-2.8,0.7-4c0.2-1.3,0.4-2.5,0.6-3.5C64.3,7.3,64.4,6.4,64.6,5.8z M60.3,2.2
			c-0.3,3.2-0.5,5.6-0.7,7.4c-0.2,1.8-0.4,3-0.5,3.6c-0.2,0.6-0.3,0.8-0.5,0.4c-0.2-0.4-0.3-1.1-0.4-2.1s-0.3-2.4-0.4-4
			s-0.3-3.4-0.5-5.3c0.5,0,1,0,1.5,0C59.3,2.2,59.8,2.2,60.3,2.2z"/>
            <path className="finale-0" d="M80,25.3h1v-0.2c0-3.2,0-6.4-0.1-9.6c-0.1-3.2-0.1-6.4-0.2-9.7c0-3.2,0-6.5,0-9.7s0.1-6.4,0.2-9.5
			c-0.2-0.3-0.7-0.5-1.4-0.5s-1.5-0.1-2.3,0c-0.8,0-1.6,0.1-2.3,0.1c-0.7,0.1-1.3,0-1.5-0.1c0.2,3.3,0.3,6.7,0.2,10
			c0,3.4-0.1,6.7-0.1,10c-0.1,3.3-0.1,6.6-0.1,9.8c0,3.2,0.1,6.4,0.4,9.5v0.1c0.1,0,0.4,0,0.8,0c0.4-0.1,0.7-0.1,0.9-0.1
			c0.5,0,1.1-0.1,1.7-0.1C77.8,25.3,78.8,25.3,80,25.3z"/>
            <path className="finale-0" d="M104-13.9c-0.6,0-1.2,0-1.9,0.1c-0.7,0.1-1.4,0.1-1.9,0.1c-0.9,2.7-1.8,5.4-2.7,7.9c-0.9,2.6-1.7,5-2.3,7.4
			l-0.4,1.7c-0.1,0.4-0.2,0.8-0.3,1.1C94.3,4.9,94.2,5,94.2,5c0,0-0.1-0.4-0.1-1.3S94,1.7,94,0.3c0-1.4,0-2.9-0.1-4.5
			c0-1.7,0-3.3,0-4.8c0-0.8,0-1.7,0-2.5s0-1.5,0-2.1c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0-0.6-0.1-0.9-0.1c-0.7,0-1.3,0.1-2,0.2
			c-0.7,0.1-1.3,0.2-2,0.2v3.9c0,3.5,0,6.8-0.1,10c0,3.2-0.1,6.5-0.1,10c0,2.5,0,5.1,0.1,7.6s0.2,4.9,0.3,7.3
			c0.3,0.1,0.6,0.1,0.9,0.2c0.3,0,0.6,0.1,1,0.1c0.2,0,0.4,0,0.8,0c0.4,0,0.8-0.1,1.2-0.1s0.7-0.1,1-0.2c0.3-0.1,0.4-0.1,0.4-0.2
			c0.2-0.7,0.4-1.2,0.5-1.7s0.3-0.9,0.5-1.3c0.2-0.4,0.3-0.9,0.5-1.4c0.2-0.5,0.4-1.1,0.6-1.9c0.1-0.2,0.2-0.5,0.4-1.1
			c0.2-0.5,0.4-1.1,0.7-1.8c0.2-0.7,0.5-1.4,0.8-2.2s0.5-1.4,0.8-2c0.2-0.6,0.4-1.1,0.6-1.6c0.2-0.4,0.3-0.6,0.3-0.6
			c0.1,0.1,0.2,0.4,0.2,1.1c0.1,0.7,0.1,1.3,0.1,1.9c0,1.5,0,2.9-0.1,4.1c0,1.3-0.1,2.6-0.1,3.9c0,0.8,0,1.6,0,2.4
			c0,0.8,0,1.7,0.1,2.6c0.9,0.2,1.9,0.3,3,0.3c1,0,1.9-0.1,2.7-0.3V7.5c0-1.6,0-3.1,0-4.7c0-1.6,0-3.2,0-4.8c0-1.1,0-2.1,0-3.1
			c0-1,0-2,0-3c0-0.7,0-1.5,0-2.2c0-0.7,0-1.5,0-2.2v-1.1c-0.1,0-0.2-0.1-0.4-0.2C104.8-13.9,104.4-13.9,104-13.9z"/>
        </g>
    </g>
}

const ExitBtn = () => {


    return <g transform="matrix(1 0 0 -1 0 0)">
        <g>
            <path className="finale-3" d="M-114.7,44.3l25.7-94c0.2-0.6,0.8-1,1.4-0.8l201.4,25.5c0.6,0.2,0.9,0.7,0.8,1.3l-10.9,73.4
			c-0.1,0.6-0.6,1-1.2,1l-216.3-4.8C-114.4,45.7-114.9,45-114.7,44.3z"/>
        </g>
        <g>
            <path className="finale-4" d="M-18.7-8.3v-0.1c0.1-1.2,0.1-2.4,0-3.7c0-1.3-0.2-2.5-0.4-3.6c-2.8,0-5.7,0-8.6-0.1s-5.7,0-8.4,0.2
			c0,3.2,0,6.4-0.1,9.6c-0.1,3.2-0.1,6.3-0.1,9.4c0,3.1,0,6.3,0,9.4c0,3.1,0.1,6.3,0.2,9.5c1.1,0.1,2.3,0.1,3.5,0.2
			c1.3,0.1,2.5,0.1,3.8,0.1c1.3,0,2.5,0.1,3.7,0.1c1.2,0,2.2,0.1,3.2,0.1s1.6,0,2.2-0.1c0.6,0,0.8-0.1,0.8-0.1
			c0.1-1.3,0.2-2.6,0.1-3.9c-0.1-1.3-0.1-2.6-0.1-3.9v-0.1c-0.9-0.3-1.8-0.4-2.7-0.5c-0.9-0.1-1.8-0.1-2.7-0.1c-0.9,0-1.8,0-2.7,0
			c-0.9,0-1.8-0.1-2.7-0.2h-0.1l0-0.1c0-1.1,0-2.2-0.1-3.3c-0.1-1.1,0-2.2,0.1-3.2l0-0.1c0.8-0.1,1.7-0.1,2.5-0.1
			c0.9,0,1.7,0,2.6-0.1V7.3c0.1-1.3,0.2-2.5,0.1-3.8c-0.1-1.3-0.1-2.6,0-3.9C-25-0.6-25.5-0.7-26-0.7c-0.5,0-1,0-1.5,0
			c-0.5,0-0.9,0-1.3-0.1c-0.4,0-0.7-0.1-0.8-0.3c-0.1-1.1-0.2-2.3-0.1-3.6c0-1.3,0.1-2.4,0.1-3.3l0-0.1c1.8,0,3.6-0.1,5.4-0.2
			C-22.4-8.3-20.6-8.4-18.7-8.3z"/>
            <path className="finale-4" d="M4.9,22.8c-0.4-1.6-0.8-3.2-1.2-4.8c-0.4-1.6-0.9-3.2-1.3-4.9c-0.4-1.6-0.8-3.3-1.1-5
			C0.9,6.5,0.6,4.8,0.4,3.1C0.6,1.5,0.9,0,1.2-1.6s0.7-3.2,1-4.7C2.6-7.9,3-9.5,3.3-11.1c0.4-1.6,0.7-3.1,1-4.6
			c-0.2,0-0.4,0.1-0.5,0.1s-0.3,0-0.5,0c-0.4,0-0.9,0-1.3-0.1c-0.4,0-0.9-0.1-1.4-0.1c-0.7,0-1.3,0.1-1.8,0.2l0,0.1
			c-0.3,0.9-0.6,1.9-0.8,2.9c-0.2,1.1-0.4,2.2-0.6,3.3C-2.9-8.2-3.1-7.1-3.3-6c-0.2,1.1-0.5,2-0.8,2.9l0,0.1
			c-0.4-1.1-0.7-2.2-0.9-3.2c-0.2-1.1-0.5-2.2-0.7-3.3c-0.2-1.1-0.5-2.2-0.7-3.2c-0.2-1.1-0.5-2-0.9-3c-0.3,0-0.6-0.1-1-0.1
			s-0.7,0-1,0c-0.6,0-1.3,0-1.9,0.1c-0.7,0-1.2,0.2-1.8,0.4c0.3,1.4,0.6,2.8,1,4.4c0.4,1.6,0.8,3.1,1.2,4.7C-10.4-4.6-10-3-9.6-1.4
			c0.4,1.6,0.7,3.2,1,4.8c-0.5,3.2-1.2,6.4-2,9.6c-0.9,3.1-1.8,6.2-2.7,9.4c0.9,0.1,1.7,0.1,2.4,0.2c0.7,0,1.3,0.1,1.8,0.1
			c0.7,0,1.3,0,1.6,0c0.3,0,0.5-0.1,0.6-0.3c0.2-0.8,0.4-1.7,0.6-2.8c0.2-1.1,0.5-2.2,0.7-3.4s0.5-2.3,0.7-3.4
			c0.2-1.1,0.5-2,0.6-2.7c0.2,0.7,0.5,1.7,0.8,2.8c0.3,1.1,0.5,2.3,0.8,3.4c0.2,1.2,0.5,2.3,0.7,3.5c0.2,1.1,0.4,2.1,0.6,2.9v0.1
			c0.7,0.1,1.6,0.1,2.5,0.2c1,0,2.2,0.1,3.8,0.1h0C5,22.9,5,22.9,4.9,22.8C4.9,22.9,4.9,22.9,4.9,22.8z"/>
            <path className="finale-4" d="M17.2,23.1h1v-0.2c0-3.2,0-6.4-0.1-9.6C18,10.1,18,6.9,17.9,3.7c0-3.2,0-6.5,0-9.7s0.1-6.4,0.2-9.5
			c-0.2-0.3-0.7-0.5-1.4-0.5s-1.5-0.1-2.3,0c-0.8,0-1.6,0.1-2.3,0.1c-0.7,0.1-1.3,0-1.5-0.1c0.2,3.3,0.3,6.7,0.2,10
			c0,3.4-0.1,6.7-0.1,10c-0.1,3.3-0.1,6.6-0.1,9.8c0,3.2,0.1,6.4,0.4,9.5v0.1c0.1,0,0.4,0,0.8,0c0.4-0.1,0.7-0.1,0.9-0.1
			c0.5,0,1.1-0.1,1.7-0.1C15,23.1,15.9,23.1,17.2,23.1z"/>
            <path className="finale-4" d="M41.7,22.7c0.1-1.2,0.1-2.6,0.1-4.2c0-1.6-0.1-3-0.2-4.4c-0.5,0.1-1.2,0.2-2,0.2c-0.8,0-1.8-0.1-3.1-0.2h-0.1
			h0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0-0.2,0-0.5-0.1-0.9c0-0.4,0-1,0-1.8s0-1.9,0-3.3c0-1.3,0-3.1,0.1-5.2c0-2.1,0.1-4.7,0.1-7.6
			c0-3,0.1-6.5,0.1-10.5v-0.1v-0.1c-0.5-0.1-1-0.2-1.6-0.2c-0.6,0-1.2,0-1.8,0c-0.6,0-1.2,0-1.8,0c-0.6,0-1.1-0.1-1.6-0.2
			c0,0.1,0,0.8,0,2c0,1.2,0,2.9,0.1,5.3s0,5.3,0,9s-0.1,8.1-0.3,13.2l0,0.1c-0.3,0.2-0.7,0.4-1.2,0.4s-1,0.1-1.5,0.1
			c-0.5,0-1,0-1.4,0c-0.4,0-0.7,0-0.9,0.1c-0.1,0.1-0.1,0.5-0.1,1.1c0,0.6-0.1,1.3-0.1,2.1s0,1.7,0,2.7c0,1,0,1.9,0.1,2.8
			c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c2.8,0,5.7,0.1,8.8,0.1C35.9,23.3,38.9,23.1,41.7,22.7z"/>
        </g>
        <g>
            <g>
                <path className="finale-0" d="M-18.7-2.3v-0.1c0.1-1.2,0.1-2.4,0-3.7c0-1.3-0.2-2.5-0.4-3.6c-2.8,0-5.7,0-8.6-0.1s-5.7,0-8.4,0.2
				c0,3.2,0,6.4-0.1,9.6c-0.1,3.2-0.1,6.3-0.1,9.4c0,3.1,0,6.3,0,9.4c0,3.1,0.1,6.3,0.2,9.5c1.1,0.1,2.3,0.1,3.5,0.2
				c1.3,0.1,2.5,0.1,3.8,0.1c1.3,0,2.5,0.1,3.7,0.1c1.2,0,2.2,0.1,3.2,0.1s1.6,0,2.2-0.1c0.6,0,0.8-0.1,0.8-0.1
				c0.1-1.3,0.2-2.6,0.1-3.9c-0.1-1.3-0.1-2.6-0.1-3.9v-0.1c-0.9-0.3-1.8-0.4-2.7-0.5c-0.9-0.1-1.8-0.1-2.7-0.1c-0.9,0-1.8,0-2.7,0
				c-0.9,0-1.8-0.1-2.7-0.2h-0.1l0-0.1c0-1.1,0-2.2-0.1-3.3c-0.1-1.1,0-2.2,0.1-3.2l0-0.1c0.8-0.1,1.7-0.1,2.5-0.1
				c0.9,0,1.7,0,2.6-0.1v-0.1c0.1-1.3,0.2-2.5,0.1-3.8c-0.1-1.3-0.1-2.6,0-3.9C-25,5.4-25.5,5.3-26,5.3c-0.5,0-1,0-1.5,0
				c-0.5,0-0.9,0-1.3-0.1c-0.4,0-0.7-0.1-0.8-0.3c-0.1-1.1-0.2-2.3-0.1-3.6c0-1.3,0.1-2.4,0.1-3.3l0-0.1c1.8,0,3.6-0.1,5.4-0.2
				C-22.4-2.3-20.6-2.4-18.7-2.3z"/>
                <path className="finale-0" d="M4.9,28.8c-0.4-1.6-0.8-3.2-1.2-4.8c-0.4-1.6-0.9-3.2-1.3-4.9c-0.4-1.6-0.8-3.3-1.1-5
				c-0.3-1.7-0.6-3.4-0.8-5.1C0.6,7.5,0.9,6,1.2,4.4s0.7-3.2,1-4.7C2.6-1.9,3-3.5,3.3-5.1c0.4-1.6,0.7-3.1,1-4.6
				c-0.2,0-0.4,0.1-0.5,0.1s-0.3,0-0.5,0c-0.4,0-0.9,0-1.3-0.1C1.5-9.7,1-9.8,0.6-9.8c-0.7,0-1.3,0.1-1.8,0.2l0,0.1
				c-0.3,0.9-0.6,1.9-0.8,2.9c-0.2,1.1-0.4,2.2-0.6,3.3C-2.9-2.2-3.1-1.1-3.3,0c-0.2,1.1-0.5,2-0.8,2.9l0,0.1
				c-0.4-1.1-0.7-2.2-0.9-3.2c-0.2-1.1-0.5-2.2-0.7-3.3C-6-4.6-6.2-5.7-6.4-6.7c-0.2-1.1-0.5-2-0.9-3c-0.3,0-0.6-0.1-1-0.1
				s-0.7,0-1,0c-0.6,0-1.3,0-1.9,0.1c-0.7,0-1.2,0.2-1.8,0.4c0.3,1.4,0.6,2.8,1,4.4c0.4,1.6,0.8,3.1,1.2,4.7C-10.4,1.4-10,3-9.6,4.6
				c0.4,1.6,0.7,3.2,1,4.8c-0.5,3.2-1.2,6.4-2,9.6c-0.9,3.1-1.8,6.2-2.7,9.4c0.9,0.1,1.7,0.1,2.4,0.2c0.7,0,1.3,0.1,1.8,0.1
				c0.7,0,1.3,0,1.6,0c0.3,0,0.5-0.1,0.6-0.3c0.2-0.8,0.4-1.7,0.6-2.8c0.2-1.1,0.5-2.2,0.7-3.4s0.5-2.3,0.7-3.4
				c0.2-1.1,0.5-2,0.6-2.7c0.2,0.7,0.5,1.7,0.8,2.8c0.3,1.1,0.5,2.3,0.8,3.4c0.2,1.2,0.5,2.3,0.7,3.5c0.2,1.1,0.4,2.1,0.6,2.9v0.1
				c0.7,0.1,1.6,0.1,2.5,0.2c1,0,2.2,0.1,3.8,0.1h0C5,28.9,5,28.9,4.9,28.8C4.9,28.9,4.9,28.9,4.9,28.8z"/>
                <path className="finale-0" d="M17.2,29.1h1v-0.2c0-3.2,0-6.4-0.1-9.6c-0.1-3.2-0.1-6.4-0.2-9.7c0-3.2,0-6.5,0-9.7s0.1-6.4,0.2-9.5
				c-0.2-0.3-0.7-0.5-1.4-0.5s-1.5-0.1-2.3,0c-0.8,0-1.6,0.1-2.3,0.1c-0.7,0.1-1.3,0-1.5-0.1c0.2,3.3,0.3,6.7,0.2,10
				c0,3.4-0.1,6.7-0.1,10c-0.1,3.3-0.1,6.6-0.1,9.8c0,3.2,0.1,6.4,0.4,9.5v0.1c0.1,0,0.4,0,0.8,0c0.4-0.1,0.7-0.1,0.9-0.1
				c0.5,0,1.1-0.1,1.7-0.1C15,29.1,15.9,29.1,17.2,29.1z"/>
                <path className="finale-0" d="M41.7,28.7c0.1-1.2,0.1-2.6,0.1-4.2c0-1.6-0.1-3-0.2-4.4c-0.5,0.1-1.2,0.2-2,0.2c-0.8,0-1.8-0.1-3.1-0.2
				h-0.1h0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0-0.2,0-0.5-0.1-0.9c0-0.4,0-1,0-1.8s0-1.9,0-3.3c0-1.3,0-3.1,0.1-5.2
				c0-2.1,0.1-4.7,0.1-7.6c0-3,0.1-6.5,0.1-10.5v-0.1v-0.1c-0.5-0.1-1-0.2-1.6-0.2c-0.6,0-1.2,0-1.8,0c-0.6,0-1.2,0-1.8,0
				c-0.6,0-1.1-0.1-1.6-0.2c0,0.1,0,0.8,0,2c0,1.2,0,2.9,0.1,5.3s0,5.3,0,9s-0.1,8.1-0.3,13.2l0,0.1c-0.3,0.2-0.7,0.4-1.2,0.4
				s-1,0.1-1.5,0.1c-0.5,0-1,0-1.4,0c-0.4,0-0.7,0-0.9,0.1c-0.1,0.1-0.1,0.5-0.1,1.1c0,0.6-0.1,1.3-0.1,2.1s0,1.7,0,2.7
				c0,1,0,1.9,0.1,2.8c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c2.8,0,5.7,0.1,8.8,0.1C35.9,29.3,38.9,29.1,41.7,28.7z"/>
            </g>
        </g>
    </g>

}

const RollyOk = ({containerRef}) => {

    const {get} = useAssetManager();

    const [animationElement, setAnimationElement] = useState(null);

    useEffect(() => {
        if (containerRef?.current) {


            const sceneAsset = get('finale.finale_01');

            sceneAsset.load(() => {

                const params = {
                    container: sceneRef.current,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    animationData: sceneAsset.asset,
                    assetsPath: sceneAsset.assetsPath ?? ''
                };
                sceneRef.current?.children?.[0]?.remove();
                const animationItem = lottie.loadAnimation(params);
                // animationItem.renderer.svgElement.setAttribute("opacity", 0);
                animationElement && animationElement.destroy();
                setAnimationElement(animationItem);
            })


        }
    }, [containerRef]);

    const setAnimationBox = (animationToBox, refBox) => {
        if (animationToBox?.renderer?.svgElement) {
            const bBox = refBox.current.getBBox();
            animationToBox.renderer.svgElement.setAttribute("width", 800);
            animationToBox.renderer.svgElement.setAttribute("height", 900);
            // animationToBox.renderer.svgElement.setAttribute("opacity", 1);
        }
    }


    useEffect(() => {
        setAnimationBox(animationElement, containerRef)
    }, [animationElement]);


    const sceneRef = useRef();

    return <g ref={sceneRef} transform="matrix(1, 0, 0, 1, -445, -341)">

    </g>

}

export const GameEndScreen = () => {

    const containerRef = useRef();
    const [entered, setEntered] = useState(false);

    const toWelcome = useGameActionSetter(EXIT_STATE_ACTION);
    const toLevels = useGameActionSetter(PLAY_AGAIN_STATE_ACTION);

    const opSpring = useSpring({
        from: {opacity: 0},
        to: {opacity: 1},
        config: {
            duration: 1000
        },
        delay: 500,
        onRest: () => {
            setEntered(true)
        }
    })

    return <animated.g style={opSpring}>
        <svg viewBox="0 0 1920 1080">
            <symbol id="completed-splash-text" viewBox="-356 -239.8 712 479.7">

                <use height="479.7" id="XMLID_5_" style={{
                    overflow: "visible",
                }} transform="matrix(1 0 0 1 8.9654 8.0366)" width="712" x="-365"
                     xlinkHref="#splash-text-bck" y="-247.9"/>
                <g>
                    <path className="finale-0" d="M-79.4,141.5L-79.4,141.5c-1.7-5.6-3.7-11-6.1-16.3c-2.3-5.3-4.7-10.5-7.3-15.8c-2.5-5.2-5-10.5-7.4-15.7
			c-2.4-5.2-4.5-10.6-6.3-15.9c-0.4,0.1-0.7,0.1-1.1,0.1s-0.8,0-1.2,0c-1.3,0-2.7,0-4.2-0.1c-1.5-0.1-3-0.1-4.4-0.1
			c-0.4,0-0.7,0-1.1,0c-0.4,0-0.7,0-1.1,0.1h-0.2c1.6,5,3.5,10.2,5.6,15.6c2.1,5.4,4.3,10.8,6.4,16.2v0.2c-0.7,2.7-1.5,5.4-2.6,8
			c-1,2.7-2.1,5.3-3.3,8c-1.1,2.6-2.2,5.3-3.3,7.9c-1,2.6-1.9,5.2-2.5,7.8c1.2,0,2.5,0.1,3.9,0.3c1.3,0.2,2.7,0.3,4,0.3
			c0.7,0,1.5,0,2.2-0.1s1.4-0.3,2.1-0.6c0.3-1.3,0.7-2.9,1.3-4.8c0.6-1.8,1.2-3.7,1.8-5.6c0.6-1.9,1.3-3.7,2-5.4
			c0.7-1.7,1.3-3.2,1.9-4.3c0.6,1.1,1.2,2.5,1.9,4.3c0.6,1.7,1.3,3.6,1.9,5.5c0.6,1.9,1.3,3.8,1.9,5.6c0.6,1.8,1.2,3.4,1.7,4.6
			c1.1,0.2,2.6,0.4,4.5,0.5c1.9,0.1,3.6,0.1,5.2,0.1c0.7,0,1.4,0,2,0C-80.2,141.6-79.7,141.6-79.4,141.5z"/>
                    <path className="finale-0" d="M-80.2,119.8c0.4,0.9,1.1,1.7,2.1,2.4c0.9,0.7,2.1,1.2,3.3,1.7c1.3,0.5,2.6,0.8,4,1c1.4,0.2,2.9,0.3,4.4,0.3
			c1.2-0.1,2.4-0.1,3.5,0c1.1,0.1,2.3,0.1,3.6,0c2-0.1,3.8-0.5,5.6-1.1c1.8-0.6,3.3-1.6,4.6-2.8c0.9-0.6,1.5-1.4,2-2.7
			c0.5-1.2,0.9-2.7,1.1-4.3c0.2-1.6,0.4-3.4,0.5-5.3c0.1-1.9,0.1-3.9,0.2-5.9c-0.1-1.8-0.1-3.8-0.2-6.1c-0.1-2.3-0.2-4.4-0.5-6.5
			c-0.2-2.1-0.5-4-0.8-5.6c-0.3-1.7-0.8-2.8-1.4-3.4c-1-1.5-2.4-2.5-4.1-3.1c-1.7-0.6-3.5-1-5.5-1.1c-2-0.2-4-0.2-6.1,0
			c-2.1,0.2-4,0.2-5.7,0.2c-3.1,0.6-5.8,1.9-7.9,4c-1.7,1.5-2.8,3.3-3.4,5.5c-0.6,2.2-1,4.5-1.1,6.9c-0.1,2.4-0.1,4.9,0,7.3
			c0.1,2.5,0,4.7-0.1,6.7c0,1.1,0,2.2,0.1,3.3c0.1,1.1,0.2,2.2,0.4,3.4c0.1,0.7,0.3,1.6,0.6,2.7C-80.6,118.2-80.4,119.1-80.2,119.8z
			 M-68.7,108.9c-0.4-0.2-0.6-0.9-0.8-1.8c-0.2-2-0.4-3.9-0.4-5.8s-0.1-4-0.2-6.1c-0.1-1.4,0.3-2.5,1.1-3.3c0.8-0.8,1.8-1.3,2.9-1.6
			c1.1-0.3,2.3-0.3,3.5-0.2c1.2,0.2,2.2,0.4,3,0.9c1,0.5,1.7,1.5,2.1,3c0.2,2,0.4,3.9,0.6,5.9c0.2,2,0,3.9-0.3,5.7
			c-0.3,2.1-1.2,3.7-2.7,4.7c-1.5,1-3.4,1.2-5.8,0.6C-66.9,110.6-67.9,109.9-68.7,108.9z"/>
                    <path className="finale-0" d="M-15,82.8c-0.3-0.2-0.7-0.4-1.1-0.6c-0.5-0.2-0.8-0.5-1.1-0.7c-1.3-0.9-2.7-1.7-4.3-2.2
			c-1.6-0.6-3.2-0.9-4.9-0.9c-1.7-0.1-3.3,0.1-4.9,0.6c-1.6,0.4-3,1.2-4.3,2.3c-0.6,0.6-1.1,1.7-1.4,3.4c-0.3,1.7-0.6,3.8-0.6,6.3
			c-0.1,2.4-0.1,5.1-0.1,8c0,2.9,0.1,5.9,0.1,8.8c0.1,3,0.1,5.8,0.2,8.6c0.1,2.8,0,5.2-0.1,7.3c2.1,0.3,4.1,0.4,5.7,0.4
			c1.7,0,3.6,0,5.7,0c0.1-1.4,0.2-3,0.2-4.8c0-1.8,0-3.6,0-5.5c0-1.9,0-3.8,0-5.7c0-1.9,0.1-3.7,0.2-5.3c0.1-0.7,0.1-1.5,0.2-2.4
			c0.1-0.9,0.2-1.8,0.3-2.6c0.2-0.9,0.4-1.6,0.6-2.3c0.3-0.7,0.7-1.2,1.1-1.6c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2-0.1
			c0.5-0.2,1.2-0.4,2.2-0.6c0.9-0.2,1.9-0.1,2.8,0.2c0.9,0.3,1.7,0.8,2.5,1.7c0.7,0.8,1.2,2.1,1.3,3.7c0.1,2.1,0.2,4.2,0.1,6.2
			c-0.1,2.1-0.1,4.2-0.2,6.2c-0.1,2.1-0.2,4.1-0.2,6.1c0,2,0.1,4,0.4,6.1v0.4c1,0.3,1.9,0.5,2.7,0.6c0.8,0.1,1.5,0.1,2.3,0
			c0.8,0,1.7-0.1,2.7-0.1c1-0.1,2.2,0,3.7,0.1c0-7.5,0.1-15.1,0.2-22.6c0.2-7.5,0.1-15.3-0.1-23.4c-0.7,0.1-1.3,0.1-1.8,0.1
			c-0.5,0-1,0-1.4,0c-0.4,0-0.9,0-1.4,0c-0.5,0-1.1-0.1-1.8-0.1c-0.2,0-0.7,0-1.3-0.1c-0.6-0.1-1.2-0.2-1.8-0.2
			c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0-0.7,0.1-0.7,0.3c-0.1,1-0.2,1.9-0.1,2.6C-14.9,81.2-15,82-15,82.8z"/>
                    <path className="finale-0" d="M14.5,120.5L14.5,120.5c-0.3,0-1-0.1-2-0.2c-1-0.1-2.1-0.2-3.3-0.3c-1.2-0.1-2.2-0.1-3.2-0.1
			c-1,0-1.5,0.2-1.5,0.5c0.2,0.7,0.4,1.7,0.8,2.9c0.3,1.3,0.7,2.7,1.1,4.3c0.4,1.6,0.8,3.2,1.2,4.9c0.4,1.7,0.8,3.2,1.2,4.7
			c0.4,1.4,0.7,2.7,1,3.7c0.3,1,0.4,1.7,0.5,2c1.7,0,3.1,0,4.2,0c1.2,0,2.3,0,3.5,0c1.2,0,2.1,0,2.7,0v-0.1
			c-0.7-2.8-1.3-5.1-1.8-6.9c-0.5-1.9-0.9-3.6-1.3-5.1c-0.4-1.6-0.9-3.1-1.3-4.6c-0.4-1.5-1-3.4-1.7-5.5L14.5,120.5z"/>
                    <path className="finale-0" d="M37.9,123.5c0.4-2.2,0.9-4.7,1.3-7.6c0.4-2.8,0.9-5.7,1.4-8.7c0.5-2.9,1-5.7,1.6-8.4c0.6-2.7,1.2-4.9,1.8-6.8
			c0.7,1.8,1.3,4.2,1.8,6.9c0.6,2.8,1.1,5.7,1.6,8.7c0.5,3,1.1,5.9,1.7,8.7c0.6,2.8,1.3,5.1,2,7c1,0,2,0,2.9,0.1
			c0.9,0.1,1.9,0.1,2.8,0.2c1,0.1,2,0.1,3,0.1c1,0,2.2,0,3.5-0.1c-1.3-3.5-2.4-7.1-3.4-10.9c-1-3.8-1.9-7.6-2.7-11.5
			c-0.9-3.9-1.7-7.9-2.6-11.8c-0.9-4-1.8-7.9-2.8-11.7c-1.2-0.2-2.6-0.3-4.1-0.3c-1.5,0-3,0-4.6,0c-1.6,0-3.1,0.1-4.6,0.1
			c-1.5,0-2.8,0-4,0c-1.2,7.7-2.6,15.5-4.2,23.4c-1.6,8-3.4,15.6-5.3,22.8c2.2-0.2,4.4-0.3,6.6-0.1
			C33.9,123.8,35.9,123.8,37.9,123.5z"/>
                    <path className="finale-0" d="M79,100.9v-1.7c0.1-1.8,0.1-3.3,0.2-4.5c0.1-1.2,0.5-2.3,1.1-3.4c0.7-1.4,1.8-2.5,3.1-3.1
			c1.3-0.7,2.7-0.9,4-0.6c1.3,0.2,2.4,1,3.3,2.3c0.9,1.3,1.3,3.1,1.1,5.7c0.6-0.1,1.4-0.1,2.5,0c1,0,2.1,0.1,3.3,0.1
			c1.1,0.1,2.2,0.1,3.3,0c1,0,1.9-0.1,2.6-0.3c-0.1-1.6-0.2-3.2-0.4-4.7c-0.1-1.5-0.3-3-0.5-4.3c-0.2-1.3-0.4-2.5-0.7-3.4
			c-0.3-1-0.6-1.6-1.1-2c-1-1.5-2.4-2.5-4-3.1s-3.5-1-5.4-1.1c-2-0.1-4-0.1-6,0c-2.1,0.1-3.9,0.2-5.7,0.2c-1.7,0.2-3.3,0.8-4.8,1.7
			c-1.7,1.2-3,2.7-4.1,4.5c-1.1,1.9-2,3.9-2.7,6.2c-0.7,2.2-1.1,4.6-1.4,7.1c-0.2,2.5-0.3,5-0.2,7.5c0.2,2.3,0.4,4.5,0.6,6.6
			c0.2,2.1,0.7,4.2,1.4,6.2c0.8,1.8,2,3.3,3.7,4.5c1.7,1.3,3.5,2.2,5.6,2.8c2.1,0.6,4.3,1,6.6,1.1c2.3,0.1,4.6-0.2,6.8-0.7
			c2.8-0.5,5.1-1.5,6.8-2.9c1.7-1.5,3-3.2,3.9-5.3c0.9-2.1,1.4-4.4,1.6-6.9c0.2-2.5,0.2-5.1,0-7.6c-0.4-0.1-1.2-0.2-2.4-0.4
			c-1.2-0.1-2.6-0.2-4.2-0.4c-1.6-0.1-3.4-0.2-5.2-0.3c-1.8-0.1-3.6-0.1-5.3-0.1c-1.7,0-3.2,0-4.5,0.1
			C80.6,100.7,79.6,100.8,79,100.9z M90.1,115.2c-0.5,0.3-1,0.5-1.5,0.7c-0.5,0.2-1.1,0.2-1.7,0.2c-1.5,0-2.7-0.2-3.6-0.5
			s-1.7-0.7-2.2-1.3c-0.6-0.6-1-1.2-1.2-1.9c-0.3-0.7-0.5-1.5-0.6-2.3c-0.1-0.7-0.2-1.1-0.1-1.4c0-0.3,0.3-0.5,0.9-0.6
			c0.6-0.1,1.4-0.1,2.6-0.1c1.2,0,3,0,5.3,0c1.6,0,2.8,0,3.6-0.1c0.8-0.1,1.4-0.1,1.7,0c0.3,0,0.5,0.1,0.5,0.3c0,0.2,0,0.5,0,1
			c0,1.2-0.3,2.4-1,3.5C92.1,113.8,91.2,114.6,90.1,115.2z"/>
                    <path className="finale-0" d="M-232.8,5.7c-0.1-1.6-0.2-3.2-0.4-4.7c-0.1-1.5-0.3-3-0.5-4.3c-0.2-1.3-0.4-2.5-0.7-3.4
			c-0.3-1-0.6-1.6-1.1-2.1c-1-1.5-2.4-2.5-4.1-3.1c-1.7-0.6-3.5-1-5.5-1.1c-2-0.2-4-0.2-6.1,0c-2.1,0.2-4,0.2-5.7,0.2
			c-3.1,0.6-5.8,1.9-7.9,4c-1.7,1.5-2.8,3.3-3.4,5.5c-0.6,2.2-1,4.5-1.1,6.9c-0.1,2.4-0.1,4.9,0,7.3c0.1,2.4,0,4.7-0.2,6.7
			c0,2.2,0.2,4.4,0.6,6.6c0.1,0.7,0.3,1.6,0.6,2.8c0.4,1.2,0.6,2.1,0.8,2.7c0.4,0.9,1.1,1.7,2.1,2.4c0.9,0.7,2.1,1.2,3.3,1.7
			c1.3,0.5,2.6,0.8,4,1c1.4,0.2,2.9,0.3,4.4,0.3c1.2-0.1,2.4-0.1,3.5,0c1.1,0.1,2.3,0.1,3.6,0c2-0.1,3.8-0.5,5.6-1.1
			c1.8-0.6,3.3-1.5,4.6-2.8c0.9-0.6,1.5-1.4,2-2.5c0.5-1,0.8-2.3,1.1-3.7c0.2-1.4,0.4-3,0.4-4.7c0-1.7,0.1-3.6,0.1-5.7
			c-0.8-0.2-1.7-0.3-2.7-0.3c-1,0-2.1,0-3.1,0.1c-1.1,0.1-2.1,0.2-3.1,0.3c-1,0.1-1.9,0.2-2.7,0.4c-0.3,2.1-1.2,3.8-2.7,5
			c-1.5,1.2-3.4,1.5-5.8,0.9c-1.3-0.4-2.3-1-3.1-2c-0.4-0.2-0.6-0.9-0.8-1.8c-0.2-1.9-0.4-3.8-0.4-5.8c0-2-0.1-4-0.2-6.2
			c-0.1-1.4,0.2-2.5,1.1-3.3c0.8-0.8,1.8-1.3,3-1.6c1.2-0.3,2.4-0.3,3.6-0.2c1.2,0.2,2.2,0.5,2.9,1c0.6,0.4,1.1,1.1,1.4,2.2
			c0.3,1,0.6,1.9,0.8,2.7c0.6-0.1,1.4-0.1,2.5,0c1,0,2.1,0.1,3.3,0.1c1.2,0.1,2.3,0.1,3.3,0C-234.4,6-233.5,5.9-232.8,5.7z"/>
                    <path className="finale-0" d="M-224.5,30c0.4,0.9,1.1,1.7,2.1,2.4c0.9,0.7,2.1,1.2,3.3,1.7c1.3,0.5,2.6,0.8,4,1c1.4,0.2,2.9,0.3,4.4,0.3
			c1.2-0.1,2.4-0.1,3.5,0c1.1,0.1,2.3,0.1,3.6,0c2-0.1,3.8-0.5,5.6-1.1c1.8-0.6,3.3-1.6,4.6-2.8c0.9-0.6,1.5-1.4,2-2.7
			c0.5-1.2,0.9-2.7,1.1-4.3c0.2-1.6,0.4-3.4,0.5-5.3c0.1-1.9,0.1-3.9,0.2-5.9c-0.1-1.8-0.1-3.8-0.2-6.1c-0.1-2.3-0.2-4.4-0.5-6.5
			c-0.2-2.1-0.5-4-0.8-5.6c-0.3-1.7-0.8-2.8-1.4-3.4c-1-1.5-2.4-2.5-4.1-3.1c-1.7-0.6-3.5-1-5.5-1.1c-2-0.2-4-0.2-6.1,0
			c-2.1,0.2-4,0.2-5.7,0.2c-3.1,0.6-5.8,1.9-7.9,4c-1.7,1.5-2.8,3.3-3.4,5.5c-0.6,2.2-1,4.5-1.1,6.9c-0.1,2.4-0.1,4.9,0,7.3
			c0.1,2.5,0,4.7-0.1,6.7c0,1.1,0,2.2,0.1,3.3c0.1,1.1,0.2,2.2,0.4,3.4c0.1,0.7,0.3,1.6,0.6,2.7C-224.9,28.4-224.7,29.3-224.5,30z
			 M-213,19.2c-0.4-0.2-0.6-0.9-0.8-1.8c-0.2-2-0.4-3.9-0.4-5.8s-0.1-4-0.2-6.1c-0.1-1.4,0.3-2.5,1.1-3.3c0.8-0.8,1.8-1.3,2.9-1.6
			c1.1-0.3,2.3-0.3,3.5-0.2c1.2,0.2,2.2,0.4,3,0.9c1,0.5,1.7,1.5,2.1,3c0.2,2,0.4,3.9,0.6,5.9c0.2,2,0,3.9-0.3,5.7
			c-0.3,2.1-1.2,3.7-2.7,4.7c-1.5,1-3.4,1.2-5.8,0.6C-211.1,20.8-212.2,20.1-213,19.2z"/>
                    <path className="finale-0" d="M-169.7,29.1c0.3,0.2,0.7,0.4,1.1,0.6c0.5,0.2,0.8,0.5,1.1,0.7c1.3,0.9,2.7,1.7,4.3,2.2
			c1.6,0.6,3.2,0.9,4.9,0.9c1.7,0.1,3.3-0.1,4.9-0.6c1.6-0.4,3-1.2,4.3-2.3c0.3-0.3,0.6-0.8,0.9-1.6c0.3,0.2,0.7,0.4,1.1,0.6
			c0.4,0.2,0.8,0.4,1.1,0.7c1.3,0.9,2.7,1.7,4.3,2.2c1.6,0.6,3.2,0.9,4.9,0.9c1.7,0.1,3.3-0.1,4.9-0.6c1.6-0.4,3-1.2,4.3-2.3
			c0.6-0.6,1.1-1.7,1.4-3.4c0.3-1.7,0.6-3.8,0.6-6.3c0.1-2.5,0.1-5.1,0.1-8c0-2.9-0.1-5.9-0.1-8.8c-0.1-3-0.1-5.8-0.2-8.6
			c-0.1-2.8,0-5.2,0.1-7.3c-2.1-0.3-4.1-0.4-5.7-0.4c-1.7,0-3.6,0-5.7,0c-0.1,1.4-0.2,3-0.2,4.8c0,1.8,0,3.6,0,5.5
			c0,1.9,0,3.8,0,5.7c0,1.9-0.1,3.7-0.2,5.3c-0.1,0.7-0.1,1.5-0.2,2.4c-0.1,0.9-0.2,1.8-0.3,2.6c-0.2,0.9-0.4,1.6-0.6,2.3
			c-0.3,0.7-0.7,1.2-1.1,1.6c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0.1c-0.4,0.2-0.8,0.3-1.4,0.5c-0.6,0.1-1.1,0.2-1.8,0.2
			c-0.6,0-1.3-0.1-1.9-0.3c-0.6-0.2-1.2-0.5-1.8-1c0.1-2.4,0.1-4.9,0-7.6c-0.1-2.7-0.1-5.3-0.2-7.9c-0.1-2.6-0.1-5.1-0.1-7.5
			c0-2.4,0-4.5,0.1-6.4c-2.1-0.3-4.1-0.4-5.7-0.4c-1.7,0-3.6,0-5.7,0c-0.1,1.4-0.2,3-0.2,4.8c0,1.8,0,3.6,0,5.5c0,1.9,0,3.8,0,5.7
			c0,1.9-0.1,3.7-0.2,5.3c-0.1,0.7-0.1,1.5-0.2,2.4c-0.1,0.9-0.2,1.8-0.3,2.6c-0.2,0.9-0.4,1.6-0.6,2.3c-0.3,0.7-0.7,1.2-1.1,1.6
			c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0.1c-0.5,0.2-1.2,0.4-2.2,0.6c-1,0.2-1.9,0.1-2.8-0.2s-1.7-0.8-2.5-1.7
			s-1.2-2.1-1.3-3.7c-0.1-2.1-0.2-4.2-0.1-6.3c0.1-2.1,0.1-4.1,0.2-6.2s0.1-4.1,0.1-6.1c0-2-0.1-4-0.4-6.1v-0.4
			c-1-0.3-1.9-0.5-2.7-0.6c-0.8-0.1-1.5-0.1-2.3,0c-0.8,0-1.7,0.1-2.7,0.1c-1,0.1-2.2,0-3.7-0.1c0,7.5-0.1,15.1-0.2,22.6
			c-0.2,7.5-0.1,15.3,0.1,23.4c0.7-0.1,1.3-0.1,1.8-0.1c0.5,0,0.9,0,1.4,0c0.4,0,0.9,0,1.4,0c0.5,0,1.1,0.1,1.8,0.1
			c0.2,0,0.7,0,1.3,0.1c0.6,0.1,1.2,0.2,1.8,0.2c0.6,0.1,1.2,0.1,1.7,0c0.5,0,0.7-0.1,0.7-0.3c0.1-1,0.2-1.9,0.1-2.6
			C-169.8,30.7-169.8,29.9-169.7,29.1z"/>
                    <path className="finale-0" d="M-104.6-29.5v-1c0-0.1-0.4-0.1-1.2-0.2s-1.8-0.2-3-0.2c-1.2,0-2.5,0-3.8,0c-1.3,0-2.5,0.1-3.5,0.3v6.1
			c0,5.1-0.1,10.2-0.2,15.3c-0.1,5.1-0.2,10.1-0.3,15c-0.1,4.9-0.2,9.8-0.2,14.5c0,4.7,0,9.3,0.1,13.7c1.9,0,4,0,6.2,0.1
			c2.3,0.1,4.3,0,6.2-0.2c0.1-0.1,0.1-0.3,0.1-0.6c0-0.6,0-1.1,0-1.5c0-0.4,0-0.9,0-1.6c0.1-0.3,0.3-0.5,0.4-0.7
			c0.2-0.2,0.3,0,0.5,0.3c0.9,1.8,2.1,3,3.7,3.5c1.5,0.5,3.2,0.7,4.9,0.4c3.5-0.2,6.2-1.1,8.3-2.7c2-1.6,3.5-3.6,4.5-6
			c1-2.4,1.7-5.1,2-8.1c0.3-3,0.5-6,0.6-9.1c0-2.1-0.1-4.2-0.4-6.2c-0.3-2-0.7-3.9-1.3-5.6c-0.6-1.7-1.3-3.1-2.3-4.3
			c-1-1.2-2.1-2-3.4-2.5c-1.7-0.6-3.5-1-5.5-1.1c-2-0.2-3.9-0.2-5.7,0c-1.8,0.2-3.3,0.4-4.5,0.9c-1.2,0.4-1.9,0.9-2,1.5
			c0.1-2.9,0.1-5.2,0.1-7c0-1.8,0-3.2,0-4.3c0-1.1,0-1.9,0-2.5c0-0.6-0.1-1.1-0.1-1.7c0-0.5-0.1-1.1-0.1-1.7
			C-104.5-27.4-104.6-28.3-104.6-29.5z M-90.4,5.1c0.3,0.9,0.5,1.7,0.6,2.7c0.1,0.9,0.1,1.8,0.1,2.7c0,1.5-0.2,3-0.6,4.5
			c-0.4,1.4-0.9,2.7-1.7,3.9c-0.7,1.1-1.6,2-2.7,2.7c-1,0.7-2.2,1-3.5,1c-1.6,0-2.8-0.3-3.7-1c-0.9-0.7-1.5-1.6-2-2.8
			c-0.5-1.2-0.7-2.6-0.8-4.2s-0.1-3.4-0.1-5.3c0-2.4,0-4.3,0.1-5.7c0.1-1.4,0.3-2.4,0.7-3.2s0.9-1.2,1.7-1.4
			c0.8-0.2,1.8-0.3,3.1-0.3c2.5,0,4.5,0.6,6,1.7C-91.6,1.3-90.7,3-90.4,5.1z"/>
                    <path className="finale-0" d="M-71.5,54.9l12.5-0.3c0-2.5,0-5.5-0.1-9.1c-0.1-3.6-0.1-7.4-0.2-11.4c-0.1-4-0.1-8.2-0.1-12.6
			c0-4.4,0-8.5,0-12.6s0-7.8,0.1-11.4c0.1-3.5,0.2-6.5,0.3-9c-0.3-0.4-1.1-0.6-2.3-0.6c-1.2,0-2.6,0-4,0c-1.4,0-2.8,0.1-4.1,0.1
			c-1.3,0.1-2.1,0-2.6-0.1c0.4,5.5,0.6,11.1,0.5,16.6c-0.1,5.6-0.2,11.2-0.4,16.7c-0.2,5.6-0.3,11.2-0.3,16.7
			C-72.3,43.8-72,49.3-71.5,54.9z"/>
                    <path className="finale-0" d="M-39.5,11.2V9.5c0.1-1.8,0.1-3.3,0.2-4.5c0.1-1.2,0.5-2.3,1.1-3.4c0.7-1.4,1.8-2.5,3.1-3.1
			c1.3-0.7,2.7-0.9,4-0.6c1.3,0.2,2.4,1,3.3,2.3c0.9,1.3,1.3,3.1,1.1,5.7c0.6-0.1,1.4-0.1,2.5,0c1,0,2.1,0.1,3.3,0.1
			c1.1,0.1,2.2,0.1,3.3,0c1,0,1.9-0.1,2.6-0.3c-0.1-1.6-0.2-3.2-0.4-4.7c-0.1-1.5-0.3-3-0.5-4.3c-0.2-1.3-0.4-2.5-0.7-3.4
			c-0.3-1-0.6-1.6-1.1-2c-1-1.5-2.4-2.5-4-3.1s-3.5-1-5.4-1.1c-2-0.1-4-0.1-6,0c-2.1,0.1-3.9,0.2-5.7,0.2c-1.7,0.2-3.3,0.8-4.8,1.7
			c-1.7,1.2-3,2.7-4.1,4.5c-1.1,1.9-2,3.9-2.7,6.2c-0.7,2.2-1.1,4.6-1.4,7.1c-0.2,2.5-0.3,5-0.2,7.5c0.2,2.3,0.4,4.5,0.6,6.6
			c0.2,2.1,0.7,4.2,1.4,6.2c0.8,1.8,2,3.3,3.7,4.5c1.7,1.3,3.5,2.2,5.6,2.8c2.1,0.6,4.3,1,6.6,1.1c2.3,0.1,4.6-0.2,6.8-0.7
			c2.8-0.5,5.1-1.5,6.8-2.9c1.7-1.5,3-3.2,3.9-5.3c0.9-2.1,1.4-4.4,1.6-6.9c0.2-2.5,0.2-5.1,0-7.6c-0.4-0.1-1.2-0.2-2.4-0.4
			c-1.2-0.1-2.6-0.2-4.2-0.4c-1.6-0.1-3.4-0.2-5.2-0.3c-1.8-0.1-3.6-0.1-5.3-0.1c-1.7,0-3.2,0-4.5,0.1C-37.9,11-38.9,11-39.5,11.2z
			 M-28.4,25.4c-0.5,0.3-1,0.5-1.5,0.7c-0.5,0.2-1.1,0.2-1.7,0.2c-1.5,0-2.7-0.2-3.6-0.5s-1.7-0.7-2.2-1.3c-0.6-0.6-1-1.2-1.2-1.9
			c-0.3-0.7-0.5-1.5-0.6-2.3c-0.1-0.7-0.2-1.1-0.1-1.4c0-0.3,0.3-0.5,0.9-0.6c0.6-0.1,1.4-0.1,2.6-0.1c1.2,0,3,0,5.3,0
			c1.6,0,2.8,0,3.6-0.1c0.8-0.1,1.4-0.1,1.7,0c0.3,0,0.5,0.1,0.5,0.3c0,0.2,0,0.5,0,1c0,1.2-0.3,2.4-1,3.5
			C-26.4,24-27.2,24.9-28.4,25.4z"/>
                    <path className="finale-0" d="M8.7,51V38.6c0-0.8,0-1.6,0-2.5c0-0.9,0-1.7,0-2.7c1.9,0.1,3.9,0.2,6,0.3c2.1,0.1,4.7,0.3,7.9,0.5
			c0.4-0.5,0.7-1.2,0.7-2.2c0.1-1,0.1-2,0-3.1c-0.1-1.1-0.1-2.2-0.2-3.4c-0.1-1.1-0.1-2.1,0-2.9H9c0-3.3,0-6.5,0-9.7
			c0-3.2,0-6.1,0.1-8.9c0.6-0.9,1.5-1.5,2.7-2c1.2-0.5,2.4-0.9,3.8-1.1c1.3-0.3,2.7-0.4,4.1-0.5c1.4-0.1,2.6-0.1,3.6,0
			c0.1-0.9,0.2-1.8,0.4-2.8c0.1-1,0.2-2.1,0.3-3.2c0.1-1.1,0-2.2,0-3.3c-0.1-1.1-0.3-2.1-0.6-3c-2.5-0.1-5-0.1-7.6,0
			c-2.6,0.2-5.1,0.5-7.4,1.1c-2.3,0.6-4.4,1.4-6.2,2.5C0.1-7.2-1.2-5.7-2-3.8C-2.8,0.9-3.2,5.4-3.4,9.6c-0.1,4.2-0.2,8.5-0.1,13
			c-1-0.1-2-0.1-3.1-0.1c-1.1,0-2.3,0-3.5,0l0.3,11.2c1.2-0.1,2.4-0.1,3.4-0.1c1.1,0,2.1,0,3.1,0c0,1.3,0,2.8-0.1,4.4
			c-0.1,1.6-0.1,3.1-0.1,4.7c0,1.6,0,3.1,0,4.6c0,1.5,0.1,2.8,0.2,4c0.8,0.1,1.7,0.2,2.6,0.1c0.9-0.1,1.9-0.1,3-0.2
			c1-0.1,2.1-0.2,3.1-0.2C6.6,50.8,7.6,50.8,8.7,51z"/>
                    <path className="finale-0" d="M41.1,11.2V9.5c0.1-1.8,0.1-3.3,0.2-4.5c0.1-1.2,0.5-2.3,1.1-3.4c0.7-1.4,1.8-2.5,3.1-3.1
			c1.3-0.7,2.7-0.9,4-0.6c1.3,0.2,2.4,1,3.3,2.3c0.9,1.3,1.3,3.1,1.1,5.7c0.6-0.1,1.4-0.1,2.5,0c1,0,2.1,0.1,3.3,0.1
			c1.1,0.1,2.2,0.1,3.3,0c1,0,1.9-0.1,2.6-0.3c-0.1-1.6-0.2-3.2-0.4-4.7c-0.1-1.5-0.3-3-0.5-4.3c-0.2-1.3-0.4-2.5-0.7-3.4
			c-0.3-1-0.6-1.6-1.1-2c-1-1.5-2.4-2.5-4-3.1s-3.5-1-5.4-1.1c-2-0.1-4-0.1-6,0c-2.1,0.1-3.9,0.2-5.7,0.2c-1.7,0.2-3.3,0.8-4.8,1.7
			c-1.7,1.2-3,2.7-4.1,4.5c-1.1,1.9-2,3.9-2.7,6.2c-0.7,2.2-1.1,4.6-1.4,7.1c-0.2,2.5-0.3,5-0.2,7.5c0.2,2.3,0.4,4.5,0.6,6.6
			c0.2,2.1,0.7,4.2,1.4,6.2c0.8,1.8,2,3.3,3.7,4.5c1.7,1.3,3.5,2.2,5.6,2.8c2.1,0.6,4.3,1,6.6,1.1c2.3,0.1,4.6-0.2,6.8-0.7
			c2.8-0.5,5.1-1.5,6.8-2.9c1.7-1.5,3-3.2,3.9-5.3c0.9-2.1,1.4-4.4,1.6-6.9c0.2-2.5,0.2-5.1,0-7.6c-0.4-0.1-1.2-0.2-2.4-0.4
			c-1.2-0.1-2.6-0.2-4.2-0.4c-1.6-0.1-3.4-0.2-5.2-0.3c-1.8-0.1-3.6-0.1-5.3-0.1c-1.7,0-3.2,0-4.5,0.1C42.7,11,41.7,11,41.1,11.2z
			 M52.2,25.4c-0.5,0.3-1,0.5-1.5,0.7c-0.5,0.2-1.1,0.2-1.7,0.2c-1.5,0-2.7-0.2-3.6-0.5s-1.7-0.7-2.2-1.3c-0.6-0.6-1-1.2-1.2-1.9
			c-0.3-0.7-0.5-1.5-0.6-2.3c-0.1-0.7-0.2-1.1-0.1-1.4c0-0.3,0.3-0.5,0.9-0.6c0.6-0.1,1.4-0.1,2.6-0.1c1.2,0,3,0,5.3,0
			c1.6,0,2.8,0,3.6-0.1c0.8-0.1,1.4-0.1,1.7,0c0.3,0,0.5,0.1,0.5,0.3c0,0.2,0,0.5,0,1c0,1.2-0.3,2.4-1,3.5
			C54.2,24,53.3,24.9,52.2,25.4z"/>
                    <path className="finale-0" d="M97.2,51.7v1c0,0.1,0.4,0.1,1.2,0.2s1.8,0.2,3,0.2c1.2,0,2.5,0,3.8,0c1.3,0,2.5-0.1,3.5-0.3v-6.1
			c0-10.2,0.2-20.3,0.5-30.3c0.3-10,0.3-19.4,0.1-28.2c-1.9,0-4,0-6.2-0.1c-2.3-0.1-4.3,0-6.2,0.2c-0.1,0.1-0.1,0.3-0.1,0.6
			c0,0.6,0,1.1,0,1.5c0,0.4,0,0.9,0,1.6c-0.1,0.3-0.3,0.5-0.4,0.7c-0.2,0.2-0.3,0-0.5-0.3c-0.9-1.8-2.1-3-3.7-3.5
			c-1.5-0.5-3.2-0.7-4.9-0.4c-3.5,0.2-6.2,1.1-8.3,2.7c-2,1.6-3.5,3.6-4.5,6c-1,2.4-1.7,5.1-2,8.1c-0.3,3-0.5,6-0.6,9.1
			c0,2.1,0.1,4.2,0.4,6.2c0.3,2,0.7,3.9,1.3,5.6c0.6,1.7,1.3,3.1,2.3,4.3c0.9,1.2,2.1,2,3.4,2.5c1.7,0.6,3.5,1,5.5,1.1
			c2,0.2,3.9,0.2,5.7,0c1.8-0.2,3.3-0.4,4.5-0.9c1.2-0.4,1.9-0.9,2-1.5c-0.1,2.9-0.1,5.2-0.1,7c0,1.8,0,3.2,0,4.3c0,1.1,0,1.9,0,2.5
			c0,0.6,0.1,1.1,0.1,1.7c0,0.5,0.1,1.1,0.1,1.7C97.1,49.5,97.1,50.5,97.2,51.7z M82.9,17.1c-0.3-0.9-0.5-1.7-0.6-2.7
			c-0.1-0.9-0.1-1.8-0.1-2.7c0-1.5,0.2-3,0.6-4.5c0.4-1.4,0.9-2.7,1.7-3.9s1.6-2,2.7-2.7c1-0.7,2.2-1,3.5-1c1.5,0,2.7,0.3,3.6,1
			c0.9,0.7,1.6,1.6,2,2.8S97,6,97.1,7.6c0.1,1.6,0.1,3.4,0.1,5.4c0,2.4,0,4.3-0.1,5.7c-0.1,1.4-0.3,2.4-0.7,3.2
			c-0.4,0.7-0.9,1.2-1.7,1.4c-0.8,0.2-1.8,0.3-3.1,0.3c-2.5,0-4.5-0.6-6-1.7C84.2,20.8,83.2,19.2,82.9,17.1z"/>
                    <path className="finale-0" d="M153.9,51V38.6c0-0.8,0-1.6,0-2.5c0-0.9,0-1.7,0-2.7c1.9,0.1,3.9,0.2,6,0.3c2.1,0.1,4.7,0.3,7.9,0.5
			c0.4-0.5,0.7-1.2,0.7-2.2c0.1-1,0.1-2,0-3.1c-0.1-1.1-0.1-2.2-0.2-3.4c-0.1-1.1-0.1-2.1,0-2.9h-14.2c0-3.3,0-6.5,0-9.7
			c0-3.2,0-6.1,0.1-8.9c0.6-0.9,1.5-1.5,2.7-2c1.2-0.5,2.4-0.9,3.8-1.1c1.3-0.3,2.7-0.4,4.1-0.5c1.4-0.1,2.6-0.1,3.6,0
			c0.1-0.9,0.2-1.8,0.4-2.8c0.1-1,0.2-2.1,0.3-3.2c0.1-1.1,0-2.2,0-3.3c-0.1-1.1-0.3-2.1-0.6-3c-2.5-0.1-5-0.1-7.6,0
			c-2.6,0.2-5.1,0.5-7.4,1.1c-2.3,0.6-4.4,1.4-6.2,2.5c-1.8,1.1-3.1,2.6-3.9,4.5c-0.8,4.7-1.3,9.2-1.4,13.4c-0.1,4.2-0.2,8.5-0.1,13
			c-1-0.1-2-0.1-3.1-0.1c-1.1,0-2.3,0-3.5,0l0.3,11.2c1.2-0.1,2.4-0.1,3.4-0.1c1.1,0,2.1,0,3.1,0c0,1.3,0,2.8-0.1,4.4
			c-0.1,1.6-0.1,3.1-0.1,4.7c0,1.6,0,3.1,0,4.6c0,1.5,0.1,2.8,0.2,4c0.8,0.1,1.7,0.2,2.6,0.1c0.9-0.1,1.9-0.1,3-0.2
			c1-0.1,2.1-0.2,3.1-0.2C151.8,50.8,152.8,50.8,153.9,51z"/>
                    <path className="finale-0" d="M187.3,29.1c0.3,0.2,0.7,0.4,1.1,0.6c0.5,0.2,0.8,0.5,1.1,0.7c1.3,0.9,2.7,1.7,4.3,2.2
			c1.6,0.6,3.2,0.9,4.9,0.9c1.7,0.1,3.3-0.1,4.9-0.6c1.6-0.4,3-1.2,4.3-2.3c0.6-0.6,1.1-1.7,1.4-3.4c0.3-1.7,0.6-3.8,0.6-6.3
			c0.1-2.5,0.1-5.1,0.1-8c0-2.9-0.1-5.9-0.1-8.8c-0.1-3-0.1-5.8-0.2-8.6c-0.1-2.8,0-5.2,0.1-7.3c-2.1-0.3-4.1-0.4-5.7-0.4
			c-1.7,0-3.6,0-5.7,0c-0.1,1.4-0.2,3-0.2,4.8c0,1.8,0,3.6,0,5.5c0,1.9,0,3.8,0,5.7c0,1.9-0.1,3.7-0.2,5.3c-0.1,0.7-0.1,1.5-0.2,2.4
			c-0.1,0.9-0.2,1.8-0.3,2.6c-0.2,0.9-0.4,1.6-0.6,2.3c-0.3,0.7-0.7,1.2-1.1,1.6c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0.1
			c-0.5,0.2-1.2,0.4-2.2,0.6c-1,0.2-1.9,0.1-2.8-0.2c-0.9-0.3-1.7-0.8-2.5-1.7c-0.7-0.8-1.2-2.1-1.3-3.7c-0.1-2.1-0.2-4.2-0.1-6.3
			c0.1-2.1,0.1-4.1,0.2-6.2s0.1-4.1,0.1-6.1c0-2-0.1-4-0.4-6.1v-0.4c-1-0.3-1.9-0.5-2.7-0.6c-0.8-0.1-1.5-0.1-2.3,0
			c-0.8,0-1.7,0.1-2.7,0.1c-1,0.1-2.2,0-3.7-0.1c0,2.5,0,5.5,0,9.1c0,3.5-0.1,7.3-0.1,11.3c0,4,0,8.2,0,12.5c0,4.3,0,8.5,0,12.6
			c0,4.1,0,8,0,11.6c0,3.6,0.1,6.8,0.1,9.5c0.7-0.1,1.3-0.1,1.8-0.1c0.5,0,0.9,0,1.4,0c0.4,0,0.9,0,1.4,0c0.5,0,1.1,0.1,1.8,0.1
			c0.2,0,0.7,0,1.3,0.1c0.6,0.1,1.2,0.2,1.8,0.2c0.6,0.1,1.2,0.1,1.7,0c0.5,0,0.7-0.1,0.7-0.3c0.1-0.3,0.1-1.1,0.1-2.4
			c0-1.3,0-2.9,0-4.7c0-1.8,0-3.7,0-5.8c0-2.1,0-4,0-5.7c0-1.8,0-3.3,0-4.6C187.3,30.1,187.3,29.3,187.3,29.1z"/>
                    <path className="finale-0" d="M229.8,11.2V9.5c0.1-1.8,0.1-3.3,0.2-4.5c0.1-1.2,0.5-2.3,1.1-3.4c0.7-1.4,1.8-2.5,3.1-3.1
			c1.3-0.7,2.7-0.9,4-0.6c1.3,0.2,2.4,1,3.3,2.3c0.9,1.3,1.3,3.1,1.1,5.7c0.6-0.1,1.4-0.1,2.5,0c1,0,2.1,0.1,3.3,0.1
			c1.1,0.1,2.2,0.1,3.3,0c1,0,1.9-0.1,2.6-0.3c-0.1-1.6-0.2-3.2-0.4-4.7c-0.1-1.5-0.3-3-0.5-4.3c-0.2-1.3-0.4-2.5-0.7-3.4
			c-0.3-1-0.6-1.6-1.1-2c-1-1.5-2.4-2.5-4-3.1s-3.5-1-5.4-1.1c-2-0.1-4-0.1-6,0c-2.1,0.1-3.9,0.2-5.7,0.2c-1.7,0.2-3.3,0.8-4.8,1.7
			c-1.7,1.2-3,2.7-4.1,4.5c-1.1,1.9-2,3.9-2.7,6.2c-0.7,2.2-1.1,4.6-1.4,7.1c-0.2,2.5-0.3,5-0.2,7.5c0.2,2.3,0.4,4.5,0.6,6.6
			c0.2,2.1,0.7,4.2,1.4,6.2c0.8,1.8,2,3.3,3.7,4.5c1.7,1.3,3.5,2.2,5.6,2.8c2.1,0.6,4.3,1,6.6,1.1c2.3,0.1,4.6-0.2,6.8-0.7
			c2.8-0.5,5.1-1.5,6.8-2.9c1.7-1.5,3-3.2,3.9-5.3c0.9-2.1,1.4-4.4,1.6-6.9c0.2-2.5,0.2-5.1,0-7.6c-0.4-0.1-1.2-0.2-2.4-0.4
			c-1.2-0.1-2.6-0.2-4.2-0.4c-1.6-0.1-3.4-0.2-5.2-0.3c-1.8-0.1-3.6-0.1-5.3-0.1c-1.7,0-3.2,0-4.5,0.1C231.4,11,230.4,11,229.8,11.2
			z M240.9,25.4c-0.5,0.3-1,0.5-1.5,0.7c-0.5,0.2-1.1,0.2-1.7,0.2c-1.5,0-2.7-0.2-3.6-0.5s-1.7-0.7-2.2-1.3c-0.6-0.6-1-1.2-1.2-1.9
			c-0.3-0.7-0.5-1.5-0.6-2.3c-0.1-0.7-0.2-1.1-0.1-1.4c0-0.3,0.3-0.5,0.9-0.6c0.6-0.1,1.4-0.1,2.6-0.1c1.2,0,3,0,5.3,0
			c1.6,0,2.8,0,3.6-0.1c0.8-0.1,1.4-0.1,1.7,0c0.3,0,0.5,0.1,0.5,0.3c0,0.2,0,0.5,0,1c0,1.2-0.3,2.4-1,3.5
			C242.9,24,242,24.9,240.9,25.4z"/>
                    <path className="finale-0" d="M-259.4-34.8l12.5-0.3c0-2.5,0-5.5-0.1-9.1c-0.1-3.6-0.1-7.4-0.2-11.4c-0.1-4-0.1-8.2-0.1-12.6
			c0-4.4,0-8.5,0-12.6s0-7.8,0.1-11.4c0.1-3.5,0.2-6.5,0.3-9c-0.3-0.4-1.1-0.6-2.3-0.6c-1.2,0-2.6,0-4,0c-1.4,0-2.8,0.1-4.1,0.1
			c-1.3,0.1-2.1,0-2.6-0.1c0.4,5.5,0.6,11.1,0.5,16.6c-0.1,5.6-0.2,11.2-0.4,16.7c-0.2,5.6-0.3,11.2-0.3,16.7
			C-260.2-46-260-40.4-259.4-34.8z"/>
                    <path className="finale-0" d="M-227.4-78.6v-1.7c0.1-1.8,0.1-3.3,0.2-4.5c0.1-1.2,0.5-2.3,1.1-3.4c0.7-1.4,1.8-2.5,3.1-3.1
			c1.3-0.7,2.7-0.9,4-0.6c1.3,0.2,2.4,1,3.3,2.3c0.9,1.3,1.3,3.1,1.1,5.7c0.6-0.1,1.4-0.1,2.5,0c1,0,2.1,0.1,3.3,0.1
			c1.1,0.1,2.2,0.1,3.3,0c1,0,1.9-0.1,2.6-0.3c-0.1-1.6-0.2-3.2-0.4-4.7c-0.1-1.5-0.3-3-0.5-4.3c-0.2-1.3-0.4-2.5-0.7-3.4
			c-0.3-1-0.6-1.6-1.1-2c-1-1.5-2.4-2.5-4-3.1s-3.5-1-5.4-1.1c-2-0.1-4-0.1-6,0c-2.1,0.1-3.9,0.2-5.7,0.2c-1.7,0.2-3.3,0.8-4.8,1.7
			c-1.7,1.2-3,2.7-4.1,4.5c-1.1,1.9-2,3.9-2.7,6.2c-0.7,2.2-1.1,4.6-1.4,7.1c-0.2,2.5-0.3,5-0.2,7.5c0.2,2.3,0.4,4.5,0.6,6.6
			c0.2,2.1,0.7,4.2,1.4,6.2c0.8,1.8,2,3.3,3.7,4.5c1.7,1.3,3.5,2.2,5.6,2.8c2.1,0.6,4.3,1,6.6,1.1c2.3,0.1,4.6-0.2,6.8-0.7
			c2.8-0.5,5.1-1.5,6.8-2.9c1.7-1.5,3-3.2,3.9-5.3c0.9-2.1,1.4-4.4,1.6-6.9c0.2-2.5,0.2-5.1,0-7.6c-0.4-0.1-1.2-0.2-2.4-0.4
			c-1.2-0.1-2.6-0.2-4.2-0.4c-1.6-0.1-3.4-0.2-5.2-0.3c-1.8-0.1-3.6-0.1-5.3-0.1c-1.7,0-3.2,0-4.5,0.1
			C-225.8-78.8-226.8-78.7-227.4-78.6z M-216.3-64.3c-0.5,0.3-1,0.5-1.5,0.7c-0.5,0.2-1.1,0.2-1.7,0.2c-1.5,0-2.7-0.2-3.6-0.5
			s-1.7-0.7-2.2-1.3c-0.6-0.6-1-1.2-1.2-1.9c-0.3-0.7-0.5-1.5-0.6-2.3c-0.1-0.7-0.2-1.1-0.1-1.4c0-0.3,0.3-0.5,0.9-0.6
			c0.6-0.1,1.4-0.1,2.6-0.1c1.2,0,3,0,5.3,0c1.6,0,2.8,0,3.6-0.1c0.8-0.1,1.4-0.1,1.7,0c0.3,0,0.5,0.1,0.5,0.3c0,0.2,0,0.5,0,1
			c0,1.2-0.3,2.4-1,3.5C-214.3-65.7-215.2-64.9-216.3-64.3z"/>
                    <path className="finale-0" d="M-158.3-55.9c0.1-0.4,0.1-0.7,0.1-1.1c0-0.4,0-0.7,0-1.1V-59c-0.2-6.6-0.2-13.1,0.1-19.5
			c0.2-6.4,0.2-12.9-0.3-19.4c0-0.8,0-1.6,0-2.4c0-0.8-0.4-1.3-1.1-1.4h-1.1c-1,0-2,0-3,0.1c-1,0.1-2,0.1-3,0.1c-0.7,0-1.3,0-2-0.1
			c-0.6-0.1-1.3-0.1-2-0.1c-0.4,0.1-0.7,0.4-0.9,0.7c-0.2,0.4-0.2,0.8-0.2,1.3c0,0.7,0.1,1.2,0.2,1.7c0.1,0.4,0.2,0.9,0.2,1.6
			c-1.5-1.5-3.3-2.7-5.6-3.8c-2.2-1-4.4-1.6-6.6-1.6c-2.4,0-4.4,0.8-6.1,2.5c-1.7,1.7-3.1,3.7-4.2,6.2c-1.1,2.5-1.9,5.1-2.4,8
			c-0.5,2.9-0.8,5.5-0.8,8c0,3.1,0.4,5.9,1.2,8.5c0.8,2.6,1.9,4.8,3.3,6.7c1.4,1.9,3,3.3,4.9,4.4c1.9,1.1,3.9,1.6,6.1,1.7
			c1.1,0,2.1,0,3.1,0c0.9,0,1.8-0.2,2.6-0.5c0.8-0.3,1.5-0.7,2.1-1.3c0.6-0.6,1.1-1.4,1.6-2.4c-0.1,1,0,1.8,0.1,2.5
			c0.2,0.7,0.2,1.4,0.2,2.2c0,0.1,0.2,0.1,0.6,0.1c0.4,0,0.8,0,1.3,0c0.5,0,1,0,1.5,0c0.5,0,0.8,0,1,0c1-0.1,2.3-0.1,3.8-0.2
			C-162-55.7-160.3-55.8-158.3-55.9z M-185-74.2c-0.2-0.8-0.4-1.6-0.5-2.4c-0.1-0.8-0.1-1.6-0.1-2.4c0-2.8,0.7-5.1,2.2-7
			c1.4-1.9,3.4-2.9,6-2.9c1.5,0,2.7,0.3,3.4,0.8c0.8,0.5,1.3,1.3,1.7,2.2c0.3,0.9,0.5,2.1,0.6,3.5c0,1.4,0,2.9,0,4.6
			c0,2.1,0,3.8,0,5.1c0,1.3-0.2,2.2-0.5,2.9c-0.3,0.7-0.7,1.1-1.4,1.3c-0.6,0.2-1.6,0.3-2.9,0.3c-2.5,0-4.5-0.6-5.9-1.8
			C-183.9-71.3-184.7-72.6-185-74.2z"/>
                    <path className="finale-0" d="M-136.7-58.9c0.2,0.1,0.8,0.4,1.7,0.7c0.9,0.4,1.8,0.7,2.6,1c1.2,0.4,2.5,0.7,4,0.9c1.5,0.2,2.8,0.4,4.1,0.4
			c1.3,0.1,2.4,0.1,3.3,0c0.9,0,1.3-0.1,1.3-0.1c0-2.1,0-3.9,0-5.5c0-1.6,0-3,0-4.3c0-1.3,0-2.4,0-3.4c0-1,0-2,0.1-2.9l-0.8,0.3
			c-0.3,0.1-0.6,0.2-1,0.3c-0.3,0.1-0.7,0.2-1,0.3c-0.7,0.2-1.5,0.3-2.3,0.5c-0.8,0.1-1.2,0.2-1.2,0.2c-1.7,0.2-3.2,0.3-4.5,0.3
			c-1.4,0-2.6-0.2-3.6-0.6c-1-0.4-1.8-1.1-2.3-2c-0.6-0.9-0.8-2.2-0.7-4c0.1-2.1,0.1-4.2,0.2-6.1c0.1-1.9,0.1-3.8,0.1-5.7
			c0-1.9,0-3.9,0-5.9c0-2,0-4.1,0-6.4v-0.4c-1-0.3-2-0.5-2.8-0.6c-0.9-0.1-1.7-0.1-2.7,0c-0.9,0-1.9,0.1-3,0.1
			c-1.1,0.1-2.3,0-3.8-0.1c0,7.5-0.1,15.1-0.2,22.6c-0.2,7.5-0.1,15.3,0.1,23.4c0.7-0.1,1.3-0.1,1.8-0.1c0.5,0,0.9,0,1.4,0
			c0.4,0,0.9,0,1.4,0c0.5,0,1.1,0.1,1.8,0.1c0.2,0,0.7,0,1.3,0.1c0.6,0.1,1.3,0.1,2,0.2c0.7,0.1,1.3,0.1,1.8,0
			c0.5,0,0.8-0.1,0.8-0.2c0-0.5,0-0.9,0-1.1c0-0.2,0-0.4,0-0.6c0-0.2,0-0.3,0-0.5C-136.7-58.2-136.7-58.5-136.7-58.9z"/>
                    <path className="finale-0" d="M-101-60.7c0.3,0.2,0.7,0.4,1.1,0.6c0.5,0.2,0.8,0.5,1.1,0.7c1.3,0.9,2.7,1.7,4.3,2.2
			c1.6,0.6,3.2,0.9,4.9,0.9c1.7,0.1,3.3-0.1,4.9-0.6c1.6-0.4,3-1.2,4.3-2.3c0.6-0.6,1.1-1.7,1.4-3.4c0.3-1.7,0.6-3.8,0.6-6.3
			c0.1-2.5,0.1-5.1,0.1-8c0-2.9-0.1-5.9-0.1-8.8c-0.1-3-0.1-5.8-0.2-8.6c-0.1-2.8,0-5.2,0.1-7.3c-2.1-0.3-4.1-0.4-5.7-0.4
			c-1.7,0-3.6,0-5.7,0c-0.1,1.4-0.2,3-0.2,4.8c0,1.8,0,3.6,0,5.5c0,1.9,0,3.8,0,5.7c0,1.9-0.1,3.7-0.2,5.3c-0.1,0.7-0.1,1.5-0.2,2.4
			c-0.1,0.9-0.2,1.8-0.3,2.6c-0.2,0.9-0.4,1.6-0.6,2.3c-0.3,0.7-0.7,1.2-1.1,1.6c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0.1
			c-0.5,0.2-1.2,0.4-2.2,0.6c-1,0.2-1.9,0.1-2.8-0.2s-1.7-0.8-2.5-1.7s-1.2-2.1-1.3-3.7c-0.1-2.1-0.2-4.2-0.1-6.3
			c0.1-2.1,0.1-4.1,0.2-6.2c0.1-2.1,0.1-4.1,0.1-6.1c0-2-0.1-4-0.4-6.1v-0.4c-1-0.3-1.9-0.5-2.7-0.6c-0.8-0.1-1.5-0.1-2.3,0
			c-0.8,0-1.7,0.1-2.7,0.1c-1,0.1-2.2,0-3.7-0.1c0,7.5-0.1,15.1-0.2,22.6c-0.2,7.5-0.1,15.3,0.1,23.4c0.7-0.1,1.3-0.1,1.8-0.1
			c0.5,0,0.9,0,1.4,0c0.4,0,0.9,0,1.4,0c0.5,0,1.1,0.1,1.8,0.1c0.2,0,0.7,0,1.3,0.1c0.6,0.1,1.2,0.2,1.8,0.2c0.6,0.1,1.2,0.1,1.7,0
			c0.5,0,0.7-0.1,0.7-0.3c0.1-1,0.2-1.9,0.1-2.6C-101-59-101-59.8-101-60.7z"/>
                    <path className="finale-0" d="M-58.3-55.4h1.6v-0.3c-0.1-7.4-0.2-15-0.4-22.7c-0.2-7.7-0.1-15.3,0.3-22.7c-0.3-0.4-1.1-0.6-2.3-0.6
			c-1.2,0-2.6,0-4,0c-1.4,0-2.8,0.1-4.1,0.1c-1.3,0.1-2.1,0-2.6-0.1c0.3,3.9,0.4,7.8,0.4,11.8c0,4-0.1,7.9-0.2,11.9
			c-0.1,3.9-0.2,7.8-0.2,11.6s0.2,7.6,0.6,11.2v0.1c0.2,0.1,0.7,0.1,1.2,0c0.6-0.1,1-0.1,1.3-0.1c0.8-0.1,1.9-0.1,3.3-0.2
			C-62-55.5-60.3-55.5-58.3-55.4z M-69.6-35.4l12.5-0.3c0-0.4,0-1,0-2c0-1,0.1-2,0.1-3.1c0-1.1,0.1-2.3,0.1-3.5c0-1.2,0-2.3,0-3.2
			c-0.2-0.4-0.8-0.6-1.7-0.7c-0.9-0.1-1.9-0.2-3.1-0.1c-1.2,0-2.5,0.1-3.8,0.2c-1.3,0.1-2.6,0.1-3.8,0c0,1.8-0.1,3.6-0.2,5.6
			C-69.6-40.6-69.7-38.2-69.6-35.4z"/>
                    <path className="finale-0" d="M-36-60.7c0.3,0.2,0.7,0.4,1.1,0.6c0.5,0.2,0.8,0.5,1.1,0.7c1.3,0.9,2.7,1.7,4.3,2.2c1.6,0.6,3.2,0.9,4.9,0.9
			c1.7,0.1,3.3-0.1,4.9-0.6c1.6-0.4,3-1.2,4.3-2.3c0.6-0.6,1.1-1.7,1.4-3.4c0.3-1.7,0.6-3.8,0.6-6.3c0.1-2.5,0.1-5.1,0.1-8
			c0-2.9-0.1-5.9-0.1-8.8c-0.1-3-0.1-5.8-0.2-8.6c-0.1-2.8,0-5.2,0.1-7.3c-2.1-0.3-4.1-0.4-5.7-0.4c-1.7,0-3.6,0-5.7,0
			c-0.1,1.4-0.2,3-0.2,4.8c0,1.8,0,3.6,0,5.5c0,1.9,0,3.8,0,5.7c0,1.9-0.1,3.7-0.2,5.3c-0.1,0.7-0.1,1.5-0.2,2.4
			c-0.1,0.9-0.2,1.8-0.3,2.6c-0.2,0.9-0.4,1.6-0.6,2.3c-0.3,0.7-0.7,1.2-1.1,1.6c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0.1
			c-0.5,0.2-1.2,0.4-2.2,0.6c-1,0.2-1.9,0.1-2.8-0.2s-1.7-0.8-2.5-1.7s-1.2-2.1-1.3-3.7c-0.1-2.1-0.2-4.2-0.1-6.3
			c0.1-2.1,0.1-4.1,0.2-6.2c0.1-2.1,0.1-4.1,0.1-6.1c0-2-0.1-4-0.4-6.1v-0.4c-1-0.3-1.9-0.5-2.7-0.6c-0.8-0.1-1.5-0.1-2.3,0
			c-0.8,0-1.7,0.1-2.7,0.1c-1,0.1-2.2,0-3.7-0.1c0,7.5-0.1,15.1-0.2,22.6c-0.2,7.5-0.1,15.3,0.1,23.4c0.7-0.1,1.3-0.1,1.8-0.1
			c0.5,0,0.9,0,1.4,0c0.4,0,0.9,0,1.4,0c0.5,0,1.1,0.1,1.8,0.1c0.2,0,0.7,0,1.3,0.1c0.6,0.1,1.2,0.2,1.8,0.2c0.6,0.1,1.2,0.1,1.7,0
			c0.5,0,0.7-0.1,0.7-0.3c0.1-1,0.2-1.9,0.1-2.6C-36.1-59-36-59.8-36-60.7z"/>
                    <path className="finale-0" d="M20.4-120c-0.6-0.1-1.7-0.2-3.3-0.1c-1.6,0.1-3.6,0.1-6.2,0.1c-2.9,0-5.5,0.6-7.7,1.8c-2.3,1.2-4.1,2.9-5.5,5
			c-0.2,0.4-0.5,1-0.7,1.7c-0.2,0.7-0.4,1.4-0.6,2.2c-0.2,0.7-0.2,1.4-0.3,2.1c0,0.7,0,1.3,0,1.7c0.6,0.2,1.4,0.4,2.5,0.5
			c1,0.1,2.1,0.1,3.3,0.1c1.1,0,2.2-0.1,3.3-0.2c1.1-0.1,2-0.2,2.7-0.3c0-1.6,0.5-2.9,1.5-3.8c1-0.9,2.1-1.5,3.4-1.6
			c1.3-0.1,2.5,0.2,3.7,0.9s2,2,2.3,3.7c0.1,0.6,0.1,1.2,0.2,1.9c0.1,0.6,0.1,1.2,0,1.7c-0.1,1-0.1,2.1,0,3.2
			c-0.6-0.7-1.3-1.2-2.2-1.5c-0.8-0.3-1.7-0.5-2.7-0.6c-1-0.1-1.9-0.2-2.9-0.2c-1,0-1.9,0-2.8-0.1c-1.2,0-2.3,0.1-3.5,0.3
			s-2.3,0.5-3.4,0.9c-1.1,0.4-2,1-2.9,1.7C-2-98.2-2.7-97.2-3.3-96c-0.9,1.7-1.5,3.4-1.8,5.2c-0.4,1.8-0.6,3.7-0.7,5.6
			c0,2.9,0.1,6,0.2,9.3c0.1,3.3,0.7,6.4,1.6,9.3c0.9,2.9,2.5,5.4,4.5,7.4c2.1,2,5.1,3.1,9,3.4c2,0.1,3.8-0.1,5.5-0.8
			c1.7-0.6,2.9-1.8,3.6-3.5c0.2-0.1,0.4,0,0.4,0.3c0,0.3,0,0.8,0,1.3c0,0.5-0.1,1-0.1,1.6c0,0.5,0,0.9,0.2,1.2
			c2,0.2,4.1,0.3,6.2,0.2c2.1-0.1,4.2-0.1,6.2-0.1c0.2-7.7,0.4-15.7,0.4-24c0-8.4-0.1-17-0.5-25.8c-0.1-1.5-0.1-2.9,0-4.4
			c0.1-1.4-0.2-2.6-0.8-3.6c-0.9-1.3-1.7-2.4-2.6-3.2c-0.9-0.8-1.8-1.4-2.6-1.9c-0.9-0.5-1.7-0.8-2.6-1C22-119.8,21.2-120,20.4-120z
			 M4.5-79.9v-0.8c0-3.1,0.9-5.8,2.8-8c1.1-1.3,2.6-2.2,4.4-2.4c1.8-0.3,3.6-0.2,5.1,0.3c0.6,0.2,1,0.5,1.3,0.9
			c0.3,0.4,0.6,0.9,0.7,1.4c0.2,0.5,0.3,1.1,0.4,1.7c0.1,0.6,0.2,1.1,0.2,1.7c0,2.4,0,4.9-0.1,7.5c-0.1,2.5-0.4,5-1.1,7.3
			c-0.4,0.9-1,1.6-1.8,2.2c-0.8,0.5-1.7,0.8-2.7,1c-1,0.1-1.9,0-2.9-0.2c-1-0.3-1.8-0.7-2.5-1.3c-0.9-1-1.7-2.2-2.3-3.4
			c-0.6-1.3-1.1-2.6-1.3-4c-0.1-0.6-0.2-1.2-0.2-1.8C4.5-78.7,4.5-79.3,4.5-79.9z"/>
                    <path className="finale-0" d="M73.4-119.3v-1c0-0.1-0.4-0.1-1.2-0.2s-1.8-0.2-3-0.2c-1.2,0-2.5,0-3.8,0c-1.3,0-2.5,0.1-3.5,0.3v6.1
			c0,5.1-0.1,10.2-0.2,15.3C61.5-94,61.4-89,61.3-84c-0.1,4.9-0.2,9.8-0.2,14.5c0,4.7,0,9.3,0.1,13.7c1.9,0,4,0,6.2,0.1
			c2.3,0.1,4.3,0,6.2-0.2c0.1-0.1,0.1-0.3,0.1-0.6c0-0.6,0-1.1,0-1.5c0-0.4,0-0.9,0-1.6c0.1-0.3,0.3-0.5,0.4-0.7
			c0.2-0.2,0.3,0,0.5,0.3c0.9,1.8,2.1,3,3.7,3.5c1.5,0.5,3.2,0.7,4.9,0.4c3.5-0.2,6.2-1.1,8.3-2.7s3.5-3.6,4.5-6
			c1-2.4,1.7-5.1,2-8.1c0.3-3,0.5-6,0.6-9.1c0-2.1-0.1-4.2-0.4-6.2c-0.3-2-0.7-3.9-1.3-5.6c-0.6-1.7-1.3-3.1-2.3-4.3
			c-1-1.2-2.1-2-3.4-2.5c-1.7-0.6-3.5-1-5.5-1.1c-2-0.2-3.9-0.2-5.7,0c-1.8,0.2-3.3,0.4-4.5,0.9c-1.2,0.4-1.9,0.9-2,1.5
			c0.1-2.9,0.1-5.2,0.1-7c0-1.8,0-3.2,0-4.3c0-1.1,0-1.9,0-2.5c0-0.6-0.1-1.1-0.1-1.7c0-0.5-0.1-1.1-0.1-1.7
			C73.5-117.1,73.4-118.1,73.4-119.3z M87.6-84.6c0.3,0.9,0.5,1.7,0.6,2.7c0.1,0.9,0.1,1.8,0.1,2.7c0,1.5-0.2,3-0.6,4.5
			c-0.4,1.4-0.9,2.7-1.7,3.9c-0.7,1.1-1.6,2-2.7,2.7c-1,0.7-2.2,1-3.5,1c-1.6,0-2.8-0.3-3.7-1c-0.9-0.7-1.5-1.6-2-2.8
			c-0.5-1.2-0.7-2.6-0.8-4.2s-0.1-3.4-0.1-5.3c0-2.4,0-4.3,0.1-5.7c0.1-1.4,0.3-2.4,0.7-3.2s0.9-1.2,1.7-1.4
			c0.8-0.2,1.8-0.3,3.1-0.3c2.5,0,4.5,0.6,6,1.7C86.4-88.4,87.3-86.8,87.6-84.6z"/>
                    <path className="finale-0" d="M143.3-55.9c0.1-0.4,0.1-0.7,0.1-1.1c0-0.4,0-0.7,0-1.1V-59c-0.2-6.6-0.2-13.1,0.1-19.5
			c0.2-6.4,0.2-12.9-0.3-19.4c0-0.8,0-1.6,0-2.4c0-0.8-0.4-1.3-1.1-1.4H141c-1,0-2,0-3,0.1c-1,0.1-2,0.1-3,0.1c-0.7,0-1.3,0-2-0.1
			c-0.6-0.1-1.3-0.1-2-0.1c-0.4,0.1-0.7,0.4-0.9,0.7c-0.2,0.4-0.2,0.8-0.2,1.3c0,0.7,0.1,1.2,0.2,1.7c0.1,0.4,0.2,0.9,0.2,1.6
			c-1.5-1.5-3.3-2.7-5.6-3.8c-2.2-1-4.4-1.6-6.6-1.6c-2.4,0-4.4,0.8-6.1,2.5c-1.7,1.7-3.1,3.7-4.2,6.2c-1.1,2.5-1.9,5.1-2.4,8
			c-0.5,2.9-0.8,5.5-0.8,8c0,3.1,0.4,5.9,1.2,8.5c0.8,2.6,1.9,4.8,3.3,6.7c1.4,1.9,3,3.3,4.9,4.4c1.9,1.1,3.9,1.6,6.1,1.7
			c1.1,0,2.1,0,3.1,0c0.9,0,1.8-0.2,2.6-0.5c0.8-0.3,1.5-0.7,2.1-1.3c0.6-0.6,1.1-1.4,1.6-2.4c-0.1,1,0,1.8,0.1,2.5
			c0.2,0.7,0.2,1.4,0.2,2.2c0,0.1,0.2,0.1,0.6,0.1c0.4,0,0.8,0,1.3,0c0.5,0,1,0,1.5,0c0.5,0,0.8,0,1,0c1-0.1,2.3-0.1,3.8-0.2
			C139.6-55.7,141.4-55.8,143.3-55.9z M116.6-74.2c-0.2-0.8-0.4-1.6-0.5-2.4c-0.1-0.8-0.1-1.6-0.1-2.4c0-2.8,0.7-5.1,2.2-7
			c1.4-1.9,3.4-2.9,6-2.9c1.5,0,2.7,0.3,3.4,0.8c0.8,0.5,1.3,1.3,1.7,2.2c0.3,0.9,0.5,2.1,0.6,3.5c0,1.4,0,2.9,0,4.6
			c0,2.1,0,3.8,0,5.1c0,1.3-0.2,2.2-0.5,2.9c-0.3,0.7-0.7,1.1-1.4,1.3c-0.6,0.2-1.6,0.3-2.9,0.3c-2.5,0-4.5-0.6-5.9-1.8
			C117.8-71.3,116.9-72.6,116.6-74.2z"/>
                    <path className="finale-0" d="M168.8-38.8v-12.3c0-0.8,0-1.6,0-2.5c0-0.9,0-1.7,0-2.7c1.9,0.1,3.9,0.2,6,0.3c2.1,0.1,4.7,0.3,7.9,0.5
			c0.4-0.5,0.7-1.2,0.7-2.2c0.1-1,0.1-2,0-3.1c-0.1-1.1-0.1-2.2-0.2-3.4c-0.1-1.1-0.1-2.1,0-2.9h-14.2c0-3.3,0-6.5,0-9.7
			c0-3.2,0-6.1,0.1-8.9c0.6-0.9,1.5-1.5,2.7-2c1.2-0.5,2.4-0.9,3.8-1.1c1.3-0.3,2.7-0.4,4.1-0.5c1.4-0.1,2.6-0.1,3.6,0
			c0.1-0.9,0.2-1.8,0.4-2.8c0.1-1,0.2-2.1,0.3-3.2c0.1-1.1,0-2.2,0-3.3c-0.1-1.1-0.3-2.1-0.6-3c-2.5-0.1-5-0.1-7.6,0
			c-2.6,0.2-5.1,0.5-7.4,1.1c-2.3,0.6-4.4,1.4-6.2,2.5c-1.8,1.1-3.1,2.6-3.9,4.5c-0.8,4.7-1.3,9.2-1.4,13.4c-0.1,4.2-0.2,8.5-0.1,13
			c-1-0.1-2-0.1-3.1-0.1c-1.1,0-2.3,0-3.5,0l0.3,11.2c1.2-0.1,2.4-0.1,3.4-0.1c1.1,0,2.1,0,3.1,0c0,1.3,0,2.8-0.1,4.4
			c-0.1,1.6-0.1,3.1-0.1,4.7c0,1.6,0,3.1,0,4.6c0,1.5,0.1,2.8,0.2,4c0.8,0.1,1.7,0.2,2.6,0.1c0.9-0.1,1.9-0.1,3-0.2
			c1-0.1,2.1-0.2,3.1-0.2C166.7-38.9,167.7-38.9,168.8-38.8z"/>
                    <path className="finale-0" d="M202.2-60.7c0.3,0.2,0.7,0.4,1.1,0.6c0.5,0.2,0.8,0.5,1.1,0.7c1.3,0.9,2.7,1.7,4.3,2.2
			c1.6,0.6,3.2,0.9,4.9,0.9c1.7,0.1,3.3-0.1,4.9-0.6c1.6-0.4,3-1.2,4.3-2.3c0.6-0.6,1.1-1.7,1.4-3.4c0.3-1.7,0.6-3.8,0.6-6.3
			c0.1-2.5,0.1-5.1,0.1-8c0-2.9-0.1-5.9-0.1-8.8c-0.1-3-0.1-5.8-0.2-8.6c-0.1-2.8,0-5.2,0.1-7.3c-2.1-0.3-4.1-0.4-5.7-0.4
			c-1.7,0-3.6,0-5.7,0c-0.1,1.4-0.2,3-0.2,4.8c0,1.8,0,3.6,0,5.5c0,1.9,0,3.8,0,5.7c0,1.9-0.1,3.7-0.2,5.3c-0.1,0.7-0.1,1.5-0.2,2.4
			c-0.1,0.9-0.2,1.8-0.3,2.6c-0.2,0.9-0.4,1.6-0.6,2.3c-0.3,0.7-0.7,1.2-1.1,1.6c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0.1
			c-0.5,0.2-1.2,0.4-2.2,0.6c-1,0.2-1.9,0.1-2.8-0.2c-0.9-0.3-1.7-0.8-2.5-1.7c-0.7-0.8-1.2-2.1-1.3-3.7c-0.1-2.1-0.2-4.2-0.1-6.3
			c0.1-2.1,0.1-4.1,0.2-6.2s0.1-4.1,0.1-6.1c0-2-0.1-4-0.4-6.1v-0.4c-1-0.3-1.9-0.5-2.7-0.6c-0.8-0.1-1.5-0.1-2.3,0
			c-0.8,0-1.7,0.1-2.7,0.1c-1,0.1-2.2,0-3.7-0.1c0,2.5,0,5.5,0,9.1c0,3.5-0.1,7.3-0.1,11.3c0,4,0,8.2,0,12.5c0,4.3,0,8.5,0,12.6
			c0,4.1,0,8,0,11.6c0,3.6,0.1,6.8,0.1,9.5c0.7-0.1,1.3-0.1,1.8-0.1c0.5,0,0.9,0,1.4,0c0.4,0,0.9,0,1.4,0c0.5,0,1.1,0.1,1.8,0.1
			c0.2,0,0.7,0,1.3,0.1c0.6,0.1,1.2,0.2,1.8,0.2c0.6,0.1,1.2,0.1,1.7,0c0.5,0,0.7-0.1,0.7-0.3c0.1-0.3,0.1-1.1,0.1-2.4
			c0-1.3,0-2.9,0-4.7c0-1.8,0-3.7,0-5.8c0-2.1,0-4,0-5.7c0-1.8,0-3.3,0-4.6C202.2-59.6,202.2-60.4,202.2-60.7z"/>
                    <path className="finale-0" d="M244.1-101.6l-10.4,0.1h-0.3c-0.1,0.7-0.2,1.5-0.2,2.6c0,1,0,2.1,0,3.1c0,1,0.1,2,0.1,2.9c0,0.9,0,1.6,0,2
			c0.6,0.2,1.5,0.4,2.6,0.6c1.1,0.1,2.3,0.2,3.4,0.2c1.2,0,2.3,0,3.3-0.1c1-0.1,1.7-0.2,2.2-0.4c0-0.1,0-0.6-0.1-1.5
			c-0.1-1-0.1-2-0.2-3.3s-0.1-2.4-0.2-3.6c-0.1-1.2-0.1-2-0.1-2.4v-0.1v-0.1H244.1z"/>
                </g>
            </symbol>
            <symbol id="congrats-splash-text" viewBox="-393 -138.8 786 277.5">
                <g>
                    <g>
                        <path className="finale-1" d="M386.8-37.1l-750.3-101.7c-3.6-0.4-6.9,2.1-7.5,5.8l-22,138.3c-0.6,3.7,2,7.3,5.7,7.9l746.5,125.5
				c3.7,0.6,7.3-2,7.9-5.7l25.8-162.2C393.5-33.1,390.8-36.7,386.8-37.1z"/>
                    </g>
                </g>
                <g>
                    <path className="finale-2" d="M-310.4-36.3c-0.1-0.2-0.2-0.4-0.3-0.7s-0.1-0.6-0.2-0.7c-0.1-0.2-0.1-1.5,0.1-3.7c0.2-2.3,0.5-4.9,0.8-8
			c0.3-3.1,0.7-6.3,1.2-9.6c0.4-3.3,0.9-6.2,1.5-8.6c0.5-2,1.3-3.9,2.4-5.7c1.1-1.8,2.7-2.9,4.8-3.1c1.6,0.1,3,0.5,4.3,1.3
			c1.3,0.8,2.2,1.9,2.7,3.4c0.6,1.7,0.7,3.5,0.3,5.2c-0.3,1.8-0.5,3.6-0.3,5.3c1,0,2.1,0.1,3.3,0.3c1.2,0.2,2.5,0.4,3.7,0.6
			c1.3,0.2,2.5,0.3,3.7,0.4c1.2,0.1,2.4,0.1,3.4-0.1c1.7-3.5,2.5-7.1,2.4-10.7c-0.1-3.7-0.9-7.1-2.4-10.2c-1.5-3.1-3.7-5.8-6.5-8.1
			c-2.8-2.3-6.1-3.7-10-4.3c-2.5-0.7-5.1-1-7.8-1.1c-2.7,0-5.3,0.3-7.7,1.1c-2.5,0.8-4.6,2-6.4,3.8c-1.8,1.7-3.1,4.1-3.8,7
			c-0.5,2.5-0.9,5.1-1.4,7.8c-0.4,2.7-0.9,5.8-1.4,9.2c-0.5,3.4-1,7.2-1.5,11.5c-0.5,4.3-1.1,9.3-1.8,15c-0.3,1.2-0.4,2.4-0.3,3.6
			c0.1,1.3,0.3,2.4,0.5,3.5c0.4,1.9,1,3.7,1.7,5.4c0.7,1.7,1.7,3.2,2.9,4.5c0.7,0.7,1.5,1.2,2.3,1.8c0.9,0.5,1.8,1,2.7,1.4
			c3.1,1.5,6.2,2.3,9.4,2.5c3.2,0.2,6.2-0.1,9.2-1c2.9-0.9,5.6-2.2,8.2-3.9c2.5-1.7,4.6-3.8,6.3-6.3c1.7-2.4,2.9-5.2,3.5-8.2
			c0.7-3,0.7-6.2,0-9.6c-2.5-0.9-5-1.4-7.5-1.6c-2.5-0.2-5.1-0.2-7.8,0c-0.4,1.3-0.7,2.8-1,4.5c-0.3,1.7-0.7,3.2-1.2,4.6
			c-0.5,1.4-1.3,2.5-2.4,3.3c-1.1,0.8-2.6,1-4.6,0.7c-0.9-0.3-1.8-0.6-2.8-0.9C-309.2-35.1-309.9-35.6-310.4-36.3z"/>
                    <path className="finale-2" d="M-273.5-40.6c0.3,1.1,1,2.1,2,3c1,0.9,2.2,1.7,3.5,2.4c1.4,0.7,2.9,1.3,4.5,1.7c1.6,0.4,3.3,0.8,4.9,1
			c1.4,0,2.8,0.2,4,0.5c1.3,0.3,2.7,0.4,4.2,0.5c2.3,0.2,4.4,0,6.6-0.5c2.1-0.5,4-1.3,5.7-2.5c1.1-0.5,2-1.4,2.7-2.7
			c0.7-1.3,1.4-2.9,1.9-4.7c0.5-1.8,1-3.8,1.3-6c0.4-2.2,0.7-4.4,1.1-6.7c0.2-2,0.4-4.4,0.7-7c0.2-2.6,0.4-5.1,0.5-7.5
			c0.1-2.4,0-4.6-0.1-6.5c-0.1-1.9-0.5-3.3-1.1-4.1c-1-1.8-2.4-3.2-4.2-4.2c-1.8-1-3.9-1.7-6.1-2.1c-2.2-0.5-4.5-0.8-6.9-0.9
			c-2.4-0.1-4.6-0.3-6.5-0.6c-3.7,0.2-6.9,1.3-9.6,3.4c-2.1,1.4-3.7,3.4-4.7,5.8c-1,2.4-1.8,5-2.3,7.8c-0.5,2.7-0.9,5.5-1.2,8.3
			c-0.3,2.8-0.7,5.4-1.2,7.6c-0.2,1.3-0.3,2.5-0.4,3.7c-0.1,1.2-0.1,2.5-0.1,3.9c0,0.8,0.1,1.8,0.3,3.2
			C-273.8-42.4-273.6-41.4-273.5-40.6z M-258.8-51.2c-0.4-0.3-0.6-1.1-0.7-2.2c0-2.3,0.2-4.5,0.5-6.7c0.3-2.2,0.5-4.5,0.7-7
			c0.1-1.6,0.7-2.8,1.8-3.6c1-0.8,2.2-1.2,3.5-1.3c1.3-0.1,2.7,0,4.1,0.3c1.4,0.4,2.5,0.8,3.3,1.5c1.1,0.7,1.8,2,2,3.8
			c0,2.3-0.1,4.6-0.2,6.9c-0.1,2.3-0.5,4.4-1.2,6.4c-0.7,2.4-1.9,4.1-3.8,5c-1.8,0.9-4.1,0.8-6.7-0.1C-256.9-49.1-258-50-258.8-51.2
			z"/>
                    <path className="finale-2" d="M-213.4-33.7c0.3,0.3,0.7,0.6,1.2,0.9c0.5,0.3,0.9,0.7,1.2,1c1.3,1.2,2.9,2.3,4.6,3.2
			c1.7,0.9,3.6,1.5,5.5,1.8c1.9,0.3,3.8,0.4,5.7,0.1c1.9-0.2,3.6-0.9,5.3-2c0.8-0.5,1.5-1.8,2.1-3.7c0.6-1.9,1.2-4.3,1.7-7.1
			c0.5-2.8,0.9-5.8,1.3-9.2c0.4-3.3,0.8-6.7,1.2-10.1c0.4-3.4,0.7-6.7,1.1-9.8c0.3-3.2,0.7-5.9,1.2-8.3c-2.4-0.7-4.6-1.1-6.5-1.3
			c-1.9-0.2-4.1-0.5-6.6-0.8c-0.4,1.6-0.7,3.4-0.9,5.4c-0.3,2-0.5,4.1-0.8,6.3c-0.3,2.2-0.5,4.3-0.9,6.5c-0.3,2.2-0.7,4.2-1.1,6.1
			c-0.2,0.8-0.4,1.7-0.6,2.8c-0.2,1-0.5,2-0.8,2.9c-0.3,1-0.7,1.8-1.1,2.5c-0.4,0.7-0.9,1.3-1.5,1.6c-0.1,0.1-0.2,0.1-0.2,0.1
			c-0.1,0-0.1,0-0.2,0.1c-0.6,0.2-1.4,0.3-2.6,0.4c-1.1,0-2.2-0.2-3.2-0.6c-1-0.5-1.9-1.2-2.6-2.3c-0.7-1.1-1-2.5-0.9-4.4
			c0.2-2.5,0.5-4.9,0.8-7.2c0.4-2.3,0.8-4.7,1.2-7c0.4-2.3,0.8-4.7,1.1-7c0.3-2.3,0.5-4.6,0.5-7l0.1-0.4c-1.1-0.5-2.1-0.9-3-1
			c-0.9-0.2-1.8-0.3-2.7-0.4c-0.9-0.1-1.9-0.2-3.1-0.2c-1.1-0.1-2.5-0.3-4.2-0.7c-1.1,8.6-2.4,17.2-3.7,25.8
			c-1.3,8.6-2.4,17.5-3.4,26.8c0.8,0,1.6,0.1,2.1,0.1c0.6,0,1.1,0.1,1.6,0.2c0.5,0.1,1,0.1,1.6,0.3c0.6,0.1,1.2,0.3,2.1,0.4
			c0.3,0,0.8,0.2,1.4,0.4c0.7,0.2,1.4,0.4,2.1,0.5c0.7,0.2,1.3,0.3,1.9,0.3c0.6,0,0.9,0,0.9-0.3c0.3-1.2,0.5-2.1,0.5-2.9
			C-213.7-31.8-213.6-32.7-213.4-33.7z"/>
                    <path className="finale-2" d="M-142.6-93.3c-0.7-0.2-1.9-0.4-3.8-0.6c-1.8-0.2-4.2-0.4-7-0.8c-3.3-0.4-6.3-0.1-9.1,0.9
			c-2.8,1.1-5.1,2.7-7,4.8c-0.3,0.5-0.7,1.1-1,1.8c-0.4,0.8-0.7,1.6-1,2.4c-0.3,0.8-0.5,1.6-0.6,2.4c-0.1,0.8-0.2,1.4-0.2,2
			c0.7,0.4,1.6,0.7,2.8,1c1.2,0.3,2.4,0.5,3.7,0.6c1.3,0.1,2.6,0.2,3.8,0.3c1.2,0.1,2.3,0.1,3.1,0c0.2-1.8,1-3.2,2.3-4.1
			c1.3-0.9,2.7-1.4,4.1-1.3c1.5,0.1,2.8,0.6,4.1,1.6c1.3,1,2,2.5,2.1,4.6c0,0.7,0,1.4-0.1,2.2c0,0.7-0.1,1.4-0.3,1.9
			c-0.3,1.2-0.5,2.4-0.5,3.7c-0.6-0.9-1.3-1.5-2.2-2c-0.9-0.5-1.9-0.9-2.9-1.1c-1.1-0.3-2.2-0.5-3.3-0.6c-1.1-0.1-2.2-0.3-3.1-0.5
			c-1.3-0.2-2.7-0.2-4.1-0.2c-1.4,0-2.7,0.2-4,0.5c-1.3,0.3-2.5,0.8-3.6,1.6c-1.1,0.7-2,1.7-2.8,2.9c-1.2,1.8-2.2,3.6-2.9,5.7
			c-0.7,2-1.3,4.1-1.7,6.2c-0.4,3.3-0.8,6.8-1.2,10.6c-0.4,3.8-0.2,7.4,0.4,10.9c0.6,3.5,2,6.5,4.1,9.1c2.1,2.6,5.3,4.3,9.8,5.2
			c2.2,0.4,4.3,0.4,6.4-0.1c2-0.5,3.6-1.6,4.7-3.5c0.3-0.1,0.4,0,0.4,0.4c0,0.4-0.1,0.9-0.2,1.5c-0.1,0.6-0.2,1.2-0.3,1.8
			c-0.1,0.6-0.1,1.1,0.1,1.5c2.3,0.6,4.6,1,7,1.2c2.4,0.2,4.8,0.5,7.2,0.8c1.4-8.7,2.8-17.8,4.1-27.4s2.4-19.4,3.3-29.5
			c0.2-1.7,0.4-3.3,0.7-5c0.3-1.6,0.2-3.1-0.4-4.3c-0.8-1.7-1.6-3-2.5-4.1c-0.9-1-1.8-1.9-2.7-2.5c-0.9-0.7-1.8-1.2-2.8-1.5
			C-140.8-92.9-141.7-93.1-142.6-93.3z M-166.8-49.8l0.1-0.9c0.5-3.6,1.9-6.5,4.4-8.7c1.5-1.4,3.3-2.1,5.4-2.1
			c2.1,0,4.1,0.3,5.8,1.1c0.6,0.3,1,0.7,1.3,1.2c0.3,0.5,0.5,1.1,0.6,1.7c0.1,0.6,0.2,1.3,0.2,2c0,0.7,0,1.3,0,2
			c-0.4,2.8-0.8,5.6-1.3,8.5s-1.3,5.6-2.3,8.2c-0.6,1-1.3,1.7-2.4,2.2c-1,0.5-2.1,0.7-3.2,0.7c-1.1,0-2.2-0.2-3.3-0.7
			c-1-0.5-1.9-1.1-2.7-1.9c-0.9-1.3-1.6-2.8-2.2-4.3c-0.5-1.5-0.9-3.1-0.9-4.8c0-0.7,0-1.4,0.1-2.1C-167-48.4-166.9-49.1-166.8-49.8
			z"/>
                    <path className="finale-2" d="M-117-18.9c0.3,0.2,0.9,0.5,1.8,1.1c1,0.6,1.9,1.1,2.8,1.6c1.3,0.6,2.7,1.1,4.4,1.6c1.6,0.5,3.2,0.8,4.7,1.1
			c1.5,0.3,2.7,0.4,3.7,0.5c1,0.1,1.5,0.1,1.5,0c0.3-2.4,0.6-4.5,0.8-6.3c0.2-1.8,0.4-3.5,0.6-4.9c0.2-1.4,0.4-2.7,0.5-3.9
			c0.2-1.2,0.3-2.3,0.5-3.3l-1,0.2c-0.4,0.1-0.8,0.2-1.2,0.2c-0.4,0.1-0.8,0.1-1.2,0.2c-0.9,0.1-1.7,0.2-2.6,0.2c-0.9,0-1.4,0-1.4,0
			c-1.9,0-3.7-0.2-5.2-0.4c-1.6-0.2-2.9-0.6-4-1.3c-1.1-0.6-1.9-1.5-2.4-2.7c-0.5-1.1-0.6-2.7-0.2-4.6c0.4-2.4,0.8-4.7,1.1-6.9
			c0.4-2.2,0.7-4.3,1-6.5c0.3-2.2,0.6-4.4,0.9-6.7c0.3-2.3,0.7-4.7,1-7.3l0.1-0.4c-1.1-0.5-2.2-0.9-3.2-1.1c-1-0.2-2-0.4-3-0.5
			c-1.1-0.1-2.2-0.2-3.4-0.3c-1.2-0.1-2.7-0.3-4.3-0.7c-1.1,8.6-2.4,17.2-3.7,25.8c-1.3,8.6-2.4,17.5-3.4,26.8
			c0.8,0,1.6,0.1,2.1,0.1c0.6,0,1.1,0.1,1.6,0.2c0.5,0.1,1,0.2,1.6,0.3c0.6,0.1,1.2,0.3,2.1,0.4c0.3,0,0.8,0.1,1.5,0.3
			c0.7,0.2,1.5,0.3,2.3,0.5c0.8,0.2,1.5,0.3,2.1,0.3c0.6,0,0.9,0,0.9-0.1c0.1-0.6,0.1-1,0.2-1.3s0.1-0.5,0.1-0.7
			c0-0.2,0.1-0.4,0.1-0.6C-117.1-18-117-18.4-117-18.9z"/>
                    <path className="finale-2" d="M-50.9-6.6c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.4,0.2-0.8,0.2-1.3l0.1-1c0.8-7.6,1.8-15,3.1-22.3
			c1.2-7.3,2.1-14.7,2.6-22.2c0.1-0.9,0.2-1.8,0.3-2.7c0.1-0.9-0.3-1.5-1.1-1.7l-1.3-0.2c-1.1-0.1-2.3-0.2-3.4-0.3
			c-1.2,0-2.3-0.1-3.4-0.3c-0.8-0.1-1.5-0.3-2.2-0.5c-0.7-0.2-1.5-0.4-2.2-0.5c-0.5,0.1-0.9,0.3-1.1,0.7c-0.2,0.4-0.4,0.9-0.5,1.4
			c-0.1,0.8-0.1,1.4,0,1.9c0.1,0.5,0.1,1.1,0,1.8c-1.5-1.9-3.4-3.6-5.8-5.1c-2.4-1.5-4.8-2.5-7.3-2.8c-2.7-0.4-5.2,0.3-7.4,1.9
			c-2.2,1.6-4.1,3.8-5.7,6.4c-1.6,2.6-3,5.6-4,8.8c-1,3.2-1.7,6.2-2.1,9c-0.5,3.5-0.4,6.8,0.1,9.8c0.5,3.1,1.4,5.8,2.7,8.1
			c1.3,2.3,2.9,4.3,4.9,5.8c2,1.5,4.2,2.5,6.7,2.9c1.3,0.2,2.4,0.3,3.5,0.4c1.1,0.1,2.1,0.1,3.1-0.1c1-0.2,1.8-0.6,2.6-1.2
			c0.8-0.6,1.5-1.4,2.2-2.5c-0.2,1.1-0.3,2.1-0.2,2.9c0.1,0.8,0,1.7-0.1,2.5c0,0.1,0.2,0.2,0.7,0.2c0.5,0.1,1,0.2,1.5,0.2
			c0.6,0,1.1,0.1,1.7,0.2c0.6,0.1,0.9,0.1,1.2,0.2c1.2,0.1,2.7,0.2,4.4,0.4C-55.1-6.9-53.1-6.8-50.9-6.6z M-78.6-31.5
			c-0.2-0.9-0.2-1.9-0.2-2.9c0.1-1,0.1-1.9,0.3-2.8c0.4-3.1,1.6-5.7,3.5-7.7c1.9-2,4.4-2.8,7.3-2.4c1.7,0.2,3,0.7,3.8,1.4
			c0.8,0.7,1.3,1.6,1.6,2.8c0.2,1.1,0.3,2.5,0.1,4.1c-0.2,1.6-0.4,3.3-0.6,5.3c-0.3,2.4-0.6,4.4-0.8,5.8c-0.2,1.4-0.5,2.5-1,3.2
			c-0.4,0.7-1,1.1-1.8,1.3c-0.8,0.1-1.9,0.1-3.3-0.1c-2.9-0.4-5-1.4-6.5-2.9C-77.7-28.1-78.5-29.7-78.6-31.5z"/>
                    <path className="finale-2" d="M-27,16.4l1.9-14.1c0.1-0.9,0.3-1.9,0.4-2.8c0.2-1,0.3-2,0.5-3c2.2,0.4,4.4,0.8,6.8,1.2
			c2.4,0.5,5.4,1,8.9,1.7c0.6-0.5,1-1.3,1.2-2.4C-7.1-4-7-5.2-6.9-6.4c0.1-1.3,0.2-2.6,0.2-3.9c0.1-1.3,0.2-2.4,0.5-3.3l-16.3-2.2
			c0.5-3.8,1-7.5,1.5-11.1c0.5-3.6,1-7,1.4-10.1c0.8-0.9,1.9-1.5,3.3-1.9c1.4-0.4,2.9-0.6,4.5-0.7c1.6-0.1,3.2-0.1,4.7,0
			c1.6,0.1,3,0.3,4.1,0.5c0.3-1,0.6-2,0.8-3.2c0.3-1.2,0.6-2.4,0.8-3.6c0.2-1.2,0.4-2.5,0.4-3.8s0-2.5-0.2-3.6
			c-2.8-0.5-5.7-0.9-8.7-1.1c-3-0.2-5.9-0.2-8.7,0.1c-2.7,0.3-5.2,0.9-7.5,1.9c-2.2,1-3.9,2.5-5.1,4.6c-1.6,5.3-2.8,10.3-3.6,15.1
			c-0.8,4.8-1.5,9.7-2.1,14.8c-1.1-0.2-2.3-0.4-3.5-0.6c-1.2-0.2-2.6-0.3-4-0.5l-1.4,12.8c1.4,0.1,2.7,0.3,4,0.4
			c1.2,0.2,2.4,0.3,3.5,0.5c-0.2,1.5-0.5,3.2-0.8,5c-0.3,1.8-0.6,3.6-0.8,5.4c-0.2,1.8-0.5,3.6-0.6,5.3c-0.2,1.7-0.3,3.2-0.3,4.5
			c0.9,0.3,1.9,0.4,3,0.5c1.1,0.1,2.2,0.1,3.4,0.2c1.2,0.1,2.4,0.1,3.6,0.2C-29.4,15.9-28.2,16.1-27,16.4z"/>
                    <path className="finale-2" d="M29.3-43.4c-0.3-0.3-0.7-0.6-1.2-0.9c-0.5-0.4-0.9-0.7-1.2-1c-1.3-1.2-2.9-2.3-4.6-3.2
			c-1.7-0.9-3.6-1.5-5.5-1.8c-1.9-0.3-3.8-0.4-5.7-0.1c-1.9,0.2-3.6,0.9-5.3,2c-0.8,0.5-1.5,1.8-2.1,3.7C3-42.8,2.5-40.4,2-37.6
			c-0.5,2.8-0.9,5.8-1.3,9.2c-0.4,3.3-0.8,6.7-1.2,10.1c-0.4,3.4-0.7,6.7-1.1,9.8c-0.3,3.2-0.7,5.9-1.2,8.3c2.4,0.7,4.6,1.1,6.5,1.3
			c1.9,0.2,4.1,0.5,6.6,0.8c0.4-1.6,0.7-3.4,0.9-5.4c0.3-2,0.5-4.1,0.8-6.3c0.3-2.2,0.5-4.3,0.9-6.5c0.3-2.2,0.7-4.2,1.1-6.1
			c0.2-0.8,0.4-1.8,0.6-2.8s0.5-2,0.8-2.9c0.3-1,0.7-1.8,1.1-2.5c0.4-0.7,0.9-1.3,1.5-1.6c0.1-0.1,0.2-0.1,0.2-0.1
			c0.1,0,0.1,0,0.2-0.1c0.6-0.2,1.4-0.3,2.6-0.4c1.1,0,2.2,0.2,3.2,0.6c1,0.5,1.9,1.2,2.6,2.3c0.7,1.1,1,2.5,0.9,4.4
			c-0.2,2.4-0.5,4.8-0.8,7.2c-0.4,2.4-0.8,4.7-1.2,7.1c-0.4,2.3-0.8,4.6-1.1,7c-0.3,2.3-0.5,4.6-0.4,7l-0.1,0.4c1.1,0.5,2.1,0.9,3,1
			c0.9,0.2,1.8,0.3,2.7,0.4c0.9,0.1,1.9,0.2,3.1,0.2c1.1,0.1,2.5,0.3,4.2,0.7c1.1-8.6,2.4-17.2,3.7-25.8c1.3-8.6,2.4-17.5,3.4-26.8
			c-0.8,0-1.6-0.1-2.1-0.1c-0.6,0-1.1-0.1-1.6-0.2c-0.5-0.1-1-0.2-1.6-0.3c-0.6-0.1-1.2-0.3-2.1-0.4c-0.3,0-0.8-0.2-1.4-0.4
			c-0.7-0.2-1.4-0.4-2.1-0.5c-0.7-0.2-1.3-0.3-1.9-0.3c-0.6,0-0.9,0-0.9,0.3c-0.3,1.2-0.5,2.1-0.5,2.9
			C29.6-45.3,29.5-44.4,29.3-43.4z"/>
                    <path className="finale-2" d="M41.8,30.1l14.3,1.6c0.4-2.9,0.8-6.3,1.3-10.4c0.5-4.1,1-8.4,1.5-13c0.5-4.6,1.1-9.4,1.7-14.4
			c0.6-5,1.2-9.8,1.9-14.4c0.6-4.6,1.2-8.9,1.8-12.9c0.6-4,1.2-7.4,1.7-10.2c-0.3-0.5-1.1-0.8-2.5-1c-1.4-0.2-2.9-0.4-4.6-0.6
			c-1.6-0.2-3.2-0.3-4.7-0.5c-1.5-0.1-2.5-0.3-2.9-0.5c-0.3,6.4-1,12.7-1.9,19.1c-0.9,6.4-1.9,12.7-3,19c-1.1,6.3-2,12.7-2.9,19.1
			C42.5,17.3,42,23.7,41.8,30.1z"/>
                    <path className="finale-2" d="M108.6,14.5c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.4,0.2-0.8,0.2-1.3l0.1-1c0.8-7.6,1.8-15,3.1-22.3
			c1.2-7.3,2.1-14.7,2.6-22.2c0.1-0.9,0.2-1.8,0.3-2.7c0.1-0.9-0.3-1.5-1.1-1.7l-1.3-0.2c-1.1-0.1-2.3-0.2-3.4-0.3
			c-1.2,0-2.3-0.1-3.4-0.3c-0.8-0.1-1.5-0.3-2.2-0.5c-0.7-0.2-1.5-0.4-2.2-0.5c-0.5,0.1-0.9,0.3-1.1,0.7c-0.2,0.4-0.4,0.9-0.5,1.4
			c-0.1,0.8-0.1,1.4,0,1.9c0.1,0.5,0.1,1.1,0,1.8c-1.5-1.9-3.4-3.6-5.8-5.1c-2.4-1.5-4.8-2.5-7.3-2.8c-2.7-0.4-5.2,0.3-7.4,1.9
			c-2.2,1.6-4.1,3.8-5.7,6.4c-1.6,2.6-3,5.6-4,8.8c-1,3.2-1.7,6.2-2.1,9c-0.5,3.5-0.4,6.8,0.1,9.8c0.5,3.1,1.4,5.8,2.7,8.1
			c1.3,2.3,2.9,4.3,4.9,5.8c2,1.5,4.2,2.5,6.7,2.9c1.3,0.2,2.4,0.3,3.5,0.4c1.1,0.1,2.1,0.1,3.1-0.1c1-0.2,1.8-0.6,2.6-1.1
			c0.8-0.6,1.5-1.4,2.2-2.5c-0.2,1.1-0.3,2.1-0.2,2.9c0.1,0.8,0,1.7-0.1,2.5c0,0.1,0.2,0.2,0.7,0.2c0.4,0.1,1,0.2,1.5,0.2
			c0.6,0,1.1,0.1,1.7,0.2c0.6,0.1,0.9,0.1,1.2,0.2c1.2,0.1,2.7,0.2,4.4,0.4C104.3,14.2,106.3,14.3,108.6,14.5z M80.8-10.4
			c-0.2-0.9-0.2-1.9-0.2-2.9c0.1-1,0.1-1.9,0.3-2.8c0.4-3.1,1.6-5.7,3.5-7.7c1.9-2,4.4-2.8,7.3-2.4c1.7,0.2,3,0.7,3.8,1.4
			c0.8,0.7,1.3,1.6,1.6,2.8c0.2,1.1,0.3,2.5,0.1,4.1c-0.2,1.6-0.4,3.3-0.6,5.3c-0.3,2.4-0.6,4.4-0.8,5.8c-0.2,1.4-0.5,2.5-1,3.2
			c-0.4,0.7-1,1.1-1.8,1.3c-0.8,0.1-1.9,0.1-3.3-0.1c-2.9-0.4-5-1.4-6.5-2.9C81.7-7,80.9-8.6,80.8-10.4z"/>
                    <path className="finale-2" d="M132.4,37.5l1.9-14.1c0.1-0.9,0.3-1.9,0.4-2.8c0.2-1,0.3-2,0.5-3c2.2,0.4,4.4,0.8,6.8,1.2
			c2.4,0.5,5.4,1,8.9,1.7c0.6-0.5,1-1.3,1.2-2.4c0.2-1.1,0.4-2.2,0.5-3.5c0.1-1.3,0.2-2.6,0.2-3.9c0.1-1.3,0.2-2.4,0.5-3.3L137,5.3
			c0.5-3.8,1-7.5,1.5-11.1c0.5-3.6,1-7,1.4-10.1c0.8-0.9,1.9-1.5,3.3-1.9c1.4-0.4,2.9-0.6,4.5-0.7c1.6-0.1,3.2-0.1,4.7,0
			c1.6,0.1,3,0.3,4.1,0.5c0.3-1,0.6-2,0.9-3.2c0.3-1.2,0.6-2.4,0.8-3.6c0.2-1.2,0.4-2.5,0.4-3.8c0.1-1.3,0-2.5-0.2-3.6
			c-2.8-0.5-5.7-0.9-8.7-1.1c-3-0.2-5.9-0.2-8.7,0.1c-2.7,0.3-5.2,0.9-7.5,1.9c-2.2,1-3.9,2.5-5.1,4.6c-1.6,5.3-2.8,10.3-3.6,15.1
			c-0.8,4.8-1.5,9.7-2.1,14.8c-1.1-0.2-2.3-0.4-3.5-0.6c-1.2-0.2-2.6-0.3-4-0.5L113.9,15c1.4,0.1,2.7,0.3,4,0.4
			c1.2,0.2,2.4,0.3,3.5,0.5c-0.2,1.5-0.5,3.2-0.8,5c-0.3,1.8-0.6,3.6-0.8,5.4c-0.2,1.8-0.5,3.6-0.6,5.3c-0.2,1.7-0.3,3.2-0.3,4.5
			c0.9,0.3,1.9,0.4,3,0.5c1.1,0.1,2.2,0.1,3.4,0.2c1.2,0.1,2.4,0.1,3.6,0.2C130.1,37,131.3,37.2,132.4,37.5z"/>
                    <path className="finale-2" d="M153.6,44.3l14.3,1.6c0.1-0.4,0.2-1.2,0.4-2.2c0.2-1.1,0.4-2.3,0.6-3.6c0.2-1.3,0.4-2.6,0.6-4
			c0.2-1.4,0.4-2.6,0.5-3.6c-0.2-0.5-0.8-0.8-1.8-1.1c-1-0.3-2.2-0.5-3.5-0.6s-2.8-0.3-4.4-0.4c-1.6-0.1-3-0.3-4.3-0.5
			c-0.3,2-0.6,4.1-1.1,6.3C154.4,38.3,154,41,153.6,44.3z M169.6,23.1l1.8,0.2l0-0.3c1-8.5,2-17.1,3-26c0.9-8.8,2.2-17.5,3.8-25.9
			c-0.3-0.5-1.1-0.8-2.5-1c-1.4-0.2-2.9-0.4-4.6-0.6c-1.6-0.2-3.2-0.3-4.7-0.5c-1.5-0.1-2.5-0.3-2.9-0.5c-0.2,4.5-0.7,9-1.3,13.5
			c-0.6,4.5-1.3,9-2.1,13.5c-0.7,4.5-1.4,8.9-2,13.2c-0.6,4.4-0.9,8.7-1.1,12.8l0,0.1c0.3,0.1,0.7,0.2,1.4,0.2c0.7,0,1.2,0,1.5,0.1
			c0.9,0,2.2,0.1,3.8,0.3C165.4,22.5,167.3,22.7,169.6,23.1z"/>
                    <path className="finale-2" d="M180.1,19.5c0.3,1.1,1,2.1,2,3c1,0.9,2.2,1.7,3.5,2.4c1.4,0.7,2.9,1.3,4.5,1.7c1.6,0.4,3.3,0.8,4.9,1
			c1.4,0,2.8,0.2,4,0.5c1.3,0.3,2.7,0.4,4.2,0.5c2.3,0.2,4.4,0,6.6-0.5c2.1-0.5,4-1.3,5.7-2.5c1.1-0.5,2-1.4,2.7-2.7
			c0.7-1.3,1.4-2.9,1.9-4.7c0.5-1.8,1-3.8,1.3-6c0.4-2.2,0.7-4.4,1.1-6.7c0.2-2,0.4-4.4,0.7-7c0.2-2.6,0.4-5.1,0.5-7.5
			c0.1-2.4,0-4.6-0.1-6.5c-0.1-1.9-0.5-3.3-1.1-4.1c-1-1.8-2.4-3.2-4.2-4.2c-1.8-1-3.9-1.7-6.1-2.1c-2.2-0.5-4.5-0.8-6.9-0.9
			c-2.4-0.1-4.6-0.3-6.5-0.6c-3.7,0.2-6.9,1.3-9.6,3.4c-2.1,1.4-3.7,3.4-4.7,5.8c-1,2.4-1.8,5-2.3,7.8c-0.5,2.7-0.9,5.5-1.2,8.3
			c-0.3,2.8-0.7,5.4-1.2,7.6c-0.2,1.3-0.3,2.5-0.4,3.7c-0.1,1.2-0.1,2.5-0.1,3.9c0,0.8,0.1,1.8,0.3,3.2
			C179.8,17.6,180,18.7,180.1,19.5z M194.9,8.8c-0.4-0.3-0.6-1.1-0.7-2.2c0-2.3,0.2-4.5,0.5-6.7c0.3-2.2,0.5-4.5,0.7-7
			c0.1-1.6,0.7-2.8,1.8-3.6c1-0.8,2.2-1.2,3.5-1.3c1.3-0.1,2.7,0,4.1,0.3c1.4,0.4,2.5,0.8,3.3,1.5c1.1,0.7,1.8,2,2,3.8
			c0,2.3-0.1,4.6-0.2,6.9c-0.1,2.3-0.5,4.4-1.2,6.4c-0.7,2.4-1.9,4.1-3.8,5c-1.8,0.9-4.1,0.8-6.7-0.1C196.7,11,195.6,10.1,194.9,8.8
			z"/>
                    <path className="finale-2" d="M240.3,26.4c0.3,0.3,0.7,0.6,1.2,0.9c0.5,0.3,0.9,0.7,1.2,1c1.3,1.2,2.9,2.3,4.6,3.2c1.7,0.9,3.6,1.5,5.5,1.8
			c1.9,0.3,3.8,0.4,5.7,0.1c1.9-0.2,3.6-0.9,5.3-2c0.8-0.5,1.5-1.8,2.1-3.7c0.6-1.9,1.2-4.3,1.7-7.1c0.5-2.8,0.9-5.8,1.3-9.2
			c0.4-3.3,0.8-6.7,1.2-10.1c0.4-3.4,0.7-6.7,1.1-9.8c0.3-3.2,0.7-5.9,1.2-8.3c-2.4-0.7-4.6-1.1-6.5-1.3c-1.9-0.2-4.1-0.5-6.6-0.8
			c-0.4,1.6-0.7,3.4-0.9,5.4c-0.3,2-0.5,4.1-0.8,6.3c-0.3,2.2-0.5,4.3-0.9,6.5c-0.3,2.2-0.7,4.2-1.1,6.1c-0.2,0.8-0.4,1.7-0.6,2.8
			c-0.2,1-0.5,2-0.8,2.9c-0.3,1-0.7,1.8-1.1,2.5c-0.4,0.7-0.9,1.3-1.5,1.6c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0.1
			c-0.6,0.2-1.4,0.3-2.6,0.4c-1.1,0-2.2-0.2-3.2-0.6c-1-0.5-1.9-1.2-2.6-2.3c-0.7-1.1-1-2.5-0.9-4.4c0.2-2.5,0.5-4.9,0.8-7.2
			c0.4-2.3,0.8-4.7,1.2-7c0.4-2.3,0.8-4.7,1.1-7c0.3-2.3,0.5-4.6,0.5-7l0.1-0.4c-1.1-0.5-2.1-0.9-3-1c-0.9-0.2-1.8-0.3-2.7-0.4
			c-0.9-0.1-1.9-0.2-3.1-0.2c-1.1-0.1-2.5-0.3-4.2-0.7c-1.1,8.6-2.4,17.2-3.7,25.8c-1.3,8.6-2.4,17.5-3.4,26.8
			c0.8,0,1.6,0.1,2.1,0.1c0.6,0,1.1,0.1,1.6,0.2c0.5,0.1,1,0.1,1.6,0.3c0.6,0.1,1.2,0.3,2.1,0.4c0.3,0,0.8,0.2,1.4,0.4
			c0.7,0.2,1.4,0.4,2.1,0.5c0.7,0.2,1.3,0.3,1.9,0.3c0.6,0,0.9,0,0.9-0.3c0.3-1.2,0.5-2.1,0.5-2.9C239.9,28.2,240.1,27.3,240.3,26.4
			z"/>
                    <path className="finale-2" d="M316.9,25.8c-2.2-0.6-4.6-1.2-7.3-1.6c-2.7-0.4-5.2-0.6-7.7-0.4c0.1,0.9-0.1,1.7-0.6,2.5
			c-0.5,0.8-1.2,1.4-2,2c-0.8,0.5-1.7,0.9-2.7,1.1c-1,0.2-1.9,0.2-2.8,0c-1.6-0.5-2.7-1.3-3.4-2.3c-0.7-1.1-0.9-2.1-0.8-3.3
			c0.1-1.1,0.7-2.2,1.7-3.2c1-1,2.3-1.7,4-2c4,0,7.8-0.3,11.3-0.8c3.6-0.5,6.3-1.7,8.2-3.6c1.9-2.3,3.2-4.8,3.9-7.3
			c0.7-2.6,0.6-5.9-0.2-9.9c-1.2-3.6-3.4-6.4-6.6-8.3c-3.2-1.9-6.9-3.1-11-3.6c-3.6-0.6-6.8-0.7-9.8-0.3c-3,0.4-5.6,1.2-7.9,2.5
			c-2.3,1.3-4.1,2.9-5.6,5c-1.5,2.1-2.4,4.5-2.7,7.3l0,0.3l0,0.2c2.3,0.6,4.9,1,7.8,1.3c3,0.3,5.5,0.6,7.7,0.9
			c0.3-1.6,1.2-2.8,2.8-3.6c1.6-0.8,3.2-1.2,4.8-1.2c1.6,0,3.1,0.5,4.3,1.5c1.2,0.9,1.7,2.4,1.3,4.4c0,1.7-0.6,2.9-1.7,3.6
			c-1.1,0.7-2.5,1.2-4.1,1.4c-1.7,0.2-3.5,0.4-5.6,0.5c-2.1,0.1-4.2,0.4-6.2,1c-4.1,0.8-7.2,2.5-9.4,5.1c-2.2,2.6-3.4,5.4-3.7,8.5
			c-0.3,3.1,0.3,6,1.8,8.8c1.5,2.8,3.9,4.9,7.2,6.3c1.3,0.5,2.9,1,4.8,1.5c1.9,0.5,3.9,0.8,6.1,1.1c2.2,0.3,4.4,0.5,6.6,0.5
			c2.2,0,4.2,0,6.1-0.2c3.5-1,6.1-2.9,7.9-5.7C315.3,32.9,316.4,29.6,316.9,25.8z"/>
                    <path className="finale-2" d="M318.5,64.1l0.3,0c1.8,0.4,3.9,0.9,6.3,1.5c2.4,0.6,4.6,0.7,6.6,0.4c1-9,2.1-18.2,3.3-27.8
			c1.2-9.6,2.5-18.8,4.1-27.7c-1.8-0.5-4-0.9-6.6-1.2c-2.6-0.4-4.9-0.7-6.9-1.1l-0.1,0c-0.5,4.6-1.1,9.2-1.8,13.9s-1.3,9.2-2,13.8
			c-0.7,4.6-1.3,9.3-1.9,14C319.3,54.5,318.8,59.3,318.5,64.1z M336.4-8.3l-7.9-1.1c-0.2,0-0.4,0.4-0.5,1.3c-0.1,0.9-0.2,2-0.3,3.3
			c-0.1,1.3-0.2,2.7-0.2,4.1c0,1.4-0.1,2.6-0.3,3.6l0,0.1c0.8,0.5,1.7,0.8,2.8,1c1,0.2,2.1,0.4,3.3,0.4c1.2,0.1,2.3,0.1,3.4,0.2
			c1.1,0,2.2,0.1,3.3,0.3c0.2-2,0.4-4.1,0.6-6.3c0.2-2.2,0.5-4.3,0.7-6.4l-0.7-0.1c-0.8-0.1-1.5-0.2-2.1-0.2c-0.5,0-1.2-0.1-1.9-0.2
			L336.4-8.3z"/>
                </g>
                <g>
                    <path className="finale-0" d="M-310.4-25.3c-0.1-0.2-0.2-0.4-0.3-0.7s-0.1-0.6-0.2-0.7c-0.1-0.2-0.1-1.5,0.1-3.7c0.2-2.3,0.5-4.9,0.8-8
			c0.3-3.1,0.7-6.3,1.2-9.6c0.4-3.3,0.9-6.2,1.5-8.6c0.5-2,1.3-3.9,2.4-5.7c1.1-1.8,2.7-2.9,4.8-3.1c1.6,0.1,3,0.5,4.3,1.3
			c1.3,0.8,2.2,1.9,2.7,3.4c0.6,1.7,0.7,3.5,0.3,5.2c-0.3,1.8-0.5,3.6-0.3,5.3c1,0,2.1,0.1,3.3,0.3c1.2,0.2,2.5,0.4,3.7,0.6
			c1.3,0.2,2.5,0.3,3.7,0.4c1.2,0.1,2.4,0.1,3.4-0.1c1.7-3.5,2.5-7.1,2.4-10.7c-0.1-3.7-0.9-7.1-2.4-10.2c-1.5-3.1-3.7-5.8-6.5-8.1
			c-2.8-2.3-6.1-3.7-10-4.3c-2.5-0.7-5.1-1-7.8-1.1c-2.7,0-5.3,0.3-7.7,1.1c-2.5,0.8-4.6,2-6.4,3.8c-1.8,1.7-3.1,4.1-3.8,7
			c-0.5,2.5-0.9,5.1-1.4,7.8c-0.4,2.7-0.9,5.8-1.4,9.2c-0.5,3.4-1,7.2-1.5,11.5c-0.5,4.3-1.1,9.3-1.8,15c-0.3,1.2-0.4,2.4-0.3,3.6
			c0.1,1.3,0.3,2.4,0.5,3.5c0.4,1.9,1,3.7,1.7,5.4c0.7,1.7,1.7,3.2,2.9,4.5c0.7,0.7,1.5,1.2,2.3,1.8c0.9,0.5,1.8,1,2.7,1.4
			c3.1,1.5,6.2,2.3,9.4,2.5c3.2,0.2,6.2-0.1,9.2-1c2.9-0.9,5.6-2.2,8.2-3.9c2.5-1.7,4.6-3.8,6.3-6.3c1.7-2.4,2.9-5.2,3.5-8.2
			c0.7-3,0.7-6.2,0-9.6c-2.5-0.9-5-1.4-7.5-1.6c-2.5-0.2-5.1-0.2-7.8,0c-0.4,1.3-0.7,2.8-1,4.5c-0.3,1.7-0.7,3.2-1.2,4.6
			c-0.5,1.4-1.3,2.5-2.4,3.3c-1.1,0.8-2.6,1-4.6,0.7c-0.9-0.3-1.8-0.6-2.8-0.9C-309.2-24.1-309.9-24.6-310.4-25.3z"/>
                    <path className="finale-0" d="M-273.5-29.6c0.3,1.1,1,2.1,2,3c1,0.9,2.2,1.7,3.5,2.4c1.4,0.7,2.9,1.3,4.5,1.7c1.6,0.4,3.3,0.8,4.9,1
			c1.4,0,2.8,0.2,4,0.5c1.3,0.3,2.7,0.4,4.2,0.5c2.3,0.2,4.4,0,6.6-0.5c2.1-0.5,4-1.3,5.7-2.5c1.1-0.5,2-1.4,2.7-2.7
			c0.7-1.3,1.4-2.9,1.9-4.7c0.5-1.8,1-3.8,1.3-6c0.4-2.2,0.7-4.4,1.1-6.7c0.2-2,0.4-4.4,0.7-7c0.2-2.6,0.4-5.1,0.5-7.5
			c0.1-2.4,0-4.6-0.1-6.5c-0.1-1.9-0.5-3.3-1.1-4.1c-1-1.8-2.4-3.2-4.2-4.2c-1.8-1-3.9-1.7-6.1-2.1c-2.2-0.5-4.5-0.8-6.9-0.9
			c-2.4-0.1-4.6-0.3-6.5-0.6c-3.7,0.2-6.9,1.3-9.6,3.4c-2.1,1.4-3.7,3.4-4.7,5.8c-1,2.4-1.8,5-2.3,7.8c-0.5,2.7-0.9,5.5-1.2,8.3
			c-0.3,2.8-0.7,5.4-1.2,7.6c-0.2,1.3-0.3,2.5-0.4,3.7c-0.1,1.2-0.1,2.5-0.1,3.9c0,0.8,0.1,1.8,0.3,3.2
			C-273.8-31.4-273.6-30.4-273.5-29.6z M-258.8-40.2c-0.4-0.3-0.6-1.1-0.7-2.2c0-2.3,0.2-4.5,0.5-6.7c0.3-2.2,0.5-4.5,0.7-7
			c0.1-1.6,0.7-2.8,1.8-3.6c1-0.8,2.2-1.2,3.5-1.3c1.3-0.1,2.7,0,4.1,0.3c1.4,0.4,2.5,0.8,3.3,1.5c1.1,0.7,1.8,2,2,3.8
			c0,2.3-0.1,4.6-0.2,6.9c-0.1,2.3-0.5,4.4-1.2,6.4c-0.7,2.4-1.9,4.1-3.8,5c-1.8,0.9-4.1,0.8-6.7-0.1C-256.9-38.1-258-39-258.8-40.2
			z"/>
                    <path className="finale-0" d="M-213.4-22.7c0.3,0.3,0.7,0.6,1.2,0.9c0.5,0.3,0.9,0.7,1.2,1c1.3,1.2,2.9,2.3,4.6,3.2
			c1.7,0.9,3.6,1.5,5.5,1.8c1.9,0.3,3.8,0.4,5.7,0.1c1.9-0.2,3.6-0.9,5.3-2c0.8-0.5,1.5-1.8,2.1-3.7c0.6-1.9,1.2-4.3,1.7-7.1
			c0.5-2.8,0.9-5.8,1.3-9.2c0.4-3.3,0.8-6.7,1.2-10.1c0.4-3.4,0.7-6.7,1.1-9.8c0.3-3.2,0.7-5.9,1.2-8.3c-2.4-0.7-4.6-1.1-6.5-1.3
			c-1.9-0.2-4.1-0.5-6.6-0.8c-0.4,1.6-0.7,3.4-0.9,5.4c-0.3,2-0.5,4.1-0.8,6.3c-0.3,2.2-0.5,4.3-0.9,6.5c-0.3,2.2-0.7,4.2-1.1,6.1
			c-0.2,0.8-0.4,1.7-0.6,2.8c-0.2,1-0.5,2-0.8,2.9c-0.3,1-0.7,1.8-1.1,2.5c-0.4,0.7-0.9,1.3-1.5,1.6c-0.1,0.1-0.2,0.1-0.2,0.1
			c-0.1,0-0.1,0-0.2,0.1c-0.6,0.2-1.4,0.3-2.6,0.4c-1.1,0-2.2-0.2-3.2-0.6c-1-0.5-1.9-1.2-2.6-2.3c-0.7-1.1-1-2.5-0.9-4.4
			c0.2-2.5,0.5-4.9,0.8-7.2c0.4-2.3,0.8-4.7,1.2-7c0.4-2.3,0.8-4.7,1.1-7c0.3-2.3,0.5-4.6,0.5-7l0.1-0.4c-1.1-0.5-2.1-0.9-3-1
			c-0.9-0.2-1.8-0.3-2.7-0.4c-0.9-0.1-1.9-0.2-3.1-0.2c-1.1-0.1-2.5-0.3-4.2-0.7c-1.1,8.6-2.4,17.2-3.7,25.8
			c-1.3,8.6-2.4,17.5-3.4,26.8c0.8,0,1.6,0.1,2.1,0.1c0.6,0,1.1,0.1,1.6,0.2c0.5,0.1,1,0.1,1.6,0.3c0.6,0.1,1.2,0.3,2.1,0.4
			c0.3,0,0.8,0.2,1.4,0.4c0.7,0.2,1.4,0.4,2.1,0.5c0.7,0.2,1.3,0.3,1.9,0.3c0.6,0,0.9,0,0.9-0.3c0.3-1.2,0.5-2.1,0.5-2.9
			C-213.7-20.8-213.6-21.7-213.4-22.7z"/>
                    <path className="finale-0" d="M-142.6-82.3c-0.7-0.2-1.9-0.4-3.8-0.6c-1.8-0.2-4.2-0.4-7-0.8c-3.3-0.4-6.3-0.1-9.1,0.9
			c-2.8,1.1-5.1,2.7-7,4.8c-0.3,0.5-0.7,1.1-1,1.8c-0.4,0.8-0.7,1.6-1,2.4c-0.3,0.8-0.5,1.6-0.6,2.4c-0.1,0.8-0.2,1.4-0.2,2
			c0.7,0.4,1.6,0.7,2.8,1c1.2,0.3,2.4,0.5,3.7,0.6c1.3,0.1,2.6,0.2,3.8,0.3c1.2,0.1,2.3,0.1,3.1,0c0.2-1.8,1-3.2,2.3-4.1
			c1.3-0.9,2.7-1.4,4.1-1.3c1.5,0.1,2.8,0.6,4.1,1.6c1.3,1,2,2.5,2.1,4.6c0,0.7,0,1.4-0.1,2.2c0,0.7-0.1,1.4-0.3,1.9
			c-0.3,1.2-0.5,2.4-0.5,3.7c-0.6-0.9-1.3-1.5-2.2-2c-0.9-0.5-1.9-0.9-2.9-1.1c-1.1-0.3-2.2-0.5-3.3-0.6c-1.1-0.1-2.2-0.3-3.1-0.5
			c-1.3-0.2-2.7-0.2-4.1-0.2c-1.4,0-2.7,0.2-4,0.5c-1.3,0.3-2.5,0.8-3.6,1.6c-1.1,0.7-2,1.7-2.8,2.9c-1.2,1.8-2.2,3.6-2.9,5.7
			c-0.7,2-1.3,4.1-1.7,6.2c-0.4,3.3-0.8,6.8-1.2,10.6c-0.4,3.8-0.2,7.4,0.4,10.9c0.6,3.5,2,6.5,4.1,9.1c2.1,2.6,5.3,4.3,9.8,5.2
			c2.2,0.4,4.3,0.4,6.4-0.1c2-0.5,3.6-1.6,4.7-3.5c0.3-0.1,0.4,0,0.4,0.4c0,0.4-0.1,0.9-0.2,1.5c-0.1,0.6-0.2,1.2-0.3,1.8
			c-0.1,0.6-0.1,1.1,0.1,1.5c2.3,0.6,4.6,1,7,1.2c2.4,0.2,4.8,0.5,7.2,0.8c1.4-8.7,2.8-17.8,4.1-27.4s2.4-19.4,3.3-29.5
			c0.2-1.7,0.4-3.3,0.7-5c0.3-1.6,0.2-3.1-0.4-4.3c-0.8-1.7-1.6-3-2.5-4.1c-0.9-1-1.8-1.9-2.7-2.5c-0.9-0.7-1.8-1.2-2.8-1.5
			C-140.8-81.9-141.7-82.1-142.6-82.3z M-166.8-38.8l0.1-0.9c0.5-3.6,1.9-6.5,4.4-8.7c1.5-1.4,3.3-2.1,5.4-2.1
			c2.1,0,4.1,0.3,5.8,1.1c0.6,0.3,1,0.7,1.3,1.2c0.3,0.5,0.5,1.1,0.6,1.7c0.1,0.6,0.2,1.3,0.2,2c0,0.7,0,1.3,0,2
			c-0.4,2.8-0.8,5.6-1.3,8.5s-1.3,5.6-2.3,8.2c-0.6,1-1.3,1.7-2.4,2.2c-1,0.5-2.1,0.7-3.2,0.7c-1.1,0-2.2-0.2-3.3-0.7
			c-1-0.5-1.9-1.1-2.7-1.9c-0.9-1.3-1.6-2.8-2.2-4.3c-0.5-1.5-0.9-3.1-0.9-4.8c0-0.7,0-1.4,0.1-2.1C-167-37.4-166.9-38.1-166.8-38.8
			z"/>
                    <path className="finale-0" d="M-117-7.9c0.3,0.2,0.9,0.5,1.8,1.1c1,0.6,1.9,1.1,2.8,1.6c1.3,0.6,2.7,1.1,4.4,1.6c1.6,0.5,3.2,0.8,4.7,1.1
			c1.5,0.3,2.7,0.4,3.7,0.5c1,0.1,1.5,0.1,1.5,0c0.3-2.4,0.6-4.5,0.8-6.3c0.2-1.8,0.4-3.5,0.6-4.9c0.2-1.4,0.4-2.7,0.5-3.9
			c0.2-1.2,0.3-2.3,0.5-3.3l-1,0.2c-0.4,0.1-0.8,0.2-1.2,0.2c-0.4,0.1-0.8,0.1-1.2,0.2c-0.9,0.1-1.7,0.2-2.6,0.2c-0.9,0-1.4,0-1.4,0
			c-1.9,0-3.7-0.2-5.2-0.4c-1.6-0.2-2.9-0.6-4-1.3c-1.1-0.6-1.9-1.5-2.4-2.7c-0.5-1.1-0.6-2.7-0.2-4.6c0.4-2.4,0.8-4.7,1.1-6.9
			c0.4-2.2,0.7-4.3,1-6.5c0.3-2.2,0.6-4.4,0.9-6.7c0.3-2.3,0.7-4.7,1-7.3l0.1-0.4c-1.1-0.5-2.2-0.9-3.2-1.1c-1-0.2-2-0.4-3-0.5
			c-1.1-0.1-2.2-0.2-3.4-0.3c-1.2-0.1-2.7-0.3-4.3-0.7c-1.1,8.6-2.4,17.2-3.7,25.8c-1.3,8.6-2.4,17.5-3.4,26.8
			c0.8,0,1.6,0.1,2.1,0.1c0.6,0,1.1,0.1,1.6,0.2c0.5,0.1,1,0.2,1.6,0.3c0.6,0.1,1.2,0.3,2.1,0.4c0.3,0,0.8,0.1,1.5,0.3
			c0.7,0.2,1.5,0.3,2.3,0.5c0.8,0.2,1.5,0.3,2.1,0.3c0.6,0,0.9,0,0.9-0.1c0.1-0.6,0.1-1,0.2-1.3s0.1-0.5,0.1-0.7
			c0-0.2,0.1-0.4,0.1-0.6C-117.1-7-117-7.4-117-7.9z"/>
                    <path className="finale-0" d="M-50.9,4.4c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.4,0.2-0.8,0.2-1.3l0.1-1c0.8-7.6,1.8-15,3.1-22.3
			c1.2-7.3,2.1-14.7,2.6-22.2c0.1-0.9,0.2-1.8,0.3-2.7c0.1-0.9-0.3-1.5-1.1-1.7l-1.3-0.2c-1.1-0.1-2.3-0.2-3.4-0.3
			c-1.2,0-2.3-0.1-3.4-0.3c-0.8-0.1-1.5-0.3-2.2-0.5c-0.7-0.2-1.5-0.4-2.2-0.5c-0.5,0.1-0.9,0.3-1.1,0.7c-0.2,0.4-0.4,0.9-0.5,1.4
			c-0.1,0.8-0.1,1.4,0,1.9c0.1,0.5,0.1,1.1,0,1.8c-1.5-1.9-3.4-3.6-5.8-5.1c-2.4-1.5-4.8-2.5-7.3-2.8c-2.7-0.4-5.2,0.3-7.4,1.9
			c-2.2,1.6-4.1,3.8-5.7,6.4c-1.6,2.6-3,5.6-4,8.8c-1,3.2-1.7,6.2-2.1,9c-0.5,3.5-0.4,6.8,0.1,9.8c0.5,3.1,1.4,5.8,2.7,8.1
			c1.3,2.3,2.9,4.3,4.9,5.8c2,1.5,4.2,2.5,6.7,2.9c1.3,0.2,2.4,0.3,3.5,0.4c1.1,0.1,2.1,0.1,3.1-0.1c1-0.2,1.8-0.6,2.6-1.2
			c0.8-0.6,1.5-1.4,2.2-2.5c-0.2,1.1-0.3,2.1-0.2,2.9c0.1,0.8,0,1.7-0.1,2.5c0,0.1,0.2,0.2,0.7,0.2c0.5,0.1,1,0.2,1.5,0.2
			c0.6,0,1.1,0.1,1.7,0.2c0.6,0.1,0.9,0.1,1.2,0.2c1.2,0.1,2.7,0.2,4.4,0.4C-55.1,4.1-53.1,4.2-50.9,4.4z M-78.6-20.5
			c-0.2-0.9-0.2-1.9-0.2-2.9c0.1-1,0.1-1.9,0.3-2.8c0.4-3.1,1.6-5.7,3.5-7.7c1.9-2,4.4-2.8,7.3-2.4c1.7,0.2,3,0.7,3.8,1.4
			c0.8,0.7,1.3,1.6,1.6,2.8c0.2,1.1,0.3,2.5,0.1,4.1c-0.2,1.6-0.4,3.3-0.6,5.3c-0.3,2.4-0.6,4.4-0.8,5.8c-0.2,1.4-0.5,2.5-1,3.2
			c-0.4,0.7-1,1.1-1.8,1.3c-0.8,0.1-1.9,0.1-3.3-0.1c-2.9-0.4-5-1.4-6.5-2.9C-77.7-17.1-78.5-18.7-78.6-20.5z"/>
                    <path className="finale-0" d="M-27,27.4l1.9-14.1c0.1-0.9,0.3-1.9,0.4-2.8c0.2-1,0.3-2,0.5-3c2.2,0.4,4.4,0.8,6.8,1.2
			c2.4,0.5,5.4,1,8.9,1.7c0.6-0.5,1-1.3,1.2-2.4C-7.1,7-7,5.8-6.9,4.6C-6.8,3.3-6.7,2-6.6,0.7c0.1-1.3,0.2-2.4,0.5-3.3l-16.3-2.2
			c0.5-3.8,1-7.5,1.5-11.1c0.5-3.6,1-7,1.4-10.1c0.8-0.9,1.9-1.5,3.3-1.9c1.4-0.4,2.9-0.6,4.5-0.7c1.6-0.1,3.2-0.1,4.7,0
			c1.6,0.1,3,0.3,4.1,0.5c0.3-1,0.6-2,0.8-3.2c0.3-1.2,0.6-2.4,0.8-3.6c0.2-1.2,0.4-2.5,0.4-3.8s0-2.5-0.2-3.6
			c-2.8-0.5-5.7-0.9-8.7-1.1c-3-0.2-5.9-0.2-8.7,0.1c-2.7,0.3-5.2,0.9-7.5,1.9c-2.2,1-3.9,2.5-5.1,4.6c-1.6,5.3-2.8,10.3-3.6,15.1
			c-0.8,4.8-1.5,9.7-2.1,14.8c-1.1-0.2-2.3-0.4-3.5-0.6c-1.2-0.2-2.6-0.3-4-0.5l-1.4,12.8c1.4,0.1,2.7,0.3,4,0.4
			c1.2,0.2,2.4,0.3,3.5,0.5c-0.2,1.5-0.5,3.2-0.8,5c-0.3,1.8-0.6,3.6-0.8,5.4c-0.2,1.8-0.5,3.6-0.6,5.3c-0.2,1.7-0.3,3.2-0.3,4.5
			c0.9,0.3,1.9,0.4,3,0.5c1.1,0.1,2.2,0.1,3.4,0.2c1.2,0.1,2.4,0.1,3.6,0.2C-29.4,26.9-28.2,27.1-27,27.4z"/>
                    <path className="finale-0" d="M29.3-32.4c-0.3-0.3-0.7-0.6-1.2-0.9c-0.5-0.4-0.9-0.7-1.2-1c-1.3-1.2-2.9-2.3-4.6-3.2
			c-1.7-0.9-3.6-1.5-5.5-1.8c-1.9-0.3-3.8-0.4-5.7-0.1c-1.9,0.2-3.6,0.9-5.3,2c-0.8,0.5-1.5,1.8-2.1,3.7C3-31.8,2.5-29.4,2-26.6
			c-0.5,2.8-0.9,5.8-1.3,9.2c-0.4,3.3-0.8,6.7-1.2,10.1C-0.9-4-1.2-0.7-1.6,2.5c-0.3,3.2-0.7,5.9-1.2,8.3c2.4,0.7,4.6,1.1,6.5,1.3
			c1.9,0.2,4.1,0.5,6.6,0.8c0.4-1.6,0.7-3.4,0.9-5.4c0.3-2,0.5-4.1,0.8-6.3c0.3-2.2,0.5-4.3,0.9-6.5c0.3-2.2,0.7-4.2,1.1-6.1
			c0.2-0.8,0.4-1.8,0.6-2.8s0.5-2,0.8-2.9c0.3-1,0.7-1.8,1.1-2.5c0.4-0.7,0.9-1.3,1.5-1.6c0.1-0.1,0.2-0.1,0.2-0.1
			c0.1,0,0.1,0,0.2-0.1c0.6-0.2,1.4-0.3,2.6-0.4c1.1,0,2.2,0.2,3.2,0.6c1,0.5,1.9,1.2,2.6,2.3c0.7,1.1,1,2.5,0.9,4.4
			c-0.2,2.4-0.5,4.8-0.8,7.2c-0.4,2.4-0.8,4.7-1.2,7.1c-0.4,2.3-0.8,4.6-1.1,7c-0.3,2.3-0.5,4.6-0.4,7l-0.1,0.4c1.1,0.5,2.1,0.9,3,1
			c0.9,0.2,1.8,0.3,2.7,0.4c0.9,0.1,1.9,0.2,3.1,0.2c1.1,0.1,2.5,0.3,4.2,0.7c1.1-8.6,2.4-17.2,3.7-25.8c1.3-8.6,2.4-17.5,3.4-26.8
			c-0.8,0-1.6-0.1-2.1-0.1c-0.6,0-1.1-0.1-1.6-0.2c-0.5-0.1-1-0.2-1.6-0.3c-0.6-0.1-1.2-0.3-2.1-0.4c-0.3,0-0.8-0.2-1.4-0.4
			c-0.7-0.2-1.4-0.4-2.1-0.5c-0.7-0.2-1.3-0.3-1.9-0.3c-0.6,0-0.9,0-0.9,0.3c-0.3,1.2-0.5,2.1-0.5,2.9
			C29.6-34.3,29.5-33.4,29.3-32.4z"/>
                    <path className="finale-0" d="M41.8,41.1l14.3,1.6c0.4-2.9,0.8-6.3,1.3-10.4c0.5-4.1,1-8.4,1.5-13c0.5-4.6,1.1-9.4,1.7-14.4
			c0.6-5,1.2-9.8,1.9-14.4c0.6-4.6,1.2-8.9,1.8-12.9c0.6-4,1.2-7.4,1.7-10.2c-0.3-0.5-1.1-0.8-2.5-1c-1.4-0.2-2.9-0.4-4.6-0.6
			c-1.6-0.2-3.2-0.3-4.7-0.5c-1.5-0.1-2.5-0.3-2.9-0.5c-0.3,6.4-1,12.7-1.9,19.1c-0.9,6.4-1.9,12.7-3,19c-1.1,6.3-2,12.7-2.9,19.1
			C42.5,28.3,42,34.7,41.8,41.1z"/>
                    <path className="finale-0" d="M108.6,25.5c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.4,0.2-0.8,0.2-1.3l0.1-1c0.8-7.6,1.8-15,3.1-22.3
			c1.2-7.3,2.1-14.7,2.6-22.2c0.1-0.9,0.2-1.8,0.3-2.7c0.1-0.9-0.3-1.5-1.1-1.7l-1.3-0.2c-1.1-0.1-2.3-0.2-3.4-0.3
			c-1.2,0-2.3-0.1-3.4-0.3c-0.8-0.1-1.5-0.3-2.2-0.5c-0.7-0.2-1.5-0.4-2.2-0.5c-0.5,0.1-0.9,0.3-1.1,0.7c-0.2,0.4-0.4,0.9-0.5,1.4
			c-0.1,0.8-0.1,1.4,0,1.9c0.1,0.5,0.1,1.1,0,1.8c-1.5-1.9-3.4-3.6-5.8-5.1c-2.4-1.5-4.8-2.5-7.3-2.8c-2.7-0.4-5.2,0.3-7.4,1.9
			c-2.2,1.6-4.1,3.8-5.7,6.4c-1.6,2.6-3,5.6-4,8.8c-1,3.2-1.7,6.2-2.1,9c-0.5,3.5-0.4,6.8,0.1,9.8c0.5,3.1,1.4,5.8,2.7,8.1
			c1.3,2.3,2.9,4.3,4.9,5.8c2,1.5,4.2,2.5,6.7,2.9c1.3,0.2,2.4,0.3,3.5,0.4c1.1,0.1,2.1,0.1,3.1-0.1c1-0.2,1.8-0.6,2.6-1.1
			c0.8-0.6,1.5-1.4,2.2-2.5c-0.2,1.1-0.3,2.1-0.2,2.9c0.1,0.8,0,1.7-0.1,2.5c0,0.1,0.2,0.2,0.7,0.2c0.4,0.1,1,0.2,1.5,0.2
			c0.6,0,1.1,0.1,1.7,0.2c0.6,0.1,0.9,0.1,1.2,0.2c1.2,0.1,2.7,0.2,4.4,0.4C104.3,25.2,106.3,25.3,108.6,25.5z M80.8,0.6
			c-0.2-0.9-0.2-1.9-0.2-2.9c0.1-1,0.1-1.9,0.3-2.8c0.4-3.1,1.6-5.7,3.5-7.7c1.9-2,4.4-2.8,7.3-2.4c1.7,0.2,3,0.7,3.8,1.4
			c0.8,0.7,1.3,1.6,1.6,2.8c0.2,1.1,0.3,2.5,0.1,4.1c-0.2,1.6-0.4,3.3-0.6,5.3c-0.3,2.4-0.6,4.4-0.8,5.8c-0.2,1.4-0.5,2.5-1,3.2
			c-0.4,0.7-1,1.1-1.8,1.3c-0.8,0.1-1.9,0.1-3.3-0.1c-2.9-0.4-5-1.4-6.5-2.9C81.7,4,80.9,2.4,80.8,0.6z"/>
                    <path className="finale-0" d="M132.4,48.5l1.9-14.1c0.1-0.9,0.3-1.9,0.4-2.8c0.2-1,0.3-2,0.5-3c2.2,0.4,4.4,0.8,6.8,1.2
			c2.4,0.5,5.4,1,8.9,1.7c0.6-0.5,1-1.3,1.2-2.4c0.2-1.1,0.4-2.2,0.5-3.5c0.1-1.3,0.2-2.6,0.2-3.9c0.1-1.3,0.2-2.4,0.5-3.3L137,16.3
			c0.5-3.8,1-7.5,1.5-11.1c0.5-3.6,1-7,1.4-10.1c0.8-0.9,1.9-1.5,3.3-1.9c1.4-0.4,2.9-0.6,4.5-0.7c1.6-0.1,3.2-0.1,4.7,0
			c1.6,0.1,3,0.3,4.1,0.5c0.3-1,0.6-2,0.9-3.2c0.3-1.2,0.6-2.4,0.8-3.6c0.2-1.2,0.4-2.5,0.4-3.8c0.1-1.3,0-2.5-0.2-3.6
			c-2.8-0.5-5.7-0.9-8.7-1.1c-3-0.2-5.9-0.2-8.7,0.1c-2.7,0.3-5.2,0.9-7.5,1.9c-2.2,1-3.9,2.5-5.1,4.6c-1.6,5.3-2.8,10.3-3.6,15.1
			c-0.8,4.8-1.5,9.7-2.1,14.8c-1.1-0.2-2.3-0.4-3.5-0.6c-1.2-0.2-2.6-0.3-4-0.5L113.9,26c1.4,0.1,2.7,0.3,4,0.4
			c1.2,0.2,2.4,0.3,3.5,0.5c-0.2,1.5-0.5,3.2-0.8,5c-0.3,1.8-0.6,3.6-0.8,5.4c-0.2,1.8-0.5,3.6-0.6,5.3c-0.2,1.7-0.3,3.2-0.3,4.5
			c0.9,0.3,1.9,0.4,3,0.5c1.1,0.1,2.2,0.1,3.4,0.2c1.2,0.1,2.4,0.1,3.6,0.2C130.1,48,131.3,48.2,132.4,48.5z"/>
                    <path className="finale-0" d="M153.6,55.3l14.3,1.6c0.1-0.4,0.2-1.2,0.4-2.2c0.2-1.1,0.4-2.3,0.6-3.6c0.2-1.3,0.4-2.6,0.6-4
			c0.2-1.4,0.4-2.6,0.5-3.6c-0.2-0.5-0.8-0.8-1.8-1.1c-1-0.3-2.2-0.5-3.5-0.6s-2.8-0.3-4.4-0.4c-1.6-0.1-3-0.3-4.3-0.5
			c-0.3,2-0.6,4.1-1.1,6.3C154.4,49.3,154,52,153.6,55.3z M169.6,34.1l1.8,0.2l0-0.3c1-8.5,2-17.1,3-26c0.9-8.8,2.2-17.5,3.8-25.9
			c-0.3-0.5-1.1-0.8-2.5-1c-1.4-0.2-2.9-0.4-4.6-0.6c-1.6-0.2-3.2-0.3-4.7-0.5c-1.5-0.1-2.5-0.3-2.9-0.5c-0.2,4.5-0.7,9-1.3,13.5
			c-0.6,4.5-1.3,9-2.1,13.5c-0.7,4.5-1.4,8.9-2,13.2c-0.6,4.4-0.9,8.7-1.1,12.8l0,0.1c0.3,0.1,0.7,0.2,1.4,0.2c0.7,0,1.2,0,1.5,0.1
			c0.9,0,2.2,0.1,3.8,0.3C165.4,33.5,167.3,33.7,169.6,34.1z"/>
                    <path className="finale-0" d="M180.1,30.5c0.3,1.1,1,2.1,2,3c1,0.9,2.2,1.7,3.5,2.4c1.4,0.7,2.9,1.3,4.5,1.7c1.6,0.4,3.3,0.8,4.9,1
			c1.4,0,2.8,0.2,4,0.5c1.3,0.3,2.7,0.4,4.2,0.5c2.3,0.2,4.4,0,6.6-0.5c2.1-0.5,4-1.3,5.7-2.5c1.1-0.5,2-1.4,2.7-2.7
			c0.7-1.3,1.4-2.9,1.9-4.7c0.5-1.8,1-3.8,1.3-6c0.4-2.2,0.7-4.4,1.1-6.7c0.2-2,0.4-4.4,0.7-7c0.2-2.6,0.4-5.1,0.5-7.5
			c0.1-2.4,0-4.6-0.1-6.5c-0.1-1.9-0.5-3.3-1.1-4.1c-1-1.8-2.4-3.2-4.2-4.2c-1.8-1-3.9-1.7-6.1-2.1c-2.2-0.5-4.5-0.8-6.9-0.9
			c-2.4-0.1-4.6-0.3-6.5-0.6c-3.7,0.2-6.9,1.3-9.6,3.4c-2.1,1.4-3.7,3.4-4.7,5.8c-1,2.4-1.8,5-2.3,7.8c-0.5,2.7-0.9,5.5-1.2,8.3
			c-0.3,2.8-0.7,5.4-1.2,7.6c-0.2,1.3-0.3,2.5-0.4,3.7c-0.1,1.2-0.1,2.5-0.1,3.9c0,0.8,0.1,1.8,0.3,3.2
			C179.8,28.6,180,29.7,180.1,30.5z M194.9,19.8c-0.4-0.3-0.6-1.1-0.7-2.2c0-2.3,0.2-4.5,0.5-6.7c0.3-2.2,0.5-4.5,0.7-7
			c0.1-1.6,0.7-2.8,1.8-3.6c1-0.8,2.2-1.2,3.5-1.3c1.3-0.1,2.7,0,4.1,0.3c1.4,0.4,2.5,0.8,3.3,1.5c1.1,0.7,1.8,2,2,3.8
			c0,2.3-0.1,4.6-0.2,6.9c-0.1,2.3-0.5,4.4-1.2,6.4c-0.7,2.4-1.9,4.1-3.8,5c-1.8,0.9-4.1,0.8-6.7-0.1
			C196.7,22,195.6,21.1,194.9,19.8z"/>
                    <path className="finale-0" d="M240.3,37.4c0.3,0.3,0.7,0.6,1.2,0.9c0.5,0.3,0.9,0.7,1.2,1c1.3,1.2,2.9,2.3,4.6,3.2c1.7,0.9,3.6,1.5,5.5,1.8
			c1.9,0.3,3.8,0.4,5.7,0.1c1.9-0.2,3.6-0.9,5.3-2c0.8-0.5,1.5-1.8,2.1-3.7c0.6-1.9,1.2-4.3,1.7-7.1c0.5-2.8,0.9-5.8,1.3-9.2
			c0.4-3.3,0.8-6.7,1.2-10.1c0.4-3.4,0.7-6.7,1.1-9.8c0.3-3.2,0.7-5.9,1.2-8.3c-2.4-0.7-4.6-1.1-6.5-1.3c-1.9-0.2-4.1-0.5-6.6-0.8
			c-0.4,1.6-0.7,3.4-0.9,5.4c-0.3,2-0.5,4.1-0.8,6.3c-0.3,2.2-0.5,4.3-0.9,6.5c-0.3,2.2-0.7,4.2-1.1,6.1c-0.2,0.8-0.4,1.7-0.6,2.8
			c-0.2,1-0.5,2-0.8,2.9c-0.3,1-0.7,1.8-1.1,2.5c-0.4,0.7-0.9,1.3-1.5,1.6c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0.1
			c-0.6,0.2-1.4,0.3-2.6,0.4c-1.1,0-2.2-0.2-3.2-0.6c-1-0.5-1.9-1.2-2.6-2.3c-0.7-1.1-1-2.5-0.9-4.4c0.2-2.5,0.5-4.9,0.8-7.2
			c0.4-2.3,0.8-4.7,1.2-7c0.4-2.3,0.8-4.7,1.1-7c0.3-2.3,0.5-4.6,0.5-7l0.1-0.4c-1.1-0.5-2.1-0.9-3-1c-0.9-0.2-1.8-0.3-2.7-0.4
			c-0.9-0.1-1.9-0.2-3.1-0.2c-1.1-0.1-2.5-0.3-4.2-0.7c-1.1,8.6-2.4,17.2-3.7,25.8c-1.3,8.6-2.4,17.5-3.4,26.8
			c0.8,0,1.6,0.1,2.1,0.1c0.6,0,1.1,0.1,1.6,0.2c0.5,0.1,1,0.1,1.6,0.3c0.6,0.1,1.2,0.3,2.1,0.4c0.3,0,0.8,0.2,1.4,0.4
			c0.7,0.2,1.4,0.4,2.1,0.5c0.7,0.2,1.3,0.3,1.9,0.3c0.6,0,0.9,0,0.9-0.3c0.3-1.2,0.5-2.1,0.5-2.9C239.9,39.2,240.1,38.3,240.3,37.4
			z"/>
                    <path className="finale-0" d="M316.9,36.8c-2.2-0.6-4.6-1.2-7.3-1.6c-2.7-0.4-5.2-0.6-7.7-0.4c0.1,0.9-0.1,1.7-0.6,2.5
			c-0.5,0.8-1.2,1.4-2,2c-0.8,0.5-1.7,0.9-2.7,1.1c-1,0.2-1.9,0.2-2.8,0c-1.6-0.5-2.7-1.3-3.4-2.3c-0.7-1.1-0.9-2.1-0.8-3.3
			c0.1-1.1,0.7-2.2,1.7-3.2c1-1,2.3-1.7,4-2c4,0,7.8-0.3,11.3-0.8c3.6-0.5,6.3-1.7,8.2-3.6c1.9-2.3,3.2-4.8,3.9-7.3
			c0.7-2.6,0.6-5.9-0.2-9.9c-1.2-3.6-3.4-6.4-6.6-8.3c-3.2-1.9-6.9-3.1-11-3.6c-3.6-0.6-6.8-0.7-9.8-0.3c-3,0.4-5.6,1.2-7.9,2.5
			c-2.3,1.3-4.1,2.9-5.6,5c-1.5,2.1-2.4,4.5-2.7,7.3l0,0.3l0,0.2c2.3,0.6,4.9,1,7.8,1.3c3,0.3,5.5,0.6,7.7,0.9
			c0.3-1.6,1.2-2.8,2.8-3.6c1.6-0.8,3.2-1.2,4.8-1.2c1.6,0,3.1,0.5,4.3,1.5c1.2,0.9,1.7,2.4,1.3,4.4c0,1.7-0.6,2.9-1.7,3.6
			c-1.1,0.7-2.5,1.2-4.1,1.4c-1.7,0.2-3.5,0.4-5.6,0.5c-2.1,0.1-4.2,0.4-6.2,1c-4.1,0.8-7.2,2.5-9.4,5.1c-2.2,2.6-3.4,5.4-3.7,8.5
			c-0.3,3.1,0.3,6,1.8,8.8c1.5,2.8,3.9,4.9,7.2,6.3c1.3,0.5,2.9,1,4.8,1.5c1.9,0.5,3.9,0.8,6.1,1.1c2.2,0.3,4.4,0.5,6.6,0.5
			c2.2,0,4.2,0,6.1-0.2c3.5-1,6.1-2.9,7.9-5.7C315.3,43.9,316.4,40.6,316.9,36.8z"/>
                    <path className="finale-0" d="M318.5,75.1l0.3,0c1.8,0.4,3.9,0.9,6.3,1.5c2.4,0.6,4.6,0.7,6.6,0.4c1-9,2.1-18.2,3.3-27.8
			c1.2-9.6,2.5-18.8,4.1-27.7c-1.8-0.5-4-0.9-6.6-1.2c-2.6-0.4-4.9-0.7-6.9-1.1l-0.1,0c-0.5,4.6-1.1,9.2-1.8,13.9s-1.3,9.2-2,13.8
			c-0.7,4.6-1.3,9.3-1.9,14C319.3,65.5,318.8,70.3,318.5,75.1z M336.4,2.7l-7.9-1.1c-0.2,0-0.4,0.4-0.5,1.3c-0.1,0.9-0.2,2-0.3,3.3
			c-0.1,1.3-0.2,2.7-0.2,4.1c0,1.4-0.1,2.6-0.3,3.6l0,0.1c0.8,0.5,1.7,0.8,2.8,1c1,0.2,2.1,0.4,3.3,0.4c1.2,0.1,2.3,0.1,3.4,0.2
			c1.1,0,2.2,0.1,3.3,0.3c0.2-2,0.4-4.1,0.6-6.3c0.2-2.2,0.5-4.3,0.7-6.4l-0.7-0.1c-0.8-0.1-1.5-0.2-2.1-0.2c-0.5,0-1.2-0.1-1.9-0.2
			L336.4,2.7z"/>
                </g>
            </symbol>
            <symbol id="splash-text-bck" viewBox="-365 -247.9 712 479.7">
                <polyline className="finale-4" points="-362.8,215.7 321.8,188 307,-198.5 -365,-247.9 	"/>
                <polygon className="finale-36" points="333,-184.1 -347,-231.8 -345.9,231.8 347,202.4 	"/>
            </symbol>
            <rect className="finale-37" height="860" width="1529" x="195.5" y="110"/>
            <g>
                <defs>
                    <rect height="871.6" id="SVGID_2_" width="1539.8" x="181.7" y="108.6"/>
                </defs>
                <clipPath id="SVGID_5_">
                    <use style={{
                        overflow: "visible",
                    }} xlinkHref="#SVGID_2_"/>
                </clipPath>
            </g>
            <path className="finale-0"
                  d="M216,115.7l1504.3,15l-21.4,826.2L203.3,969.7L216,115.7z M191.9,975.6h1539.8V103.9H191.9V975.6z"/>
            <g>
                <g>
                    <path className="finale-2" d="M539.9,286.2c-0.6-3.7,2-7.3,5.7-7.9l746.5-125.5c3.7-0.6,7.3,2,7.9,5.7l25.8,162.2c0.6,3.9-2.2,7.5-6.1,7.9
			L569.4,430.3c-3.6,0.4-6.9-2.1-7.5-5.8"/>
                </g>
            </g>
            <g height="101.1" id="XMLID_3_" style={{
                overflow: "visible",
            }} transform="matrix(1 0 0 1 1125.0604 887.5441)" width="229.4" x="-114.7"
               xlinkHref="#exit" y="-50.6">
                <GameBtn onClick={() => toWelcome()}>
                    <ExitBtn/>
                </GameBtn>
            </g>
            <use height="116.7" style={{
                overflow: "visible",
            }} transform="matrix(1 0 0 -1 825.5931 887.5441)" width="264.7" x="-132.4"
                 xlinkHref="#play_again" y="-58.3"/>
            <animated.g>
                <use height="479.7" style={{
                    overflow: "visible",
                }} transform="matrix(1 0 0 -1 718.8822 599.7646)" width="712" x="-356"
                     xlinkHref="#completed-splash-text" y="-239.8"/>
            </animated.g>
            <defs>
                <mask id="rolly-clip" width={1920} height={1080}>
                    <path transform="translate(0 786.87)" fill="black" d="M1920,293.13V148C1797.27,100.66,1474.18-1,963.2,0,438.06-1,116.51,103.79,0,150.16v143Z"/>
                </mask>
            </defs>
            <use height="277.5" id="XMLID_2_" style={{
                overflow: "visible",
            }} transform="matrix(1 0 0 -1 925.6203 282.5365)" width="786" x="-393"
                 xlinkHref="#congrats-splash-text" y="-138.8"/>
            <g height="116.7" id="XMLID_6_" style={{
                overflow: "visible",
            }} transform="matrix(1 0 0 1 825.5931 887.5441)" width="264.7" x="-132.4"
               xlinkHref="#play_again" y="-58.3">
                <GameBtn onClick={() => toLevels()}>
                    <PlayAgainBtn/>
                </GameBtn>
            </g>
            <defs>
                <g id="back-path">
                    <g fill="none" width="1920" height="293.13" transform="translate(0 786.87)">
                        <path fill="none" className="cls-9g" d="M1920,293.13V148C1797.27,100.66,1474.18-1,963.2,0,438.06-1,116.51,103.79,0,150.16v143Z"/>
                    </g>
                </g>
                <clipPath id="back-clip">
                    <use xlinkHref="#back-path"/>
                </clipPath>
            </defs>
            <g height="470.1" style={{
                overflow: "visible",
            }} transform="matrix(1 0 0 1 1175.1284 583.8679)" width="694.9" x="-347.4"
               xlinkHref="#rolly-ok" y="-235">
                {
                    containerRef?.current && <RollyOk containerRef={containerRef}/>
                }
                <g opacity={0} ref={containerRef} transform="matrix(1 0 0 -1 0 0)">

                </g>
            </g>
        </svg>
    </animated.g>;
}
