import {
    ANSWER_SELECTION_SCREEN_STATE,
    ASK_REPLAY_STATE_ACTION,
    AVATAR_CHANGE_STATE_ACTION,
    AVATAR_CHOOSED_STATE_ACTION,
    AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE,
    BACK_STATE_ACTION,
    CHECK_STATE_ACTION,
    COMPLETE_STATE_ACTION,
    CONFIRMED_STATE_ACTION,
    ENTERED_STATE_ACTION,
    ENTERING_STATE_ACTION,
    EXIT_STATE_ACTION,
    EXITED_STATE_ACTION,
    EXITING_STATE_ACTION,
    GAME_COMPLETE_STATE_ACTION,
    GAME_END_SCREEN_STATE,
    GOING_BACK_STATE_ACTION,
    IDLE_STATE_ACTION,
    LEVEL_COMPLETE_STATE_ACTION,
    LEVEL_END_SCREEN_STATE,
    LEVEL_SCENE_SCREEN_STATE,
    LEVEL_SELECTION_SCREEN_STATE,
    LOADING_SCREEN_SCREEN_STATE,
    LOGIN_SCREEN_STATE,
    LOGIN_SUCCESS_STATE_ACTION,
    MOUNT_STATE_ACTION,
    NEXT_PRESENTATION_STATE_ACTION,
    NEXT_SCENE_STATE_ACTION,
    PAUSE_STATE_ACTION,
    PLAY_AGAIN_STATE_ACTION,
    PLAY_SCENE_STATE_ACTION,
    PLAY_STATE_ACTION,
    PLAYING_STATE_ACTION,
    PRELOAD_STATE_ACTION,
    PRELOADING_STATE_ACTION,
    preloadScreen,
    PRESENTATION_STATE_ACTION,
    PREV_SCENE_STATE_ACTION,
    QUESTION_INTRO_SCREEN_STATE,
    RESTART_STATE_ACTION,
    SELECTED_STATE_ACTION,
    TRY_LOGIN_STATE_ACTION,
    UNLOCK_STATE_ACTION,
    UNLOCKING_STATE_ACTION,
    WELCOME_SCREEN_SCREEN_STATE
} from "./index";

export const gameStateFlow = {
    ['*']: {
        [ENTERING_STATE_ACTION]: preloadScreen(),
        [PRELOAD_STATE_ACTION]: ENTERED_STATE_ACTION,
        [ENTERED_STATE_ACTION]: IDLE_STATE_ACTION,
        [EXITING_STATE_ACTION]: EXITED_STATE_ACTION,
        [AVATAR_CHANGE_STATE_ACTION]: {group: AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE},
        ['logout']: {group: LOGIN_SCREEN_STATE}
    },
    [IDLE_STATE_ACTION]: {
        // on idle state go to loading screen
        [IDLE_STATE_ACTION]: {group: LOGIN_SCREEN_STATE, action: preloadScreen()},
    },
    [LOGIN_SCREEN_STATE]: {
        [TRY_LOGIN_STATE_ACTION]: IDLE_STATE_ACTION,
        [LOGIN_SUCCESS_STATE_ACTION]: EXITING_STATE_ACTION,
        [EXITING_STATE_ACTION]: EXITED_STATE_ACTION,
        [EXITED_STATE_ACTION]: {group: LOADING_SCREEN_SCREEN_STATE, action: preloadScreen()},
    },
    // preload all necessary
    [LOADING_SCREEN_SCREEN_STATE]: {
        [PRELOAD_STATE_ACTION]: ENTERING_STATE_ACTION,
        [ENTERING_STATE_ACTION]: PRELOADING_STATE_ACTION,
        [PRELOADING_STATE_ACTION]: EXITING_STATE_ACTION,
        [EXITED_STATE_ACTION]: {group: WELCOME_SCREEN_SCREEN_STATE},
    },
    [WELCOME_SCREEN_SCREEN_STATE]: {
        [ENTERING_STATE_ACTION]: ENTERED_STATE_ACTION,
        [EXITING_STATE_ACTION]: EXITED_STATE_ACTION,
        // on play button click exit
        [EXITED_STATE_ACTION]: {group: AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE},
    },
    [AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE]: {
        [AVATAR_CHOOSED_STATE_ACTION]: {group: LEVEL_SELECTION_SCREEN_STATE}
    },
    [LEVEL_SELECTION_SCREEN_STATE]: {
        [UNLOCK_STATE_ACTION]: UNLOCKING_STATE_ACTION,
        [NEXT_SCENE_STATE_ACTION]: preloadScreen(),
        [PREV_SCENE_STATE_ACTION]: preloadScreen(),
        [COMPLETE_STATE_ACTION]: preloadScreen(),
        [BACK_STATE_ACTION]: GOING_BACK_STATE_ACTION,
        [GOING_BACK_STATE_ACTION]: ENTERING_STATE_ACTION,
        [PLAY_SCENE_STATE_ACTION]: {group: LEVEL_SCENE_SCREEN_STATE},
        [PLAY_AGAIN_STATE_ACTION]: GOING_BACK_STATE_ACTION,
        [IDLE_STATE_ACTION]: CHECK_STATE_ACTION,
    },
    [LEVEL_SCENE_SCREEN_STATE]: {
        [ENTERING_STATE_ACTION]: ENTERED_STATE_ACTION,
        [ENTERED_STATE_ACTION]: MOUNT_STATE_ACTION,
        [MOUNT_STATE_ACTION]: PLAY_STATE_ACTION,
        [PLAY_STATE_ACTION]: PLAYING_STATE_ACTION,
        [PAUSE_STATE_ACTION]: IDLE_STATE_ACTION,
        [RESTART_STATE_ACTION]: PLAY_STATE_ACTION,
        [ASK_REPLAY_STATE_ACTION]: RESTART_STATE_ACTION,
        [GOING_BACK_STATE_ACTION]: BACK_STATE_ACTION,
        [BACK_STATE_ACTION]: {group: LEVEL_SELECTION_SCREEN_STATE, action: BACK_STATE_ACTION},
        // [TO_FULLSCREEN_STATE_ACTION]: COMPLETE_STATE_ACTION,
        [COMPLETE_STATE_ACTION]: {group: QUESTION_INTRO_SCREEN_STATE},
    },
    [QUESTION_INTRO_SCREEN_STATE]: {
        [EXITED_STATE_ACTION]: {group: ANSWER_SELECTION_SCREEN_STATE},
    },
    [ANSWER_SELECTION_SCREEN_STATE]: {
        [ENTERING_STATE_ACTION]: preloadScreen(),
        [PRELOAD_STATE_ACTION]: PRESENTATION_STATE_ACTION,
        [NEXT_PRESENTATION_STATE_ACTION]: PRESENTATION_STATE_ACTION,
        [CONFIRMED_STATE_ACTION]: {group: LEVEL_END_SCREEN_STATE},
        [SELECTED_STATE_ACTION]: IDLE_STATE_ACTION,
        // [LISTENING_STATE_ACTION]: IDLE_STATE_ACTION,
    },
    [LEVEL_END_SCREEN_STATE]: {
        [LEVEL_COMPLETE_STATE_ACTION]: {
            group: LEVEL_SELECTION_SCREEN_STATE,
            action: COMPLETE_STATE_ACTION
        },
        [GAME_COMPLETE_STATE_ACTION]: {
            group: GAME_END_SCREEN_STATE,
            action: ENTERING_STATE_ACTION
        }
    },
    [GAME_END_SCREEN_STATE]: {
        [PLAY_AGAIN_STATE_ACTION]: {group: LEVEL_SELECTION_SCREEN_STATE, action: PLAY_AGAIN_STATE_ACTION},
        [EXIT_STATE_ACTION]: {group: WELCOME_SCREEN_SCREEN_STATE},
    }
}
