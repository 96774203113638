import {configureStore} from '@reduxjs/toolkit'
import generalState from './generalStateSlice';
import loadingScreenState from './loadingScreenStateSlice';
import levelSelectionScreenState from './levelSelectionStateSlice';
import levelSceneScreenState from './levelSceneScreenStateSlice';
import answerSelectionScreenState from './answerSelectionScreenStateSlice';
import toolbarState from './toolbarStateSlice';
import audioMixerState from "./audioMixerStateSlice";
import gameStateFlow from "./gameStateFlowSlice";
import avatar from "./avatarSlice";
import player, {logout} from "./playerSlice";
import debuggerR from './debuggerSlice';

import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux";
import {createTransform, persistReducer} from 'redux-persist'
import thunk from 'redux-thunk'

const reducers = combineReducers({
    generalState,
    gameStateFlow,
    loadingScreenState,
    levelSelectionScreenState,
    levelSceneScreenState,
    answerSelectionScreenState,
    toolbarState,
    audioMixerState,
    avatar,
    player,
    debuggerR,
});

const SetTransform = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState, key) => {
        // convert mySet to an Array.
        return {...inboundState, logged: false, error: false, submitting: false};
    },
    // transform state being rehydrated
    (outboundState, key) => {

        // convert mySet back to a Set.
        return {...outboundState};
    },
    // define which reducers this transform gets called for.
    {
        whitelist: ['player'],
    }
);

const persistConfig = {
    key: 'sely-game',
    storage,
    // whitelist: [],
    whitelist: ['player'],
    transforms: [SetTransform]
};

const rootReducer = (state, action) => {


    // when a logout action is dispatched it will reset redux state
    if (action.type === logout.type) {
        state = undefined;
    }

    return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

export default store;
