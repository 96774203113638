const scene_01 = () => import('./scene_01.json');
const scene_02 = () => import('./scene_02.json');
const scene_03 = () => import('./scene_03.json');
const scene_04 = () => import('./scene_04.json');
const scene_05 = () => import('./scene_05.json');
const scene_06 = () => import('./scene_06.json');
const scene_07 = () => import('./scene_07.json');
const scene_08 = () => import('./scene_08.json');
const scene_09 = () => import('./scene_09.json');
const scene_10 = () => import('./scene_10.json');
const scene_11 = () => import('./scene_11.json');
const scene_12 = () => import('./scene_12.json');
const scene_13 = () => import('./scene_13.json');
const scene_14 = () => import('./scene_14.json');
const finale = () => import('./finale_01.json');
export const question_intro = () => import('./question_intro.json');



const all_scenes = {
    scene_01,
    scene_02,
    scene_03,
    scene_04,
    scene_05,
    scene_06,
    scene_07,
    scene_08,
    scene_09,
    scene_10,
    scene_11,
    scene_12,
    scene_13,
    scene_14,
}

export const finale_01 = finale;

    export const assetPaths = {
    scene_09: 'assets/scenes/09/',
    scene_13: 'assets/scenes/13/',
}

export default all_scenes;
