//css
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/main.css';
//react
import React from "react";
//bootstrap
//libraries
//store
import store from "./store";
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/lib/integration/react';
import {persistor} from "./store/persistor";
//components
import {GameFront} from "./components/partials/GameFront";
import {AppWrapper} from "./components/partials/AppWrapper";
//setups
require("./setups");


const App = () => {
    return <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <AppWrapper>
                <GameFront/>
            </AppWrapper>
        </PersistGate>
    </Provider>;
}

export default App;
