import React from "react";

export function GameFrame() {
    return <g data-name="game-frame">
        <path className="game-frame" d="M24.09,11.71l1504.27,15-21.42,826.19L11.45,865.76ZM0,871.64H1539.81V0H0Z"/>
    </g>;
}


export default GameFrame;
