import {keys, mapValues, merge, uniq, zipObject} from 'lodash';

/**
 * @typedef {Object} batchProgressInfo
 * @property {string} id
 * @property {int} total
 * @property {int} loaded
 */

/**
 *
 * @callback progressCallback
 * @param {int} progress
 * @param {batchProgressInfo=} batchProgress
 */

/**
 * @callback finishCallback
 * @param {string} batchKey
 */


/**
 * @class AssetsManager
 *
 * Collect and manage all "loadable" resources
 */
export class AssetsManager {
    /**
     *
     * @type {LoadableAsset[]}
     * @private
     */
    _assets = [];

    /**
     *
     * @returns {LoadableAsset[]}
     */
    get assets() {
        return this._assets;
    }

    /**
     *
     * @type {string[]}
     * @private
     */
    _assetsToLoad = [];

    /**
     *
     * @return {string[]}
     */
    get assetsToLoad() {
        return this._assetsToLoad;
    }

    /**
     *
     * @type {string[]}
     */
    _assetsKeys = [];

    /**
     *
     * @returns {string[]}
     */
    get assetsKeys() {
        return this._assetsKeys;
    }

    /**
     *
     * @type {string[]}
     */
    _assetsTypes = [];

    /**
     *
     * @return {string[]}
     */
    get assetsTypes() {
        return this._assetsTypes;
    }

    /**
     *
     * @return {boolean}
     */
    get isAllLoaded() {
        return this.assetsToLoad.length <= 0;
    }

    /**
     *
     * @param {LoadableAsset} loadableAsset
     * @param {string} key
     */
    addAsset = (loadableAsset, key) => {
        this._assets.push(loadableAsset);
        this._assetsKeys.push(key);
        this._assetsToLoad.push(key);
        this._assetsTypes.push(loadableAsset.assetType);
    };

    /**
     * @typedef {Function} getAssetFn
     * @param {string} key
     * @return {LoadableAsset|null}
     */

    /**
     * @type {getAssetFn} getAsset
     */
    getLoadableAsset(key) {
        const indexOf = this._assetsKeys.indexOf(key);
        return (indexOf >= 0 && this._assets[indexOf]) || null
    }

    getAsset(key) {
        return this.getLoadableAsset(key)?.asset;
    }

    /**
     * @typedef {Object<String,LoadableAsset|null|*>} LoadableAssetsList
     */

    /**
     * @typedef {Function} getAssetsListFn
     * @param {string[]|Object<string,string>} keys
     * @param {boolean=false} asAsset
     * @return {LoadableAssetsList}
     */

    /**
     * @typedef {getAssetsListFn} getAssetsList
     */
    getAssetsList(keys = [], asAsset = false) {
        if (Array.isArray(keys)) {
            return zipObject(keys, keys.map(asAsset ? this.getAsset.bind(this) : this.getLoadableAsset.bind(this)));
        } else if (typeof keys === "object") {
            return mapValues(keys, asAsset ? this.getAsset.bind(this) : this.getLoadableAsset.bind(this));
        }

        return {};
    }

    /**
     * @typedef {Record<string,Array<string>>|{default: Array<string>}| Array<string>} batchLoadConfig
     *
     */

    /**
     * @typedef {Object} preloadParams
     * @property {string[]} [checkProgress]
     * @property {progressCallback} [onProgress]
     * @property {finishCallback} [onFinish]
     * @property {batchLoadConfig} batchLoad
     * @property {boolean} [debug]
     */

    /**
     * @typedef {Function} preloadFn
     * @param {preloadParams} configs
     */

    /**
     *
     * @type {preloadFn} preload
     */
    preload({checkProgress = ["default"], onStart= () => {}, onProgress = null, onFinish = null, batchLoad = null, debug = false}) {

        onStart();


        if (Array.isArray(batchLoad) && batchLoad.length > 0) {
            batchLoad = {
                default: [...batchLoad]
            }
        }

        batchLoad = batchLoad ?? {
            default: this._assetsToLoad
        };


        /**
         * contiene le indicazioni su quali batch stanno richiedendo l'elemento
         * @type {Record<string,Array<string>>}
         */
        let batchProperty = {};

        /**
         * continene i batch che hanno concluso il caricamento
         * @type {Array<string>}
         */
        let finishedBatches = [];

        /**
         * contiene il progresso di ogni singolo batch in caricamento
         * @type {Record<string,Partial<batchProgressInfo>>}
         */
        let batchProgresses = {};

        /**
         * colleziona tutti gli elementi da caricare
         * @type {string[]}
         */
        let batchLoadAll = keys(batchLoad).reduce((previousValue, currentValue) => {

            /**
             *
             * @type {Array<string>}
             */
            const batchLoadElement = batchLoad[currentValue];

            batchProgresses[currentValue] = {
                total: batchLoadElement.length,
                loaded: 0,
            }


            /**
             * setta le indicazioni del batch rispetto all'elemento
             */
            batchLoadElement.map(el => {
                if (!batchProperty.hasOwnProperty(el)) {
                    batchProperty[el] = [];
                }
                batchProperty[el].push(currentValue);

                return null;
            })

            return uniq(merge(previousValue, batchLoadElement));
        }, []);


        /**
         *
         * @type {number}
         */
        const batchLoadAllTotal = batchLoadAll.length;


        // ritorna di default se non ci sono elementi
        if (batchLoadAllTotal <= 0) {
            onFinish && ((keys(batchLoad).map(el => onFinish(el))) || onFinish("default"));
            return;
        }

        // avvia il caricamento di tutti gli elementi
        batchLoadAll.map(key => {

            // avvia il caricamento

            const asset = this.getLoadableAsset(key);
            asset && asset.load(loaded => {

                /**
                 * posizione dell'elemento nell'array generale
                 * @type {number}
                 */
                const batchPosition = batchLoadAll.indexOf(key);

                /**
                 * posizione dell'elemento nell'array degli asset da caricare
                 * @type {number}
                 */
                const keyPosition = this.assetsToLoad.indexOf(key);

                /**
                 * a caricamento è avvenuto
                 */
                if (loaded) {

                    /**
                     * rimuove l'elemento da quelli da caricare
                     */
                    this._assetsToLoad.splice(keyPosition, 1);

                    /**
                     * rimuove l'elemento dall'array generale
                     */
                    batchLoadAll.splice(batchPosition, 1);

                    /**
                     *
                     * @type {number}
                     */
                    const totalLoaded = batchLoadAllTotal - batchLoadAll.length;

                    checkProgress.indexOf("default") >= 0 &&
                    onProgress &&
                    onProgress(totalLoaded / batchLoadAllTotal * 100, {
                        id: 'default',
                        total: batchLoadAllTotal,
                        loaded: totalLoaded
                    });


                    /**
                     * rimuove l'elemento dalle proprietà dei batch
                     */
                    batchProperty?.[key]?.map(batchKey => {
                        /**
                         * @type {Array<string>}
                         */
                        const batchLoadElement = batchLoad[batchKey];

                        /**
                         * @type {number}
                         */
                        const indexOf = batchLoadElement.indexOf(key);

                        if (indexOf >= 0) {

                            batchLoad[batchKey].splice(indexOf, 1);

                            batchProgresses[batchKey].loaded += 1;

                            checkProgress.indexOf(batchKey) >= 0 &&
                            onProgress &&
                            onProgress(batchProgresses[batchKey].loaded / batchProgresses[batchKey].total * 100, {
                                id: batchKey,
                                ...batchProgresses[batchKey],
                                loadedKey: key
                            });
                        }

                    });

                    batchProperty?.[key]?.map(batchKey => {
                        if (batchLoad[batchKey].length <= 0) {
                            if (finishedBatches.indexOf(batchKey) < 0) {


                                onFinish &&
                                onFinish(batchKey);

                                finishedBatches.push(batchKey);
                            }
                        }
                    })
                } else {
                }

            })

            debug && console.groupEnd();

            return null
        })

        if (this.isAllLoaded) {
            onFinish &&
            onFinish("all");
        }

    }

}
