import React from "react";

export const AvatarBackground = () => {
    return <g>
        <polygon className="st5" points="-206,-366.3 -259.4,360.7 242,358.4 212.1,-382.2 	"/>
        <g>
            <polygon className="st6" points="-190.5,-353.4 -242.1,382.2 259.4,381 227.7,-368.6 		"/>
        </g>
    </g>;
};
