import {useEffect} from "react";
import {getListenerRepo} from "../repositories/listenersRepository";

export const SubState = ({listeners, children}) => {

    useEffect(() => {


        const newAdd = getListenerRepo().add(listeners);

        // //region TODO substitute
        // let allL = [];
        //
        // for (const groupKey in listeners) {
        //     if (listeners.hasOwnProperty(groupKey)) {
        //         for (const actionKey in listeners[groupKey]) {
        //             if (listeners[groupKey].hasOwnProperty(actionKey)) {
        //                 const actual = storedListeners?.[groupKey]?.[actionKey] ?? [];
        //
        //                 allL.push([groupKey, actionKey, actual.length]);
        //
        //                 storedListeners = merge(storedListeners, {
        //                     [groupKey]: {
        //                         [actionKey]: [
        //                             ...actual,
        //                             listeners[groupKey][actionKey]
        //                         ]
        //                     }
        //                 });
        //             }
        //         }
        //     }
        // }
        // //endregion


        return () => {

            newAdd.clear();

            // //TODO substitute
            // for (const pos of allL) {
            //     storedListeners[pos[0]][pos[1]].splice(pos[2], 1);
            // }
        }

    }, [1])


    return children;
}
