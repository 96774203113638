import React from "react";
import {useGameStateSetter} from "../../hooks/useGameStateSetter";

export const StateChanger = ({children, event, toState, ...props}) => {
    const changeState = useGameStateSetter(toState);

    return React.cloneElement(children, {
        changeState: () => changeState(),
        [event]: () => changeState(),
        handleEvent: event,
        ...props
    })

}
