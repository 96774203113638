export const scenesAvailable = [
    "scenes.scene_01",
    "scenes.scene_02",
    "scenes.scene_03",
    "scenes.scene_04",
    "scenes.scene_05",
    "scenes.scene_06",
    "scenes.scene_07",
    "scenes.scene_08",
    "scenes.scene_09",
    "scenes.scene_10",
    "scenes.scene_11",
    "scenes.scene_12",
    "scenes.scene_13",
    "scenes.scene_14",
]; //scelto completo
export const previewsAvailable = [
    "previews.scene_01",
    "previews.scene_02",
    "previews.scene_03",
    "previews.scene_04",
    "previews.scene_05",
    "previews.scene_06",
    "previews.scene_07",
    "previews.scene_08",
    "previews.scene_09",
    "previews.scene_10",
    "previews.scene_11",
    "previews.scene_12",
    "previews.scene_13",
    "previews.scene_14",
]; //scelto completo
export const scenesAudio = [
    "scenes.audio_01",
    "scenes.audio_02",
    "scenes.audio_03",
    "scenes.audio_04",
    "scenes.audio_05",
    "scenes.audio_06",
    "scenes.audio_07",
    "scenes.audio_08",
    "scenes.audio_09",
    "scenes.audio_10",
    "scenes.audio_11",
    "scenes.audio_12",
    "scenes.audio_13",
    "scenes.audio_14",
];
export const scenesTitle = [
    "A playdate at the park!",
    "At the bus stop!",
    "A loud sound!",
    "Looking outside the window!",
    "A yummy dessert!",
    "Playing at the playground!",
    "Playing dodgeball!",
    "A windy breeze!",
    "Going for a walk!",
    "A class birthday party!",
    "At the schoolyard!",
    "It's cartoon time!",
    "It's nighty- night time!",
    "On the school bus!",
]
