import React from "react";

export default function LogoutButton() {
    return <g>
        <defs>
            <style>{".cls-1{fill:#1d76ce;}.cls-2{fill:#052b63;}.cls-3{fill:#fff;}"}</style>
            <symbol id="Log_out" data-name="Log out" viewBox="0 0 94 86.5">
                <path className="cls-1"
                      d="M0,5.39,22,85.8a1,1,0,0,0,1.23.65l70-21.76A1,1,0,0,0,94,63.58L84.68.82a1,1,0,0,0-1-.82L.94,4.12A1,1,0,0,0,0,5.39Z"/>
                <g id="w6Kivl.tif">
                    <path className="cls-2"
                          d="M51.07,19.6c0,1.3,0,2.59,0,3.89,0,.41-.13.5-.51.51a96.23,96.23,0,0,0-12.09,1.27c-1.42.24-2.84.53-4.26.78-.26.05-.38.13-.42.41a94.14,94.14,0,0,0-1,9.76c-.18,3.26-.23,6.51-.12,9.77A113.19,113.19,0,0,0,33.81,58.6c0,.32.21.38.48.44,3.71.74,7.45,1.31,11.22,1.68,1.71.17,3.42.31,5.13.35.36,0,.44.13.44.47q0,4,0,7.94c0,.35-.13.4-.43.39-2.13-.09-4.26-.26-6.38-.48a145.88,145.88,0,0,1-14.53-2.31c-2.77-.57-4.13-1.93-4.62-5a129.22,129.22,0,0,1-1.4-24.54A105.71,105.71,0,0,1,25,23.79c.13-.73.27-1.46.45-2.17a4.38,4.38,0,0,1,3.46-3.42,125.73,125.73,0,0,1,16.15-2.6c1.8-.18,3.61-.23,5.41-.39.56-.05.62.16.61.62,0,1.26,0,2.51,0,3.77Z"/>
                    <path className="cls-2"
                          d="M56.57,53.71c0-1.07,0-2.13,0-3.2,0-.55-.17-.69-.67-.75-3.18-.34-6.32-.89-9.46-1.5-1.56-.31-3.12-.66-4.67-1.06A4.56,4.56,0,0,1,38.43,42a4.31,4.31,0,0,1,3.5-4c3.61-.93,7.28-1.58,11-2.15,1.08-.16,2.15-.31,3.23-.42.37,0,.46-.16.46-.52,0-2.25,0-4.49,0-6.74A1.94,1.94,0,0,1,58.18,26a2.23,2.23,0,0,1,1.66.47,76.06,76.06,0,0,1,7.25,5.64A113.29,113.29,0,0,1,76,40.77c.11.13.23.25.34.39a2.08,2.08,0,0,1,0,2.9A98.8,98.8,0,0,1,59.93,58.64a3,3,0,0,1-.91.46,1.92,1.92,0,0,1-2.45-2V53.71Z"/>
                </g>
                <g id="w6Kivl.tif-2" data-name="w6Kivl.tif">
                    <path className="cls-3"
                          d="M51.07,13.6c0,1.3,0,2.59,0,3.89,0,.41-.13.5-.51.51a96.23,96.23,0,0,0-12.09,1.27c-1.42.24-2.84.53-4.26.78-.26.05-.38.13-.42.41a94.14,94.14,0,0,0-1,9.76c-.18,3.26-.23,6.51-.12,9.77A113.19,113.19,0,0,0,33.81,52.6c0,.32.21.38.48.44,3.71.74,7.45,1.31,11.22,1.68,1.71.17,3.42.31,5.13.35.36,0,.44.13.44.47q0,4,0,7.94c0,.35-.13.4-.43.39-2.13-.09-4.26-.26-6.38-.48a145.88,145.88,0,0,1-14.53-2.31c-2.77-.57-4.13-1.93-4.62-5a129.22,129.22,0,0,1-1.4-24.54A105.71,105.71,0,0,1,25,17.79c.13-.73.27-1.46.45-2.17a4.38,4.38,0,0,1,3.46-3.42A125.73,125.73,0,0,1,45.07,9.6c1.8-.18,3.61-.23,5.41-.39.56-.05.62.16.61.62,0,1.26,0,2.51,0,3.77Z"/>
                    <path className="cls-3"
                          d="M56.57,47.71c0-1.07,0-2.13,0-3.2,0-.55-.17-.69-.67-.75-3.18-.34-6.32-.89-9.46-1.5-1.56-.31-3.12-.66-4.67-1.06A4.56,4.56,0,0,1,38.43,36,4.31,4.31,0,0,1,41.93,32c3.61-.93,7.28-1.58,11-2.15,1.08-.16,2.15-.31,3.23-.42.37,0,.46-.16.46-.52,0-2.25,0-4.49,0-6.74A1.94,1.94,0,0,1,58.18,20a2.23,2.23,0,0,1,1.66.47,76.06,76.06,0,0,1,7.25,5.64A113.29,113.29,0,0,1,76,34.77c.11.13.23.25.34.39a2.08,2.08,0,0,1,0,2.9A98.8,98.8,0,0,1,59.93,52.64a3,3,0,0,1-.91.46,1.92,1.92,0,0,1-2.45-2V47.71Z"/>
                </g>
            </symbol>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <use width="94" height="86.5" xlinkHref="#Log_out"/>
            </g>
        </g>
    </g>;
}
