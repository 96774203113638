import {SelyLogo} from "./SelyLogo";
import {RollySplash} from "./RollySplash";

export function LoginGraphic() {
    return <g>
        <defs>
            <SelyLogo/>
            <RollySplash/>
        </defs>

        <rect className="login-43" height="860" width="1529" x="5.4" y="5.82"/>
        <path className="login-2" d="M214.19,115.89l1504.27,15L1697,957l-1495.5,12.9ZM190.1,975.82H1729.9V104.18H190.1Z"
              transform="translate(-190.1 -104.18)"/>
        <g>
            <path className="login-1"
                  d="M1241.09,312.63c.06,1.06.09,2.28.07,3.65a29.06,29.06,0,0,1-.26,3.79,10.14,10.14,0,0,0-2.26-.14l-3.6.14h-.14a.1.1,0,0,1,0,.08.12.12,0,0,0,0,.07,4.84,4.84,0,0,0-.07.74c0,.34,0,.87,0,1.58s0,1.67,0,2.84,0,2.67.07,4.51.06,4.05.1,6.62.08,5.61.14,9.1v.1a13.62,13.62,0,0,1-1.9.16c-.69,0-1.37,0-2.06,0h-2a12.06,12.06,0,0,0-1.87.14,14.11,14.11,0,0,1,0-1.73c0-1,0-2.55.07-4.58s0-4.63,0-7.8-.17-7-.36-11.47l-.05,0a4,4,0,0,0-1.44-.36c-.57-.05-1.15-.08-1.72-.1l-1.59,0a.82.82,0,0,1-1.17-1.06c-.05-.51-.08-1.12-.1-1.84s0-1.5,0-2.33,0-1.63.09-2.4c0,0,0-.05,0-.05s0,0,0-.05l10.11-.07A91.15,91.15,0,0,1,1241.09,312.63Z"
                  transform="translate(-190.1 -104.18)"/>
            <path className="login-1"
                  d="M1249.2,321.85h.82V322q0,5.82-.22,11.86t.17,11.86c-.16.19-.56.29-1.2.31h-2.09l-2.13-.07a5,5,0,0,0-1.35,0q.24-3,.22-6.14t-.12-6.19c-.07-2.05-.1-4.07-.1-6.08a58.35,58.35,0,0,1,.29-5.83v0a1.7,1.7,0,0,1,.65,0c.3,0,.53,0,.69,0,.42,0,1,.07,1.73.1S1248.18,321.88,1249.2,321.85Zm-5.9-10.46,6.53.14c0,.19,0,.54,0,1s0,1,.05,1.63,0,1.2,0,1.83,0,1.17,0,1.65a1.26,1.26,0,0,1-.89.39,10.19,10.19,0,0,1-1.63.07c-.62,0-1.29-.05-2-.1a18.74,18.74,0,0,0-2,0c0-.93,0-1.9-.12-2.9S1243.27,312.86,1243.3,311.39Z"
                  transform="translate(-190.1 -104.18)"/>
            <path className="login-1"
                  d="M1259.62,324.58l.6-.33a4.87,4.87,0,0,0,.6-.38,8.86,8.86,0,0,1,2.25-1.16,9.34,9.34,0,0,1,2.57-.48,8.82,8.82,0,0,1,2.57.29,5.85,5.85,0,0,1,2.25,1.2,2.55,2.55,0,0,1,.48.82c.16-.1.36-.2.58-.31a4.9,4.9,0,0,0,.58-.36,8.86,8.86,0,0,1,2.25-1.16,9.34,9.34,0,0,1,2.57-.48,8.82,8.82,0,0,1,2.57.29,5.85,5.85,0,0,1,2.25,1.2,3.38,3.38,0,0,1,.75,1.8,21.81,21.81,0,0,1,.33,3.29c0,1.28.07,2.68,0,4.2s0,3.06-.07,4.61-.06,3.05-.1,4.49,0,2.7,0,3.79a18.48,18.48,0,0,1-3,.21l-3,0c-.06-.74-.09-1.57-.09-2.5s0-1.88,0-2.88,0-2,0-3-.06-1.92-.12-2.79c0-.38-.06-.8-.1-1.27a12.49,12.49,0,0,0-.16-1.37,6.53,6.53,0,0,0-.34-1.2,1.8,1.8,0,0,0-.6-.81.14.14,0,0,0-.1-.05.14.14,0,0,1-.09-.05,3.78,3.78,0,0,0-.72-.24,4.62,4.62,0,0,0-.94-.09,3,3,0,0,0-1.94.67q0,1.88,0,4c0,1.39-.07,2.77-.1,4.12s0,2.68-.07,3.94,0,2.38.07,3.34a18.48,18.48,0,0,1-3,.21l-3,0c-.06-.74-.09-1.57-.09-2.5s0-1.88,0-2.88,0-2,0-3-.06-1.92-.12-2.79c0-.38-.06-.8-.1-1.27a12.49,12.49,0,0,0-.16-1.37,6.53,6.53,0,0,0-.34-1.2,1.8,1.8,0,0,0-.6-.81.14.14,0,0,0-.1-.05.14.14,0,0,1-.09-.05,4,4,0,0,0-1.13-.31,3.14,3.14,0,0,0-1.46.1,2.78,2.78,0,0,0-1.3.86,3.22,3.22,0,0,0-.67,1.94c-.07,1.12-.08,2.22,0,3.29s.07,2.15.12,3.22.07,2.13.07,3.19a26,26,0,0,1-.19,3.17v.19a7,7,0,0,1-1.42.29,9.87,9.87,0,0,1-1.22,0l-1.39-.07a13.07,13.07,0,0,0-1.92.05c0-3.94,0-7.87-.12-11.81s-.06-8,.07-12.24l1,.07.72,0,.72,0,1-.07a4.82,4.82,0,0,0,.67-.07l1-.12a6.65,6.65,0,0,1,.87,0c.25,0,.38.08.38.17a8.61,8.61,0,0,1,.07,1.35A9.88,9.88,0,0,0,1259.62,324.58Z"
                  transform="translate(-190.1 -104.18)"/>
            <path className="login-1"
                  d="M1291.87,334v.86c0,1,.07,1.74.12,2.35a4.05,4.05,0,0,0,.6,1.78,3.72,3.72,0,0,0,1.63,1.63,3.29,3.29,0,0,0,2.07.34,2.67,2.67,0,0,0,1.7-1.18,4.63,4.63,0,0,0,.6-3,10.35,10.35,0,0,0,1.3,0c.54,0,1.11,0,1.7-.07s1.16,0,1.71,0a5.91,5.91,0,0,1,1.34.16c-.06.84-.13,1.65-.19,2.45s-.15,1.55-.24,2.23a14.24,14.24,0,0,1-.36,1.78,2.09,2.09,0,0,1-.55,1,4.42,4.42,0,0,1-2.12,1.63,10,10,0,0,1-2.83.58,27.41,27.41,0,0,1-3.14,0c-1.07-.07-2.06-.1-3-.1a6.82,6.82,0,0,1-2.5-.86,7.63,7.63,0,0,1-2.16-2.38,14.09,14.09,0,0,1-1.39-3.21,19.77,19.77,0,0,1-.72-3.72,27.3,27.3,0,0,1-.1-3.94c.1-1.18.2-2.33.31-3.43a13.6,13.6,0,0,1,.75-3.24,6,6,0,0,1,1.92-2.38,9.3,9.3,0,0,1,2.93-1.48,12.9,12.9,0,0,1,3.45-.56,13.11,13.11,0,0,1,3.55.39,7.28,7.28,0,0,1,3.56,1.53,7.64,7.64,0,0,1,2,2.79,11.44,11.44,0,0,1,.84,3.6,29.37,29.37,0,0,1,0,4,7.72,7.72,0,0,1-1.28.19c-.62.07-1.36.13-2.2.2s-1.76.11-2.72.14-1.88.05-2.75.05-1.68,0-2.38-.05A11.81,11.81,0,0,1,1291.87,334Zm5.81-7.44a3.33,3.33,0,0,0-.77-.36,3,3,0,0,0-.91-.12,6,6,0,0,0-1.87.24,2.8,2.8,0,0,0-1.15.67,2.7,2.7,0,0,0-.65,1,5.29,5.29,0,0,0-.31,1.2,3.1,3.1,0,0,0-.08.74c0,.14.17.24.46.29a8.38,8.38,0,0,0,1.37.05q.93,0,2.76,0c.83,0,1.45,0,1.87.05a5.69,5.69,0,0,0,.89,0c.17,0,.26-.06.26-.14v-.51a3.64,3.64,0,0,0-.5-1.82A3.21,3.21,0,0,0,1297.68,326.51Z"
                  transform="translate(-190.1 -104.18)"/>
            <path className="login-1"
                  d="M1324.94,313.16v6.43c0,.42,0,.85,0,1.3s0,.91,0,1.39c1,0,2-.08,3.15-.14l4.1-.24a1.85,1.85,0,0,1,.38,1.13,10.67,10.67,0,0,1,0,1.6c0,.58-.07,1.16-.12,1.76a6.68,6.68,0,0,0,0,1.51h-7.44V333c0,1.65,0,3.19.05,4.63a3.17,3.17,0,0,0,1.39,1.06,9.84,9.84,0,0,0,2,.6,13.77,13.77,0,0,0,2.13.26,15,15,0,0,0,1.9,0c.06.45.13.95.19,1.49s.11,1.11.14,1.68a11.1,11.1,0,0,1,0,1.73,6.81,6.81,0,0,1-.31,1.58,35.51,35.51,0,0,1-4,0,22.25,22.25,0,0,1-3.88-.55,10.94,10.94,0,0,1-3.24-1.3,4.9,4.9,0,0,1-2-2.35,49.69,49.69,0,0,1-.72-7c-.07-2.2-.08-4.46-.05-6.8-.51,0-1,.05-1.61.05h-1.85l.15-5.85c.64,0,1.24,0,1.8,0h1.6c0-.7,0-1.46,0-2.28s-.05-1.64-.05-2.47,0-1.63,0-2.4.06-1.45.12-2.06a6.1,6.1,0,0,1,1.37,0l1.56.12c.54.05,1.09.09,1.63.12A8.86,8.86,0,0,0,1324.94,313.16Z"
                  transform="translate(-190.1 -104.18)"/>
            <path className="login-1"
                  d="M1335.36,324.11a3.05,3.05,0,0,1,1.08-1.25,7.4,7.4,0,0,1,1.73-.89,9.3,9.3,0,0,1,2.11-.5,17.23,17.23,0,0,1,2.28-.15,10.47,10.47,0,0,0,1.85,0,10.92,10.92,0,0,1,1.89,0,10.18,10.18,0,0,1,2.93.6,6.79,6.79,0,0,1,2.4,1.46,2.89,2.89,0,0,1,1.06,1.4,9.93,9.93,0,0,1,.57,2.23,22.87,22.87,0,0,1,.24,2.78c0,1,.07,2,.1,3.1s-.07,2-.12,3.17-.13,2.32-.24,3.4-.26,2.07-.43,2.93a3.59,3.59,0,0,1-.75,1.78,4.36,4.36,0,0,1-2.13,1.63,10.76,10.76,0,0,1-2.86.6,21,21,0,0,1-3.17,0c-1.08-.08-2.08-.12-3-.12a7.56,7.56,0,0,1-4.13-2.11,5.92,5.92,0,0,1-1.78-2.88,16.39,16.39,0,0,1-.57-3.63c-.07-1.26-.07-2.53,0-3.81a26.59,26.59,0,0,0-.07-3.51c0-.57,0-1.14.05-1.7s.1-1.14.19-1.75a7,7,0,0,1,.34-1.42C1335.12,324.93,1335.26,324.46,1335.36,324.11Zm6,5.66a1.48,1.48,0,0,0-.43,1,24.13,24.13,0,0,0-.19,3c0,1,0,2.07-.1,3.19a2.1,2.1,0,0,0,.58,1.7,3.19,3.19,0,0,0,1.51.82,5,5,0,0,0,1.85.09,4.41,4.41,0,0,0,1.58-.45,2.17,2.17,0,0,0,1.1-1.59c.13-1,.24-2.05.32-3.09a10.6,10.6,0,0,0-.17-2.95,3.36,3.36,0,0,0-1.39-2.45,3.82,3.82,0,0,0-3-.34A3.25,3.25,0,0,0,1341.36,329.77Z"
                  transform="translate(-190.1 -104.18)"/>
            <path className="login-1"
                  d="M1366.18,311.1l6.52.14c0,1.31,0,2.9,0,4.75s-.07,3.84-.1,6-.06,4.3-.07,6.57,0,4.47,0,6.58,0,4.09,0,5.93.08,3.4.15,4.68c-.16.19-.56.29-1.2.31h-2.09l-2.14-.07a5,5,0,0,0-1.34,0q.33-4.32.26-8.68t-.21-8.74q-.15-4.36-.17-8.74A82.28,82.28,0,0,1,1366.18,311.1Z"
                  transform="translate(-190.1 -104.18)"/>
            <path className="login-1"
                  d="M1376.35,324.11a3.13,3.13,0,0,1,1.08-1.25,7.4,7.4,0,0,1,1.73-.89,9.3,9.3,0,0,1,2.11-.5,17.23,17.23,0,0,1,2.28-.15,10.47,10.47,0,0,0,1.85,0,10.92,10.92,0,0,1,1.89,0,10.18,10.18,0,0,1,2.93.6,6.79,6.79,0,0,1,2.4,1.46,2.89,2.89,0,0,1,1.06,1.4,9.91,9.91,0,0,1,.58,2.23,27.74,27.74,0,0,1,.24,2.78c0,1,.06,2,.09,3.1s-.07,2-.12,3.17-.13,2.32-.24,3.4-.26,2.07-.43,2.93a3.59,3.59,0,0,1-.74,1.78,4.43,4.43,0,0,1-2.14,1.63,10.76,10.76,0,0,1-2.86.6,20.83,20.83,0,0,1-3.16,0c-1.09-.08-2.09-.12-3-.12a7.56,7.56,0,0,1-4.13-2.11,5.83,5.83,0,0,1-1.77-2.88,16.39,16.39,0,0,1-.58-3.63c-.06-1.26-.07-2.53,0-3.81a28.31,28.31,0,0,0-.08-3.51c0-.57,0-1.14.05-1.7s.1-1.14.19-1.75a7.76,7.76,0,0,1,.34-1.42C1376.11,324.93,1376.26,324.46,1376.35,324.11Zm6,5.66a1.53,1.53,0,0,0-.43,1,24.13,24.13,0,0,0-.19,3c0,1,0,2.07-.1,3.19a2.1,2.1,0,0,0,.58,1.7,3.19,3.19,0,0,0,1.51.82,5,5,0,0,0,1.85.09,4.35,4.35,0,0,0,1.58-.45,2.18,2.18,0,0,0,1.11-1.59c.12-1,.23-2.05.31-3.09a10.6,10.6,0,0,0-.17-2.95,3.33,3.33,0,0,0-1.39-2.45,3.82,3.82,0,0,0-3-.34A3.25,3.25,0,0,0,1382.35,329.77Z"
                  transform="translate(-190.1 -104.18)"/>
            <path className="login-1"
                  d="M1410.48,355.59a8.31,8.31,0,0,1-1.73.05c-.83,0-1.9-.05-3.21-.05a8.37,8.37,0,0,1-4-1,8.07,8.07,0,0,1-2.88-2.59,5.41,5.41,0,0,1-.36-.89A10.18,10.18,0,0,1,1398,350a7.73,7.73,0,0,1-.15-1.11,4.38,4.38,0,0,1,0-.91,4.28,4.28,0,0,1,1.29-.26,13.66,13.66,0,0,1,1.71-.05c.59,0,1.16.05,1.72.09s1,.11,1.42.17a2.58,2.58,0,0,0,.79,2,2.93,2.93,0,0,0,1.78.84,3,3,0,0,0,1.92-.48,2.8,2.8,0,0,0,1.22-1.92c0-.32.07-.64.1-1a4.39,4.39,0,0,0,0-.89,6.74,6.74,0,0,1,0-1.68,2.89,2.89,0,0,1-1.13.77,6.73,6.73,0,0,1-1.39.34,12.62,12.62,0,0,1-1.51.09c-.52,0-1,0-1.45,0a12.88,12.88,0,0,1-1.84-.14,8.47,8.47,0,0,1-1.78-.48,5.41,5.41,0,0,1-1.51-.92,4.28,4.28,0,0,1-1.1-1.48,10.25,10.25,0,0,1-1-2.72,21.09,21.09,0,0,1-.39-2.9c0-1.5,0-3.12.1-4.85a17.43,17.43,0,0,1,.84-4.87,9,9,0,0,1,2.37-3.84,7.26,7.26,0,0,1,4.71-1.75,6.72,6.72,0,0,1,2.85.4,3.29,3.29,0,0,1,1.9,1.85c.13.07.2,0,.22-.16s0-.4,0-.68,0-.54,0-.81a1.07,1.07,0,0,1,.12-.65A19,19,0,0,1,1413,322c1.09,0,2.18.07,3.27.07q.2,6,.21,12.55t-.26,13.47c0,.77,0,1.52,0,2.28a3.24,3.24,0,0,1-.43,1.89,7.93,7.93,0,0,1-1.37,1.68,7.1,7.1,0,0,1-1.37,1,5,5,0,0,1-1.34.5A9.07,9.07,0,0,1,1410.48,355.59Zm-8.3-21v.43a6.34,6.34,0,0,0,1.44,4.18,3.56,3.56,0,0,0,2.3,1.27,6,6,0,0,0,2.69-.17,1.37,1.37,0,0,0,.67-.46,2.24,2.24,0,0,0,.38-.72,4.71,4.71,0,0,0,.22-.86c0-.3.09-.6.12-.89,0-1.28,0-2.58-.07-3.91a18.23,18.23,0,0,0-.55-3.82,2.42,2.42,0,0,0-.94-1.13,3.5,3.5,0,0,0-1.42-.5,3.86,3.86,0,0,0-1.51.12,3.73,3.73,0,0,0-1.32.7,8.27,8.27,0,0,0-1.22,1.8,7.15,7.15,0,0,0-.7,2.09,5,5,0,0,0-.09.93Z"
                  transform="translate(-190.1 -104.18)"/>
            <path className="login-1"
                  d="M1425.69,321.85h.82V322q0,5.82-.22,11.86t.17,11.86c-.16.19-.56.29-1.2.31h-2.09L1421,346a5.09,5.09,0,0,0-1.35,0c.16-2,.24-4.06.22-6.14s-.06-4.14-.12-6.19-.1-4.07-.1-6.08a58.35,58.35,0,0,1,.29-5.83v0a1.71,1.71,0,0,1,.65,0c.3,0,.54,0,.7,0,.41,0,1,.07,1.72.1S1424.67,321.88,1425.69,321.85Zm-5.9-10.46,6.53.14c0,.19,0,.54,0,1s0,1,.05,1.63,0,1.2,0,1.83,0,1.17,0,1.65a1.23,1.23,0,0,1-.89.39,10.13,10.13,0,0,1-1.63.07c-.62,0-1.29-.05-2-.1a18.72,18.72,0,0,0-2,0c0-.93,0-1.9-.12-2.9S1419.76,312.86,1419.79,311.39Z"
                  transform="translate(-190.1 -104.18)"/>
            <path className="login-1"
                  d="M1436.16,324.58l.6-.33a4.87,4.87,0,0,0,.6-.38,8.86,8.86,0,0,1,2.25-1.16,9.41,9.41,0,0,1,2.57-.48,8.82,8.82,0,0,1,2.57.29,6,6,0,0,1,2.26,1.2,3.39,3.39,0,0,1,.74,1.8,21.68,21.68,0,0,1,.34,3.29c0,1.28.06,2.68,0,4.2s0,3.06-.07,4.61-.06,3.05-.09,4.49,0,2.7,0,3.79a18.48,18.48,0,0,1-3,.21l-3,0c-.06-.74-.09-1.57-.09-2.5s0-1.88,0-2.88,0-2,0-3,0-1.92-.12-2.79c0-.38-.06-.8-.09-1.27s-.09-.92-.17-1.37a5.83,5.83,0,0,0-.34-1.2,1.8,1.8,0,0,0-.6-.81.14.14,0,0,0-.09-.05.14.14,0,0,1-.1-.05,4,4,0,0,0-1.13-.31,3.14,3.14,0,0,0-1.46.1,2.83,2.83,0,0,0-1.3.86,3.22,3.22,0,0,0-.67,1.94c-.06,1.12-.08,2.22,0,3.29s.07,2.15.12,3.22.07,2.13.07,3.19a26,26,0,0,1-.19,3.17v.19a6.94,6.94,0,0,1-1.41.29,10,10,0,0,1-1.23,0l-1.39-.07a13.07,13.07,0,0,0-1.92.05c0-3.94,0-7.87-.12-11.81s-.06-8,.07-12.24l1,.07.72,0,.72,0,1-.07a4.82,4.82,0,0,0,.67-.07l1-.12a6.65,6.65,0,0,1,.87,0c.25,0,.38.08.38.17a7.54,7.54,0,0,1,.07,1.35C1436.12,323.74,1436.13,324.14,1436.16,324.58Z"
                  transform="translate(-190.1 -104.18)"/>
        </g>
        <g>
            <path
                d="M1105.57,733.49a12.2,12.2,0,0,1,1.1,2.12,15.25,15.25,0,0,1,.71,2.29,5.51,5.51,0,0,1,.19,1c0,.31,0,.62.07.94,0,.94-.07,1.89-.1,2.84a17.4,17.4,0,0,1-.32,2.8s0,0,0,0,0,0,0,0a15.37,15.37,0,0,1-1.64,3.36,36.07,36.07,0,0,1-2.18,3c-1.32,0-2.65.07-4,.08h-3.9l0,0a47.78,47.78,0,0,1-.42-4.9c-.06-1.69-.08-3.41-.08-5.15s0-3.49,0-5.26,0-3.53,0-5.27l0-.06,0-.06a11,11,0,0,1,1.76-.28c.61,0,1.22-.07,1.84-.06l1.85,0c.61,0,1.2,0,1.78,0a4.88,4.88,0,0,1,1.85.95A6.15,6.15,0,0,1,1105.57,733.49Zm-2.76,10.89a5.45,5.45,0,0,0,.74-1.71,4.68,4.68,0,0,0,0-2c-.07-.3-.14-.61-.22-.94a4.48,4.48,0,0,0-.34-1,3.22,3.22,0,0,0-.56-.79,2.05,2.05,0,0,0-.84-.53c-.34,0-.7-.06-1.11-.07a4.75,4.75,0,0,0-1.14.1h-.09v.12c0,.54.06,1.12.06,1.74s0,1.27,0,1.94,0,1.35,0,2,0,1.37.06,2.05a1.23,1.23,0,0,0,.92.27c.39,0,.76-.05,1.12-.09a.38.38,0,0,0,.28,0,1.26,1.26,0,0,1,.26-.14,2.22,2.22,0,0,0,.53-.43A3.88,3.88,0,0,0,1102.81,744.38Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1113,736.91h.51V737q0,3.63-.13,7.41t.1,7.41a1.06,1.06,0,0,1-.75.2h-1.3l-1.34,0a3.21,3.21,0,0,0-.84,0c.1-1.26.15-2.54.14-3.84s0-2.59-.08-3.87-.06-2.54-.06-3.79a36.12,36.12,0,0,1,.18-3.65v0a1.35,1.35,0,0,1,.41,0l.43,0,1.08.06Q1112,736.94,1113,736.91Zm-3.69-6.54,4.09.09c0,.12,0,.34,0,.65s0,.65,0,1l0,1.14c0,.39,0,.73,0,1a.76.76,0,0,1-.55.24,6.32,6.32,0,0,1-1,.05l-1.25-.06a10.26,10.26,0,0,0-1.23,0c0-.58,0-1.18-.07-1.81S1109.27,731.29,1109.29,730.37Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1123.36,731.24v-.33a1.25,1.25,0,0,1,.41-.07q.4,0,1-.06H1126a7.21,7.21,0,0,1,1.14.1v2q0,5,.15,9.9t0,9.21c-.62,0-1.3,0-2,0a11.67,11.67,0,0,1-2-.08.51.51,0,0,1,0-.21c0-.2,0-.36,0-.48s0-.29,0-.51a.64.64,0,0,0-.13-.22s-.11,0-.17.1a2.15,2.15,0,0,1-1.2,1.16,3.39,3.39,0,0,1-1.59.13,4.89,4.89,0,0,1-2.7-.88,4.7,4.7,0,0,1-1.48-1.95,9.69,9.69,0,0,1-.66-2.66c-.11-1-.18-2-.2-3a15.08,15.08,0,0,1,.14-2,9.42,9.42,0,0,1,.42-1.81,4.72,4.72,0,0,1,.75-1.41,2.52,2.52,0,0,1,1.12-.83,6.61,6.61,0,0,1,1.79-.37,11.29,11.29,0,0,1,1.84,0,6.1,6.1,0,0,1,1.47.28q.6.21.66.48,0-1.41,0-2.28v-1.39c0-.35,0-.62,0-.81l0-.54q0-.26,0-.57C1123.33,732,1123.34,731.64,1123.36,731.24Zm-4.65,11.31a3.21,3.21,0,0,0-.18.87q0,.45,0,.87a5.9,5.9,0,0,0,.18,1.46,4.21,4.21,0,0,0,.54,1.26,2.92,2.92,0,0,0,.87.88,2.05,2.05,0,0,0,1.14.33,1.93,1.93,0,0,0,1.19-.33,2,2,0,0,0,.66-.9,4.37,4.37,0,0,0,.27-1.36c0-.53,0-1.12,0-1.76s0-1.39,0-1.84a2.82,2.82,0,0,0-.23-1,.84.84,0,0,0-.55-.46,3.87,3.87,0,0,0-1-.11,3.23,3.23,0,0,0-2,.54A2.28,2.28,0,0,0,1118.71,742.55Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1138.33,737.09c.14.72.3,1.58.48,2.57s.37,2,.57,3,.41,2,.63,2.93a20.53,20.53,0,0,0,.66,2.29,12,12,0,0,0,.54-2.37c.14-1,.28-2,.41-3.06s.27-2.08.43-3.06a11.56,11.56,0,0,1,.6-2.37q1,0,2.07,0a18.1,18.1,0,0,1,2.31,0c-.32,1.2-.6,2.35-.85,3.44s-.5,2.15-.74,3.19-.49,2-.7,3.09-.47,2.14-.78,3.18a17.54,17.54,0,0,1-1.19,3,8.12,8.12,0,0,1-1.9,2.45,8,8,0,0,1-2.94,1.57,12.35,12.35,0,0,1-4.31.39,6.64,6.64,0,0,1-.31-2,10.6,10.6,0,0,1,.13-2c.48,0,1,0,1.49.06a4.57,4.57,0,0,0,1.36-.07,1.48,1.48,0,0,0,.92-.59,2,2,0,0,0,.16-1.44q-.63-3.63-1.59-7.26t-1.89-7a13.18,13.18,0,0,0,2.28,0A11.69,11.69,0,0,1,1138.33,737.09Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1148.26,738.32a2,2,0,0,1,.68-.78A4.56,4.56,0,0,1,1150,737a6.07,6.07,0,0,1,1.32-.32,11.16,11.16,0,0,1,1.42-.09,6.47,6.47,0,0,0,1.16,0,6.68,6.68,0,0,1,1.18,0,6.55,6.55,0,0,1,1.83.38,4.23,4.23,0,0,1,1.5.91,1.77,1.77,0,0,1,.66.87,6.34,6.34,0,0,1,.36,1.4,14.86,14.86,0,0,1,.15,1.74c0,.63,0,1.27.06,1.93s0,1.24-.07,2-.08,1.45-.15,2.13-.16,1.29-.27,1.83a2.22,2.22,0,0,1-.47,1.11,2.74,2.74,0,0,1-1.33,1,6.77,6.77,0,0,1-1.79.38,13.08,13.08,0,0,1-2,0c-.68,0-1.3-.08-1.86-.08a4.71,4.71,0,0,1-2.58-1.32,3.67,3.67,0,0,1-1.11-1.8,10.36,10.36,0,0,1-.36-2.26c0-.79,0-1.59,0-2.39a17.15,17.15,0,0,0,0-2.19c0-.36,0-.71,0-1.06s.06-.72.12-1.1a4.3,4.3,0,0,1,.21-.88C1148.11,738.84,1148.2,738.54,1148.26,738.32Zm3.75,3.54a.93.93,0,0,0-.27.6,15.43,15.43,0,0,0-.12,1.91c0,.63,0,1.29-.06,2a1.33,1.33,0,0,0,.36,1.07,2,2,0,0,0,.95.51,3.15,3.15,0,0,0,1.15.06,2.83,2.83,0,0,0,1-.29,1.35,1.35,0,0,0,.69-1c.08-.64.15-1.28.2-1.93a6.61,6.61,0,0,0-.11-1.85,2.08,2.08,0,0,0-.87-1.53,2.35,2.35,0,0,0-1.89-.21A2,2,0,0,0,1152,741.86Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1168.78,750.38l-.37.21a2.51,2.51,0,0,0-.38.24,5.62,5.62,0,0,1-3,1,5.42,5.42,0,0,1-1.61-.18,3.57,3.57,0,0,1-1.41-.75,2.07,2.07,0,0,1-.46-1.12,13.82,13.82,0,0,1-.21-2.06c0-.8,0-1.67,0-2.62s0-1.91,0-2.88,0-1.91.06-2.81,0-1.69,0-2.37a11.88,11.88,0,0,1,1.88-.13h1.87q.06.69.06,1.56c0,.58,0,1.18,0,1.8s0,1.24,0,1.86,0,1.2.07,1.74c0,.24,0,.51.06.8a7.9,7.9,0,0,0,.11.85,3.7,3.7,0,0,0,.21.75,1.17,1.17,0,0,0,.37.51.09.09,0,0,0,.06,0l.06,0a2.57,2.57,0,0,0,.71.2,2,2,0,0,0,.91-.06,1.76,1.76,0,0,0,.81-.54,2,2,0,0,0,.42-1.22q.06-1,0-2c0-.68,0-1.35-.07-2s-.05-1.34-.06-2a12,12,0,0,1,.13-2v-.12a4.52,4.52,0,0,1,.89-.18,6.4,6.4,0,0,1,.76,0l.87,0a8.5,8.5,0,0,0,1.2,0q0,3.69.08,7.38t0,7.65l-.6,0-.45,0-.45,0-.6,0-.42,0-.6.07a3.41,3.41,0,0,1-.54,0c-.16,0-.24-.05-.24-.11a5.27,5.27,0,0,1,0-.84A6.59,6.59,0,0,0,1168.78,750.38Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1186,747.86v4a5.8,5.8,0,0,0-1,0l-1,.08-1,.07a4,4,0,0,1-.86,0,9.28,9.28,0,0,1-.07-1.18q0-.82,0-1.86c0-.69,0-1.42,0-2.18s0-1.49,0-2.2,0-1.35,0-1.91,0-1,0-1.29h-1l-1.13,0-.09-3.66H1181c.35,0,.68,0,1,0a8.89,8.89,0,0,1,0-1.62,5.94,5.94,0,0,1,.44-1.69,5,5,0,0,1,1-1.5,3.82,3.82,0,0,1,1.65-1,11.18,11.18,0,0,1,2.75-.52,25.54,25.54,0,0,1,2.89,0,4.23,4.23,0,0,1,.2,1,7.79,7.79,0,0,1,0,1.08q0,.54-.09,1.05t-.12.93a5.59,5.59,0,0,0-.82,0l-1,.09-1,.14-.93.13a1,1,0,0,0-.85.71,2.82,2.82,0,0,0,0,1.33h4.59a2.38,2.38,0,0,0,0,.65,4.43,4.43,0,0,1,0,.73v.53c0,.13,0,.26,0,.4s0,.32-.05.54,0,.52-.09.9l-2.56-.15-2-.09Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1192.21,738.32a2,2,0,0,1,.68-.78A4.56,4.56,0,0,1,1194,737a6.07,6.07,0,0,1,1.32-.32,11.16,11.16,0,0,1,1.42-.09,6.47,6.47,0,0,0,1.16,0,6.68,6.68,0,0,1,1.18,0,6.55,6.55,0,0,1,1.83.38,4.23,4.23,0,0,1,1.5.91,1.77,1.77,0,0,1,.66.87,6.34,6.34,0,0,1,.36,1.4,14.86,14.86,0,0,1,.15,1.74c0,.63,0,1.27.06,1.93s0,1.24-.07,2-.08,1.45-.15,2.13-.16,1.29-.27,1.83a2.22,2.22,0,0,1-.47,1.11,2.74,2.74,0,0,1-1.33,1,6.77,6.77,0,0,1-1.79.38,13.08,13.08,0,0,1-2,0c-.68,0-1.3-.08-1.86-.08a4.71,4.71,0,0,1-2.58-1.32,3.67,3.67,0,0,1-1.11-1.8,10.36,10.36,0,0,1-.36-2.26c0-.79,0-1.59,0-2.39a17.15,17.15,0,0,0-.05-2.19c0-.36,0-.71,0-1.06s.06-.72.12-1.1a4.3,4.3,0,0,1,.21-.88C1192.06,738.84,1192.15,738.54,1192.21,738.32Zm3.75,3.54a.93.93,0,0,0-.27.6,15.43,15.43,0,0,0-.12,1.91c0,.63,0,1.29-.06,2a1.33,1.33,0,0,0,.36,1.07,2,2,0,0,0,1,.51,3.15,3.15,0,0,0,1.15.06,2.83,2.83,0,0,0,1-.29,1.35,1.35,0,0,0,.69-1c.08-.64.15-1.28.2-1.93a6.61,6.61,0,0,0-.11-1.85,2.08,2.08,0,0,0-.87-1.53,2.35,2.35,0,0,0-1.89-.21A2,2,0,0,0,1196,741.86Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1209.49,738.05l.56-.24.85-.33a8.28,8.28,0,0,1,1.29-.28c.48-.07.93-.12,1.35-.14s.78,0,1.07,0a1.28,1.28,0,0,1,.43,0c0,.68,0,1.28,0,1.8s0,1,0,1.4v1.12q0,.51,0,1l-.27-.09-.31-.1-.32-.11c-.24-.06-.48-.11-.73-.15l-.41-.06a13.44,13.44,0,0,0-1.48-.09,3,3,0,0,0-1.17.21,1.61,1.61,0,0,0-.77.66,2.36,2.36,0,0,0-.24,1.29q0,1.05.06,2c0,.62,0,1.25,0,1.88s0,1.27,0,1.92,0,1.34,0,2.08v.12a4.45,4.45,0,0,1-.93.18,8.29,8.29,0,0,1-.87,0l-1-.05a9.12,9.12,0,0,0-1.25,0q0-3.69-.07-7.38t0-7.65l.6.05h.9l.6-.05.44,0,.66-.06a4.12,4.12,0,0,1,.6,0c.17,0,.25,0,.25.07v.56a1,1,0,0,0,0,.16A2.28,2.28,0,0,1,1209.49,738.05Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1224.58,758a5.39,5.39,0,0,1-1.08,0q-.78,0-2,0a5.21,5.21,0,0,1-2.52-.6,5,5,0,0,1-1.8-1.62,2.72,2.72,0,0,1-.22-.55c-.07-.23-.13-.47-.18-.71a4.36,4.36,0,0,1-.09-.69,3.47,3.47,0,0,1,0-.57,3,3,0,0,1,.81-.16,9.36,9.36,0,0,1,1.07,0q.56,0,1.08.06c.35,0,.64.06.88.1a1.64,1.64,0,0,0,.5,1.25,1.85,1.85,0,0,0,1.11.52,1.89,1.89,0,0,0,1.2-.3,1.75,1.75,0,0,0,.76-1.2l.06-.61a2.61,2.61,0,0,0,0-.56,4.61,4.61,0,0,1,0-1.05,1.76,1.76,0,0,1-.7.48,4,4,0,0,1-.87.21,7.46,7.46,0,0,1-.95.06q-.48,0-.9,0a7.52,7.52,0,0,1-1.15-.09,5,5,0,0,1-1.11-.3,3.38,3.38,0,0,1-1-.57,2.76,2.76,0,0,1-.69-.93,6.43,6.43,0,0,1-.6-1.69,13.51,13.51,0,0,1-.24-1.82q0-1.41.06-3a11.06,11.06,0,0,1,.53-3,5.7,5.7,0,0,1,1.48-2.4,4.52,4.52,0,0,1,2.94-1.1,4.24,4.24,0,0,1,1.79.26,2,2,0,0,1,1.18,1.15c.08,0,.13,0,.14-.1a2.91,2.91,0,0,0,0-.42l0-.51a.72.72,0,0,1,.07-.41,12.21,12.21,0,0,1,2-.07c.68,0,1.36,0,2,0q.12,3.75.14,7.85t-.17,8.41c0,.48,0,1,0,1.43a2,2,0,0,1-.27,1.18,5,5,0,0,1-.85,1.05,4.5,4.5,0,0,1-.86.62,3.18,3.18,0,0,1-.84.31A6.2,6.2,0,0,1,1224.58,758Zm-5.19-13.11v.27a3.94,3.94,0,0,0,.9,2.61,2.22,2.22,0,0,0,1.44.8,3.75,3.75,0,0,0,1.68-.11.85.85,0,0,0,.42-.28,1.61,1.61,0,0,0,.24-.45,3.05,3.05,0,0,0,.14-.54c0-.19,0-.38.07-.56,0-.8,0-1.61,0-2.44a10.94,10.94,0,0,0-.35-2.39,1.43,1.43,0,0,0-.58-.7,2.17,2.17,0,0,0-.89-.32,2.3,2.3,0,0,0-.94.08,2.27,2.27,0,0,0-.83.43,5.46,5.46,0,0,0-.76,1.13,4.54,4.54,0,0,0-.44,1.3,3,3,0,0,0-.06.59Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1234,744.47V745c0,.6,0,1.09.08,1.47a2.48,2.48,0,0,0,.37,1.11,2.29,2.29,0,0,0,1,1,2,2,0,0,0,1.29.21,1.68,1.68,0,0,0,1.07-.73,3,3,0,0,0,.37-1.85,6.44,6.44,0,0,0,.81,0l1.07,0c.37,0,.72,0,1.06,0a3.71,3.71,0,0,1,.84.1q-.06.78-.12,1.53c0,.5-.09,1-.15,1.4a8.06,8.06,0,0,1-.22,1.11,1.31,1.31,0,0,1-.35.64,2.76,2.76,0,0,1-1.32,1,6.18,6.18,0,0,1-1.77.36,16,16,0,0,1-2,0c-.67,0-1.29-.06-1.85-.06a4.26,4.26,0,0,1-1.56-.54,4.7,4.7,0,0,1-1.35-1.48,9.11,9.11,0,0,1-.87-2,12.17,12.17,0,0,1-.45-2.33,16.85,16.85,0,0,1-.06-2.46c.06-.74.13-1.45.2-2.14a8.41,8.41,0,0,1,.46-2,3.79,3.79,0,0,1,1.2-1.48,5.86,5.86,0,0,1,1.83-.93,8,8,0,0,1,2.16-.35,8.22,8.22,0,0,1,2.22.24,4.53,4.53,0,0,1,2.22,1,4.69,4.69,0,0,1,1.28,1.74,7,7,0,0,1,.52,2.25,17.24,17.24,0,0,1,0,2.49,5.37,5.37,0,0,1-.79.12l-1.38.12c-.53,0-1.1.07-1.7.09l-1.72,0c-.55,0-1,0-1.49,0A7.08,7.08,0,0,1,1234,744.47Zm3.63-4.65a2.05,2.05,0,0,0-.48-.22,1.91,1.91,0,0,0-.57-.08,3.78,3.78,0,0,0-1.17.15,1.81,1.81,0,0,0-.72.42,1.74,1.74,0,0,0-.4.63,3.77,3.77,0,0,0-.2.75,1.83,1.83,0,0,0,0,.47c0,.09.1.15.28.18a5.06,5.06,0,0,0,.86,0l1.72,0q.78,0,1.17,0a4.08,4.08,0,0,0,.56,0c.11,0,.16,0,.16-.09v-.32a2.22,2.22,0,0,0-.31-1.14A2,2,0,0,0,1237.66,739.82Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1249,731.48v4c0,.26,0,.53,0,.81s0,.57,0,.87l2-.09,2.56-.15a1.18,1.18,0,0,1,.24.71,8.33,8.33,0,0,1,0,1c0,.36,0,.73-.07,1.1a4.12,4.12,0,0,0,0,.94h-4.65v3.17c0,1,0,2,0,2.89a2,2,0,0,0,.87.66,6.54,6.54,0,0,0,1.23.38,8.6,8.6,0,0,0,1.34.16,11.55,11.55,0,0,0,1.18,0q.06.42.12.93t.09,1a7.79,7.79,0,0,1,0,1.08,4.23,4.23,0,0,1-.2,1,23,23,0,0,1-2.49,0,14.08,14.08,0,0,1-2.43-.35,6.89,6.89,0,0,1-2-.81,3.1,3.1,0,0,1-1.28-1.47,31.8,31.8,0,0,1-.45-4.36c0-1.37,0-2.79,0-4.25-.32,0-.65,0-1,0h-1.16l.09-3.66,1.13,0h1c0-.44,0-.91,0-1.42s0-1,0-1.55,0-1,0-1.5,0-.91.07-1.29a4,4,0,0,1,.86,0l1,.08,1,.07A5.8,5.8,0,0,0,1249,731.48Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1264.15,737.09c.14.72.3,1.58.48,2.57s.37,2,.57,3,.41,2,.63,2.93a20.53,20.53,0,0,0,.66,2.29,12,12,0,0,0,.54-2.37c.14-1,.28-2,.41-3.06s.27-2.08.43-3.06a11.56,11.56,0,0,1,.6-2.37q1,0,2.07,0a18.1,18.1,0,0,1,2.31,0c-.32,1.2-.6,2.35-.85,3.44s-.5,2.15-.74,3.19-.49,2-.7,3.09-.47,2.14-.78,3.18a17.54,17.54,0,0,1-1.19,3,8.12,8.12,0,0,1-1.9,2.45,8,8,0,0,1-2.94,1.57,12.35,12.35,0,0,1-4.31.39,6.64,6.64,0,0,1-.31-2,10.6,10.6,0,0,1,.13-2c.48,0,1,0,1.49.06a4.57,4.57,0,0,0,1.36-.07,1.48,1.48,0,0,0,.92-.59,2,2,0,0,0,.16-1.44q-.63-3.63-1.59-7.26t-1.89-7a13.18,13.18,0,0,0,2.28,0A11.69,11.69,0,0,1,1264.15,737.09Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1274.08,738.32a2,2,0,0,1,.68-.78,4.56,4.56,0,0,1,1.08-.55,6.07,6.07,0,0,1,1.32-.32,11.16,11.16,0,0,1,1.42-.09,6.47,6.47,0,0,0,1.16,0,6.68,6.68,0,0,1,1.18,0,6.55,6.55,0,0,1,1.83.38,4.23,4.23,0,0,1,1.5.91,1.77,1.77,0,0,1,.66.87,6.34,6.34,0,0,1,.36,1.4,14.86,14.86,0,0,1,.15,1.74c0,.63,0,1.27.06,1.93s0,1.24-.07,2-.08,1.45-.15,2.13-.16,1.29-.27,1.83a2.22,2.22,0,0,1-.47,1.11,2.74,2.74,0,0,1-1.33,1,6.77,6.77,0,0,1-1.79.38,13.08,13.08,0,0,1-2,0c-.68,0-1.3-.08-1.86-.08a4.71,4.71,0,0,1-2.58-1.32,3.67,3.67,0,0,1-1.11-1.8,10.36,10.36,0,0,1-.36-2.26c0-.79,0-1.59,0-2.39a17.15,17.15,0,0,0,0-2.19c0-.36,0-.71,0-1.06s.06-.72.12-1.1a4.3,4.3,0,0,1,.21-.88C1273.93,738.84,1274,738.54,1274.08,738.32Zm3.75,3.54a.93.93,0,0,0-.27.6,15.43,15.43,0,0,0-.12,1.91c0,.63,0,1.29-.06,2a1.33,1.33,0,0,0,.36,1.07,2,2,0,0,0,1,.51,3.15,3.15,0,0,0,1.15.06,2.83,2.83,0,0,0,1-.29,1.35,1.35,0,0,0,.69-1c.08-.64.15-1.28.2-1.93a6.61,6.61,0,0,0-.11-1.85,2.08,2.08,0,0,0-.87-1.53,2.35,2.35,0,0,0-1.89-.21A2,2,0,0,0,1277.83,741.86Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1294.6,750.38l-.37.21a2.51,2.51,0,0,0-.38.24,5.62,5.62,0,0,1-3,1,5.42,5.42,0,0,1-1.61-.18,3.57,3.57,0,0,1-1.41-.75,2.07,2.07,0,0,1-.46-1.12,13.82,13.82,0,0,1-.21-2.06c0-.8,0-1.67,0-2.62s0-1.91,0-2.88,0-1.91.06-2.81,0-1.69,0-2.37a11.88,11.88,0,0,1,1.88-.13h1.87q.06.69.06,1.56c0,.58,0,1.18,0,1.8s0,1.24,0,1.86,0,1.2.07,1.74c0,.24,0,.51.06.8a7.9,7.9,0,0,0,.11.85,3.7,3.7,0,0,0,.21.75,1.17,1.17,0,0,0,.37.51.09.09,0,0,0,.06,0,.09.09,0,0,1,.06,0,2.57,2.57,0,0,0,.71.2,2,2,0,0,0,.91-.06,1.76,1.76,0,0,0,.81-.54,2,2,0,0,0,.42-1.22q.06-1,0-2c0-.68,0-1.35-.07-2s0-1.34-.06-2a12,12,0,0,1,.13-2v-.12a4.52,4.52,0,0,1,.89-.18,6.4,6.4,0,0,1,.76,0l.87,0a8.5,8.5,0,0,0,1.2,0q0,3.69.08,7.38t0,7.65l-.6,0-.45,0-.45,0-.6,0-.42,0-.6.07a3.41,3.41,0,0,1-.54,0c-.16,0-.24-.05-.24-.11a5.27,5.27,0,0,1,0-.84A6.59,6.59,0,0,0,1294.6,750.38Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1304.86,738.05l.56-.24.85-.33a8.28,8.28,0,0,1,1.29-.28c.48-.07.93-.12,1.35-.14s.78,0,1.07,0a1.28,1.28,0,0,1,.43,0c0,.68,0,1.28,0,1.8s0,1,0,1.4v1.12q0,.51,0,1l-.27-.09-.31-.1-.32-.11c-.24-.06-.48-.11-.73-.15l-.41-.06a13.44,13.44,0,0,0-1.48-.09,3,3,0,0,0-1.17.21,1.61,1.61,0,0,0-.77.66,2.36,2.36,0,0,0-.24,1.29q0,1.05.06,2c0,.62,0,1.25,0,1.88s0,1.27,0,1.92,0,1.34,0,2.08v.12a4.45,4.45,0,0,1-.93.18,8.29,8.29,0,0,1-.87,0l-1-.05a9.12,9.12,0,0,0-1.25,0q0-3.69-.07-7.38t0-7.65l.6.05h.9l.6-.05.44,0,.66-.06a4.12,4.12,0,0,1,.6,0c.17,0,.25,0,.25.07v.56a1,1,0,0,0,0,.16A2.28,2.28,0,0,1,1304.86,738.05Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1321.39,738.62l.38-.21a2.38,2.38,0,0,0,.37-.24,5.61,5.61,0,0,1,3-1,5.35,5.35,0,0,1,1.6.18,3.57,3.57,0,0,1,1.41.75,2.11,2.11,0,0,1,.47,1.13,13.67,13.67,0,0,1,.21,2c0,.8,0,1.68,0,2.63s0,1.91-.05,2.88,0,1.9-.06,2.8,0,1.69,0,2.37a11.87,11.87,0,0,1-1.87.14l-1.88,0q-.06-.69-.06-1.56c0-.58,0-1.18,0-1.8s0-1.24,0-1.86,0-1.2-.08-1.74c0-.24,0-.5-.06-.79a8.08,8.08,0,0,0-.1-.86,3.7,3.7,0,0,0-.21-.75,1.18,1.18,0,0,0-.38-.51.09.09,0,0,0-.06,0l-.06,0a2.53,2.53,0,0,0-.7-.19,2,2,0,0,0-.92.06,1.76,1.76,0,0,0-.81.54,2,2,0,0,0-.42,1.21c0,.7,0,1.39,0,2.06s0,1.34.08,2,0,1.33,0,2a16.44,16.44,0,0,1-.12,2v.12a4.43,4.43,0,0,1-.88.18,6.54,6.54,0,0,1-.77,0l-.87-.05a8.5,8.5,0,0,0-1.2,0q0-3.69-.07-7.38t0-7.65l.6.05h.9l.6-.05a3.06,3.06,0,0,0,.42,0l.6-.08a3.32,3.32,0,0,1,.54,0c.16,0,.24,0,.24.1a5.46,5.46,0,0,1,.05.84A6.41,6.41,0,0,0,1321.39,738.62Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1334.56,736.91h.51V737q0,3.63-.13,7.41t.1,7.41a1.06,1.06,0,0,1-.75.2H1333l-1.34,0a3.17,3.17,0,0,0-.84,0c.1-1.26.15-2.54.14-3.84s0-2.59-.08-3.87-.06-2.54-.06-3.79a36.12,36.12,0,0,1,.18-3.65v0a1.35,1.35,0,0,1,.41,0l.43,0,1.08.06Q1333.6,736.94,1334.56,736.91Zm-3.69-6.54,4.08.09c0,.12,0,.34,0,.65s0,.65,0,1l0,1.14c0,.39,0,.73,0,1a.76.76,0,0,1-.55.24,6.32,6.32,0,0,1-1,.05l-1.25-.06a10.29,10.29,0,0,0-1.23,0c0-.58,0-1.18-.07-1.81S1330.85,731.29,1330.87,730.37Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1348.66,746.24l-.12,1.53c0,.5-.09,1-.15,1.4a8.06,8.06,0,0,1-.22,1.11,1.55,1.55,0,0,1-.35.67,2.74,2.74,0,0,1-1.33,1,6.77,6.77,0,0,1-1.79.38,13.08,13.08,0,0,1-2,0c-.68-.05-1.3-.08-1.86-.08a4.71,4.71,0,0,1-2.58-1.32,3.67,3.67,0,0,1-1.11-1.8,10.36,10.36,0,0,1-.36-2.26c0-.79,0-1.59,0-2.39a13.91,13.91,0,0,0-.08-2.19,13.12,13.12,0,0,1,.18-2.16,4.58,4.58,0,0,1,.21-.9l.27-.87a2,2,0,0,1,.68-.78,4.56,4.56,0,0,1,1.08-.55,6.07,6.07,0,0,1,1.32-.32,11.16,11.16,0,0,1,1.42-.09,6.47,6.47,0,0,0,1.16,0,6.68,6.68,0,0,1,1.18,0,6.91,6.91,0,0,1,1.83.36,4.07,4.07,0,0,1,1.5.9,1.94,1.94,0,0,1,.65.81,5.18,5.18,0,0,1,.34,1.2,10.13,10.13,0,0,1,.14,1.55c0,.57,0,1.18,0,1.84a3.91,3.91,0,0,1-.88.09l-1,0q-.52,0-1-.09l-.89-.12a2.45,2.45,0,0,0-.87-1.63,2,2,0,0,0-1.89-.29,2,2,0,0,0-1,.66.93.93,0,0,0-.27.6,14.75,14.75,0,0,0-.12,1.89q0,1-.06,2a1.27,1.27,0,0,0,.35,1.08,2.05,2.05,0,0,0,1,.53,3.27,3.27,0,0,0,1.17.06,2.22,2.22,0,0,0,1-.32,1.33,1.33,0,0,0,.47-.7c.11-.33.19-.63.25-.89a6.44,6.44,0,0,0,.81,0l1.08-.05c.38,0,.74,0,1.08,0A3.71,3.71,0,0,1,1348.66,746.24Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1354.51,730.22v6.57c0,.66,0,1.36,0,2.1a11.1,11.1,0,0,0,0,1.8,5.54,5.54,0,0,0,.89-1c.25-.36.48-.72.7-1.09l.68-1.13a7.46,7.46,0,0,1,.79-1.08,3.09,3.09,0,0,0,1,.17h1.31c.45,0,.89,0,1.33-.06a8.18,8.18,0,0,1,1.08,0c-.74,1.14-1.54,2.27-2.4,3.38s-1.71,2.22-2.55,3.34q1.35,2.19,2.72,4.34a47.06,47.06,0,0,1,2.5,4.45c-.28,0-.63,0-1.06,0l-1.34.07c-.46,0-.9,0-1.33,0a7.53,7.53,0,0,1-1.1-.12c-.3-.48-.57-1-.82-1.47l-.74-1.48c-.24-.49-.49-1-.76-1.46a11.42,11.42,0,0,0-.92-1.38q0,.54,0,1.26t0,1.5c0,.52,0,1,0,1.58a10.27,10.27,0,0,1-.15,1.45,7.09,7.09,0,0,0-.82,0l-1,0c-.36,0-.72,0-1.09,0a7.73,7.73,0,0,1-1-.06q-.21-5.58-.09-11t.09-10.83h1l1.11,0,1.06,0A6,6,0,0,1,1354.51,730.22Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1367.41,738.62l.38-.21a2.38,2.38,0,0,0,.37-.24,5.61,5.61,0,0,1,3-1,5.35,5.35,0,0,1,1.6.18,3.57,3.57,0,0,1,1.41.75,2.11,2.11,0,0,1,.47,1.13,13.67,13.67,0,0,1,.21,2c0,.8,0,1.68,0,2.63s0,1.91-.05,2.88,0,1.9-.06,2.8,0,1.69,0,2.37a11.87,11.87,0,0,1-1.87.14l-1.88,0q-.06-.69-.06-1.56c0-.58,0-1.18,0-1.8s0-1.24,0-1.86,0-1.2-.08-1.74c0-.24,0-.5-.06-.79a8.08,8.08,0,0,0-.1-.86,3.7,3.7,0,0,0-.21-.75,1.18,1.18,0,0,0-.38-.51.09.09,0,0,0-.06,0,.09.09,0,0,1-.06,0,2.53,2.53,0,0,0-.7-.19,2,2,0,0,0-.92.06,1.76,1.76,0,0,0-.81.54,2,2,0,0,0-.42,1.21c0,.7,0,1.39,0,2.06s0,1.34.08,2,0,1.33,0,2a16.44,16.44,0,0,1-.12,2v.12a4.43,4.43,0,0,1-.88.18,6.54,6.54,0,0,1-.77,0l-.87-.05a8.5,8.5,0,0,0-1.2,0q0-3.69-.07-7.38t0-7.65l.6.05h.9l.6-.05a3.06,3.06,0,0,0,.42,0l.6-.08a3.32,3.32,0,0,1,.54,0c.16,0,.24,0,.24.1a5.46,5.46,0,0,1,.05.84A6.41,6.41,0,0,0,1367.41,738.62Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1389,737.06c0,.12,0,.24,0,.36s0,.24,0,.36v.3q-.09,3.24,0,6.38a61.16,61.16,0,0,1-.09,6.34c0,.26,0,.52,0,.78a.42.42,0,0,1-.38.45h-.36c-.32,0-.64,0-1,0s-.66,0-1,0a4.48,4.48,0,0,0-.64,0,4.63,4.63,0,0,1-.65,0,.4.4,0,0,1-.28-.24,1.08,1.08,0,0,1-.08-.42,2,2,0,0,1,.06-.54,1.88,1.88,0,0,0,.06-.51,6.26,6.26,0,0,1-1.81,1.23,5.08,5.08,0,0,1-2.15.51,2.73,2.73,0,0,1-2-.81,6.55,6.55,0,0,1-1.37-2,11.18,11.18,0,0,1-.79-2.61,14.77,14.77,0,0,1-.26-2.61,9.3,9.3,0,0,1,.39-2.76,7.21,7.21,0,0,1,1.07-2.17,5.09,5.09,0,0,1,1.6-1.44,4.22,4.22,0,0,1,2-.56l1,0a2.85,2.85,0,0,1,.85.15,1.89,1.89,0,0,1,.69.42,2.25,2.25,0,0,1,.51.79,3,3,0,0,1,0-.82,3.35,3.35,0,0,0,.07-.71s.07,0,.2,0h1.24l1.25.06Zm-8.73,6a3.64,3.64,0,0,0-.15.8c0,.27,0,.53,0,.79a3.75,3.75,0,0,0,.71,2.3,2.3,2.3,0,0,0,2,.94,2,2,0,0,0,1.13-.25,1.41,1.41,0,0,0,.54-.72,3.68,3.68,0,0,0,.18-1.14c0-.45,0-1,0-1.52s0-1.25,0-1.66a2.81,2.81,0,0,0-.15-1,.67.67,0,0,0-.45-.42,3.73,3.73,0,0,0-1-.09,2.93,2.93,0,0,0-1.93.59A2.32,2.32,0,0,0,1380.28,743Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1395.16,738.62l.38-.21a2.38,2.38,0,0,0,.37-.24,5.61,5.61,0,0,1,3-1,5.35,5.35,0,0,1,1.6.18,3.57,3.57,0,0,1,1.41.75,1.62,1.62,0,0,1,.3.51l.36-.19a2,2,0,0,0,.36-.23,5.61,5.61,0,0,1,3-1,5.35,5.35,0,0,1,1.6.18,3.57,3.57,0,0,1,1.41.75,2.11,2.11,0,0,1,.47,1.13,13.67,13.67,0,0,1,.21,2c0,.8,0,1.68,0,2.63s0,1.91,0,2.88,0,1.9-.06,2.8,0,1.69,0,2.37a11.87,11.87,0,0,1-1.87.14l-1.88,0q-.06-.69-.06-1.56c0-.58,0-1.18,0-1.8s0-1.24,0-1.86,0-1.2-.08-1.74c0-.24,0-.5-.06-.79a8.08,8.08,0,0,0-.1-.86,3.7,3.7,0,0,0-.21-.75,1.18,1.18,0,0,0-.38-.51.09.09,0,0,0-.06,0l-.06,0a2,2,0,0,0-.45-.15,2.65,2.65,0,0,0-.58-.06,2,2,0,0,0-.63.11,2,2,0,0,0-.59.31c0,.78,0,1.61,0,2.48l-.06,2.58c0,.85,0,1.67,0,2.46s0,1.48,0,2.08a11.87,11.87,0,0,1-1.87.14l-1.88,0q-.06-.69-.06-1.56c0-.58,0-1.18,0-1.8s0-1.24,0-1.86,0-1.2-.08-1.74c0-.24,0-.5-.06-.79a8.08,8.08,0,0,0-.1-.86,3.7,3.7,0,0,0-.21-.75,1.18,1.18,0,0,0-.38-.51.09.09,0,0,0-.06,0,.09.09,0,0,1-.06,0,2.53,2.53,0,0,0-.7-.19,2,2,0,0,0-.92.06,1.76,1.76,0,0,0-.81.54,2,2,0,0,0-.42,1.21c0,.7,0,1.39,0,2.06s0,1.34.08,2,0,1.33,0,2a16.44,16.44,0,0,1-.12,2v.12a4.43,4.43,0,0,1-.88.18,6.54,6.54,0,0,1-.77,0l-.87-.05a8.5,8.5,0,0,0-1.2,0q0-3.69-.07-7.38t0-7.65l.6.05h.9l.6-.05a3.06,3.06,0,0,0,.42,0l.6-.08a3.32,3.32,0,0,1,.54,0c.16,0,.24,0,.24.1a5.46,5.46,0,0,1,.05.84A6.41,6.41,0,0,0,1395.16,738.62Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1415.32,744.47V745c0,.6,0,1.09.08,1.47a2.48,2.48,0,0,0,.37,1.11,2.29,2.29,0,0,0,1,1,2,2,0,0,0,1.29.21,1.68,1.68,0,0,0,1.07-.73,3,3,0,0,0,.37-1.85,6.44,6.44,0,0,0,.81,0l1.07,0c.37,0,.72,0,1.06,0a3.71,3.71,0,0,1,.84.1q-.06.78-.12,1.53c0,.5-.09,1-.15,1.4a8.06,8.06,0,0,1-.22,1.11,1.31,1.31,0,0,1-.35.64,2.76,2.76,0,0,1-1.32,1,6.18,6.18,0,0,1-1.77.36,16,16,0,0,1-2,0c-.67,0-1.29-.06-1.85-.06a4.26,4.26,0,0,1-1.56-.54,4.7,4.7,0,0,1-1.35-1.48,9.11,9.11,0,0,1-.87-2,12.17,12.17,0,0,1-.45-2.33,16.85,16.85,0,0,1-.06-2.46c.06-.74.13-1.45.2-2.14a8.41,8.41,0,0,1,.46-2,3.79,3.79,0,0,1,1.2-1.48,5.86,5.86,0,0,1,1.83-.93,8,8,0,0,1,2.16-.35,8.22,8.22,0,0,1,2.22.24,4.53,4.53,0,0,1,2.22,1,4.69,4.69,0,0,1,1.28,1.74,7,7,0,0,1,.52,2.25,17.24,17.24,0,0,1,0,2.49,5.37,5.37,0,0,1-.79.12l-1.38.12c-.53,0-1.1.07-1.7.09l-1.72,0c-.55,0-1,0-1.49,0A7.08,7.08,0,0,1,1415.32,744.47Zm3.63-4.65a2.05,2.05,0,0,0-.48-.22,1.91,1.91,0,0,0-.57-.08,3.78,3.78,0,0,0-1.17.15,1.81,1.81,0,0,0-.72.42,1.74,1.74,0,0,0-.4.63,3.77,3.77,0,0,0-.2.75,1.83,1.83,0,0,0,0,.47c0,.09.1.15.28.18a5.06,5.06,0,0,0,.86,0l1.72,0q.78,0,1.17,0a4.08,4.08,0,0,0,.56,0c.11,0,.16,0,.16-.09v-.32a2.22,2.22,0,0,0-.31-1.14A2,2,0,0,0,1419,739.82Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1431.31,738.32a2,2,0,0,1,.68-.78,4.56,4.56,0,0,1,1.08-.55,6.07,6.07,0,0,1,1.32-.32,11.16,11.16,0,0,1,1.42-.09,6.47,6.47,0,0,0,1.16,0,6.68,6.68,0,0,1,1.18,0,6.55,6.55,0,0,1,1.83.38,4.23,4.23,0,0,1,1.5.91,1.77,1.77,0,0,1,.66.87,6.34,6.34,0,0,1,.36,1.4,14.86,14.86,0,0,1,.15,1.74c0,.63,0,1.27.06,1.93s0,1.24-.07,2-.08,1.45-.15,2.13-.16,1.29-.27,1.83a2.22,2.22,0,0,1-.47,1.11,2.74,2.74,0,0,1-1.33,1,6.77,6.77,0,0,1-1.79.38,13.08,13.08,0,0,1-2,0c-.68,0-1.3-.08-1.86-.08a4.71,4.71,0,0,1-2.58-1.32,3.67,3.67,0,0,1-1.11-1.8,10.36,10.36,0,0,1-.36-2.26c0-.79,0-1.59,0-2.39a17.15,17.15,0,0,0,0-2.19c0-.36,0-.71,0-1.06s.06-.72.12-1.1a4.3,4.3,0,0,1,.21-.88C1431.16,738.84,1431.25,738.54,1431.31,738.32Zm3.75,3.54a.93.93,0,0,0-.27.6,15.43,15.43,0,0,0-.12,1.91c0,.63,0,1.29-.06,2a1.33,1.33,0,0,0,.36,1.07,2,2,0,0,0,1,.51,3.15,3.15,0,0,0,1.15.06,2.83,2.83,0,0,0,1-.29,1.35,1.35,0,0,0,.69-1c.08-.64.15-1.28.2-1.93a6.61,6.61,0,0,0-.11-1.85,2.08,2.08,0,0,0-.87-1.53,2.35,2.35,0,0,0-1.89-.21A2,2,0,0,0,1435.06,741.86Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1448.59,738.05l.56-.24.85-.33a8.28,8.28,0,0,1,1.29-.28c.48-.07.93-.12,1.35-.14s.78,0,1.07,0a1.28,1.28,0,0,1,.43,0c0,.68,0,1.28,0,1.8s0,1,0,1.4v1.12q0,.51,0,1l-.27-.09-.31-.1-.32-.11c-.24-.06-.48-.11-.73-.15l-.41-.06a13.44,13.44,0,0,0-1.48-.09,3,3,0,0,0-1.17.21,1.61,1.61,0,0,0-.77.66,2.36,2.36,0,0,0-.24,1.29q0,1.05.06,2c0,.62,0,1.25,0,1.88s0,1.27,0,1.92,0,1.34,0,2.08v.12a4.45,4.45,0,0,1-.93.18,8.29,8.29,0,0,1-.87,0l-1-.05a9.12,9.12,0,0,0-1.25,0q0-3.69-.07-7.38t0-7.65l.6.05h.9l.6-.05.44,0,.66-.06a4.12,4.12,0,0,1,.6,0c.17,0,.25,0,.25.07v.56a1,1,0,0,0,0,.16A2.28,2.28,0,0,1,1448.59,738.05Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1465.06,757.76v.33s-.13,0-.4.08-.6.05-1,.06h-1.25a7.1,7.1,0,0,1-1.14-.11v-2q0-2.49-.06-5c0-1.66-.07-3.29-.1-4.9s0-3.19-.06-4.74,0-3,0-4.49c.62,0,1.3,0,2,0a11.74,11.74,0,0,1,2,.07.51.51,0,0,1,0,.21c0,.2,0,.36,0,.48s0,.29,0,.51a.7.7,0,0,0,.14.23c.05,0,.1,0,.16-.11a2.13,2.13,0,0,1,1.2-1.15,3.39,3.39,0,0,1,1.59-.14,4.89,4.89,0,0,1,2.7.89,4.73,4.73,0,0,1,1.49,1.95,9.28,9.28,0,0,1,.64,2.65q.15,1.47.21,3a15.1,15.1,0,0,1-.13,2,9.53,9.53,0,0,1-.42,1.82,4.72,4.72,0,0,1-.75,1.41,2.52,2.52,0,0,1-1.13.82,6.53,6.53,0,0,1-1.78.38,11.42,11.42,0,0,1-1.85,0,6.06,6.06,0,0,1-1.47-.29q-.6-.21-.66-.48,0,1.41,0,2.28v1.4c0,.35,0,.62,0,.81l0,.54q0,.26,0,.57C1465.1,757.06,1465.08,757.36,1465.06,757.76Zm4.65-11.31a3.21,3.21,0,0,0,.18-.87q0-.45,0-.87a5.82,5.82,0,0,0-.18-1.45,4.21,4.21,0,0,0-.54-1.26,3,3,0,0,0-.87-.89,2.05,2.05,0,0,0-1.14-.33,1.9,1.9,0,0,0-1.2.33,2.08,2.08,0,0,0-.64.9,4.44,4.44,0,0,0-.27,1.38c0,.54,0,1.12,0,1.74,0,.78,0,1.4,0,1.85a2.78,2.78,0,0,0,.22,1,.87.87,0,0,0,.56.47,3.85,3.85,0,0,0,1,.1,3.26,3.26,0,0,0,2-.54A2.27,2.27,0,0,0,1469.71,746.45Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1487.14,737.06c0,.12,0,.24,0,.36s0,.24,0,.36v.3q-.09,3.24,0,6.38a61.16,61.16,0,0,1-.09,6.34c0,.26,0,.52,0,.78a.42.42,0,0,1-.38.45h-.36c-.32,0-.64,0-1,0s-.66,0-1,0a4.48,4.48,0,0,0-.64,0,4.63,4.63,0,0,1-.65,0,.4.4,0,0,1-.28-.24,1.08,1.08,0,0,1-.08-.42,2,2,0,0,1,.06-.54,1.88,1.88,0,0,0,.06-.51,6.26,6.26,0,0,1-1.81,1.23,5.08,5.08,0,0,1-2.15.51,2.73,2.73,0,0,1-2-.81,6.55,6.55,0,0,1-1.37-2,11.18,11.18,0,0,1-.79-2.61,14.77,14.77,0,0,1-.26-2.61,9.3,9.3,0,0,1,.39-2.76,7.21,7.21,0,0,1,1.07-2.17,5.09,5.09,0,0,1,1.6-1.44,4.22,4.22,0,0,1,2-.56l1,0a2.85,2.85,0,0,1,.85.15,1.89,1.89,0,0,1,.69.42,2.25,2.25,0,0,1,.51.79,3,3,0,0,1,0-.82,3.35,3.35,0,0,0,.07-.71s.07,0,.2,0h1.24l1.25.06Zm-8.73,6a3.64,3.64,0,0,0-.15.8c0,.27,0,.53,0,.79a3.75,3.75,0,0,0,.71,2.3,2.3,2.3,0,0,0,2,.94,2,2,0,0,0,1.13-.25,1.41,1.41,0,0,0,.54-.72,3.68,3.68,0,0,0,.18-1.14c0-.45,0-1,0-1.52s0-1.25,0-1.66a2.81,2.81,0,0,0-.15-1,.67.67,0,0,0-.45-.42,3.73,3.73,0,0,0-1-.09,2.93,2.93,0,0,0-1.93.59A2.32,2.32,0,0,0,1478.41,743Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1501.33,741.62a16.46,16.46,0,0,1-2.11.18,9.58,9.58,0,0,1-2.18-.18,1.06,1.06,0,0,0-.07-.72,1.83,1.83,0,0,0-.48-.63,2.12,2.12,0,0,0-.72-.4,2,2,0,0,0-.8-.11,1.67,1.67,0,0,0-1,.53,1.33,1.33,0,0,0-.35.88,1.6,1.6,0,0,0,.36,1,2.15,2.15,0,0,0,1,.72,25.36,25.36,0,0,1,3.15.65,3.92,3.92,0,0,1,2.16,1.3,5.66,5.66,0,0,1,.81,2.21,6.2,6.2,0,0,1-.42,2.77,4.1,4.1,0,0,1-2.16,2.1,7.72,7.72,0,0,1-3.21.6,9.25,9.25,0,0,1-2.76-.27,6.15,6.15,0,0,1-2.11-1,4.59,4.59,0,0,1-1.88-3.78v-.15a14.59,14.59,0,0,1,2.25-.07c.84,0,1.57,0,2.19,0a1.42,1.42,0,0,0,.65,1.13,2.73,2.73,0,0,0,1.3.51,2,2,0,0,0,1.26-.26,1.21,1.21,0,0,0,.54-1.2,1.14,1.14,0,0,0-.33-1.08,2.89,2.89,0,0,0-1.11-.55,15.31,15.31,0,0,0-1.56-.35,7.61,7.61,0,0,1-1.71-.51,4.42,4.42,0,0,1-2.44-1.78,4.3,4.3,0,0,1,.12-4.94,3.9,3.9,0,0,1,2.26-1.5,9.81,9.81,0,0,1,1.4-.24,16.11,16.11,0,0,1,1.75-.09,16.57,16.57,0,0,1,1.86.11,15.27,15.27,0,0,1,1.71.28,3.73,3.73,0,0,1,2,1.91A6.9,6.9,0,0,1,1501.33,741.62Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1514.83,741.62a16.46,16.46,0,0,1-2.11.18,9.58,9.58,0,0,1-2.18-.18,1.06,1.06,0,0,0-.07-.72,1.83,1.83,0,0,0-.48-.63,2.12,2.12,0,0,0-.72-.4,2,2,0,0,0-.8-.11,1.67,1.67,0,0,0-1,.53,1.33,1.33,0,0,0-.35.88,1.6,1.6,0,0,0,.36,1,2.15,2.15,0,0,0,1,.72,25.36,25.36,0,0,1,3.15.65,3.92,3.92,0,0,1,2.16,1.3,5.66,5.66,0,0,1,.81,2.21,6.2,6.2,0,0,1-.42,2.77,4.1,4.1,0,0,1-2.16,2.1,7.72,7.72,0,0,1-3.21.6,9.25,9.25,0,0,1-2.76-.27,6.15,6.15,0,0,1-2.11-1,4.59,4.59,0,0,1-1.88-3.78v-.15a14.59,14.59,0,0,1,2.25-.07c.84,0,1.57,0,2.19,0a1.42,1.42,0,0,0,.65,1.13,2.73,2.73,0,0,0,1.3.51,2,2,0,0,0,1.26-.26,1.21,1.21,0,0,0,.54-1.2,1.14,1.14,0,0,0-.33-1.08,2.89,2.89,0,0,0-1.11-.55,15.31,15.31,0,0,0-1.56-.35,7.61,7.61,0,0,1-1.71-.51,4.42,4.42,0,0,1-2.44-1.78,4.3,4.3,0,0,1,.12-4.94,3.9,3.9,0,0,1,2.26-1.5,9.81,9.81,0,0,1,1.4-.24,16.11,16.11,0,0,1,1.75-.09,16.57,16.57,0,0,1,1.86.11,15.27,15.27,0,0,1,1.71.28,3.73,3.73,0,0,1,2,1.91A6.9,6.9,0,0,1,1514.83,741.62Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1519.48,737.09c.14.72.28,1.55.42,2.48s.29,1.87.44,2.83.31,1.88.49,2.75a15.72,15.72,0,0,0,.6,2.2q.33-.9.54-1.68c.14-.52.28-1.08.42-1.69s.29-1.3.44-2.07.34-1.71.58-2.81q.15-.66.21-1.05t.18-1a5.72,5.72,0,0,0,1,0l.8-.06.73,0a4.17,4.17,0,0,1,.81.09c.14.72.28,1.55.41,2.49s.27,1.89.42,2.85.31,1.88.49,2.76a15.69,15.69,0,0,0,.6,2.22,14.81,14.81,0,0,0,.69-2.32c.22-1,.43-2,.63-3s.4-2,.59-2.88a13.88,13.88,0,0,1,.55-2,17.8,17.8,0,0,0,1.83-.09,11.85,11.85,0,0,1,2.07,0,35.15,35.15,0,0,0-1.09,3.56c-.31,1.23-.61,2.48-.89,3.76l-.84,3.86c-.28,1.29-.59,2.56-.93,3.82a10,10,0,0,1-1.27.11H1529l-1.38,0-1.25,0c-.18-1.08-.36-2.23-.55-3.45s-.4-2.39-.62-3.51a32.7,32.7,0,0,0-.55,3.47A23.74,23.74,0,0,1,1524,752a10.36,10.36,0,0,1-1.33.11h-1.5l-1.5,0-1.31,0q-.6-3.75-1.38-7.65t-1.74-7.44a12,12,0,0,0,2.16,0A10.57,10.57,0,0,1,1519.48,737.09Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1536.46,738.32a2,2,0,0,1,.68-.78,4.56,4.56,0,0,1,1.08-.55,6.07,6.07,0,0,1,1.32-.32,11.16,11.16,0,0,1,1.42-.09,6.47,6.47,0,0,0,1.16,0,6.68,6.68,0,0,1,1.18,0,6.55,6.55,0,0,1,1.83.38,4.23,4.23,0,0,1,1.5.91,1.77,1.77,0,0,1,.66.87,6.34,6.34,0,0,1,.36,1.4,14.86,14.86,0,0,1,.15,1.74c0,.63,0,1.27.06,1.93s0,1.24-.07,2-.08,1.45-.15,2.13-.16,1.29-.27,1.83a2.22,2.22,0,0,1-.47,1.11,2.74,2.74,0,0,1-1.33,1,6.77,6.77,0,0,1-1.79.38,13.08,13.08,0,0,1-2,0c-.68,0-1.3-.08-1.86-.08a4.71,4.71,0,0,1-2.58-1.32,3.67,3.67,0,0,1-1.11-1.8,10.36,10.36,0,0,1-.36-2.26c0-.79,0-1.59,0-2.39a17.15,17.15,0,0,0-.05-2.19c0-.36,0-.71,0-1.06s.06-.72.12-1.1a4.3,4.3,0,0,1,.21-.88C1536.31,738.84,1536.4,738.54,1536.46,738.32Zm3.75,3.54a.93.93,0,0,0-.27.6,15.43,15.43,0,0,0-.12,1.91c0,.63,0,1.29-.06,2a1.33,1.33,0,0,0,.36,1.07,2,2,0,0,0,1,.51,3.15,3.15,0,0,0,1.15.06,2.83,2.83,0,0,0,1-.29,1.35,1.35,0,0,0,.69-1c.08-.64.15-1.28.2-1.93a6.61,6.61,0,0,0-.11-1.85,2.08,2.08,0,0,0-.87-1.53,2.35,2.35,0,0,0-1.89-.21A2,2,0,0,0,1540.21,741.86Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1553.74,738.05l.56-.24.85-.33a8.28,8.28,0,0,1,1.29-.28c.48-.07.93-.12,1.35-.14s.78,0,1.07,0a1.28,1.28,0,0,1,.43,0c0,.68,0,1.28,0,1.8s0,1,0,1.4v1.12q0,.51,0,1l-.27-.09-.31-.1-.32-.11c-.24-.06-.48-.11-.73-.15l-.41-.06a13.44,13.44,0,0,0-1.48-.09,3,3,0,0,0-1.17.21,1.61,1.61,0,0,0-.77.66,2.36,2.36,0,0,0-.24,1.29q0,1.05.06,2c0,.62,0,1.25,0,1.88s0,1.27,0,1.92,0,1.34,0,2.08v.12a4.45,4.45,0,0,1-.93.18,8.29,8.29,0,0,1-.87,0l-1-.05a9.12,9.12,0,0,0-1.25,0q0-3.69-.07-7.38t0-7.65l.6.05h.9l.6-.05.44,0,.66-.06a4.12,4.12,0,0,1,.6,0c.17,0,.25,0,.25.07v.56a1,1,0,0,0,0,.16A2.28,2.28,0,0,1,1553.74,738.05Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1568.5,731.24v-.33a1.25,1.25,0,0,1,.41-.07q.4,0,1-.06h1.24a7.21,7.21,0,0,1,1.14.1v2q0,5,.15,9.9t0,9.21c-.62,0-1.3,0-2,0a11.67,11.67,0,0,1-2-.08.51.51,0,0,1,0-.21c0-.2,0-.36,0-.48s0-.29,0-.51a.64.64,0,0,0-.13-.22s-.11,0-.17.1a2.15,2.15,0,0,1-1.2,1.16,3.39,3.39,0,0,1-1.59.13,4.89,4.89,0,0,1-2.7-.88,4.7,4.7,0,0,1-1.48-1.95,9.69,9.69,0,0,1-.66-2.66c-.11-1-.18-2-.2-3a15.08,15.08,0,0,1,.14-2,9.42,9.42,0,0,1,.42-1.81,4.72,4.72,0,0,1,.75-1.41,2.52,2.52,0,0,1,1.12-.83,6.61,6.61,0,0,1,1.79-.37,11.29,11.29,0,0,1,1.84,0,6.1,6.1,0,0,1,1.47.28q.6.21.66.48,0-1.41,0-2.28v-1.39c0-.35,0-.62,0-.81l0-.54q0-.26,0-.57C1568.47,732,1568.48,731.64,1568.5,731.24Zm-4.65,11.31a3.21,3.21,0,0,0-.18.87q0,.45,0,.87a5.9,5.9,0,0,0,.18,1.46,4.21,4.21,0,0,0,.54,1.26,2.92,2.92,0,0,0,.87.88,2.05,2.05,0,0,0,1.14.33,1.93,1.93,0,0,0,1.19-.33,2,2,0,0,0,.66-.9,4.37,4.37,0,0,0,.27-1.36c0-.53,0-1.12,0-1.76s0-1.39,0-1.84a2.82,2.82,0,0,0-.23-1,.84.84,0,0,0-.55-.46,3.87,3.87,0,0,0-1-.11,3.23,3.23,0,0,0-2,.54A2.28,2.28,0,0,0,1563.85,742.55Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1577.74,746l.06-1.14c.1-.64.23-1.25.38-1.84a5.48,5.48,0,0,1,.79-1.73,10.06,10.06,0,0,1,1-1.48,13.76,13.76,0,0,0,1.15-1.7,2.93,2.93,0,0,0,.45-1.56q0-.72-1.11-1.11a1.36,1.36,0,0,0-1.06-.13,1.68,1.68,0,0,0-.65.51,7.88,7.88,0,0,0-.51.73,1.26,1.26,0,0,1-.63.54,11.33,11.33,0,0,1-1.84,0l-1.82-.09v0a6.95,6.95,0,0,1,1.74-4.2,6.53,6.53,0,0,1,1.83-1.42,5.49,5.49,0,0,1,2.34-.62,6.77,6.77,0,0,1,2.63.27,5.93,5.93,0,0,1,2.16,1.2,5.32,5.32,0,0,1,1.39,2,4.83,4.83,0,0,1,.33,2.46,5.34,5.34,0,0,1-.51,1.74,8.74,8.74,0,0,1-.9,1.44,7.5,7.5,0,0,1-.91,1.07,7.56,7.56,0,0,0-.89,1,7.08,7.08,0,0,0-.94,1.74,13.3,13.3,0,0,0-.53,1.92q-.06.24-.12.51a1.1,1.1,0,0,0,0,.51v0a9.47,9.47,0,0,1-1.84.06,14.28,14.28,0,0,0-1.85,0h0a.41.41,0,0,1-.12-.31Zm3.51,6-3.39,0h-.09a5.88,5.88,0,0,1-.07-.84q0-.51,0-1t0-1c0-.3,0-.52,0-.66a3.43,3.43,0,0,1,.86-.18,10.37,10.37,0,0,1,1.12-.06c.38,0,.74,0,1.07,0a2.55,2.55,0,0,1,.7.13s0,.19,0,.5,0,.66-.06,1.06,0,.79-.06,1.17,0,.64,0,.78V752Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1253.38,777.47l2.25,10.5v0a8.32,8.32,0,0,1-2,.14,11.84,11.84,0,0,1-2-.26h0a10.71,10.71,0,0,1-.34-1.14q-.16-.66-.33-1.35c-.11-.46-.23-.9-.35-1.32a7.35,7.35,0,0,0-.39-1.08,4.25,4.25,0,0,0-.6-.06h-.37l-.36,0h-.53a5.83,5.83,0,0,0-.34,1.13q-.13.64-.27,1.35c-.09.47-.19.92-.29,1.36A4.73,4.73,0,0,1,1247,788l0,0h0a5.48,5.48,0,0,1-1.2,0q-1-.06-3,0l-.06,0q.06-.21.21-1.17t.42-2.49c.18-1,.4-2.19.65-3.52s.54-2.75.88-4.25.71-3.06,1.11-4.68.84-3.23,1.32-4.83a3.19,3.19,0,0,1,.45,0h1.64c.41,0,.94-.05,1.6-.11.06.26.16.66.29,1.19s.27,1.13.42,1.8l.48,2.14c.17.76.33,1.49.49,2.19s.31,1.34.45,1.92Zm-3.06,1.95c-.2-1.72-.37-3.06-.52-4a15.16,15.16,0,0,0-.39-1.95c-.11-.34-.22-.41-.33-.22a3.8,3.8,0,0,0-.3,1.15q-.13.87-.3,2.19c-.11.88-.24,1.83-.38,2.85.34,0,.7,0,1.08,0Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1268.62,777.62a16.46,16.46,0,0,1-2.11.18,9.58,9.58,0,0,1-2.18-.18,1.06,1.06,0,0,0-.07-.72,1.83,1.83,0,0,0-.48-.63,2.12,2.12,0,0,0-.72-.4,2,2,0,0,0-.8-.11,1.67,1.67,0,0,0-1,.53,1.33,1.33,0,0,0-.35.88,1.6,1.6,0,0,0,.36,1,2.15,2.15,0,0,0,1,.72,25.36,25.36,0,0,1,3.15.65,3.92,3.92,0,0,1,2.16,1.3,5.66,5.66,0,0,1,.81,2.21,6.2,6.2,0,0,1-.42,2.77,4.1,4.1,0,0,1-2.16,2.1,7.72,7.72,0,0,1-3.21.6,9.25,9.25,0,0,1-2.76-.27,6.15,6.15,0,0,1-2.11-1,4.59,4.59,0,0,1-1.88-3.78v-.15a14.59,14.59,0,0,1,2.25-.07c.84,0,1.57,0,2.19,0a1.42,1.42,0,0,0,.65,1.13,2.73,2.73,0,0,0,1.3.51,2,2,0,0,0,1.26-.26,1.21,1.21,0,0,0,.54-1.2,1.14,1.14,0,0,0-.33-1.08,2.89,2.89,0,0,0-1.11-.55,15.31,15.31,0,0,0-1.56-.35,7.61,7.61,0,0,1-1.71-.51,4.42,4.42,0,0,1-2.44-1.78,4.3,4.3,0,0,1,.12-4.94,3.9,3.9,0,0,1,2.26-1.5,9.81,9.81,0,0,1,1.4-.24,16.11,16.11,0,0,1,1.75-.09,16.57,16.57,0,0,1,1.86.11,15.27,15.27,0,0,1,1.71.28,3.73,3.73,0,0,1,2,1.91A6.9,6.9,0,0,1,1268.62,777.62Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1274.26,766.22v6.57c0,.66,0,1.36,0,2.1a11.1,11.1,0,0,0,0,1.8,5.54,5.54,0,0,0,.89-1c.25-.36.48-.72.7-1.09l.68-1.13a7.46,7.46,0,0,1,.79-1.08,3.09,3.09,0,0,0,1,.17h1.31c.45,0,.89,0,1.33-.06a8.18,8.18,0,0,1,1.08,0c-.74,1.14-1.54,2.27-2.4,3.38s-1.71,2.22-2.55,3.34q1.35,2.19,2.72,4.34a47.06,47.06,0,0,1,2.5,4.45c-.28,0-.63,0-1.06,0l-1.34.07c-.46,0-.9,0-1.33,0a7.53,7.53,0,0,1-1.1-.12c-.3-.48-.57-1-.82-1.47l-.74-1.48c-.24-.49-.49-1-.76-1.46a11.42,11.42,0,0,0-.92-1.38q0,.54,0,1.26t0,1.5c0,.52,0,1,0,1.58a10.27,10.27,0,0,1-.15,1.45,7.09,7.09,0,0,0-.82,0l-1,0c-.36,0-.72,0-1.09,0a7.73,7.73,0,0,1-1-.06q-.21-5.58-.09-11t.09-10.83h1l1.11,0,1.06,0A6,6,0,0,1,1274.26,766.22Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1292.08,773.09c.14.72.3,1.58.48,2.57s.37,2,.57,3,.41,2,.63,2.93a20.53,20.53,0,0,0,.66,2.29,12,12,0,0,0,.54-2.37c.14-1,.28-2,.41-3.06s.27-2.08.43-3.06a11.56,11.56,0,0,1,.6-2.37q1,0,2.07,0a18.1,18.1,0,0,1,2.31,0c-.32,1.2-.6,2.35-.85,3.44s-.5,2.15-.74,3.19-.49,2-.7,3.09-.47,2.14-.78,3.18a17.54,17.54,0,0,1-1.19,3,8.12,8.12,0,0,1-1.9,2.45,8,8,0,0,1-2.94,1.57,12.35,12.35,0,0,1-4.31.39,6.64,6.64,0,0,1-.31-2,10.6,10.6,0,0,1,.13-2c.48,0,1,0,1.49.06a4.57,4.57,0,0,0,1.36-.07,1.48,1.48,0,0,0,.92-.59,2,2,0,0,0,.16-1.44q-.63-3.63-1.59-7.26t-1.89-7a13.18,13.18,0,0,0,2.28,0A11.69,11.69,0,0,1,1292.08,773.09Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1302,774.32a2,2,0,0,1,.68-.78,4.56,4.56,0,0,1,1.08-.55,6.07,6.07,0,0,1,1.32-.32,11.16,11.16,0,0,1,1.42-.09,6.47,6.47,0,0,0,1.16,0,6.68,6.68,0,0,1,1.18,0,6.55,6.55,0,0,1,1.83.38,4.23,4.23,0,0,1,1.5.91,1.77,1.77,0,0,1,.66.87,6.34,6.34,0,0,1,.36,1.4,14.86,14.86,0,0,1,.15,1.74c0,.63,0,1.27.06,1.93s0,1.24-.07,2-.08,1.45-.15,2.13-.16,1.29-.27,1.83a2.22,2.22,0,0,1-.47,1.11,2.74,2.74,0,0,1-1.33,1,6.77,6.77,0,0,1-1.79.38,13.08,13.08,0,0,1-2,0c-.68,0-1.3-.08-1.86-.08a4.71,4.71,0,0,1-2.58-1.32,3.67,3.67,0,0,1-1.11-1.8,10.36,10.36,0,0,1-.36-2.26c0-.79,0-1.59,0-2.39a17.15,17.15,0,0,0,0-2.19c0-.36,0-.71,0-1.06s.06-.72.12-1.1a4.3,4.3,0,0,1,.21-.88C1301.86,774.84,1302,774.54,1302,774.32Zm3.75,3.54a.93.93,0,0,0-.27.6,15.43,15.43,0,0,0-.12,1.91c0,.63,0,1.29-.06,2a1.33,1.33,0,0,0,.36,1.07,2,2,0,0,0,.95.51,3.15,3.15,0,0,0,1.15.06,2.83,2.83,0,0,0,1-.29,1.35,1.35,0,0,0,.69-1c.08-.64.15-1.28.2-1.93a6.61,6.61,0,0,0-.11-1.85,2.08,2.08,0,0,0-.87-1.53,2.35,2.35,0,0,0-1.89-.21A2,2,0,0,0,1305.76,777.86Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1322.53,786.38l-.37.21a2.51,2.51,0,0,0-.38.24,5.62,5.62,0,0,1-3,1,5.42,5.42,0,0,1-1.61-.18,3.57,3.57,0,0,1-1.41-.75,2.07,2.07,0,0,1-.46-1.12,13.82,13.82,0,0,1-.21-2.06c0-.8,0-1.67,0-2.62s0-1.91,0-2.88,0-1.91.06-2.81,0-1.69,0-2.37a11.88,11.88,0,0,1,1.88-.13h1.87q.06.69.06,1.56c0,.58,0,1.18,0,1.8s0,1.24,0,1.86,0,1.2.07,1.74c0,.24,0,.51.06.8a7.9,7.9,0,0,0,.11.85,3.7,3.7,0,0,0,.21.75,1.17,1.17,0,0,0,.37.51.09.09,0,0,0,.06,0l.06,0a2.57,2.57,0,0,0,.71.2,2,2,0,0,0,.91-.06,1.76,1.76,0,0,0,.81-.54,2,2,0,0,0,.42-1.22q.06-1,0-2c0-.68,0-1.35-.07-2s-.05-1.34-.06-2a12,12,0,0,1,.13-2v-.12a4.52,4.52,0,0,1,.89-.18,6.4,6.4,0,0,1,.76,0l.87,0a8.5,8.5,0,0,0,1.2,0q0,3.69.08,7.38t0,7.65l-.6,0-.45,0-.45,0-.6,0-.42,0-.6.07a3.41,3.41,0,0,1-.54,0c-.16,0-.24-.05-.24-.11a5.27,5.27,0,0,1,0-.84A6.59,6.59,0,0,0,1322.53,786.38Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1332.79,774.05l.56-.24.85-.33a8.28,8.28,0,0,1,1.29-.28c.48-.07.93-.12,1.35-.14s.78,0,1.07,0a1.28,1.28,0,0,1,.43,0c0,.68,0,1.28,0,1.8s0,1,0,1.4v1.12q0,.51,0,1l-.27-.09-.31-.1-.32-.11c-.24-.06-.48-.11-.73-.15l-.41-.06a13.44,13.44,0,0,0-1.48-.09,3,3,0,0,0-1.17.21,1.61,1.61,0,0,0-.77.66,2.36,2.36,0,0,0-.24,1.29q0,1.05.06,2c0,.62,0,1.25,0,1.88s0,1.27,0,1.92,0,1.34,0,2.08v.12a4.45,4.45,0,0,1-.93.18,8.29,8.29,0,0,1-.87,0l-1-.05a9.12,9.12,0,0,0-1.25,0q0-3.69-.07-7.38t0-7.65l.6.05h.9l.6-.05.44,0,.66-.06a4.12,4.12,0,0,1,.6,0c.17,0,.25,0,.25.07v.56a1,1,0,0,0,0,.16A2.28,2.28,0,0,1,1332.79,774.05Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1350.28,767.48v4c0,.26,0,.53,0,.81s0,.57,0,.87l2-.09,2.56-.15a1.18,1.18,0,0,1,.24.71,8.33,8.33,0,0,1,0,1c0,.36,0,.73-.07,1.1a4.12,4.12,0,0,0,0,.94h-4.65v3.17c0,1,0,2,0,2.89a2,2,0,0,0,.87.66,6.54,6.54,0,0,0,1.23.38,8.6,8.6,0,0,0,1.34.16,11.55,11.55,0,0,0,1.18,0q.06.42.12.93t.09,1a7.79,7.79,0,0,1,0,1.08,4.23,4.23,0,0,1-.2,1,23,23,0,0,1-2.49,0,14.08,14.08,0,0,1-2.43-.35,6.89,6.89,0,0,1-2-.81,3.1,3.1,0,0,1-1.28-1.47,31.8,31.8,0,0,1-.45-4.36c0-1.37,0-2.79,0-4.25-.32,0-.65,0-1,0h-1.16l.09-3.66,1.13,0h1c0-.44,0-.91,0-1.42s0-1,0-1.55,0-1,0-1.5,0-.91.07-1.29a4,4,0,0,1,.86,0l1,.08,1,.07A5.8,5.8,0,0,0,1350.28,767.48Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1360.12,780.47V781c0,.6.05,1.09.08,1.47a2.48,2.48,0,0,0,.37,1.11,2.29,2.29,0,0,0,1,1,2,2,0,0,0,1.29.21,1.68,1.68,0,0,0,1.07-.73,3,3,0,0,0,.37-1.85,6.44,6.44,0,0,0,.81,0l1.07,0c.37,0,.72,0,1.06,0a3.71,3.71,0,0,1,.84.1q-.06.78-.12,1.53c0,.5-.09,1-.15,1.4a8.06,8.06,0,0,1-.22,1.11,1.31,1.31,0,0,1-.35.64,2.76,2.76,0,0,1-1.32,1,6.18,6.18,0,0,1-1.77.36,16,16,0,0,1-2,0c-.67,0-1.29-.06-1.85-.06a4.26,4.26,0,0,1-1.56-.54,4.7,4.7,0,0,1-1.35-1.48,9.11,9.11,0,0,1-.87-2,12.17,12.17,0,0,1-.45-2.33,16.85,16.85,0,0,1-.06-2.46c.06-.74.13-1.45.2-2.14a8.41,8.41,0,0,1,.46-2,3.79,3.79,0,0,1,1.2-1.48,5.86,5.86,0,0,1,1.83-.93,8,8,0,0,1,2.16-.35,8.22,8.22,0,0,1,2.22.24,4.53,4.53,0,0,1,2.22,1,4.69,4.69,0,0,1,1.28,1.74,7,7,0,0,1,.52,2.25,17.24,17.24,0,0,1,0,2.49,5.37,5.37,0,0,1-.79.12l-1.38.12c-.53,0-1.1.07-1.7.09l-1.72,0c-.55,0-1,0-1.49,0A7.08,7.08,0,0,1,1360.12,780.47Zm3.63-4.65a2.05,2.05,0,0,0-.48-.22,1.91,1.91,0,0,0-.57-.08,3.78,3.78,0,0,0-1.17.15,1.81,1.81,0,0,0-.72.42,1.74,1.74,0,0,0-.4.63,3.77,3.77,0,0,0-.2.75,1.83,1.83,0,0,0,0,.47c0,.09.1.15.28.18a5.06,5.06,0,0,0,.86,0l1.72,0q.78,0,1.17,0a4.08,4.08,0,0,0,.56,0c.11,0,.16,0,.16-.09v-.32a2.22,2.22,0,0,0-.31-1.14A2,2,0,0,0,1363.75,775.82Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1381.93,773.06c0,.12,0,.24,0,.36s0,.24,0,.36v.3q-.09,3.24,0,6.38a61.16,61.16,0,0,1-.09,6.34c0,.26,0,.52,0,.78a.42.42,0,0,1-.38.45h-.36c-.32,0-.64,0-1,0s-.66,0-1,0a4.48,4.48,0,0,0-.64,0,4.63,4.63,0,0,1-.65,0,.4.4,0,0,1-.28-.24,1.08,1.08,0,0,1-.08-.42,2,2,0,0,1,.06-.54,1.88,1.88,0,0,0,.06-.51,6.26,6.26,0,0,1-1.81,1.23,5.08,5.08,0,0,1-2.15.51,2.73,2.73,0,0,1-2-.81,6.55,6.55,0,0,1-1.37-2,11.18,11.18,0,0,1-.79-2.61,14.77,14.77,0,0,1-.26-2.61,9.3,9.3,0,0,1,.39-2.76,7.21,7.21,0,0,1,1.07-2.17,5.09,5.09,0,0,1,1.6-1.44,4.22,4.22,0,0,1,2-.56l1,0a2.85,2.85,0,0,1,.85.15,1.89,1.89,0,0,1,.69.42,2.25,2.25,0,0,1,.51.79,3,3,0,0,1,0-.82,3.35,3.35,0,0,0,.07-.71s.07,0,.2,0H1379l1.25.06Zm-8.73,6a3.64,3.64,0,0,0-.15.8c0,.27,0,.53,0,.79a3.75,3.75,0,0,0,.71,2.3,2.3,2.3,0,0,0,2,.94,2,2,0,0,0,1.13-.25,1.41,1.41,0,0,0,.54-.72,3.68,3.68,0,0,0,.18-1.14c0-.45,0-1,0-1.52s0-1.25,0-1.66a2.81,2.81,0,0,0-.15-1,.67.67,0,0,0-.45-.42,3.73,3.73,0,0,0-1-.09,2.93,2.93,0,0,0-1.93.59A2.32,2.32,0,0,0,1373.2,779Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1395.67,782.24q-.06.78-.12,1.53c0,.5-.09,1-.15,1.4a8.06,8.06,0,0,1-.22,1.11,1.55,1.55,0,0,1-.35.67,2.74,2.74,0,0,1-1.33,1,6.77,6.77,0,0,1-1.79.38,13.08,13.08,0,0,1-2,0c-.68-.05-1.3-.08-1.86-.08a4.71,4.71,0,0,1-2.58-1.32,3.67,3.67,0,0,1-1.11-1.8,10.36,10.36,0,0,1-.36-2.26c0-.79,0-1.59,0-2.39a13.91,13.91,0,0,0-.08-2.19,13.12,13.12,0,0,1,.18-2.16,4.58,4.58,0,0,1,.21-.9l.27-.87a2,2,0,0,1,.68-.78,4.56,4.56,0,0,1,1.08-.55,6.07,6.07,0,0,1,1.32-.32,11.16,11.16,0,0,1,1.42-.09,6.47,6.47,0,0,0,1.16,0,6.68,6.68,0,0,1,1.18,0,6.91,6.91,0,0,1,1.83.36,4.07,4.07,0,0,1,1.5.9,1.94,1.94,0,0,1,.65.81,5.18,5.18,0,0,1,.34,1.2,10.13,10.13,0,0,1,.14,1.55c0,.57,0,1.18,0,1.84a3.91,3.91,0,0,1-.88.09l-1,0q-.52,0-1-.09l-.89-.12a2.45,2.45,0,0,0-.87-1.63,2,2,0,0,0-1.89-.29,2,2,0,0,0-1,.66.93.93,0,0,0-.27.6,14.75,14.75,0,0,0-.12,1.89q0,1-.06,2a1.27,1.27,0,0,0,.35,1.08,2.05,2.05,0,0,0,1,.53,3.27,3.27,0,0,0,1.17.06,2.22,2.22,0,0,0,1-.32,1.33,1.33,0,0,0,.47-.7c.11-.33.19-.63.25-.89a6.44,6.44,0,0,0,.81,0l1.08-.05c.38,0,.74,0,1.08,0A3.71,3.71,0,0,1,1395.67,782.24Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1401.31,774.62l.38-.21a2.38,2.38,0,0,0,.37-.24,5.61,5.61,0,0,1,3-1,5.35,5.35,0,0,1,1.6.18,3.57,3.57,0,0,1,1.41.75,2.11,2.11,0,0,1,.47,1.13,13.67,13.67,0,0,1,.21,2c0,.8,0,1.68,0,2.63s0,1.91,0,2.88,0,1.9-.06,2.8,0,1.69,0,2.37a11.87,11.87,0,0,1-1.87.14l-1.88,0q-.06-.69-.06-1.56c0-.58,0-1.18,0-1.8s0-1.24,0-1.86,0-1.2-.08-1.74c0-.24,0-.5-.06-.79a8.08,8.08,0,0,0-.1-.86,3.7,3.7,0,0,0-.21-.75,1.18,1.18,0,0,0-.38-.51.09.09,0,0,0-.06,0l-.06,0a2.53,2.53,0,0,0-.7-.19,2,2,0,0,0-.92.06,1.76,1.76,0,0,0-.81.54,2,2,0,0,0-.42,1.21c0,.7-.05,1.39,0,2.06s.05,1.34.08,2,0,1.33,0,2a16.44,16.44,0,0,1-.12,2v.12a4.43,4.43,0,0,1-.88.18,6.54,6.54,0,0,1-.77,0l-.87-.05a8.5,8.5,0,0,0-1.2,0c0-.82,0-1.8,0-3s0-2.39,0-3.71,0-2.68,0-4.09v-4.13c0-1.34,0-2.6,0-3.79s0-2.23,0-3.11l.6.05h.9l.6-.05a3.06,3.06,0,0,0,.42,0l.6-.08a3.32,3.32,0,0,1,.54,0c.16,0,.24,0,.24.1a6,6,0,0,1,0,.8v7.54Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1414.42,780.47V781c0,.6,0,1.09.08,1.47a2.48,2.48,0,0,0,.37,1.11,2.29,2.29,0,0,0,1,1,2,2,0,0,0,1.29.21,1.68,1.68,0,0,0,1.07-.73,3,3,0,0,0,.37-1.85,6.44,6.44,0,0,0,.81,0l1.07,0c.37,0,.72,0,1.06,0a3.71,3.71,0,0,1,.84.1l-.12,1.53c0,.5-.09,1-.15,1.4a8.06,8.06,0,0,1-.22,1.11,1.31,1.31,0,0,1-.35.64,2.76,2.76,0,0,1-1.32,1,6.18,6.18,0,0,1-1.77.36,16,16,0,0,1-2,0c-.67,0-1.29-.06-1.85-.06a4.26,4.26,0,0,1-1.56-.54,4.7,4.7,0,0,1-1.35-1.48,9.11,9.11,0,0,1-.87-2,12.17,12.17,0,0,1-.45-2.33,16.85,16.85,0,0,1-.06-2.46c.06-.74.13-1.45.2-2.14a8.41,8.41,0,0,1,.46-2,3.79,3.79,0,0,1,1.2-1.48,5.86,5.86,0,0,1,1.83-.93,8,8,0,0,1,2.16-.35,8.22,8.22,0,0,1,2.22.24,4.53,4.53,0,0,1,2.22,1,4.69,4.69,0,0,1,1.28,1.74,7,7,0,0,1,.52,2.25,17.24,17.24,0,0,1,0,2.49,5.37,5.37,0,0,1-.79.12l-1.38.12c-.53,0-1.1.07-1.7.09l-1.72,0c-.55,0-1,0-1.49,0A7.08,7.08,0,0,1,1414.42,780.47Zm3.63-4.65a2.05,2.05,0,0,0-.48-.22,1.91,1.91,0,0,0-.57-.08,3.78,3.78,0,0,0-1.17.15,1.81,1.81,0,0,0-.72.42,1.74,1.74,0,0,0-.4.63,3.77,3.77,0,0,0-.2.75,1.83,1.83,0,0,0,0,.47c0,.09.1.15.28.18a5.06,5.06,0,0,0,.86,0l1.72,0q.78,0,1.17,0a4.08,4.08,0,0,0,.56,0c.11,0,.16,0,.16-.09v-.32a2.22,2.22,0,0,0-.31-1.14A2,2,0,0,0,1418.05,775.82Z"
                transform="translate(-190.1 -104.18)"/>
            <path
                d="M1428.25,774.05l.56-.24.85-.33a8.28,8.28,0,0,1,1.29-.28c.48-.07.93-.12,1.35-.14s.78,0,1.07,0a1.28,1.28,0,0,1,.43,0c0,.68,0,1.28,0,1.8s0,1,0,1.4v1.12q0,.51,0,1l-.27-.09-.31-.1-.32-.11c-.24-.06-.48-.11-.73-.15l-.41-.06a13.44,13.44,0,0,0-1.48-.09,3,3,0,0,0-1.17.21,1.61,1.61,0,0,0-.77.66,2.36,2.36,0,0,0-.24,1.29q0,1.05.06,2c0,.62,0,1.25,0,1.88s0,1.27,0,1.92,0,1.34,0,2.08v.12a4.45,4.45,0,0,1-.93.18,8.29,8.29,0,0,1-.87,0l-1-.05a9.12,9.12,0,0,0-1.25,0q0-3.69-.07-7.38t0-7.65l.6.05h.9l.6-.05.44,0,.66-.06a4.12,4.12,0,0,1,.6,0c.17,0,.25,0,.25.07v.56a1,1,0,0,0,0,.16A2.28,2.28,0,0,1,1428.25,774.05Z"
                transform="translate(-190.1 -104.18)"/>
            <path d="M1437.1,788l-3.39,0h-.09a5.88,5.88,0,0,1-.07-.84q0-.51,0-1t0-1c0-.3,0-.52,0-.66a3.43,3.43,0,0,1,.86-.18,10.37,10.37,0,0,1,1.12-.06c.38,0,.74,0,1.07,0a2.55,2.55,0,0,1,.7.13s0,.19,0,.5,0,.66-.06,1.06l-.06,1.17q0,.57,0,.78V788Z"
                  transform="translate(-190.1 -104.18)"/>
        </g>
        <use height="85" transform="translate(62.25 54.82)" width="128.65" xlinkHref="#sely-logo"/>
        <use height="567.86" transform="translate(196.4 149.91)" width="501.01" xlinkHref="#rolly-splash"/>

    </g>;
}
