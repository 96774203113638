import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setInteractions} from "../store/generalStateSlice";
import {useGameState} from "./useGameState";

export const useInteractiveGameState = (configs) => {
    const [listeners] = useState(configs);
    const stateManager = useGameState();
    const {action, group} = stateManager;
    const dispatch = useDispatch();


    useEffect(() => {
        const canInteract = listeners?.[group]?.[action];
        const state = (typeof canInteract === 'boolean' && canInteract) || false;
        dispatch(setInteractions(state))
    }, [action, group, listeners])
}
