import {LoadableAsset} from "../LoadableAsset";

export class ImageAsset extends LoadableAsset {
    _assetType = 'sceneLoadable';

    /**
     *
     * @param prop
     */
    constructor(prop) {
        super(prop);
    }

    _assetsPath;

    get assetsPath() {
        return this._assetsPath;
    }

    loadAsset({resolve, reject}) {

        this.assetsProperties().then((data) => {
            this._asset = new Image();
            this._asset.addEventListener("load", () => {
                resolve(data);
            });
            this._asset.src = data.default;
        }).catch(reject)
    }
}
