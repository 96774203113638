import {animated, config, to, useChain, useSpring, useSpringRef} from "react-spring";
import React, {useEffect} from "react";

export const LockSym = ({locked, unlocking, unlocked}) => {

    const rotSpringApi = useSpringRef();
    const fallSpringApi = useSpringRef();

    const rotSpring = useSpring({
        ref: rotSpringApi,
        config: config.wobbly,
        to: {rotation: locked ? -20 : 0},
        from: {rotation: locked ? 45 : -20},
        immediate: unlocked
    });
    const fallSpring = useSpring({
        ref: fallSpringApi,
        config: config.molasses,
        to: {translate: !locked ? 330 : 0, opacity: !locked ? 0 : 1},
        from: {rotation: !locked ? 0 : 330, opacity: !locked ? 1 : 0},
        immediate: !unlocking,
    });
    const trSpring = useSpring({
        config: config.wobbly,
        to: {translate: !locked ? -15 : 0},
        from: {translate: !locked ? 0 : -15},
        immediate: locked
    });


    useChain([
        rotSpringApi,
        fallSpringApi
    ], [0, (locked || unlocked) ? 0 : 1]);


    return <animated.g style={{
        transform: to([rotSpring.rotation, fallSpring.translate], (r, t) => `rotate(${r}deg) translate(0px, ${t}px)`),
        opacity: fallSpring.opacity
    }}>
        <animated.path style={{
            transform: trSpring.translate.interpolate(t => `translate(0px, ${t}px)`)
        }} className="cls-8" d="M58.74,0H20.5A10.9,10.9,0,0,0,9.41,10.7V42.55h9.22V16.29a7.57,7.57,0,0,1,7.7-7.44H52.9a7.57,7.57,0,0,1,7.7,7.44V61.56h9.23V10.7A10.9,10.9,0,0,0,58.74,0Z"/>
        <path className="cls-8" d="M72.05,37.85h-65a7,7,0,0,0-7,7V95.8a7,7,0,0,0,7.05,7.06h65a7,7,0,0,0,7-7.06V44.9A7,7,0,0,0,72.05,37.85Z"/>
        <path className="cls-9" d="M62.58,45.15H16.66a5.24,5.24,0,0,0-5,5.47V90.09a5.24,5.24,0,0,0,5,5.47H62.58a5.24,5.24,0,0,0,5-5.47V50.62A5.24,5.24,0,0,0,62.58,45.15Z"/>
        <path className="cls-10" d="M46.24,66.19a6.69,6.69,0,1,0-8.94,6.29L33.37,88.27H45.73L41.8,72.48A6.68,6.68,0,0,0,46.24,66.19Z"/>
        <path className="cls-11" d="M5.1,45.15c-.85,0-1.54,1-1.54,2.15V93.41c0,1.19.69,2.15,1.54,2.15s1.53-1,1.53-2.15V47.3C6.63,46.12,6,45.15,5.1,45.15Z"/>
    </animated.g>;
};


export default LockSym;
