export const LOADING_SCREEN_SCREEN_STATE = 'loading-screen';
export const WELCOME_SCREEN_SCREEN_STATE = 'welcome-screen';
export const AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE = 'avatar-customizer-screen';
export const LEVEL_SELECTION_SCREEN_STATE = 'level-selection-screen';
export const QUESTION_INTRO_SCREEN_STATE = 'question-intro-screen';
export const LEVEL_SCENE_SCREEN_STATE = 'level-scene-screen';
export const ANSWER_SELECTION_SCREEN_STATE = 'answer-selection-screen';
export const LEVEL_END_SCREEN_STATE = 'level-end-screen';
export const GAME_END_SCREEN_STATE = 'game-end-screen';
export const LOGIN_SCREEN_STATE = 'login-screen';

export const WANT_LEAVE_STATE_ACTION = 'want-leave-game';
export const WANT_EXIT_SCENE_STATE_ACTION = 'want-exit-scene';
export const ENTERING_STATE_ACTION = 'entering';
export const ENTERED_STATE_ACTION = 'entered';
export const EXITING_STATE_ACTION = 'exiting';
export const README_STATE_ACTION = 'readmeAction';
export const EXITED_STATE_ACTION = 'exited';
export const PRELOAD_STATE_ACTION = 'preload';
export const PRELOADING_STATE_ACTION = 'preloading';
export const IDLE_STATE_ACTION = 'idle';
export const CHECK_STATE_ACTION = 'checkState';
export const COMPLETE_STATE_ACTION = 'complete';
export const NEXT_SCENE_STATE_ACTION = 'nextScene';
export const PREV_SCENE_STATE_ACTION = 'prevScene';
export const UNLOCK_STATE_ACTION = 'unlock';
export const UNLOCKING_STATE_ACTION = 'unlocking';
export const UNLOCKED_STATE_ACTION = 'unlocked';
export const LOCKED_STATE_ACTION = 'locked';
export const BACK_STATE_ACTION = 'back';
export const MOUNT_STATE_ACTION = 'mount';
export const PLAY_STATE_ACTION = 'play';
export const PLAYING_STATE_ACTION = 'playing';
export const PAUSE_STATE_ACTION = 'pause';
export const RESTART_STATE_ACTION = 'restart';
export const ASK_REPLAY_STATE_ACTION = 'askReplay';
export const GOING_BACK_STATE_ACTION = 'goingBack';
export const TO_FULLSCREEN_STATE_ACTION = 'toFullscreen';
export const PRESENTATION_STATE_ACTION = 'presentation';
export const NEXT_PRESENTATION_STATE_ACTION = 'nextPresentation';
export const LISTEN_STATE_ACTION = 'listen';
export const LISTENING_STATE_ACTION = 'listening';
export const CONFIRMED_STATE_ACTION = 'confirmed';
export const SELECTED_STATE_ACTION = 'selected';
export const SWITCHING_SOUND_STATE_ACTION = 'switchingSound';
export const TRY_LOGIN_STATE_ACTION = 'tryingLogin';
export const LOGIN_ERROR_STATE_ACTION = 'loginError';
export const AUTH_SUCCESS_STATE_ACTION = 'authSuccess';
export const LOGIN_SUCCESS_STATE_ACTION = 'loginSuccess';
export const AVATAR_CHOOSED_STATE_ACTION = 'avatarChoosed';
export const PLAY_AGAIN_STATE_ACTION = 'playAgain';
export const EXIT_STATE_ACTION = 'exitGame';
export const AVATAR_CHANGE_STATE_ACTION = 'avatarChange';
export const PLAY_SCENE_STATE_ACTION = 'playScene';
export const LEVEL_COMPLETE_STATE_ACTION = 'levelComplete';
export const GAME_COMPLETE_STATE_ACTION = 'gameComplete';
export const _STATE_ACTION = '';

export const AUDIO_JINGLE = "miscellaneous.audio_jingle";
export const AUDIO_SWITCH_SCENE = "sfx.audio_jump";
export const AUDIO_TRANSITION_1 = "sfx.audio_transition";
export const AUDIO_TRANSITION_2 = "sfx.audio_transition2";
export const AUDIO_TRANSITION_3 = "sfx.audio_transition3";
export const AUDIO_TRANSITION_4 = "sfx.audio_transition4";
export const AUDIO_DROP_PADLOCK = "sfx.audio_drop_padlock";
export const AUDIO_ARPA_2 = "sfx.audio_arpa2";
export const AUDIO_JUMP = "sfx.audio_jump";
export const AUDIO_WIN = "sfx.audio_win";
export const AUDIO_OK = "sfx.audio_ok";
export const AUDIO_TERRIFIC = 'miscellaneous.audio_terrific';
export const AUDIO_KEEP_GOING = 'miscellaneous.audio_keep_going';
export const AUDIO_NICE_JOB = 'miscellaneous.audio_nice_job';
export const AUDIO_GREAT_JOB = 'miscellaneous.audio_great_job';
export const AUDIO_WELL_DONE = 'miscellaneous.audio_well_done';
export const AUDIO_YOU_DID_IT = 'miscellaneous.audio_you_did_it';
export const AUDIO_CONGRATS = 'miscellaneous.audio_congrants';

export const asState = (group, action) => [group, action].join('.');
export const preloadScreen = () => PRELOAD_STATE_ACTION
