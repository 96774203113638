import AnswerButton from "./AnswerButton";
import RepeatSoundButton from "./RepeatSoundButton";

export const CompleteAnswerBtn = ({answers, answPos, setChoosed, choosed, setListening, speakers, listening, whatListening}) => {
    const answer = answers[answPos];
    return <>
        <AnswerButton text={answer?.text} onClick={() => setChoosed(answer?.value)} choosed={choosed === answer?.value} transform={
            [
                "translate(265.17 436.92)",
                "translate(740.47 436.92)",
                "translate(1180.67 436.92)",
            ][answPos]
        }/>
        <g onClick={() => {
            setListening(answPos)
            speakers[answPos]();
        }} width="59.6" height="54.84" transform={
            [
                "translate(628.26 656.66)",
                "translate(1103.78 656.66)",
                "translate(1543.84 656.8)",
            ][answPos]
        }>
            <RepeatSoundButton listening={listening && whatListening === answPos}/>
        </g>
    </>
}
