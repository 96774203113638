import {at, identity, mapValues} from "lodash";
import {AUDIO_ARPA_2} from "../../constants";

export class PipeCallExecutor {
    _configs = {};
    _args = {};
    _data = {};
    _conditions = {};

    callWithArgs = configurator => configurator(this._args);
    callWithConditions = configurator => configurator(this._conditions);
    callWithConfigs = configurator => configurator(this._configs);
    callWithData = configurator => configurator(this._data);
    callWithAll = (configurator, ...others) => this.callWithConfigs(
        configs => this.callWithData(
            data => this.callWithArgs(
                args => this.callWithConditions(
                    conditions => configurator(
                        configs,
                        data,
                        args,
                        conditions,
                        ...others
                    )
                )
            )
        )
    )

    preloadCall = () => {
        this.callWithAll((
            {assetsManager: {preload}, stateManager: {nextState}},
            data,
            args
        ) => {

            const {batchLoad, forward = true, handleFinish = identity, handleProgress = identity, handleStart = identity, finishDelay = 0, debug = false} = args;


            preload({
                batchLoad: batchLoad,
                debug,
                onFinish() {
                    handleFinish();
                    forward && nextState(finishDelay);
                },
                onProgress: handleProgress,
                onStart: handleStart,
            })
        })
    };

    nextStateCall = () => this.callWithAll(({stateManager: {nextState}}, data, {delay = 0, before = identity}) => {
        typeof before === "function" && before();
        nextState({delay});
    });

    changeStateCall = () => this.callWithAll(({stateManager: {changeState}}, {}, {state}) => changeState(state))

    changeActionCall = () => this.callWithAll(({stateManager: {changeAction}}, {}, {action}) => changeAction(action))

    delayChangeActionCall = time => this.callWithAll(({stateManager: {changeAction}}, data, {action}) => setTimeout(() => changeAction(action), time));

    conditionalCall = (key, trueCall, falseCall, ...othersCall) => this.callWithAll((configs, data, args, conditions) => {

            const condition = conditions[key];

            if (typeof condition === 'boolean') {
                condition ? trueCall({
                    configs,
                    data,
                    args,
                    conditions,
                    executor: this
                }) : falseCall?.({
                    configs,
                    data,
                    args,
                    conditions,
                    executor: this
                });

            } else if (Array.isArray(condition)) {

                let toCall = true;

                [trueCall, falseCall, ...othersCall].map((call, pos) => {
                    if (toCall && condition[pos]) {
                        call({
                            configs,
                            data,
                            args,
                            conditions,
                            executor: this
                        });
                        toCall = false;
                    }
                })
            }

        }
    )
    extractData = (extractionConfigs, then = identity) => this.callWithAll((configs, prevData, args, conditions) => {


            const data = mapValues(extractionConfigs, (path, key) => {
                const absolutePath = `${path}.${key}`;
                return at({
                    configs,
                    data: prevData,
                    args,
                    conditions
                }, absolutePath)?.[0];
            });

            this._data = {
                ...prevData,
                ...data
            };


            then({
                configs,
                data: this._data,
                args,
                conditions
            });

        }
    )
    extractConfigs = (extractionConfigs, then = identity) => this.callWithAll((configs, prevData, args, conditions) => {


            const data = mapValues(extractionConfigs, (path, key) => {
                const absolutePath = `${path}.${key}`;
                return at(configs, absolutePath)?.[0];
            });

            this._data = {
                ...prevData,
                ...data
            };


            then({
                configs,
                data: this._data,
                args,
                conditions
            });

        }
    )

    condition = (key, extractionConfigs) => this.callWithAll((configs, data, args, prevConditions) => {
            let condition = false;
            if (Array.isArray(extractionConfigs)) {
                condition = mapValues(extractionConfigs, (path, key) => {
                    const absolutePath = `${path}.${key}`;
                    return at({
                        configs,
                        data,
                        args,
                        conditions: prevConditions,
                    }, absolutePath)?.[0];
                });
            } else if (typeof extractionConfigs === 'string') {
                condition = at({
                    configs,
                    data,
                    args,
                    conditions: prevConditions,
                }, extractionConfigs)?.[0];
            } else if (typeof extractionConfigs === 'function') {
                condition = extractionConfigs({
                    configs, data, args, conditions: prevConditions, executor: this
                })
            }

            this._conditions = {
                ...prevConditions,
                [key]: condition
            };


        }
    );

    playAudio = (audio, options=null) => this.callWithAll(({assetsManager: {get}}) => {
            get(audio).play(options);
        }
    )

    argsCall = (configurator) => this.callWithAll((configs, data, prevArgs, conditions) => {
            let args;

            if (typeof configurator === "function") {
                const toPass = {
                    configs,
                    data,
                    args: prevArgs,
                    conditions,
                };
                args = configurator(toPass);
            } else {
                args = configurator;
            }

            this._args = {
                ...prevArgs,
                ...args,
            };

        }
    );

    directCall = (configurator) => this.callWithAll((configs, data, args, conditions) => configurator({
        configs, data, args, conditions, executor: this
    }));

    identityAll = () => this.callWithAll((configs, data, args, conditions) => ({
        configs,
        data,
        args,
        conditions,
        executor: this
    }));

    singleCall = (toCall, argsConfigurator = identity) => this.callWithAll((configs, data, args, conditions) => {


            let fn = toCall;

            let name;

            const fnArgs = {
                configs,
                data,
                args,
                conditions,
            };


            if (typeof toCall === 'string') {
                name = toCall;
                fn = at(fnArgs, toCall)?.[0];
            }


            if (typeof fn === 'function') {


                this._args.singleRes = {...(this._args?.singleRes ?? {})};

                this._args.singleRes[name ?? fn.name] = fn(argsConfigurator(fnArgs));
            }
            return this.identityAll();
        }
    )
    logCall = (toLog) => configs => {
        return configs;
    }

    forwardCall = () => this.callWithAll((configs, data, args) => {
        configs.stateManager.nextState(args);
    });

    withConfigs(configs) {
        this._configs = configs;
        // this._args = configs?.args;
        // this._data = configs?.data;
    }

}
