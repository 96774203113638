import {AUDIO_CONGRATS, AUDIO_DROP_PADLOCK, AUDIO_GREAT_JOB, AUDIO_JINGLE, AUDIO_JUMP, AUDIO_KEEP_GOING, AUDIO_NICE_JOB, AUDIO_OK, AUDIO_SWITCH_SCENE, AUDIO_TERRIFIC, AUDIO_TRANSITION_1, AUDIO_TRANSITION_2, AUDIO_TRANSITION_3, AUDIO_TRANSITION_4, AUDIO_WELL_DONE, AUDIO_WIN, AUDIO_YOU_DID_IT} from "../constants";

export const idleCmp = [    AUDIO_CONGRATS,
    "components.BackgroundRay", "components.BackgroundTerrain"];
export const welcomeScreenCmp = [
    //for level selection
    'components.GameFrameBackground',
    'components.LearningSplashText',
    'components.LevelFrame',
    'components.GameFrame',
    'components.LockSym',
    'components.PrevLevelButton',
    'components.NextLevelButton',
    //for welcome-screen
    "components.WelcomeFrame",
    "components.PresentationSplashText",
    "components.PlayButton",
    "components.RollyRobot",
    "finale.finale_01",
    "questions.intro",
    'previews.scene_01',
    'previews.scene_02',
    'previews.scene_03',
    'previews.scene_04',
    'previews.scene_05',
    'previews.scene_06',
    'previews.scene_07',
    'previews.scene_08',
    'previews.scene_09',
    'previews.scene_10',
    'previews.scene_11',
    'previews.scene_12',
    'previews.scene_13',
    'previews.scene_14',
    AUDIO_JINGLE,
    AUDIO_TERRIFIC,
    AUDIO_SWITCH_SCENE,
    AUDIO_TRANSITION_1,
    AUDIO_TRANSITION_2,
    AUDIO_TRANSITION_3,
    AUDIO_TRANSITION_4,
    AUDIO_DROP_PADLOCK,
    AUDIO_KEEP_GOING,
    AUDIO_NICE_JOB,
    AUDIO_GREAT_JOB,
    AUDIO_WELL_DONE,
    AUDIO_YOU_DID_IT,
    AUDIO_CONGRATS,
    AUDIO_JUMP,
    AUDIO_WIN,
    AUDIO_OK,
];
