import {useAssetManager} from "./useAssetManager";
import {useDispatch} from "react-redux";
import {AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE, LEVEL_END_SCREEN_STATE, preloadScreen} from "../constants";
import {useGameState} from "./useGameState";

export const useSubscribingStates = () => {

    const stateManager = useGameState();
    const {group, action, gameStateFlowActions: {toNextState}, gameStateFlow} = stateManager;
    const assetsManager = useAssetManager();
    const dispatch = useDispatch();

    const toForwardState = (delay = 0) => {
        const flow = gameStateFlow.flow;
        const hasRegularNextState = !!flow?.[group]?.[action];
        const hasGeneralNextState = !!flow?.['*']?.[action] ?? false;


        if (hasRegularNextState || hasGeneralNextState) {
            toNextState(delay);
        }
    };

    const dispatchedActions = {
        setToolbar: (state) => dispatch(stateManager.actions.toolbarState.setToolbar(state)),
        changeState: (state) => dispatch(stateManager.actions.general.changeState(state)),
        changeAction: (state) => dispatch(stateManager.actions.general.changeAction(state)),
        changeGroup: (state) => dispatch(stateManager.actions.general.changeGroup(state)),
        setOptions: (state) => dispatch(stateManager.actions.general.setOptions(state)),
        setProgress: ({scene, progress}) => dispatch(stateManager.actions.general.setProgress({scene, progress})),
        setAnswer: ({scene, answer}) => dispatch(stateManager.actions.general.setAnswer({scene, answer})),
        setBackSound: (state) => dispatch(stateManager.actions.general.setBackSound(state)),
        setInteractions: (canInteract) => dispatch(stateManager.actions.general.setInteractions(canInteract)),
        toPreviousAction: () => dispatch(stateManager.actions.general.toPreviousAction()),
        setAsFinished: () => dispatch(stateManager.actions.general.setAsFinished()),
        logout: () => dispatch(stateManager.actions.general.logout()),
        resetAvatar: () => dispatch(stateManager.actions.general.resetAvatar()),
        backgroundAudio: {
            mute: () => dispatch(stateManager.actions.audioMixerState.mute()),
            unMute: () => dispatch(stateManager.actions.audioMixerState.unMute()),
            toMinVolume: () => dispatch(stateManager.actions.audioMixerState.toMinVolume()),
            toMaxVolume: () => dispatch(stateManager.actions.audioMixerState.toMaxVolume()),
            play: () => dispatch(stateManager.actions.audioMixerState.play()),
            pause: () => dispatch(stateManager.actions.audioMixerState.pause()),
            resume: () => dispatch(stateManager.actions.audioMixerState.resume()),
            stop: () => dispatch(stateManager.actions.audioMixerState.stop()),
        }

    };

    const groupActions = {
        'loading-screen': {
            progress: (perc) => dispatch(stateManager.actions.loadingScreen.progress(perc)),
            startLoading: () => dispatch(stateManager.actions.loadingScreen.startLoading()),
        },
        'level-selection-screen': {
            next: () => dispatch(stateManager.actions.levelSelectionScreen.next()),
            prev: () => dispatch(stateManager.actions.levelSelectionScreen.prev()),
            choose: (scene) => dispatch(stateManager.actions.levelSelectionScreen.choose(scene)),
            tryUnlock: () => dispatch(stateManager.actions.levelSelectionScreen.tryUnlock()),
            setUnlocked: () => dispatch(stateManager.actions.levelSelectionScreen.setUnlocked()),
            setCompleted: () => dispatch(stateManager.actions.levelSelectionScreen.setCompleted()),
            setUnlockable: (scene) => dispatch(stateManager.actions.levelSelectionScreen.setUnlockable(scene)),
            initPlayerStateSave: () => dispatch(stateManager.actions.loginScreen.initPlayerStateSave()),
            updatePlayerStateSave: () => dispatch(stateManager.actions.loginScreen.updatePlayerStateSave()),
            readPlayerStateSave: () => dispatch(stateManager.actions.loginScreen.readPlayerStateSave()),
        },
        'level-scene-screen': {
            setLoaded: () => dispatch(stateManager.actions.levelSceneScreen.setLoaded()),
            pause: () => dispatch(stateManager.actions.levelSceneScreen.pause()),
            play: () => dispatch(stateManager.actions.levelSceneScreen.play()),
            unload: () => dispatch(stateManager.actions.levelSceneScreen.unload()),
            restart: () => dispatch(stateManager.actions.levelSceneScreen.restart()),
            muteScene: () => dispatch(stateManager.actions.levelSceneScreen.muteScene()),
            unmuteScene: () => dispatch(stateManager.actions.levelSceneScreen.unmuteScene()),
            recordProgress: (rec) => dispatch(stateManager.actions.levelSceneScreen.recordProgress(rec)),
        },
        'answer-selection-screen': {
            initSelection: (scene) => dispatch(stateManager.actions.answerSelectionScreen.initSelection(scene)),
            listen: (scene) => dispatch(stateManager.actions.answerSelectionScreen.listen(scene)),
            setListened: (scene) => dispatch(stateManager.actions.answerSelectionScreen.setListened(scene)),
            choose: (scene) => dispatch(stateManager.actions.answerSelectionScreen.choose(scene)),
            muteScene: () => dispatch(stateManager.actions.levelSceneScreen.muteScene()),
            unmuteScene: () => dispatch(stateManager.actions.levelSceneScreen.unmuteScene()),
            restart: () => dispatch(stateManager.actions.levelSceneScreen.restart()),
            unload: () => dispatch(stateManager.actions.levelSceneScreen.unload()),
            recordProgress: (rec) => dispatch(stateManager.actions.levelSceneScreen.recordProgress(rec)),
        },
        'login-screen': {
            login: (token) => dispatch(stateManager.actions.loginScreen.login(token)),
            loginPlayer: ({nickname, classCode}) => dispatch(stateManager.actions.loginScreen.loginPlayer({nickname, classCode})),
            readPlayerInfo: () => dispatch(stateManager.actions.loginScreen.readPlayerInfo()),
            initPlayerStateSave: () => dispatch(stateManager.actions.loginScreen.initPlayerStateSave()),
            updatePlayerStateSave: () => dispatch(stateManager.actions.loginScreen.updatePlayerStateSave()),
            readPlayerStateSave: () => dispatch(stateManager.actions.loginScreen.readPlayerStateSave()),
            clearToken: () => dispatch(stateManager.actions.loginScreen.clearToken()),
        },
        [LEVEL_END_SCREEN_STATE]: {
            initPlayerStateSave: () => dispatch(stateManager.actions.loginScreen.initPlayerStateSave()),
            updatePlayerStateSave: () => dispatch(stateManager.actions.loginScreen.updatePlayerStateSave()),
            readPlayerStateSave: () => dispatch(stateManager.actions.loginScreen.readPlayerStateSave()),
            setCompleted: () => dispatch(stateManager.actions.levelEndScreen.setCompleted()),
        },
        [AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE]: {
            chooseAvatar: ({args}) => dispatch(stateManager.actions.avatarSelectionScreen.chooseAvatar(args))
        }
    };

    return {
        preloadScreen: (group) => dispatch(stateManager.actions.general.changeState(preloadScreen(group))),
        stateManager: {
            ...stateManager,
            ...dispatchedActions,
            ...(groupActions?.[group] ?? {}),
            nextState: toNextState,
            forwardState: toForwardState,
        },
        writeD: (text, type = 'general') => dispatch(stateManager.actions.debuggerR.writeDebug({text, type})),
        assetsManager
    };
}
