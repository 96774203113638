import "../../assets/scss/want-leave-dialog.scss";
import {useGameActionSetter} from "../../hooks/useGameActionSetter";
import {useDispatch} from "react-redux";
import {toPreviousAction} from "../../store/gameStateFlowSlice";
import {WantLeaveDialogBase} from "./WantLeaveDialogBase";

export const WantLeaveDialog = () => {

    const dispatch = useDispatch();

    const dontWant = () => dispatch(toPreviousAction());
    const logout = useGameActionSetter('logout');


//0 0 420 318
    return <WantLeaveDialogBase
        handleLeave={() => logout()}
        handleNotLeave={() => dontWant()}
    />

}
