import React from "react";
import {animated, useSprings} from "react-spring";

export default function GameProgressBar({progress = 50, show = true, full = false, hPercent = 212.16}) {

    const minPerc = full ? hPercent : 212.16;

    const widths = [
        minPerc + 30.26,
        minPerc,
        progress / 100 * minPerc
    ];

    const [progressSpring] = useSprings(widths.length, widths.map((el, index) => ({
        to: {width: show ? el : 0},
        from: {width: show ? 0 : el},
        delay: (full ? ((index * 100) + 250) : 0),
        // duration: ((index + 1) * 100),
        immediate: show && !full,
    })), [show, hPercent, full, progress]);

    return <svg data-name="game-progress-bar">
        <g>
            <g>
                <animated.rect className="game-progress-bar-21" width={progressSpring[0].width.interpolate(n => n.toFixed(2))} height="35.33"/>
                <animated.rect className="game-progress-bar-2" x="23.28" y="7.62" width={progressSpring[1].width.interpolate(n => n.toFixed(2))} height="20.09"/>
            </g>
            <animated.rect className="game-progress-bar-22" x="23.28" y="7.62" width={progressSpring[2].width.interpolate(n => n.toFixed(2))} height="20.09"/>
        </g>
    </svg>;
}
