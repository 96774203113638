import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    home: false,
    sound: false,
    full: false,
    close: false,
    replay: false,
};
export const toolbarStateSlice = createSlice({
    name: "toolbarState",
    initialState: initialState,
    reducers: {
        setToolbar: (state, action) => ({
            ...initialState,
            ...action.payload,
        })
    }
});

export const {setToolbar} = toolbarStateSlice.actions;

export default toolbarStateSlice.reducer;
