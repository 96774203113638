import React from "react";
import {Hair1} from "./AvatarHair/Hair1";
import {Hair2} from "./AvatarHair/Hair2";
import {Hair3} from "./AvatarHair/Hair3";
import {Hair4} from "./AvatarHair/Hair4";
import {Hair5} from "./AvatarHair/Hair5";


export const AvatarHair = ({n=0}) => {


    const els = [
        Hair1,
        Hair2,
        Hair3,
        Hair4,
        Hair5,
    ]

    return <g >
        {
            (els[n] && React.createElement(els[n])) || null
        }
    </g>
};
