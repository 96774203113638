import React from "react";

export const AvatarPartChooserBtn = ({choose = () => <></>, n = 0, chooseProps}) => {
    return <g>
        <g>
            <path className="st8" d="M-125,100.8l58.6-214.1c0.4-1.4,1.9-2.2,3.3-1.7l186.5,58c1.3,0.4,2,1.7,1.8,3L100.3,113
			c-0.2,1.3-1.4,2.2-2.7,2.2l-220.3-11C-124.4,104.1-125.5,102.5-125,100.8z"/>
            {React.createElement(choose, {n, ...chooseProps})}
        </g>
    </g>;
}
