import {useDispatch, useSelector} from "react-redux";
import {changeAction, changeGroup, changeState, toNextState} from "../store/gameStateFlowSlice";


export const useGameStateFlow = () => {


    const gameStateFlow = useSelector(({gameStateFlow}) => gameStateFlow);

    const dispatch = useDispatch();

    const dispatchChangeState = state => dispatch(changeState(state));
    const dispatchChangeGroup = group => dispatch(changeGroup(group));
    const dispatchChangeAction = action => dispatch(changeAction(action));
    const dispatchNextState = () => dispatch(toNextState());

    return [
        gameStateFlow,
        {
            changeState: dispatchChangeState,
            changeGroup: dispatchChangeGroup,
            changeAction: dispatchChangeAction,
            toNextState(delay = 0) {
                const delayType = typeof delay;
                const appliedDelay = delayType === "number" ? delay : (delayType === "object" && delay.delay) || 0;
                return setTimeout(dispatchNextState, appliedDelay);
            },
        }
    ]
}
