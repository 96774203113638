import {GAME_COMPLETE_STATE_ACTION, LEVEL_COMPLETE_STATE_ACTION, LEVEL_END_SCREEN_STATE} from "../constants";
import {DIRECT_FORWARD, makePipeFlow} from "../utils/classes/PipeCall";

export const levelEndListeners = {
    [LEVEL_END_SCREEN_STATE]: {
        [LEVEL_COMPLETE_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .callFn([
                'configs.stateManager.setCompleted',
                'configs.stateManager.updatePlayerStateSave',
            ]),
        [GAME_COMPLETE_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .callFn([
                'configs.stateManager.setAsFinished',
                'configs.stateManager.updatePlayerStateSave',
            ]),
    }
};
