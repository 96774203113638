import {createContext} from "react";
import {useGameState} from "../hooks/useGameState";

export const GameStateContext = createContext({});

export const withGameState = Component => props => {
    const {generalState, set: setGameState, group: gameStateGroup, action: gameStateAction, onGameState, options, gameState} = useGameState();
    return <GameStateContext.Provider value={{
        gameState,
        generalState,
        setGameState,
        gameStateGroup,
        gameStateAction,
        onGameState,
        gameStateOptions: options
    }}>
        <GameStateContext.Consumer>
            {
                value => <Component {...props} {...value}/>
            }
        </GameStateContext.Consumer>
    </GameStateContext.Provider>;
}
export const OnGameStateCmp = ({group, action, children, fallback = null}) => {
    const {group: generalGroup, generalState: state, action: generalAction} = useGameState();

    if (typeof group !== 'string') {
        if (typeof action === 'string') {
            return (generalAction === action && children) || fallback;
        } else if (Array.isArray(action)) {
            return (action.includes(generalAction) && children) || fallback;
        }

    }
    if (typeof action !== 'string') {
        if (typeof group === 'string') {
            return (generalGroup === group && children) || fallback;
        } else if (Array.isArray(group)) {
            return (group.includes(generalGroup) && children) || fallback;
        }
    }

    return ([group, action].join(".") === state && children) || fallback;
}
export const NotOnGameStateCmp = ({group, children, action = null, fallback = <></>}) => {
    const {group: groupAct, generalState: state} = useGameState();
    const toShow = action ? [group, action].join(".") !== state : group !== groupAct;
    return (toShow && children) || fallback;
}
