import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loaded: false,
    played: false,
    playing: false,
    toRestart: false,
    firstPlay: true,
    noAudio: false,
    recordProgress: false,
};
export const levelSceneScreenStateSlice = createSlice({
    name: 'levelSceneState',
    initialState: initialState,
    reducers: {
        setLoaded: state => ({
            ...state,
            loaded: true
        }),
        play: state => ({
            ...state,
            playing: true,
            played: true,
            toRestart: false,
        }),
        pause: state => ({
            ...state,
            playing: false
        }),
        unload: state => ({
            ...state,
            ...initialState,
        }),
        restart: state => ({
            ...state,
            toRestart: true
        }),
        playedFirstTime: state => ({
                ...state,
                firstPlay: false
            }
        ),
        muteScene: state => ({
            ...state,
            noAudio: true
        }),
        unmuteScene: state => ({
            ...state,
            noAudio: false
        }),

        recordProgress: (state, action) => {
            state.recordProgress = action.payload;
        }
    }
});

export const {setLoaded, muteScene, unmuteScene, play, pause, unload, restart, playedFirstTime, recordProgress} = levelSceneScreenStateSlice.actions;

export default levelSceneScreenStateSlice.reducer;
