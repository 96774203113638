//region miscellaneous audio
import click_next_audio from './click_next.mp3';
import congrants_audio from './congrants.mp3';
import feelings_video_audio from './feelings_video.mp3';
import great_job_audio from './great_job.mp3';
import keep_going_audio from './keep_going.mp3';
import nice_job_audio from './nice_job.mp3';
import story_intro_audio from './story_intro.mp3';
import terrific_audio from './terrific.mp3';
import welcome_audio from './welcome.mp3';
import well_done_audio from './well_done.mp3';
import you_did_it_audio from './you_did_it.mp3';
import jingle_audio from './jingle.mp3';
//endregion

const all_audio = {
    audio_click_next: click_next_audio,
    audio_congrants: congrants_audio,
    audio_feelings_video: feelings_video_audio,
    audio_great_job: great_job_audio,
    audio_keep_going: keep_going_audio,
    audio_nice_job: nice_job_audio,
    audio_story_intro: story_intro_audio,
    audio_terrific: terrific_audio,
    audio_welcome: welcome_audio,
    audio_well_done: well_done_audio,
    audio_you_did_it: you_did_it_audio,
    audio_jingle: jingle_audio,

};
export default all_audio;
