export function TextField({
                              placeholder = '', value = '', disabled = false, onChange = () => {
    }, handleSubmit = () => {
    }, classes = ''
                          }) {
    return <g className="login-field">
        {/*<rect className="login-42" height="65" rx="22.5" width="297" x="1.5" y="1.5"/>*/}
        <foreignObject height="65" width="297">
            <form onSubmit={event => {
                event.preventDefault();

                handleSubmit();
            }
            }>
                <input className={classes} disabled={disabled} type="text" placeholder={placeholder} value={value} onChange={onChange}/>
            </form>
        </foreignObject>
    </g>;
}
