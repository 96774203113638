/**
 * @class LoadableAsset
 *
 * "loadable" resource
 */
export class LoadableAsset {
    /**
     *
     * @param assetsProperties
     */
    constructor(assetsProperties) {
        this._assetsProperties = assetsProperties;
    }

    /**
     *
     * @type {boolean}
     * @private
     */
    _isLoaded = false;

    /**
     *
     * @return {boolean}
     */
    get isLoaded() {
        return this._isLoaded;
    }

    /**
     *
     * @protected {*}
     */
    _assetsProperties;

    /**
     *
     * @return {*}
     */
    get assetsProperties() {
        return this._assetsProperties;
    }

    /**
     *
     * @type {string}
     * @protected
     */
    _assetType = 'genericLoadable';

    /**
     *
     * @return {string}
     */
    get assetType() {
        return this._assetType;
    }

    /**
     *
     * @type {*}
     * @protected
     */
    _asset = null;

    /**
     *
     * @return {*}
     */
    get asset() {
        return this._asset;
    }

    /**
     *
     * @type {boolean}
     * @private
     */
    _assetLoaded = false;

    /**
     *
     * @return {boolean}
     */
    get assetLoaded() {
        return this._assetLoaded;
    }


    /**
     * @typedef {Function} resolveFunction
     * @param {boolean} success
     */

    /**
     *
     * @param {resolveFunction} resolve
     * @param {Function} reject
     */
    loadAsset({resolve, reject}) {
        resolve(true);
    }

    onLoad(thenC) {
        this._promise.then(thenC);
    }

    /**
     * @class onLoadCallback
     * @param {unknown} value
     * @return {unknown|undefined|null}
     */

    _promise;

    /**
     *
     * @param thenFn
     * @param others
     */
    load(thenFn, ...others) {
        /**
         *
         * @type {Promise<boolean>}
         */
        this._promise = (new Promise((resolve, reject) => {
            if (this.isLoaded) {
                resolve(true);
            }
            else {
                this.loadAsset({resolve, reject});
            }
        })).then(loaded => {
            this._isLoaded = loaded;
            return loaded;
        }).then(thenFn);
        others.map(prom => this._promise.then(prom));
    }
}
