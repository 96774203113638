import React from "react";

export default function GameHeart({heartProps={},...props}) {
    return <g {...props}>
        <svg data-name="game-heart">
            <path className="game-heart" {...heartProps}
                  d="M49.14,14.85c-.33,3.66-1.8,7.29-3.38,10.9a41.74,41.74,0,0,1-4.82,8c-.49.67-.93,1.37-1.35,2.08A69.75,69.75,0,0,1,32,46a23.21,23.21,0,0,1-3.3,3.42,12.05,12.05,0,0,1-2.95,1.65A3.65,3.65,0,0,1,22,50.45a25.48,25.48,0,0,1-4.57-4.17c-1.91-2.38-4-4.61-5.93-7A61.41,61.41,0,0,1,4.64,28.92C3.88,27.45,3.1,26,2.3,24.55A19.9,19.9,0,0,1,0,15.29,22.25,22.25,0,0,1,.41,9.36,14.1,14.1,0,0,1,5.05,1.71,5.51,5.51,0,0,1,9.36.44,17.22,17.22,0,0,1,14,1.63a18.23,18.23,0,0,1,5.54,3,7.68,7.68,0,0,1,1.41,1.6c.68,1.09,1.63,2,2.28,3.11s.63,1,1.27,0a13.75,13.75,0,0,1,2.62-3.07,15.47,15.47,0,0,0,1.13-1.16A15.58,15.58,0,0,1,38.28.07a7.28,7.28,0,0,1,5.84,1.68,21,21,0,0,1,3.68,4,10.63,10.63,0,0,1,1.63,6.45c0,.79-.17,1.58-.29,2.68"/>
        </svg>
    </g>;
}
