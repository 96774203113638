import {useEffect} from "react";
import {useGameState} from "./useGameState";

export const useStateListener = (state, callback) => {
    const stateManager = useGameState();
    const {generalState} = stateManager;

    useEffect(() => {
        state === generalState && callback();
    }, [generalState, state, callback])
}
