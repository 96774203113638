import React from "react";

export const GoBtn = ({level = 1, title = "A class birthday party!"}) => {
    return <svg id="go-button" viewBox="-323.8 -54.8 647.6 109.5">
        <g>
            <polygon className="goBtn0" points="211.3,-44.7 -271.8,-38.6 -280.4,43.2 213.4,23.2"/>
        </g>
        <rect x="-221.8" y="-34.4" style={{fill: "none"}} width="384.1" height="61.8"/>
        {/*<text transform="matrix(1 0 0 -1 -174.6954 -19.7168)">{title}</text>*/}
        <foreignObject
            style={{
                transform: "matrix(1, 0, 0, -1, -205, 36)",
                width: 360,
                height: 100,
            }}
        >
            <div className="goBtn-text" xmlns="http://www.w3.org/1999/xhtml">{title}</div>
        </foreignObject>
        <g>
            <g>
                <g>
                    <path className="goBtn1" d="M-323.8,53.4l8.5-101.6c0.1-0.7,0.7-1.1,1.3-1.1l89.1,10.5c0.6,0.1,1.1,0.6,1.1,1.2L-221,40
					c0,0.6-0.4,1.1-1,1.2l-100.4,13.5C-323.2,54.9-323.9,54.2-323.8,53.4z"/>
                </g>
            </g>
            <rect x="-317" y="-46.4" style={
                {
                    fill: "none"
                }
            } width="91" height="88"/>
            <foreignObject transform="matrix(1, 0, 0, -1, -320.511, 60.6982)" className="goBtn4 goBtn163" width="100" height="100" style={{fontSize: 82.2929, color: 'white', textAlign: 'center'}}>
                <div xmlns="http://www.w3.org/1999/xhtml">{level}</div>
            </foreignObject>
            {/*<text transform="matrix(1 0 0 -1 -286.5106 -29.3018)" className="goBtn4 goBtn163" style={{fontSize: 82.2929}}>*/}
            {/*    */}
            {/*</text>*/}
        </g>
        <g>
            <g>
                <path className="goBtn2" d="M323.8,48.6l-1.8-102.1c-0.2-0.7-1.2-1.2-2.2-1.2L162.4-44.3c-0.9,0-9.6,0.5-9.5,1.1l3.7,67.8
				c0.1,0.6,8.8,1.2,9.7,1.3l155.5,23.9C323,49.9,324,49.3,323.8,48.6z"/>
            </g>
        </g>
        <text transform="matrix(1 0 0 -1 169.0134 -35.3486)">
            <tspan x="0" y="0" className="goBtn3 goBtn163 goBtn164">G</tspan>
            <tspan x="32.1" y="0" className="goBtn3 goBtn163 goBtn164 goBtn165">O!</tspan>
        </text>
        <path className="goBtn3" d="M304.9-0.8l-24.9,18.4c-7,5.1-12.8,3-13.8-4.6v-48.3c1-7.6,6.9-9.7,13.8-4.6l24.9,18.4
		C312.6-15.8,312.6-6.5,304.9-0.8z"/>
        <path className="goBtn4" d="M304,10.6l-24.9,18.4c-7,5.1-12.8,3-13.8-4.6v-48.3c1-7.6,6.9-9.7,13.8-4.6L304-10.1
		C311.7-4.4,311.7,4.9,304,10.6z"/>
    </svg>

}
