import {LoadableAsset} from "../LoadableAsset";

export class ComponentAsset extends LoadableAsset {

    _assetType = 'loadableComponent'

    loadAsset({resolve, reject}) {


        this.assetsProperties().then(value => {

            this._asset = value.default;

            return resolve(true);
        }).catch(reason => reject(reason));
    }
}
