import React from "react";
import Face1 from "./AvatarFace/Face1";
import Face2 from "./AvatarFace/Face2";
import Face3 from "./AvatarFace/Face3";
import Face4 from "./AvatarFace/Face4";
import Face5 from "./AvatarFace/Face5";
import Face6 from "./AvatarFace/Face6";


export const AvatarFace = ({n = 0}) => {

    const els = [
        Face6,
        Face5,
        Face4,
        Face3,
        Face2,
        Face1,
    ]

    return <g>
        {(els[n] && React.createElement(els[n])) || null}
    </g>
};
