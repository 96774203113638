import React from "react";

export const AvatarPrevPart = () => {
    return <g>
        <path className="st10" d="M-63.5,51.2l29.7-108.7c0.2-0.7,1-1.1,1.7-0.9L62.6-29c0.6,0.2,1,0.8,0.9,1.5L50.9,57.4
		c-0.1,0.7-0.7,1.1-1.4,1.1l-111.8-5.6C-63.1,52.9-63.7,52-63.5,51.2z"/>
        <g>
            <path className="st11" d="M-22.6,11.3L3.8,30.7c7.4,5.4,13.5,3.2,14.6-4.8v-51.1c-1.1-8-7.2-10.3-14.6-4.8l-26.4,19.4
			C-30.7-4.6-30.7,5.3-22.6,11.3z"/>
            <path className="st8" d="M-21.7,23.3L4.7,42.7c7.4,5.4,13.5,3.2,14.6-4.8v-51.1c-1.1-8-7.2-10.3-14.6-4.8L-21.7,1.4
			C-29.8,7.4-29.8,17.2-21.7,23.3z"/>
        </g>
    </g>
};
