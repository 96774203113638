import {createSlice} from "@reduxjs/toolkit";
import {shuffle} from "lodash";

export const raw_questions = [
    "What do you think Rolly is feeling right now?",
    "What should Rolly do?",
    "In your opinion, what is happening? What do you think Rolly should do?",
    "How do you think Rolly felt while watching its friends playing in the snow from the window?",
    "What do you think Rolly should do?",
    "What do you think Rolly is feeling?",
    "In your opinion, what is happening? What do you think Rolly should do?",
    "What do you think Rolly should do?",
    "What do you think Rolly is feeling?",
    "In your opinion, what is happening? What do you think Rolly is feeling?",
    "What do you think Rolly should do?",
    "What do you think Rolly is feeling?",
    "What do you think Rolly should do?",
    "In your opinion, what is happening? What will Rolly do?",
];

export const raw_answers = [
    ["Rolly is angry",
        "Rolly is sad",
        "Rolly doesn't care",
    ],
    ["Rolly asks an adult for help",
        "Rolly walks away in disgust!",
        "Rolly pushes Charlie away",
    ],
    ["Jamie is scared! Rolly should comfort her",
        "Jamie is hungry. Rolly should call an adult",
        "Rolly does not care and should walk away",
    ],
    ["Rolly felt sad",
        "Rolly felt angry ",
        "Rolly did not care",
    ],
    ["Rolly should share the dessert with Jaden",
        "Rolly should apologize to Jaden and eat the dessert in a big bite ",
        "Rolly should push Jaden away",
    ],
    ["Rolly is feeling afraid",
        "Rolly is feeling angry",
        "Rolly is feeling that nothing important is happening",
    ],

    ["Kai is sad because she is not playing with the other children. Rolly should invite her to play. ",
        "Kai has disagreed with someone, and she wants to be alone. Rolly should not get close to her.  ",
        "Nothing important is happening",],

    ["Rolly should close the window and start again ",
        "Rolly should ask an adult for help",
        "Rolly should kick the cards",],

    ["Rolly is feeling disgusted",
        "Rolly is feeling angry",
        "Nothing important is happening",],


    ["Micah is enjoying the birthday celebration with his classmates. Rolly should join them. ",
        "Friends are celebrating Micah’s birthday. It is nice, but Rolly should go home.",
        "Nothing important is happening. Rolly should do something else.",],

    ["Rolly should go to play with them",
        "Rolly should leave",
        "Rolly should kick the ball away from the group",
    ],
    ["Rolly is feeling happy",
        "Rolly is feeling sad",
        "Rolly is feeling that nothing important is happening",
    ],
    ["Rolly should turn on the light ",
        "Rolly should call an adult and ask for help",
        "Rolly should not do anything",
    ],
    ["Skylar is angry because they are teasing her. Rolly should approach Skylar to help her.",
        "Skylar is feeling afraid! Rolly should not get close.",
        "Nothing important is happening. Rolly should stay in place!",
    ],
];

export const raw_sounds = [
    [
        "questions.audio_01",
        "answers.audio_1_1",
        "answers.audio_1_2",
        "answers.audio_1_3",
    ],
    [
        "questions.audio_02",
        "answers.audio_2_1",
        "answers.audio_2_2",
        "answers.audio_2_3",
    ],
    [
        "questions.audio_03",
        "answers.audio_3_1",
        "answers.audio_3_2",
        "answers.audio_3_3",
    ],
    [
        "questions.audio_04",
        "answers.audio_4_1",
        "answers.audio_4_2",
        "answers.audio_4_3",
    ],
    [
        "questions.audio_05",
        "answers.audio_5_1",
        "answers.audio_5_2",
        "answers.audio_5_3",
    ],
    [
        "questions.audio_06",
        "answers.audio_6_1",
        "answers.audio_6_2",
        "answers.audio_6_3",
    ],
    [
        "questions.audio_07",
        "answers.audio_7_1",
        "answers.audio_7_2",
        "answers.audio_7_3",
    ],
    [
        "questions.audio_08",
        "answers.audio_8_1",
        "answers.audio_8_2",
        "answers.audio_8_3",
    ],
    [
        "questions.audio_09",
        "answers.audio_9_1",
        "answers.audio_9_2",
        "answers.audio_9_3",
    ],
    [
        "questions.audio_10",
        "answers.audio_10_1",
        "answers.audio_10_2",
        "answers.audio_10_3",
    ],
    [
        "questions.audio_11",
        "answers.audio_11_1",
        "answers.audio_11_2",
        "answers.audio_11_3",
    ],
    [
        "questions.audio_12",
        "answers.audio_12_1",
        "answers.audio_12_2",
        "answers.audio_12_3",
    ],
    [
        "questions.audio_13",
        "answers.audio_13_1",
        "answers.audio_13_2",
        "answers.audio_13_3",
    ],
    [
        "questions.audio_14",
        "answers.audio_14_1",
        "answers.audio_14_2",
        "answers.audio_14_3",
    ],
];

const initialState = {
    // TODO
    listened: [false, false, false, false],
    listening: null,
    choosed: null,
    question: "",
    answers: [],
    sounds: [],
};
export const answerSelectionScreenStateSlice = createSlice({
    name: 'answerSelectionScreenState',
    initialState: initialState,
    reducers: {
        initSelection(state, action) {
            const order = shuffle([0, 1, 2]);
            return {
                ...initialState,
                question: raw_questions[action.payload],
                answers: order.map(el => ({
                    text: raw_answers[action.payload][el],
                    value: el
                })),
                sounds: [raw_sounds[action.payload][0], ...order.map(el => raw_sounds[action.payload][el + 1])],
            };
        },
        listen: (state, action) => {
            state.listening = action.payload;
        },
        setListened: (state, action) => {
            const newListened = [...state.listened];

            newListened[action.payload] = true;

            state.listened = newListened;
        },
        choose: (state, action) => {
            state.choosed = action.payload
        }
    }
})

export const {initSelection, listen, setListened, choose} = answerSelectionScreenStateSlice.actions;

export default answerSelectionScreenStateSlice.reducer;
