import {LoadableAsset} from "../LoadableAsset";
import {soundManager} from 'soundmanager2';

let toLoadAssets = []

/**
 * @class SoundAsset
 */
export class SoundAsset extends LoadableAsset {

    _assetType = 'loadableSound';
    _multiShot = false;
    _id = null;

    /**
     *
     * @param {Record<string,*>|{url:string}} assetProperties
     */
    constructor(assetProperties) {
        super(assetProperties);

        this._multiShot = assetProperties?.multiShot ?? false;
    }

    get isPlaying() {
        return (this?.asset?.playState ?? 0) === 1
    }

    queueLoad(soundAsset, {resolve, reject}) {
        toLoadAssets.push(() => {

            soundAsset.createSound();

            soundAsset.asset.load({
                onload: success => {


                    toLoadAssets.shift();

                    this.executeNextInQueue();

                    return resolve(success);
                },
                onerror: (n, s) => {
                    return reject({n, s});
                },
            })
        });
        if (toLoadAssets.length <= 1) {
            this.executeNextInQueue();
        }
    }

    executeNextInQueue() {
        if (toLoadAssets.length > 0) {
            const next = toLoadAssets[0];
            next();
        }

    }

    createSound() {
        this._asset = soundManager.createSound(this.assetsProperties);

    }

    loadAsset({resolve, reject}) {




        if (!this.isLoaded) {
            this.queueLoad(this, {reject, resolve});
        }

    }

    play(options = {}) {
        this.asset?.play(options);


        return this;
    }

    pause() {
        this.asset?.pause();
        return this;
    }

    stop() {
        this.asset?.stop();
        return this;
    }

    restart(options) {
        this.stop();
        this.play(options);
        return this;
    }

    loop() {
        this.asset?.play({
            onfinish: () => {
                this.loop();
            }
        });
        return this;
    }

    resume() {
        this.asset?.resume();
    }

    setVolume(volume) {
        // if (volume === 0) {
        //     this.asset?.mute(this._id);
        // } else {
        this.asset?.setVolume(volume, this._id);
        // }
        return this;
    }

    on(event, callback) {
        this.asset?.play({
            [event]: callback
        });

        return this;
    }
}
