import {AVATAR_CHOOSED_STATE_ACTION, AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE} from "../constants";
import {DIRECT_FORWARD, makePipeFlow} from "../utils/classes/PipeCall";

export const avatarScreenListeners = {
    [AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE]: {
        [AVATAR_CHOOSED_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .setArgs(({configs: {stateManager: {avatar}}}) => avatar)
            .callFn('configs.stateManager.chooseAvatar')
    }
};
