export function SelyLogo() {
    return <symbol data-name="sely-logo" id="sely-logo" viewBox="0 0 128.65 85">
        <path className="login-37"
              d="M2.28,58.12A3.89,3.89,0,0,1,5.45,51a21.39,21.39,0,0,0,8.67,1.79c3.79,0,6.59-.93,6.59-2.71,0-2-3.51-2.65-6-3.08C8.61,45.94.53,43.5.53,35.49.53,27.26,9,24,15.2,24a31.54,31.54,0,0,1,11.47,2.65,3.9,3.9,0,0,1-2.89,7.25,25.55,25.55,0,0,0-8.51-1.82c-3.58,0-5.44,1.36-5.44,3.08,0,2.15,3.15,2.86,4.87,3.22C22.49,40.07,30,42,30,49.58,30,58.17,20.35,61,14.34,61A30.67,30.67,0,0,1,2.28,58.12Z"/>
        <path className="login-38"
              d="M50.5,24.35A18.14,18.14,0,0,0,32.61,42.79c0,11.82,7.5,18.48,19.26,18.48a34.35,34.35,0,0,0,11.08-2,4,4,0,0,0-1.31-7.85,4,4,0,0,0-1.3.22,27.19,27.19,0,0,1-8.48,1.54c-5,0-8.46-1.74-9.9-5.57H64.11a4.26,4.26,0,0,0,4.27-4.17c0-.23,0-.45,0-.67A18.15,18.15,0,0,0,50.5,24.35ZM41.61,39.74a9.47,9.47,0,0,1,8.89-7.3c4.2,0,7.73,3.07,8.9,7.3Z"/>
        <path className="login-39" d="M71.69,56.27V14.76a4.3,4.3,0,0,1,8.59,0V56.27a4.3,4.3,0,0,1-8.59,0Z"/>
        <path className="login-40"
              d="M124.82,32.89,97.88,82.74a4.3,4.3,0,0,1-7.57-4.06l10.28-18.86L86.07,32.89a4.29,4.29,0,1,1,7.57-4.05l11.82,21.91,11.79-21.91a4.29,4.29,0,0,1,7.57,4.05Z"/>
        <path className="login-41"
              d="M124,3.51a14.23,14.23,0,0,0-18.53-.16,14.23,14.23,0,0,0-18.55.18c-5.18,4.55-6.15,12.34-2.31,18.53l0,0a3.12,3.12,0,0,0,2.66,1.49h0a3.14,3.14,0,0,0,2.88-4.39l-.88-2a8,8,0,0,1,1.86-8.83,7.81,7.81,0,0,1,10.67.34l3.64,3.66L109,8.8a8.1,8.1,0,0,1,3.32-2.05,7.82,7.82,0,0,1,7.46,1.58,8,8,0,0,1,1.88,8.84h0l-.87,2a3.14,3.14,0,0,0,2.88,4.39h0a3.13,3.13,0,0,0,2.66-1.48l0,0C130.16,15.86,129.17,8.06,124,3.51Z"/>
    </symbol>;
}
