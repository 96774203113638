import '../../assets/scss/level-selection-sreen.scss';
import {animated, useSpring} from 'react-spring';
import React, {useEffect, useRef, useState} from "react";
import lottie from "lottie-web";
import {LockSym} from "../partials/LockSym";
import {GameBtn} from "../partials/GameBtn";
import {NotOnGameStateCmp} from "../../context/GameStateContext";
import {ComponentAssets} from "../AssetsManager/Proxy";
import {useSelector} from "react-redux";
import AvatarGameFrame from "../partials/AvatarGameFrame";
import {AVATAR_CHANGE_STATE_ACTION, IDLE_STATE_ACTION, LEVEL_SELECTION_SCREEN_STATE, LOCKED_STATE_ACTION, NEXT_SCENE_STATE_ACTION, PLAY_SCENE_STATE_ACTION, PREV_SCENE_STATE_ACTION, UNLOCK_STATE_ACTION, UNLOCKED_STATE_ACTION, UNLOCKING_STATE_ACTION} from "../../constants";
import {useGameActionSetterCreator} from "../../hooks/useGameActionSetter";
import {scenesTitle} from "../../constants/assets";
import {makePipeFlow} from "../../utils/classes/PipeCall";
import {SubscribingState} from "../../context/SubscribingState";
import {ScenePreloader} from "../partials/ScenePreloader";
import {GoBtn} from "../partials/GoBtn";
import {LevelSelectionSplashText} from "../partials/LevelSelectionSplashText";
import {useDebuggerR} from "../../hooks/useDebuggerR";

const LevelSelectionScreen = () => {
    useDebuggerR("init LevelSelectionScreen");

    const [animationElement, setAnimationElement] = useState(null);

    const {state: sceneState, choosed: sceneChoosed} = useSelector(({levelSelectionScreenState}) => levelSelectionScreenState);
    const disabled = useSelector(({generalState}) => !generalState.canInteract);

    const [opacityFilter, opacityFilterApi] = useSpring(() => ({
        from: {
            opacity: .3
        },
        immediate: true
    }));

    const {playScene, nextScene, prevScene, toAvatar} =
        useGameActionSetterCreator(
            {
                playScene: PLAY_SCENE_STATE_ACTION,
                nextScene: NEXT_SCENE_STATE_ACTION,
                prevScene: PREV_SCENE_STATE_ACTION,
                toAvatar: AVATAR_CHANGE_STATE_ACTION,
            }
        );


    const sceneContainer = useRef();

    const sceneRef = useRef();

    const previewRef = useRef();

    const setAnimationBox = (animationToBox, refBox) => {
        if (animationToBox?.renderer?.svgElement && sceneRef) {
            const bBox = refBox.current.getBBox();
            const width = bBox.width;
            const height = bBox.height;
            animationToBox.renderer.svgElement.setAttribute("width", width);
            animationToBox.renderer.svgElement.setAttribute("height", height);
            // animationToBox.show();
            previewRef.current.setAttribute("width", width);
            previewRef.current.setAttribute("height", height);
            previewRef.current.style.transform = 'translate(0px,-25px) scale(1.1)';
        }
    }

    const prepareScene = (sceneAsset, preview) => {
        const params = {
            container: sceneContainer.current,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: sceneAsset.asset,
            assetsPath: sceneAsset.assetsPath ?? ''
        };
        sceneContainer.current?.children?.[0]?.remove();
        const animationItem = lottie.loadAnimation(params);
        animationElement && animationElement.destroy() && animationElement.hide();
        animationItem.hide();
        setAnimationElement(animationItem);
        setScenePreview(preview.asset.src);
    }

    const listeners = {
        [LEVEL_SELECTION_SCREEN_STATE]: {
            [IDLE_STATE_ACTION]: makePipeFlow()
                .add(({configs: {writeD, assetsManager: {get}, stateManager: {levelSelectionScreenState: {choosed: sceneChoosed, state: sceneState, previews, available: scenesAvailable}, changeAction}}}) => {
                    const sceneAsset = get(scenesAvailable[sceneChoosed]);
                    const preview = get(previews[sceneChoosed]);

                    writeD("attendo il preload della scena", LEVEL_SELECTION_SCREEN_STATE);

                    sceneAsset.onLoad(res => {
                        writeD("preload avvenuto", LEVEL_SELECTION_SCREEN_STATE);
                        writeD("preparo la scena", LEVEL_SELECTION_SCREEN_STATE);
                        prepareScene(sceneAsset, preview);
                    });

                }),
            [UNLOCK_STATE_ACTION]: ({stateManager: {changeAction, setUnlocked}}) => {
                opacityFilterApi.start({
                    from: {
                        opacity: .3
                    },
                    to: {
                        opacity: 1
                    },
                    config: {
                        duration: 1500,
                    },
                    onRest({finished}) {
                        setUnlocked();
                        changeAction(UNLOCKED_STATE_ACTION);
                    },
                    onStart: result => {

                    }
                });
            },
            [UNLOCKED_STATE_ACTION]: () => {
                opacityFilterApi.start({
                    to: {
                        opacity: 1
                    },
                    immediate: true
                })
            },
            [LOCKED_STATE_ACTION]: () => {
                opacityFilterApi.start({
                    to: {
                        opacity: .3
                    },
                    immediate: true
                })
            },
        }
    };

    useEffect(() => {
        setAnimationBox(animationElement, sceneRef)
    }, [animationElement, sceneRef]);

    const [scenePreview, setScenePreview] = useState(null);


    return <SubscribingState listeners={listeners}>
        <svg className="level-selection-screen" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1920 1080">
            <g width="1529.25" height="860.25" transform="translate(184.86 112.21)">
                <ComponentAssets.GameFrameBackground/>
            </g>
            <g width="1000" height="605" viewBox="0 0 1000 605" transform="translate(432 281.12)">
                <g>
                    <g ref={sceneRef}>
                        <ComponentAssets.LevelFrame/>
                    </g>
                    <mask id="myMask">
                        <polygon fill="white" points="982.15 530.62 18.8 588.93 18.8 0 1000.78 39.65 982.15 530.62"/>
                    </mask>
                    <animated.g mask="url(#myMask)" style={opacityFilter}>
                        <g style={{
                            transform: 'translate(0px,-25px) scale(1.1)',
                        }} ref={sceneContainer}/>
                        <image ref={previewRef} href={scenePreview} id="game-preview"/>
                    </animated.g>
                    <ScenePreloader/>
                </g>


            </g>
            <g width="1000.78" height="604.93" transform="translate(432 281.12)">
                <NotOnGameStateCmp group="level-selection-screen" action="locked">
                    <polygon
                        onMouseEnter={() => (animationElement && animationElement.play())}
                        onMouseLeave={() => (animationElement && animationElement.stop())}
                        onTouchStart={() => (animationElement && animationElement.play())}
                        onTouchEnd={() => (animationElement && animationElement.stop())}
                        // style={{cursor: gameAction === 'unlocked' ? 'pointer' : 'default',}}
                        fill="black"
                        opacity={0}
                        points="982.15 530.62 18.8 588.93 18.8 0 1000.78 39.65 982.15 530.62"
                    />
                </NotOnGameStateCmp>
            </g>

            <g width="1539.81" height="871.64" transform="translate(179.58 106.52)">
                <ComponentAssets.GameFrame/>
            </g>
            <GameBtn onClick={() => toAvatar()}>
                <g transform="matrix(1,0,0,1,0,0)">
                    <g width="209" height="209" transform="translate(183.87 764.53)">
                        <AvatarGameFrame/>
                    </g>
                </g>
            </GameBtn>
            <g width="91.67" height="115.1" transform="translate(1344.41 777.07)">
                <LockSym
                    unlocked={sceneState === UNLOCKED_STATE_ACTION}
                    locked={sceneState === LOCKED_STATE_ACTION || sceneState === 'unlockable'}
                    unlocking={sceneState === UNLOCKING_STATE_ACTION}
                />
            </g>
            <g onClick={() => !disabled && prevScene()} width="182.32" height="167.77" transform="translate(358.26 479.71)">
                <GameBtn disabled={disabled}>
                    <ComponentAssets.PrevLevelButton/>
                </GameBtn>
            </g>
            <g onClick={() => !disabled && nextScene()} width="182.32" height="167.77" transform="translate(1315.68 479.71)">
                <GameBtn disabled={disabled}>
                    <ComponentAssets.NextLevelButton/>
                </GameBtn>
            </g>
            <g transform="matrix(0.4, 0, 0, -0.4, 545, 1045)" style={{overflow: "visible"}}>
                <GameBtn onClick={() => (sceneState === 'unlocked' && playScene())} dropX={-10} dropY={-10}>
                    <GoBtn level={sceneChoosed + 1} title={scenesTitle[sceneChoosed]}/>
                </GameBtn>
            </g>

            <LevelSelectionSplashText/>
        </svg>
    </SubscribingState>

}

export default LevelSelectionScreen;
