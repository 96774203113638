import React from "react";

export const AvatarHeart = ({n = 0}) => {

    const els = [
        "#FF2DB4",
        "#F2E10D",
        "#1111EF",
        "#7C9E1D",
        "#BC130E",
        "#DD7E1B",

    ]

    return <g>
        <path fill={els[n]} d="M10.7,4.1c0-2.8-0.6-5.5-2.2-7.8c-1.3-2-2.9-3.8-4-5.9c-1.4-2.6-5.1-3.2-7.6-0.9C-8.4-6.1-10.8,0-10.7,6.9
		c0,1.7,1.1,3.2,2.9,3.6c1.9,0.5,3.3-0.6,4.7-1.7c1-0.9,1.1-3.1,2.7-2.9C0.7,6.1,0.9,8.1,1.8,9c0.6,0.6,1.2,1.4,1.9,1.9
		c2.6,2,5.1,1.2,6.3-1.9C10.6,7.4,10.7,5.7,10.7,4.1z"/>
    </g>
};
