import React, {useEffect, useRef, useState} from "react";
import {animated, config, useSpring, useSprings} from "react-spring";
import GameHeart from "./GameHeart";

export const SplashLoader = () => {
    const colors = ['#be120d', '#1d76ce', '#ff9d00', '#be120d', '#1d76ce', '#ff9d00', '#be120d', '#1d76ce', '#ff9d00'];

    const [flip, setFlip] = useState(false);

    const [mounted, setMounted] = useState(false);


    const scatter = 200;
    const beatAnimation = useSprings(colors.length, colors.map((el, index) => ({
        from: {
            transform: 'matrix(0,0,0,0,75,75)',
            opacity: 2
        },
        to: {
            transform: `matrix(3,0,0,3,${Math.ceil(Math.random() * scatter) - Math.ceil(Math.random() * scatter)},${Math.ceil(Math.random() * scatter) - Math.ceil(Math.random() * scatter)})`,
            opacity: 0
        },
        delay: flip ? 0 : (index * 250),
        config: config.slow,
        onRest: ({finished}) => {
            if (mounted && finished && index >= colors.length - 1) {
                setFlip(!flip);
            }
        },
        immediate: flip,
        reverse: flip,
    })));

    const AnimGameHeart = animated(GameHeart);

    const opSpring = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        },
    });

    const svgRef = useRef();

    useEffect(() => {
        setMounted(true);
        return () => {
            setMounted(false);
        }
    }, [svgRef])


    return <svg viewBox="0 0 1920 1080" ref={svgRef}>
        <animated.g style={opSpring}>
            <rect width={1920} height={1080} fill="#00c0ed" opacity=".5"/>
            <g transform="translate(885 465)">
                <g>
                    {beatAnimation.map((el, index) => <AnimGameHeart key={index} style={el} heartProps={{
                        style: {
                            fill: colors[index]
                        }
                    }}/>)}
                </g>
            </g>
        </animated.g>
    </svg>

}
