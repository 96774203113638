import React from "react";

export const FontPreloader = () => <div
    style={{
        opacity: 0,
        width: 1,
        height: 1,
        position: "fixed",
        zIndex: -1,
        fontFamily: "LondrinaSolid-Regular, Londrina Solid"
    }}
>test</div>;
