import '../../assets/scss/level-end-screen.scss';
import GameFrameBackground from "../partials/GameFrameBackground";
import AvatarGameFrame from "../partials/AvatarGameFrame";
import GameFrame from "../partials/GameFrame";
import React, {useEffect, useState} from "react";
import {animated, useSpring} from "react-spring";
import {GameBtn} from "../partials/GameBtn";
import {AUDIO_GREAT_JOB, AUDIO_KEEP_GOING, AUDIO_NICE_JOB, AUDIO_TERRIFIC, AUDIO_WELL_DONE, AUDIO_YOU_DID_IT, ENTERING_STATE_ACTION, GAME_COMPLETE_STATE_ACTION, IDLE_STATE_ACTION, LEVEL_COMPLETE_STATE_ACTION, LEVEL_END_SCREEN_STATE} from "../../constants";
import {useSelector} from "react-redux";
import {useGameActionSetter} from "../../hooks/useGameActionSetter";
import {SubscribingState} from "../../context/SubscribingState";

function ContinueButton() {
    return <g id="continue-btn" data-name="continue-btn">
        <path className="cls-19" d="M.05,6.21,25.39,98.86a1.14,1.14,0,0,0,1.42.75L225.3,74.53a1.15,1.15,0,0,0,.79-1.28L215.37.94A1.15,1.15,0,0,0,214.19,0L1.08,4.75A1.13,1.13,0,0,0,.05,6.21Z"/>
        <g>
            <path className="cls-20"
                  d="M92.48,73.73a22.86,22.86,0,0,1-2.66-.19,21.31,21.31,0,0,0-2.67-.2q-1.91-5.7-3.8-11.08T80.14,52l-.58-2.32c-.16-.57-.3-1.09-.44-1.58s-.24-.74-.32-.74a12,12,0,0,0-.14,1.86q-.09,1.84-.15,4.71c0,1.9-.06,4-.08,6.33s0,4.55,0,6.72v3.43q0,1.66.06,3a5.43,5.43,0,0,1-1.28.31,11,11,0,0,1-1.27.08,18.07,18.07,0,0,1-2.82-.23,16.37,16.37,0,0,0-2.75-.23V67.86q0-7.34-.09-14T70.17,40c0-3.55,0-7.07.11-10.54s.21-6.86.41-10.15a8.79,8.79,0,0,1,1.24-.24A12.32,12.32,0,0,1,73.3,19c.23,0,.61,0,1.16,0a14.78,14.78,0,0,1,1.62.15c.54.08,1,.17,1.42.27s.61.21.61.31c.27.93.52,1.7.75,2.32l.67,1.77.69,1.89c.25.7.56,1.59.9,2.67.08.25.25.74.52,1.46l.93,2.51c.35,1,.72,2,1.1,3s.75,2,1.08,2.85.6,1.57.84,2.16a3.38,3.38,0,0,0,.4.89,3.71,3.71,0,0,0,.29-1.54q.12-1.4.12-2.7,0-3.09-.09-5.76c-.06-1.77-.09-3.59-.09-5.44,0-1.08,0-2.17,0-3.28s0-2.31.09-3.59a18.06,18.06,0,0,1,4.17-.47,14.39,14.39,0,0,1,3.83.47V43.85q.06,3.24.06,6.56v6.64c0,1.5,0,2.94,0,4.33s0,2.8,0,4.24q0,1.55,0,3.09t0,3.09v1.54a3,3,0,0,1-.52.24A4.57,4.57,0,0,1,92.48,73.73Z"/>
            <path className="cls-20"
                  d="M105.82,54v1.39c0,1.55.08,2.81.14,3.79a7.8,7.8,0,0,0,.73,2.85,5.37,5.37,0,0,0,2,2.63,3,3,0,0,0,2.49.54,3.42,3.42,0,0,0,2.06-1.89,9.68,9.68,0,0,0,.72-4.75,10.54,10.54,0,0,0,1.57,0l2.06-.12a20.31,20.31,0,0,1,2,0,5.81,5.81,0,0,1,1.63.27c-.08,1.34-.16,2.66-.24,3.94s-.17,2.49-.29,3.59a26.31,26.31,0,0,1-.43,2.86,3.81,3.81,0,0,1-.67,1.66,5.83,5.83,0,0,1-2.55,2.62,9.23,9.23,0,0,1-3.42.93,24,24,0,0,1-3.79,0q-1.95-.15-3.57-.15a7,7,0,0,1-3-1.39,11.36,11.36,0,0,1-2.61-3.83A27.25,27.25,0,0,1,99,63.73a39.28,39.28,0,0,1-.87-6A55.73,55.73,0,0,1,98,51.42c.12-1.91.24-3.75.38-5.52a26.89,26.89,0,0,1,.9-5.22,9.83,9.83,0,0,1,2.31-3.82,10.81,10.81,0,0,1,3.54-2.39,12.22,12.22,0,0,1,8.46-.27,7.84,7.84,0,0,1,4.29,2.47,12.47,12.47,0,0,1,2.47,4.48,22.86,22.86,0,0,1,1,5.79,57.11,57.11,0,0,1,0,6.41,9,9,0,0,1-1.53.31q-1.14.15-2.67.3c-1,.11-2.12.19-3.28.24s-2.27.07-3.33.07-2,0-2.87-.07A9.37,9.37,0,0,1,105.82,54Zm7-12a3.64,3.64,0,0,0-.93-.58,2.81,2.81,0,0,0-1.1-.2,5.56,5.56,0,0,0-2.26.39,3.51,3.51,0,0,0-1.39,1.08,4.69,4.69,0,0,0-.78,1.62,12,12,0,0,0-.38,1.93,6.21,6.21,0,0,0-.09,1.2c0,.23.21.39.55.46a6.89,6.89,0,0,0,1.66.08c.75,0,1.86,0,3.33,0,1,0,1.76,0,2.26.08a6.21,6.21,0,0,0,1.07,0c.21,0,.32-.1.32-.23V47a7.2,7.2,0,0,0-.61-2.94A4.55,4.55,0,0,0,112.83,42Z"/>
            <path className="cls-20"
                  d="M145.93,34.74q-1.16,4.78-2.64,9.69a60.85,60.85,0,0,0-2.11,9.92,75.8,75.8,0,0,0,2,9.57q1.33,5,2.14,9.42a2,2,0,0,0-.52-.07h-.52c-.43,0-.89,0-1.39,0l-1.45,0h-.9l-1.39,0-1.39-.08a3.24,3.24,0,0,1-.84-.12h-.06a20.82,20.82,0,0,1-.78-2.89c-.22-1.06-.42-2.13-.61-3.21s-.4-2.15-.61-3.2a21,21,0,0,0-.78-2.9v-.07a38.34,38.34,0,0,0-1.57,6.44A33.84,33.84,0,0,1,131,73.42h-.81c-.38,0-.81,0-1.27,0s-.9,0-1.31,0h-.84c-.62,0-1.34,0-2.17,0a4.92,4.92,0,0,1-2-.42c.27-1.34.61-2.8,1-4.37s.82-3.16,1.25-4.78.83-3.26,1.22-4.91.71-3.21,1-4.71a74.19,74.19,0,0,0-2.11-9.73L122.16,35c1.74-.15,3.09-.23,4.06-.23h3a10.71,10.71,0,0,1,1.36.08,1.61,1.61,0,0,1,.64.15q.22,1.16.6,3c.26,1.21.51,2.45.76,3.71s.49,2.48.72,3.67.45,2.11.64,2.78c.23-.72.47-1.66.73-2.82s.5-2.38.75-3.67.48-2.53.69-3.74.42-2.21.61-3a15.06,15.06,0,0,1,1.6-.11l2.37-.08c.87,0,1.77,0,2.7,0H146C146,34.71,146,34.74,145.93,34.74Z"/>
            <path className="cls-20"
                  d="M158.45,20.53V30.88c0,.67,0,1.36,0,2.08s0,1.47,0,2.24c1.2-.05,2.46-.13,3.8-.23l4.95-.39a3.71,3.71,0,0,1,.47,1.82c0,.8,0,1.66,0,2.59s-.09,1.86-.15,2.81a13.75,13.75,0,0,0,0,2.44h-9v8.14c0,2.65,0,5.14.06,7.45a4.23,4.23,0,0,0,1.68,1.7,11.1,11.1,0,0,0,2.38,1,12.63,12.63,0,0,0,2.58.42,17.42,17.42,0,0,0,2.29,0c.08.72.15,1.52.23,2.39S168,67.09,168,68s0,1.85,0,2.78a14.59,14.59,0,0,1-.38,2.54,33.67,33.67,0,0,1-4.81,0,21.27,21.27,0,0,1-4.7-.89,11.78,11.78,0,0,1-3.91-2.09,7.63,7.63,0,0,1-2.46-3.78,104.47,104.47,0,0,1-.87-11.23q-.12-5.3-.06-10.93c-.62,0-1.27.08-1.94.08h-2.23l.17-9.42c.77.05,1.5.07,2.17.07h1.94c0-1.13,0-2.35,0-3.66s-.06-2.64-.06-4,0-2.62,0-3.86.07-2.34.14-3.32a5.94,5.94,0,0,1,1.65-.08c.6.05,1.23.12,1.89.2s1.31.14,2,.19A8.14,8.14,0,0,0,158.45,20.53Z"/>
        </g>
        <g>
            <path className="cls-2"
                  d="M92.48,66.73a22.86,22.86,0,0,1-2.66-.19,21.31,21.31,0,0,0-2.67-.2q-1.91-5.7-3.8-11.08T80.14,45l-.58-2.32c-.16-.57-.3-1.09-.44-1.58s-.24-.74-.32-.74a12,12,0,0,0-.14,1.86q-.09,1.84-.15,4.71c0,1.9-.06,4-.08,6.33s0,4.55,0,6.72v3.43q0,1.67.06,3a5.43,5.43,0,0,1-1.28.31,11,11,0,0,1-1.27.08,18.07,18.07,0,0,1-2.82-.23,16.37,16.37,0,0,0-2.75-.23V60.86q0-7.34-.09-14T70.17,33c0-3.55,0-7.07.11-10.54s.21-6.86.41-10.15a8.79,8.79,0,0,1,1.24-.24A12.32,12.32,0,0,1,73.3,12c.23,0,.61,0,1.16,0a14.78,14.78,0,0,1,1.62.15c.54.08,1,.17,1.42.27s.61.21.61.31c.27.93.52,1.7.75,2.32l.67,1.77.69,1.89c.25.7.56,1.59.9,2.67.08.25.25.74.52,1.46l.93,2.51c.35,1,.72,2,1.1,3s.75,2,1.08,2.85.6,1.57.84,2.16a3.38,3.38,0,0,0,.4.89,3.71,3.71,0,0,0,.29-1.54q.12-1.39.12-2.7,0-3.09-.09-5.76c-.06-1.77-.09-3.59-.09-5.44,0-1.08,0-2.17,0-3.28s0-2.31.09-3.59a18.06,18.06,0,0,1,4.17-.47,14.39,14.39,0,0,1,3.83.47V36.85q.06,3.24.06,6.56v6.64c0,1.5,0,2.94,0,4.33s0,2.8,0,4.24q0,1.55,0,3.09t0,3.09v1.54a3,3,0,0,1-.52.24A4.57,4.57,0,0,1,92.48,66.73Z"/>
            <path className="cls-2"
                  d="M105.82,47v1.39c0,1.55.08,2.81.14,3.79a7.8,7.8,0,0,0,.73,2.85,5.37,5.37,0,0,0,2,2.63,3,3,0,0,0,2.49.54,3.42,3.42,0,0,0,2.06-1.89,9.68,9.68,0,0,0,.72-4.75,10.54,10.54,0,0,0,1.57,0l2.06-.12a20.31,20.31,0,0,1,2,0,5.81,5.81,0,0,1,1.63.27c-.08,1.34-.16,2.66-.24,3.94s-.17,2.49-.29,3.59a26.31,26.31,0,0,1-.43,2.86,3.81,3.81,0,0,1-.67,1.66,5.83,5.83,0,0,1-2.55,2.62,9.23,9.23,0,0,1-3.42.93,24,24,0,0,1-3.79,0q-1.95-.15-3.57-.15a7,7,0,0,1-3-1.39,11.36,11.36,0,0,1-2.61-3.83A27.25,27.25,0,0,1,99,56.73a39.28,39.28,0,0,1-.87-6A55.73,55.73,0,0,1,98,44.42c.12-1.91.24-3.75.38-5.52a26.89,26.89,0,0,1,.9-5.22,9.83,9.83,0,0,1,2.31-3.82,10.81,10.81,0,0,1,3.54-2.39,12.22,12.22,0,0,1,8.46-.27,7.84,7.84,0,0,1,4.29,2.47,12.47,12.47,0,0,1,2.47,4.48,22.86,22.86,0,0,1,1,5.79,57.11,57.11,0,0,1,0,6.41,9,9,0,0,1-1.53.31q-1.14.15-2.67.3c-1,.11-2.12.19-3.28.24s-2.27.07-3.33.07-2,0-2.87-.07A9.37,9.37,0,0,1,105.82,47Zm7-12a3.64,3.64,0,0,0-.93-.58,2.81,2.81,0,0,0-1.1-.2,5.56,5.56,0,0,0-2.26.39,3.51,3.51,0,0,0-1.39,1.08,4.69,4.69,0,0,0-.78,1.62,12,12,0,0,0-.38,1.93,6.21,6.21,0,0,0-.09,1.2c0,.23.21.39.55.46a6.89,6.89,0,0,0,1.66.08c.75,0,1.86,0,3.33,0,1,0,1.76,0,2.26.08a6.21,6.21,0,0,0,1.07,0c.21,0,.32-.1.32-.23V40a7.2,7.2,0,0,0-.61-2.94A4.55,4.55,0,0,0,112.83,35Z"/>
            <path className="cls-2"
                  d="M145.93,27.74q-1.16,4.79-2.64,9.69a60.85,60.85,0,0,0-2.11,9.92,75.8,75.8,0,0,0,2,9.57q1.33,4.95,2.14,9.42a2,2,0,0,0-.52-.07h-.52c-.43,0-.89,0-1.39,0l-1.45,0h-.9l-1.39,0-1.39-.08a3.24,3.24,0,0,1-.84-.12h-.06a20.82,20.82,0,0,1-.78-2.89c-.22-1.06-.42-2.13-.61-3.21s-.4-2.15-.61-3.2a21,21,0,0,0-.78-2.9v-.07a38.34,38.34,0,0,0-1.57,6.44A33.84,33.84,0,0,1,131,66.42h-.81c-.38,0-.81,0-1.27,0s-.9,0-1.31,0h-.84c-.62,0-1.34,0-2.17,0a4.92,4.92,0,0,1-2-.42c.27-1.34.61-2.8,1-4.37s.82-3.16,1.25-4.78.83-3.26,1.22-4.91.71-3.21,1-4.71a74.19,74.19,0,0,0-2.11-9.73L122.16,28c1.74-.15,3.09-.23,4.06-.23h3a10.71,10.71,0,0,1,1.36.08,1.61,1.61,0,0,1,.64.15q.22,1.16.6,3c.26,1.21.51,2.45.76,3.71s.49,2.48.72,3.67.45,2.11.64,2.78c.23-.72.47-1.66.73-2.82s.5-2.38.75-3.67.48-2.53.69-3.74.42-2.21.61-3a15.06,15.06,0,0,1,1.6-.11l2.37-.08c.87,0,1.77,0,2.7,0H146C146,27.71,146,27.74,145.93,27.74Z"/>
            <path className="cls-2"
                  d="M158.45,13.53V23.88c0,.67,0,1.36,0,2.08s0,1.47,0,2.24c1.2-.05,2.46-.13,3.8-.23l4.95-.39a3.71,3.71,0,0,1,.47,1.82c0,.8,0,1.66,0,2.59s-.09,1.86-.15,2.81a13.75,13.75,0,0,0,0,2.44h-9v8.14c0,2.65,0,5.14.06,7.45a4.23,4.23,0,0,0,1.68,1.7,11.1,11.1,0,0,0,2.38,1,12.63,12.63,0,0,0,2.58.42,17.42,17.42,0,0,0,2.29,0c.08.72.15,1.52.23,2.39S168,60.09,168,61s0,1.85,0,2.78a14.59,14.59,0,0,1-.38,2.54,33.67,33.67,0,0,1-4.81,0,21.27,21.27,0,0,1-4.7-.89,11.78,11.78,0,0,1-3.91-2.09,7.63,7.63,0,0,1-2.46-3.78,104.47,104.47,0,0,1-.87-11.23q-.12-5.3-.06-10.93c-.62,0-1.27.08-1.94.08h-2.23l.17-9.42c.77.05,1.5.07,2.17.07h1.94c0-1.13,0-2.35,0-3.66s-.06-2.64-.06-4,0-2.62,0-3.86.07-2.34.14-3.32a5.94,5.94,0,0,1,1.65-.08c.6,0,1.23.12,1.89.2s1.31.14,2,.19A8.14,8.14,0,0,0,158.45,13.53Z"/>
        </g>
    </g>;
}

function HugeHeart() {

    const [beatSpring] = useSpring(() => ({
        from: {
            transform: 'translate(-9px,11px)'
        },
        to: {
            transform: 'translate(0px,0px) '
        },
        loop: true,
        config: {
            mass: .5,
            tension: 180,
            friction: 24,
        },
    }));


    return <g>
        <path className="cls-18"
              d="M228.12,75c.36,17.07-4.5,34.59-10,52-4.35,13.92-10.85,26.77-18.11,39.31a113.51,113.51,0,0,0-5.17,10.28c-8.11,18.12-18.61,34.86-29.9,51.13-4.2,6.05-8,12.39-13.46,17.51a56.35,56.35,0,0,1-12.73,9.13c-6,3.07-11.9,2.12-17.33-1.14-8.21-4.94-16.42-10-23.24-16.86-10-10-20.82-19.22-30.94-29.09A282.64,282.64,0,0,1,30.13,163Q23.71,153.41,17.07,144C8.26,131.51,3.86,117.45,1.8,102.46.55,93.3-.74,84.17.51,74.92,2.44,60.57,7.79,47.72,18,37.22a25.56,25.56,0,0,1,19.19-8.11A79.6,79.6,0,0,1,59,32.24c9.41,2.69,19,5.19,27.12,11A35.35,35.35,0,0,1,93.46,50c3.71,4.69,8.56,8.34,12.12,13.18,3.31,4.49,3.44,4.48,5.86-.48a65.88,65.88,0,0,1,10.49-15.53,65,65,0,0,0,4.63-5.93c10.86-15.29,26-24.49,43.82-28.74,9.42-2.24,19.15-1.21,27.81,4.73a98.06,98.06,0,0,1,19,16.44c7.18,8.26,9.84,18.33,10.87,28.9.36,3.66,0,7.37,0,12.51"/>
        <animated.g style={beatSpring}>
            <path className="cls-17"
                  d="M237.59,63.67c.36,17.07-4.51,34.59-10,52-4.34,13.92-10.85,26.77-18.1,39.31a113.51,113.51,0,0,0-5.17,10.28c-8.12,18.12-18.61,34.86-29.9,51.13-4.2,6-8,12.39-13.47,17.51a55.9,55.9,0,0,1-12.73,9.13c-5.95,3.07-11.89,2.12-17.32-1.14-8.22-4.94-16.42-10.05-23.24-16.86-10-10-20.82-19.22-30.94-29.09A282.09,282.09,0,0,1,39.6,151.59q-6.42-9.54-13.06-19c-8.81-12.49-13.22-26.55-15.27-41.54C10,81.94,8.73,72.81,10,63.56c1.94-14.35,7.29-27.2,17.45-37.7a25.6,25.6,0,0,1,19.2-8.11,79.6,79.6,0,0,1,21.83,3.13c9.41,2.69,19,5.19,27.12,11.05a35.55,35.55,0,0,1,7.35,6.66c3.72,4.69,8.57,8.34,12.13,13.18,3.3,4.49,3.44,4.48,5.86-.48A65.88,65.88,0,0,1,131.4,35.76,63.22,63.22,0,0,0,136,29.83c10.87-15.29,26-24.49,43.82-28.74C189.27-1.15,199-.12,207.66,5.82a98.06,98.06,0,0,1,19,16.44c7.18,8.26,9.84,18.33,10.87,28.9.36,3.66,0,7.37,0,12.51"/>
        </animated.g>
    </g>;
}

function HugeHeartAchievement() {

    const colors = [
        "#0d0777",
        "#00c0ed",
        "#1d76ce",
    ];

    const [nn, setNn] = useState(0);

    const points = [
        "398.27 237.99 795.89 273.89 798.63 201.85 398.27 237.99",
        "734.93 426.74 790.21 423.39 792.12 373.25 398.27 237.99 734.93 426.74",
        "564.61 437.05 632.41 432.94 398.27 237.99 564.61 437.05",
        "456.09 443.62 497.58 441.1 398.27 237.99 456.09 443.62",
        "379.21 448.27 417.12 445.97 398.27 238 379.21 448.27",
        "276.45 454.49 324.65 451.57 398.27 237.99 276.45 454.49",
        "127.41 463.51 214.21 458.26 398.27 238 127.41 463.51",
        "21.53 343.55 21.53 421.51 398.27 237.99 21.53 343.55",
        "21.53 272.01 398.27 237.99 21.53 203.98 21.53 272.01",
        "21.53 108.62 398.27 237.99 21.53 26.79 21.53 108.62",
        "398.27 237.99 202.61 3.85 114.81 1.99 398.27 237.99",
        "398.27 237.99 333.21 6.63 284.63 5.6 398.27 237.99",
        "398.27 237.99 419.07 8.46 377.39 7.58 398.27 237.99",
        "398.27 237.99 526.14 10.74 476.96 9.69 398.27 237.99",
        "398.27 237.99 667.6 13.75 587.09 12.04 398.27 237.99",
        "398.27 237.99 801.56 125.01 804.79 39.95 398.27 237.99",
    ]
    useEffect(() => {
        imIn = true;

        return () => {
            imIn = false;
        }
    }, [1])


    const firstC = useSpring({
        from: {
            elN: nn
        },
        to: {
            elN: nn + 1
        },
        config: {
            duration: 500
        },
        onRest: () => imIn && setNn(nn + 1),
    })

    return <svg id="huge-heart-achivement" data-name="huge-heart-achivement">
        <polygon points="778.07 439.26 10.71 485.23 0 105.48 784.25 110.42 778.07 439.26"/>
        <g>
            <polygon className="cls-24" points="21.53 469.92 790.21 423.39 805.67 16.69 21.53 0 21.53 469.92"/>
            <polygon className="cls-2" points="21.53 0 21.53 469.92 790.21 423.39 805.67 16.69 21.53 0"/>
            {
                points.map((el, pos) => <animated.polygon key={pos} style={{
                    fill: firstC.elN.to(theN => colors[((Math.ceil(theN) + pos % 3) % colors.length)])
                }} points={el}/>)
            }
        </g>
        <path className="cls-2" d="M216.39,119.53l85.68,313.24a3.83,3.83,0,0,0,4.8,2.54L579.7,350.5a3.82,3.82,0,0,0,2.64-4.31L546.09,101.71a3.84,3.84,0,0,0-4-3.18l-322.25,16A3.84,3.84,0,0,0,216.39,119.53Z"/>
        <g width="237.73" height="256.3" transform="translate(285.94 120.97)" xlinkHref="#huge-geart">
            <HugeHeart/>
        </g>
    </svg>;
}

// deciso di lasciare solo keep going
const expressions = [
    // {
    //     expression: "TERRIFIC!",
    //     audio: AUDIO_TERRIFIC
    // },
    {
        expression: "KEEP GOING!",
        audio: AUDIO_KEEP_GOING
    },
    // {
    //     expression: "NICE JOB!",
    //     audio: AUDIO_NICE_JOB
    // },
    // {
    //     expression: "GREAT JOB!",
    //     audio: AUDIO_GREAT_JOB
    // },
    // {
    //     expression: "WELL DONE!",
    //     audio: AUDIO_WELL_DONE
    // },
    // {
    //     expression: "YOU DID IT!",
    //     audio: AUDIO_YOU_DID_IT
    // },
];

let imIn = false;

export const LevelEndScreen = () => {

    const completeLevel = useGameActionSetter(LEVEL_COMPLETE_STATE_ACTION);// useGameStateSetter(asState(LEVEL_SELECTION_SCREEN_STATE, COMPLETE_STATE_ACTION));
    const completeGame = useGameActionSetter(GAME_COMPLETE_STATE_ACTION); // useGameStateSetter(asState(GAME_END_SCREEN_STATE, ENTERING_STATE_ACTION))

    const terrificEnded = useGameActionSetter(IDLE_STATE_ACTION);

    const [exited, setExited] = useState(false);
    const [choosed] = useState(Math.ceil(Math.random() * 999) % expressions.length);

    const isGameCompleted = useSelector(({generalState}) => generalState.progress.filter(el => el < 100).length === 0 && !generalState.finished);

    const listeners = {
        [LEVEL_END_SCREEN_STATE]: {
            [ENTERING_STATE_ACTION]: ({assetsManager: {get}, stateManager: {backgroundAudio: {toMinVolume}, gameState: {sfxVolume}}}) => {
                toMinVolume();
                get(expressions[choosed].audio).play().setVolume(sfxVolume.act);
            }
        },
    };


//width="1540" height="872" transform="translate(179.58 106.52)"
    const terrificSpring = useSpring({
        to: {
            transform: " matrix(1,0,0,1,0, 0) rotate(0deg) translate(179.58px, 789.52px)",
            transformOrigin: 'center',
        },
        from: {
            transform: " matrix(10,0,0,10,0, 0 ) rotate(1440deg) translate(179.58px, 789.52px)",
            transformOrigin: 'center',
        },
        onRest: (result, controller) => {
            if (result.finished) {
                if (!exited) {
                    controller.start({
                        from: {
                            transform: " matrix(1,0,0,1,0, 0) rotate(0deg) translate(179.58px, 389.52px)",
                            transformOrigin: 'center',
                        },
                        to: {
                            transform: " matrix(1,0,0,1,0, 0 ) rotate(0deg) translate(1579.58px, 389.52px)",
                            transformOrigin: 'center',
                        },
                        delay: 2000,
                        onRest() {
                            terrificEnded();
                            return setExited(true);
                        }
                    })
                }
            }
        }
    });

    const hugeHeartSpring = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        },
        immediate: !exited
    });

    return <SubscribingState listeners={listeners}>
        <svg className="level-end-screen" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1920 1080">
            <defs>
                <symbol id="Level_completed" data-name="Level completed" viewBox="0 0 837.24 248.58">
                    <path className="lvl-cmpl-1" d="M831.75,143.62,22.56,248.55a5.77,5.77,0,0,1-6.38-4.73L.05,130.88a5.79,5.79,0,0,1,5.12-6.38L811.57.06a5.8,5.8,0,0,1,6.7,4.7L837.18,137.2A5.8,5.8,0,0,1,831.75,143.62Z"/>
                    <text className="lvl-cmpl-2" transform="translate(64.03 216.06) rotate(-7.63)">LEVE
                        <tspan className="lvl-cmpl-3" x="189.9" y="0">L</tspan>
                        <tspan className="lvl-cmpl-4" x="235.2" y="0" xmlSpace="preserve"> COMPLETED!</tspan>
                    </text>
                    <text className="lvl-cmpl-5" transform="translate(64.03 206.11) rotate(-7.63)">LEVE
                        <tspan className="lvl-cmpl-3" x="189.9" y="0">L</tspan>
                        <tspan className="lvl-cmpl-4" x="235.2" y="0" xmlSpace="preserve"> COMPLETED!</tspan>
                    </text>
                </symbol>

                <symbol id="earned-splash-text" data-name="earned-splash-text" viewBox="0 0 837.24 248.58">
                    <path className="cls-19" d="M831.75,143.62,22.56,248.55a5.77,5.77,0,0,1-6.38-4.73L.05,130.88a5.79,5.79,0,0,1,5.12-6.38L811.57.06a5.8,5.8,0,0,1,6.7,4.7L837.18,137.2A5.8,5.8,0,0,1,831.75,143.62Z"/>
                    <g>
                        <path className="cls-2"
                              d="M59.35,131.68v.09q-.95,8.56-2.63,16.83t-3.58,16.51q-1.89,8.25-3.65,16.48T46.63,198.2a4.71,4.71,0,0,0-.84,0l-.9.11c-1,.12-2,.29-3.18.52s-2.25.41-3.32.54l-.83.1a3.37,3.37,0,0,1-.84,0l-.14,0q.9-7.71,2.35-16.18t2.9-16.91l0-.18q-1.26-4-2.92-7.86t-3.42-7.7c-1.18-2.55-2.31-5.1-3.42-7.66a70.75,70.75,0,0,1-2.82-7.63,15.89,15.89,0,0,0,2.88-.63,17.86,17.86,0,0,1,3-.64,9.52,9.52,0,0,1,1.69-.06,4.41,4.41,0,0,1,1.67.41q.59,2,1.58,4.68t2.07,5.48q1.06,2.77,2.18,5.27t1.94,4.17a36.06,36.06,0,0,0,.91-4.47c.28-1.81.54-3.7.8-5.67s.5-3.91.74-5.81.5-3.5.77-4.79a16.31,16.31,0,0,1,3.3-.91c1.4-.26,2.7-.46,3.91-.61.55-.06,1.06-.11,1.53-.13A7,7,0,0,1,59.35,131.68Z"/>
                        <path className="cls-2"
                              d="M59.62,153.88a6.09,6.09,0,0,1,1.27-2.6,10.2,10.2,0,0,1,2.3-2,11.3,11.3,0,0,1,2.93-1.33,20.2,20.2,0,0,1,3.27-.68,11.13,11.13,0,0,0,2.68-.27,11.36,11.36,0,0,1,2.74-.28,12.07,12.07,0,0,1,4.38.64A10,10,0,0,1,83,149.76a5.61,5.61,0,0,1,1.85,2.49,25.71,25.71,0,0,1,1.34,4.2c.38,1.62.72,3.39,1,5.32s.56,3.92.85,5.95q.25,2.7.56,6.13c.2,2.28.35,4.49.44,6.6s.11,4,0,5.72a8.13,8.13,0,0,1-.66,3.55,7.08,7.08,0,0,1-2.71,3.51,12.34,12.34,0,0,1-4,1.65,22.69,22.69,0,0,1-4.58.55c-1.59,0-3,.13-4.33.29a10.39,10.39,0,0,1-6.46-3.35,12.52,12.52,0,0,1-3.23-5.24,41.68,41.68,0,0,1-1.67-6.88c-.39-2.43-.69-4.87-.92-7.35s-.53-4.72-.91-6.74c-.14-1.11-.25-2.2-.33-3.29s-.13-2.22-.13-3.4a18,18,0,0,1,.16-2.79C59.46,155.51,59.56,154.57,59.62,153.88Zm10,9.86a3.35,3.35,0,0,0-.41,1.93c0,2,.2,4,.43,5.9s.43,4,.6,6.17a4.94,4.94,0,0,0,1.23,3.18,4.46,4.46,0,0,0,2.37,1.31,5.52,5.52,0,0,0,2.7-.14,5.44,5.44,0,0,0,2.18-1.15,4.32,4.32,0,0,0,1.23-3.24c0-2-.14-4-.26-6a27.65,27.65,0,0,0-.93-5.66,7.1,7.1,0,0,0-2.58-4.47,4.33,4.33,0,0,0-4.45-.12A4.68,4.68,0,0,0,69.61,163.74Z"/>
                        <path className="cls-2"
                              d="M111.56,185.34c-.21.22-.47.47-.79.75a7.36,7.36,0,0,0-.78.85,12.19,12.19,0,0,1-3,2.61,10,10,0,0,1-3.6,1.37,9.62,9.62,0,0,1-3.78-.11,7.84,7.84,0,0,1-3.54-1.92,7.59,7.59,0,0,1-1.5-3.34,56,56,0,0,1-1.24-6.27q-.56-3.69-1-8.08c-.32-2.93-.64-5.9-1-8.89s-.61-5.88-.9-8.66-.6-5.21-.94-7.3a21.6,21.6,0,0,1,4.29-.94c1.27-.12,2.72-.28,4.34-.47.26,1.41.5,3,.72,4.79s.42,3.64.63,5.55.43,3.83.69,5.73.52,3.69.81,5.35c.14.73.28,1.54.44,2.43s.34,1.76.56,2.61a13.44,13.44,0,0,0,.76,2.25,3.78,3.78,0,0,0,1.06,1.47.18.18,0,0,0,.15.07.22.22,0,0,1,.15.08,4.71,4.71,0,0,0,1.7.4,3.49,3.49,0,0,0,2.09-.44,4.26,4.26,0,0,0,1.68-1.89,7.53,7.53,0,0,0,.52-3.86q-.24-3.15-.68-6.29c-.3-2.09-.61-4.17-.93-6.22s-.61-4.1-.87-6.13a48.47,48.47,0,0,1-.42-6.14l-.05-.37a7.87,7.87,0,0,1,2-.8,10.2,10.2,0,0,1,1.77-.26c.6,0,1.28-.08,2-.11a14,14,0,0,0,2.76-.42q1.36,11.37,2.91,22.72t2.72,23.59l-1.4,0c-.38,0-.73,0-1.05.08s-.67.09-1,.17l-1.38.3a6.92,6.92,0,0,0-1,.26c-.45.15-.91.28-1.36.4a6.15,6.15,0,0,1-1.24.19c-.38,0-.58-.07-.6-.25a22.51,22.51,0,0,1-.42-2.58C111.82,187,111.71,186.2,111.56,185.34Z"/>
                        <path className="cls-2"
                              d="M144.42,144.63c.21-.21.47-.46.79-.75A6.53,6.53,0,0,0,146,143a12,12,0,0,1,3-2.61,10,10,0,0,1,3.61-1.37,9.63,9.63,0,0,1,3.78.1,8,8,0,0,1,3.54,1.92,7.79,7.79,0,0,1,1.49,3.34,56.69,56.69,0,0,1,1.24,6.28c.37,2.45.72,5.15,1.05,8.08s.64,5.89,1,8.89.61,5.88.9,8.66.6,5.21.94,7.29a20.27,20.27,0,0,1-4.29.94c-1.27.12-2.72.28-4.34.48q-.39-2.11-.72-4.8t-.63-5.55q-.3-2.87-.69-5.73t-.81-5.34c-.14-.73-.28-1.55-.44-2.43s-.34-1.76-.56-2.61a14.41,14.41,0,0,0-.76-2.25,3.63,3.63,0,0,0-1.06-1.47.19.19,0,0,0-.15-.08s-.09,0-.15-.07a4.9,4.9,0,0,0-1.7-.41,3.64,3.64,0,0,0-2.09.44,4.26,4.26,0,0,0-1.68,1.89,7.68,7.68,0,0,0-.52,3.86q.25,3.25.69,6.34t.92,6.18q.48,3.09.84,6.13c.24,2,.4,4.08.46,6.14l0,.37a8.27,8.27,0,0,1-2,.8,11.26,11.26,0,0,1-1.77.26c-.6,0-1.28.07-2,.1a15,15,0,0,0-2.76.43c-.3-2.53-.68-5.56-1.13-9.11s-.93-7.34-1.44-11.41-1-8.27-1.55-12.61-1-8.59-1.53-12.72-.95-8-1.37-11.69-.76-6.87-1-9.59c.56,0,1,0,1.4,0s.73,0,1-.07.67-.1,1-.18l1.37-.3a5.69,5.69,0,0,0,.95-.26c.45-.14.91-.28,1.36-.39a5.57,5.57,0,0,1,1.25-.2c.37,0,.57.07.59.26a24.33,24.33,0,0,1,.37,2.44c.15,1.32.34,2.9.56,4.71s.45,3.76.7,5.83.48,4,.69,5.78.4,3.33.56,4.62S144.39,144.39,144.42,144.63Z"/>
                        <path className="cls-2"
                              d="M193.82,133.82c.09.37.17.73.24,1.1s.12.74.16,1.1l.11.93q1,10,2.43,19.64t2.14,19.58c.1.8.18,1.6.26,2.41s-.16,1.3-.7,1.49l-.84.1a18.72,18.72,0,0,1-2.27.13,18.84,18.84,0,0,0-2.27.13,8.12,8.12,0,0,0-1.48.32,7.84,7.84,0,0,1-1.47.32,1.12,1.12,0,0,1-.75-.66,4.43,4.43,0,0,1-.33-1.28,8.34,8.34,0,0,1-.06-1.68,8,8,0,0,0-.05-1.59,15.09,15.09,0,0,1-3.75,4.3,9.49,9.49,0,0,1-4.77,2.17,5.74,5.74,0,0,1-4.92-1.94,21.73,21.73,0,0,1-3.9-5.82,43.54,43.54,0,0,1-2.8-7.82,59.73,59.73,0,0,1-1.56-8,37.86,37.86,0,0,1-.12-8.61,26.35,26.35,0,0,1,1.66-7,14.06,14.06,0,0,1,3.18-4.88,8.16,8.16,0,0,1,4.38-2.27c.83-.1,1.61-.17,2.33-.23a5.23,5.23,0,0,1,2,.23,4.27,4.27,0,0,1,1.75,1.1,7.49,7.49,0,0,1,1.48,2.31,12.18,12.18,0,0,1-.2-2.56,13.47,13.47,0,0,0-.09-2.19c0-.06.14-.12.43-.19a5.09,5.09,0,0,1,1-.12c.37,0,.75-.05,1.12-.09l.76-.09,2.9-.16C191,133.94,192.32,133.88,193.82,133.82Zm-18,20.83a13.78,13.78,0,0,0-.05,2.49c.05.84.12,1.66.22,2.46a14.41,14.41,0,0,0,2.48,6.88,5.06,5.06,0,0,0,4.9,2.36,3.8,3.8,0,0,0,2.51-1.1,4.51,4.51,0,0,0,1-2.37,14.57,14.57,0,0,0,0-3.56c-.14-1.39-.31-2.95-.52-4.67-.26-2.16-.48-3.87-.65-5.13a11.38,11.38,0,0,0-.7-2.87,1.87,1.87,0,0,0-1.2-1.17,6.61,6.61,0,0,0-2.22,0,5.81,5.81,0,0,0-4.26,2.34A7.8,7.8,0,0,0,175.83,154.65Z"/>
                        <path className="cls-2"
                              d="M206.81,132.36q.88,3.27,1.89,7.51c.67,2.82,1.36,5.7,2.09,8.61s1.46,5.69,2.2,8.32a61.08,61.08,0,0,0,2.2,6.63,60.21,60.21,0,0,0,.55-7.15c.08-2.85.13-5.82.17-8.88s.1-6.05.22-8.94a59.26,59.26,0,0,1,.69-7.21c.78-.1,1.52-.22,2.21-.36l2.13-.44c.73-.15,1.49-.28,2.28-.37s1.67-.17,2.65-.23q-.83,5.44-1.22,11.26T224.21,153q-.26,6-.51,12.12t-.74,12a19.41,19.41,0,0,1-3.05.69c-1.13.17-2.29.3-3.47.42l-3.48.32c-1.14.11-2.15.21-3,.32q-2.77-11.4-6-23.2t-6.78-22.44a21.24,21.24,0,0,0,5-.46A18.23,18.23,0,0,1,206.81,132.36Z"/>
                        <path className="cls-2"
                              d="M238.84,151.58l.2,1.67c.26,1.84.5,3.35.71,4.51a9.53,9.53,0,0,0,1.28,3.31,6.45,6.45,0,0,0,2.74,2.86,3.62,3.62,0,0,0,3.06.29,4.11,4.11,0,0,0,2.2-2.56,11.74,11.74,0,0,0,.18-5.79,10.67,10.67,0,0,0,1.88-.18c.78-.12,1.6-.27,2.45-.43a20.14,20.14,0,0,1,2.46-.34,6.17,6.17,0,0,1,2,.09c.1,1.61.19,3.19.29,4.74s.15,3,.17,4.35a33.13,33.13,0,0,1-.11,3.48,4.86,4.86,0,0,1-.56,2.08,7,7,0,0,1-2.68,3.51,11.35,11.35,0,0,1-4,1.6,28,28,0,0,1-4.55.55c-1.56.06-3,.17-4.29.33a8.43,8.43,0,0,1-3.81-1.23,14,14,0,0,1-3.67-4.2,33.78,33.78,0,0,1-2.76-6,48.82,48.82,0,0,1-1.9-7,68.71,68.71,0,0,1-1.05-7.56q-.21-3.45-.34-6.67a33,33,0,0,1,.32-6.37,11.85,11.85,0,0,1,2.23-4.91,12.74,12.74,0,0,1,3.89-3.37,14.69,14.69,0,0,1,10.1-1.54,9.53,9.53,0,0,1,5.49,2.34,15,15,0,0,1,3.59,5,27.84,27.84,0,0,1,2.05,6.79,71.09,71.09,0,0,1,.92,7.67,10.07,10.07,0,0,1-1.79.59q-1.34.35-3.15.75c-1.21.27-2.51.53-3.89.75s-2.71.42-4,.57-2.42.26-3.45.32A11.48,11.48,0,0,1,238.84,151.58Zm6.67-15.34a4.4,4.4,0,0,0-1.19-.56,3.48,3.48,0,0,0-1.35-.07,6.76,6.76,0,0,0-2.65.79,4.15,4.15,0,0,0-1.51,1.49,5.91,5.91,0,0,0-.7,2.06,15.15,15.15,0,0,0-.18,2.36,8.44,8.44,0,0,0,.07,1.45c.06.27.3.43.73.47a8.23,8.23,0,0,0,2-.14c.9-.14,2.23-.32,4-.53,1.21-.14,2.11-.22,2.72-.23a6.67,6.67,0,0,0,1.29-.11c.25-.06.37-.17.35-.32s-.06-.48-.12-1a8.61,8.61,0,0,0-1.15-3.43A5.68,5.68,0,0,0,245.51,136.24Z"/>
                        <path className="cls-2"
                              d="M283.54,146.22l.2,1.66c.27,1.84.51,3.35.72,4.51a9.63,9.63,0,0,0,1.27,3.32,6.56,6.56,0,0,0,2.74,2.86,3.65,3.65,0,0,0,3.06.28,4.05,4.05,0,0,0,2.2-2.56,11.63,11.63,0,0,0,.18-5.79,10.67,10.67,0,0,0,1.88-.18c.79-.12,1.6-.27,2.45-.43a22.64,22.64,0,0,1,2.46-.34,6.48,6.48,0,0,1,2,.09c.1,1.61.2,3.2.29,4.75s.15,3,.17,4.34a33.13,33.13,0,0,1-.11,3.48,4.64,4.64,0,0,1-.56,2.09,7,7,0,0,1-2.68,3.5,11.4,11.4,0,0,1-4,1.61,28,28,0,0,1-4.55.54c-1.56.06-3,.18-4.29.33a8.47,8.47,0,0,1-3.81-1.23,14,14,0,0,1-3.67-4.2,33.78,33.78,0,0,1-2.76-6,48.47,48.47,0,0,1-1.9-7,68.71,68.71,0,0,1-1.05-7.56c-.13-2.3-.25-4.52-.34-6.67a33,33,0,0,1,.32-6.37,11.85,11.85,0,0,1,2.23-4.91A13,13,0,0,1,279.9,123a14.84,14.84,0,0,1,10.1-1.54,9.54,9.54,0,0,1,5.49,2.34,15.06,15.06,0,0,1,3.59,5,27.54,27.54,0,0,1,2.05,6.79,69,69,0,0,1,.92,7.67,9.78,9.78,0,0,1-1.79.59c-.89.24-1.94.49-3.15.76s-2.51.52-3.89.74-2.71.42-4,.58-2.42.26-3.45.32A13.37,13.37,0,0,1,283.54,146.22Zm6.68-15.34a4.3,4.3,0,0,0-1.2-.56,3.42,3.42,0,0,0-1.34-.08A7,7,0,0,0,285,131a4.31,4.31,0,0,0-1.51,1.49,5.91,5.91,0,0,0-.7,2.06,14,14,0,0,0-.17,2.36,7.63,7.63,0,0,0,.06,1.45c.06.27.3.43.73.48a8.93,8.93,0,0,0,2-.15c.9-.14,2.23-.31,4-.53,1.2-.14,2.1-.22,2.71-.23a5.94,5.94,0,0,0,1.29-.11c.25-.06.37-.16.35-.32s-.06-.48-.11-1a9,9,0,0,0-1.15-3.43A5.59,5.59,0,0,0,290.22,130.88Z"/>
                        <path className="cls-2"
                              d="M331.26,117.32c.09.36.17.73.24,1.09s.12.74.17,1.11l.11.93q1,10,2.43,19.63t2.14,19.58c.09.81.18,1.61.25,2.41s-.16,1.3-.7,1.49l-.83.1a18.86,18.86,0,0,1-2.28.14,17,17,0,0,0-2.27.13,7.12,7.12,0,0,0-1.47.32,8.11,8.11,0,0,1-1.48.31,1.07,1.07,0,0,1-.75-.66,4.36,4.36,0,0,1-.33-1.27,8.38,8.38,0,0,1-.06-1.68,7.35,7.35,0,0,0-.05-1.59,15.48,15.48,0,0,1-3.74,4.3,9.45,9.45,0,0,1-4.78,2.16,5.68,5.68,0,0,1-4.91-1.94,21.45,21.45,0,0,1-3.91-5.81,44.43,44.43,0,0,1-2.8-7.83,61.23,61.23,0,0,1-1.56-8,37.86,37.86,0,0,1-.12-8.61,26.16,26.16,0,0,1,1.66-7,14,14,0,0,1,3.18-4.89,8.14,8.14,0,0,1,4.38-2.26c.83-.1,1.61-.18,2.33-.23a5.3,5.3,0,0,1,2,.22,4.55,4.55,0,0,1,1.75,1.11,7.74,7.74,0,0,1,1.47,2.3,12.85,12.85,0,0,1-.2-2.55,13.59,13.59,0,0,0-.09-2.2c0-.06.14-.12.44-.19a6.85,6.85,0,0,1,1-.12,10.35,10.35,0,0,0,1.12-.09l.76-.09c.8,0,1.76-.08,2.91-.16S329.77,117.37,331.26,117.32Zm-18,20.82a13.91,13.91,0,0,0,0,2.5c0,.83.13,1.65.22,2.45a14.44,14.44,0,0,0,2.48,6.88,5.08,5.08,0,0,0,4.9,2.37,3.82,3.82,0,0,0,2.51-1.1,4.51,4.51,0,0,0,1-2.37,15,15,0,0,0,0-3.56c-.15-1.39-.32-2.95-.53-4.68-.26-2.15-.48-3.86-.65-5.12a11.59,11.59,0,0,0-.7-2.88,1.87,1.87,0,0,0-1.19-1.17,6.67,6.67,0,0,0-2.22,0,5.82,5.82,0,0,0-4.27,2.34A7.85,7.85,0,0,0,313.27,138.14Z"/>
                        <path className="cls-2"
                              d="M346.21,118.62c.17-.15.56-.45,1.19-.9s1.25-.86,1.86-1.25a14.76,14.76,0,0,1,2.88-1.24,26.26,26.26,0,0,1,3.07-.79,22.57,22.57,0,0,1,2.46-.34c.67,0,1,0,1,0q.38,3.13.63,5.55c.17,1.61.33,3,.49,4.3s.29,2.42.41,3.47.27,2,.43,2.95l-.66-.2a4.92,4.92,0,0,0-.77-.24,6.25,6.25,0,0,1-.77-.23,14.47,14.47,0,0,0-1.75-.26,8.58,8.58,0,0,0-1-.07,23.77,23.77,0,0,0-3.47.13,5.53,5.53,0,0,0-2.63,1,4.33,4.33,0,0,0-1.53,2.25,9.57,9.57,0,0,0-.08,4q.47,3.22.88,6.09c.27,1.9.53,3.83.76,5.77s.48,3.91.74,5.91.54,4.14.81,6.42l0,.37a8.47,8.47,0,0,1-2.09.82,14.11,14.11,0,0,1-2,.28c-.7.06-1.45.1-2.27.14A15.87,15.87,0,0,0,342,163q-1.36-11.37-2.9-22.72T336.4,116.7c.56,0,1,0,1.4,0s.73,0,1.05-.08.67-.09,1-.17l1.37-.3a10,10,0,0,0,1-.22l1.51-.36a7.23,7.23,0,0,1,1.38-.22c.4,0,.6,0,.62.16.06.5.1.87.13,1.11s.06.45.07.6a3.52,3.52,0,0,0,.1.51C346.1,117.88,346.15,118.19,346.21,118.62Z"/>
                        <path className="cls-2"
                              d="M371.68,117.34c.21-.21.48-.46.79-.75a5.88,5.88,0,0,0,.78-.84,11.89,11.89,0,0,1,3-2.62,10.28,10.28,0,0,1,3.6-1.37,9.81,9.81,0,0,1,3.78.11,7.94,7.94,0,0,1,3.54,1.92,7.61,7.61,0,0,1,1.49,3.34,56.48,56.48,0,0,1,1.25,6.28c.37,2.45.71,5.15,1,8.08s.65,5.89,1,8.89.62,5.88.9,8.66.6,5.21.95,7.29a20.27,20.27,0,0,1-4.29.94c-1.28.12-2.72.28-4.34.48q-.4-2.12-.72-4.8t-.63-5.55q-.31-2.86-.69-5.73c-.25-1.91-.53-3.69-.82-5.34-.13-.74-.28-1.55-.43-2.43s-.34-1.76-.56-2.61a14.41,14.41,0,0,0-.76-2.25,3.73,3.73,0,0,0-1.06-1.47.19.19,0,0,0-.15-.08.18.18,0,0,1-.15-.07,5,5,0,0,0-1.7-.41,3.59,3.59,0,0,0-2.1.44,4.19,4.19,0,0,0-1.67,1.89,7.57,7.57,0,0,0-.52,3.86q.24,3.26.69,6.34c.29,2.06.6,4.12.91,6.18s.6,4.1.85,6.13.39,4.08.45,6.14l.05.37a8.44,8.44,0,0,1-2,.8,11.62,11.62,0,0,1-1.77.26c-.6,0-1.28.07-2,.1a14.75,14.75,0,0,0-2.76.43q-1.38-11.37-2.91-22.73t-2.73-23.59l1.41,0c.37,0,.72,0,1-.08s.66-.1,1-.17l1.37-.31a5.48,5.48,0,0,0,1-.26c.45-.14.9-.28,1.36-.39a5.49,5.49,0,0,1,1.24-.2c.38,0,.57.07.6.26a24,24,0,0,1,.41,2.57C371.43,115.72,371.53,116.48,371.68,117.34Z"/>
                        <path className="cls-2"
                              d="M404.18,131.73l.2,1.66c.27,1.85.51,3.35.72,4.51a9.66,9.66,0,0,0,1.28,3.32,6.5,6.5,0,0,0,2.74,2.86,3.65,3.65,0,0,0,3.06.29,4.1,4.1,0,0,0,2.19-2.56,11.74,11.74,0,0,0,.19-5.79,13.15,13.15,0,0,0,1.88-.18c.78-.13,1.59-.27,2.44-.44a22.64,22.64,0,0,1,2.46-.34,6.81,6.81,0,0,1,2,.09c.1,1.62.2,3.2.29,4.75s.15,3,.17,4.34a30.62,30.62,0,0,1-.11,3.48,4.6,4.6,0,0,1-.56,2.09,7.13,7.13,0,0,1-2.67,3.51,11.51,11.51,0,0,1-4,1.6,29,29,0,0,1-4.54.55c-1.57.06-3,.17-4.29.32a8.39,8.39,0,0,1-3.81-1.23,14,14,0,0,1-3.68-4.2,33,33,0,0,1-2.75-6,49.17,49.17,0,0,1-1.91-7,69.2,69.2,0,0,1-1-7.57c-.14-2.29-.25-4.52-.35-6.66a33.81,33.81,0,0,1,.33-6.37,11.8,11.8,0,0,1,2.23-4.91,12.66,12.66,0,0,1,3.89-3.38,14.41,14.41,0,0,1,4.87-1.66,14.77,14.77,0,0,1,5.22.12,9.54,9.54,0,0,1,5.49,2.34,15,15,0,0,1,3.6,5,28.42,28.42,0,0,1,2,6.79,71.4,71.4,0,0,1,.92,7.67,9.76,9.76,0,0,1-1.8.6q-1.33.35-3.15.75c-1.21.27-2.5.52-3.88.75s-2.71.42-4,.57-2.42.26-3.44.32A12.46,12.46,0,0,1,404.18,131.73Zm6.68-15.34a4.25,4.25,0,0,0-1.19-.56,3.3,3.3,0,0,0-1.35-.07,6.76,6.76,0,0,0-2.65.78,4.18,4.18,0,0,0-1.51,1.5,5.86,5.86,0,0,0-.71,2,16.74,16.74,0,0,0-.17,2.37,7.33,7.33,0,0,0,.07,1.44c0,.28.3.44.72.48a8.9,8.9,0,0,0,2-.15c.9-.13,2.23-.31,4-.52,1.2-.15,2.11-.22,2.72-.24a6.12,6.12,0,0,0,1.29-.1c.25-.06.36-.17.35-.33s-.06-.47-.12-1a8.72,8.72,0,0,0-1.15-3.42A5.61,5.61,0,0,0,410.86,116.39Z"/>
                        <path className="cls-2"
                              d="M439.89,86.08l-.12-1c0-.06.3-.18.91-.34a20.4,20.4,0,0,1,2.27-.46c.9-.14,1.86-.26,2.88-.35a12.51,12.51,0,0,1,2.68,0l.73,6.1q1.86,15.44,4,30.47t3.48,28.38c-1.44.17-3,.4-4.71.7a20.39,20.39,0,0,1-4.67.33,1.92,1.92,0,0,1-.15-.64c-.08-.62-.15-1.11-.21-1.48s-.14-.88-.23-1.56a2.38,2.38,0,0,0-.39-.66c-.14-.14-.25,0-.34.37a5.36,5.36,0,0,1-6,4.75,9.7,9.7,0,0,1-6.58-2,15,15,0,0,1-4.16-5.59,39,39,0,0,1-2.51-8q-.92-4.49-1.55-9.1a63.17,63.17,0,0,1-.44-6.33,36.83,36.83,0,0,1,.3-5.71,16.66,16.66,0,0,1,1.22-4.55,6.3,6.3,0,0,1,2.29-2.86,12.45,12.45,0,0,1,4-1.65,20.43,20.43,0,0,1,4.27-.51,11.09,11.09,0,0,1,3.51.47c1,.32,1.54.75,1.7,1.3q-.58-4.33-.91-7c-.21-1.79-.39-3.22-.51-4.3s-.22-1.91-.27-2.5-.09-1.15-.13-1.68-.09-1.11-.14-1.76S440,87.32,439.89,86.08Zm-6.57,36.15a12.4,12.4,0,0,0-.09,2.73c.06.93.14,1.82.25,2.69a23.54,23.54,0,0,0,.95,4.43,15.73,15.73,0,0,0,1.72,3.74,8.36,8.36,0,0,0,2.34,2.48,3.92,3.92,0,0,0,2.76.7,3.74,3.74,0,0,0,2.62-1.34,6.48,6.48,0,0,0,1.19-3,17.34,17.34,0,0,0,.12-4.28c-.12-1.64-.31-3.45-.54-5.43q-.43-3.6-.79-5.67a11.24,11.24,0,0,0-.9-3.13,2.27,2.27,0,0,0-1.46-1.27,6.65,6.65,0,0,0-2.36,0,6.26,6.26,0,0,0-4.35,2.21A7.84,7.84,0,0,0,433.32,122.23Z"/>
                        <path className="cls-2"
                              d="M497.93,97.3c.09.37.17.73.23,1.1s.13.74.17,1.11l.11.92q1,10,2.43,19.64T503,139.65c.1.8.18,1.61.26,2.41s-.16,1.3-.7,1.49l-.84.1a18.84,18.84,0,0,1-2.27.13,18.84,18.84,0,0,0-2.27.13,9.06,9.06,0,0,0-1.48.32,8,8,0,0,1-1.47.32,1.09,1.09,0,0,1-.75-.66,4.44,4.44,0,0,1-.33-1.27,8.46,8.46,0,0,1-.06-1.69,7.93,7.93,0,0,0-.05-1.58,15.34,15.34,0,0,1-3.75,4.29,9.41,9.41,0,0,1-4.77,2.17,5.69,5.69,0,0,1-4.92-1.94,21.57,21.57,0,0,1-3.9-5.82,44,44,0,0,1-2.81-7.82,61.43,61.43,0,0,1-1.55-8,38,38,0,0,1-.12-8.62,26,26,0,0,1,1.66-7,13.78,13.78,0,0,1,3.18-4.88,8,8,0,0,1,4.38-2.26c.83-.1,1.61-.18,2.33-.24a5.23,5.23,0,0,1,2,.23,4.38,4.38,0,0,1,1.75,1.1A7.49,7.49,0,0,1,488,102.9a12.13,12.13,0,0,1-.2-2.55,13.55,13.55,0,0,0-.09-2.2c0-.06.14-.12.43-.19a5.09,5.09,0,0,1,1-.12,10.78,10.78,0,0,0,1.12-.09l.76-.09c.79,0,1.76-.09,2.9-.16S496.43,97.36,497.93,97.3Zm-18,20.83a14.75,14.75,0,0,0-.06,2.49c.06.84.13,1.66.23,2.46a14.37,14.37,0,0,0,2.48,6.88,5,5,0,0,0,4.9,2.36,3.75,3.75,0,0,0,2.5-1.1,4.43,4.43,0,0,0,1-2.37,14.57,14.57,0,0,0,0-3.56c-.14-1.39-.32-2.94-.52-4.67-.26-2.16-.48-3.87-.65-5.13a11.38,11.38,0,0,0-.7-2.87,1.87,1.87,0,0,0-1.2-1.17,6.41,6.41,0,0,0-2.22,0,5.75,5.75,0,0,0-4.26,2.34A7.75,7.75,0,0,0,479.94,118.13Z"/>
                        <path className="cls-2"
                              d="M526.55,98.74c.21-.21.47-.46.79-.75a5.88,5.88,0,0,0,.78-.84,12.05,12.05,0,0,1,3-2.61,9.83,9.83,0,0,1,3.6-1.37,9.45,9.45,0,0,1,3.78.11A7.85,7.85,0,0,1,542,95.2a7.73,7.73,0,0,1,1.49,3.33,58.87,58.87,0,0,1,1.25,6.28q.54,3.69,1,8.08t1,8.89c.31,3,.61,5.88.9,8.66s.6,5.21.94,7.3a20.9,20.9,0,0,1-4.28.93c-1.28.13-2.73.28-4.35.48-.26-1.41-.5-3-.71-4.79s-.43-3.64-.64-5.55-.43-3.83-.68-5.74-.53-3.68-.82-5.34c-.14-.73-.28-1.54-.43-2.43s-.34-1.76-.56-2.61a14.38,14.38,0,0,0-.77-2.25,3.86,3.86,0,0,0-1-1.47c-.06,0-.11-.08-.15-.07a.19.19,0,0,1-.15-.08,4.77,4.77,0,0,0-1.71-.4,3.57,3.57,0,0,0-2.09.43,4.26,4.26,0,0,0-1.68,1.89,7.68,7.68,0,0,0-.52,3.86c.17,2.18.4,4.29.69,6.35s.6,4.11.92,6.17.6,4.1.84,6.14.4,4.08.46,6.13l0,.37a8.27,8.27,0,0,1-2,.8,11.31,11.31,0,0,1-1.76.26c-.61,0-1.29.08-2,.11a14,14,0,0,0-2.77.42q-.45-3.79-1.13-9.1t-1.44-11.41q-.76-6.11-1.55-12.62t-1.53-12.71q-.74-6.2-1.37-11.7t-1-9.59l1.4,0c.38,0,.73,0,1.05-.08s.67-.1,1-.17l1.37-.31a6.84,6.84,0,0,0,1-.25c.45-.15.9-.28,1.36-.4a6,6,0,0,1,1.24-.2c.37,0,.57.07.59.26a21.94,21.94,0,0,1,.37,2.44c.16,1.33.35,2.9.56,4.72s.46,3.76.7,5.82.48,4,.7,5.78.4,3.33.55,4.62S526.52,98.5,526.55,98.74Z"/>
                        <path className="cls-2"
                              d="M569.91,130.3c-.21.22-.47.47-.79.75a7.36,7.36,0,0,0-.78.85,12.19,12.19,0,0,1-3,2.61,10,10,0,0,1-3.6,1.37,9.49,9.49,0,0,1-3.79-.11,7.94,7.94,0,0,1-3.54-1.92,7.85,7.85,0,0,1-1.49-3.34,56,56,0,0,1-1.24-6.27q-.56-3.69-1.05-8.08c-.32-2.93-.64-5.9-1-8.89s-.61-5.88-.9-8.66-.6-5.21-.94-7.3a21.6,21.6,0,0,1,4.29-.94c1.27-.12,2.72-.28,4.34-.47.26,1.41.5,3,.72,4.79s.42,3.64.63,5.55.43,3.83.69,5.73.52,3.69.81,5.35c.14.73.28,1.54.44,2.43s.34,1.76.56,2.61a13.44,13.44,0,0,0,.76,2.25,3.78,3.78,0,0,0,1.06,1.47.18.18,0,0,0,.15.07.22.22,0,0,1,.15.08,4.66,4.66,0,0,0,1.7.4,3.62,3.62,0,0,0,2.1-.44,4.25,4.25,0,0,0,1.67-1.89,7.53,7.53,0,0,0,.52-3.86q-.24-3.15-.69-6.29c-.29-2.09-.6-4.17-.92-6.22s-.61-4.1-.87-6.13a48.47,48.47,0,0,1-.42-6.14l0-.37a7.87,7.87,0,0,1,2-.8,10.2,10.2,0,0,1,1.77-.26c.6,0,1.28-.08,2-.11A14,14,0,0,0,574,87.7q1.37,11.37,2.91,22.72T579.65,134l-1.4,0c-.38,0-.73,0-1,.08s-.67.09-1,.17l-1.38.31a5.37,5.37,0,0,0-.95.25c-.45.15-.91.28-1.36.4a6.15,6.15,0,0,1-1.24.19c-.38,0-.58-.07-.6-.25a22.51,22.51,0,0,1-.42-2.58C570.17,131.93,570.06,131.16,569.91,130.3Z"/>
                        <path className="cls-2"
                              d="M605.84,149.81a9.19,9.19,0,0,1-2.48.39c-1.21.09-2.77.24-4.66.47a9.8,9.8,0,0,1-6.06-1.15,13.64,13.64,0,0,1-4.76-4.49,10.32,10.32,0,0,1-.73-1.65c-.25-.69-.47-1.4-.68-2.12a18.16,18.16,0,0,1-.46-2.1,17.21,17.21,0,0,1-.18-1.76,5.37,5.37,0,0,1,1.82-.74,15.48,15.48,0,0,1,2.45-.39c.86-.07,1.7-.11,2.52-.11a18.21,18.21,0,0,1,2.09.08,6.07,6.07,0,0,0,1.6,3.7,4,4,0,0,0,2.77,1.31,3.61,3.61,0,0,0,2.66-1.26,5.79,5.79,0,0,0,1.33-3.91c0-.63-.06-1.26-.09-1.92a10,10,0,0,0-.21-1.71,19.69,19.69,0,0,1-.38-3.23,4.37,4.37,0,0,1-1.46,1.67,7.08,7.08,0,0,1-1.93.89,13.27,13.27,0,0,1-2.17.45c-.74.09-1.43.2-2.07.34a13.57,13.57,0,0,1-2.71.05,9.58,9.58,0,0,1-2.68-.62,7.64,7.64,0,0,1-2.39-1.49,8.78,8.78,0,0,1-1.94-2.68,24.86,24.86,0,0,1-2-5.06q-.74-2.67-1.23-5.52-.51-4.35-1-9.36a44.43,44.43,0,0,1,.09-9.53,19.46,19.46,0,0,1,2.55-7.81,9.21,9.21,0,0,1,6.4-4.19,7.64,7.64,0,0,1,4.22.29,5.71,5.71,0,0,1,3.17,3.23q.3.15.27-.36c0-.34-.07-.77-.15-1.3s-.17-1-.26-1.56a2.79,2.79,0,0,1,0-1.27,21.66,21.66,0,0,1,4.63-.79c1.58-.09,3.16-.24,4.73-.43q1.66,11.53,3.22,24.14t2.73,26c.13,1.48.31,2.94.53,4.39a8,8,0,0,1-.19,3.73,15.11,15.11,0,0,1-1.59,3.47,11,11,0,0,1-1.75,2.13,6.63,6.63,0,0,1-1.82,1.21A11.72,11.72,0,0,1,605.84,149.81Zm-16.86-39,.1.83a15.06,15.06,0,0,0,3,7.8,5,5,0,0,0,3.63,2,6.67,6.67,0,0,0,3.85-.78,2.1,2.1,0,0,0,.86-1,5.22,5.22,0,0,0,.39-1.45,11.63,11.63,0,0,0,.12-1.7c0-.6,0-1.18,0-1.74q-.44-3.69-1-7.52a42.89,42.89,0,0,0-1.68-7.25,4.38,4.38,0,0,0-1.61-2,4.33,4.33,0,0,0-2.17-.73,4.17,4.17,0,0,0-2.15.49,5,5,0,0,0-1.75,1.57,18.49,18.49,0,0,0-1.36,3.68,17.67,17.67,0,0,0-.52,4.15,12.08,12.08,0,0,0,.08,1.82Q588.88,109.92,589,110.85Z"/>
                        <path className="cls-2"
                              d="M622.7,105.49l.2,1.66c.27,1.85.51,3.35.72,4.51A9.66,9.66,0,0,0,624.9,115a6.5,6.5,0,0,0,2.74,2.86,3.65,3.65,0,0,0,3.06.29,4.12,4.12,0,0,0,2.19-2.56,11.74,11.74,0,0,0,.19-5.79,13.28,13.28,0,0,0,1.88-.18c.78-.13,1.59-.27,2.44-.44a22.64,22.64,0,0,1,2.46-.34,6.81,6.81,0,0,1,2,.09c.1,1.62.2,3.2.29,4.75s.15,3,.17,4.34a30.62,30.62,0,0,1-.11,3.48,4.64,4.64,0,0,1-.56,2.09,7.13,7.13,0,0,1-2.67,3.51,11.51,11.51,0,0,1-4,1.6,27.9,27.9,0,0,1-4.54.54q-2.36.1-4.29.33a8.39,8.39,0,0,1-3.81-1.23,14,14,0,0,1-3.68-4.2,34.38,34.38,0,0,1-2.75-6,49.17,49.17,0,0,1-1.91-7,74.46,74.46,0,0,1-1-7.57c-.13-2.3-.25-4.52-.34-6.66a33.81,33.81,0,0,1,.33-6.37,11.8,11.8,0,0,1,2.23-4.91,12.66,12.66,0,0,1,3.89-3.38,14.49,14.49,0,0,1,4.87-1.66,14.77,14.77,0,0,1,5.22.12A9.54,9.54,0,0,1,634.65,83a15,15,0,0,1,3.6,5,28,28,0,0,1,2,6.79,69,69,0,0,1,.93,7.67,9.76,9.76,0,0,1-1.8.6q-1.33.35-3.15.75c-1.21.27-2.5.52-3.89.75s-2.7.42-4,.57-2.42.26-3.44.32A12.46,12.46,0,0,1,622.7,105.49Zm6.68-15.34a4.25,4.25,0,0,0-1.19-.56,3.47,3.47,0,0,0-1.35-.08,6.93,6.93,0,0,0-2.65.79,4.18,4.18,0,0,0-1.51,1.5,5.86,5.86,0,0,0-.71,2,16.74,16.74,0,0,0-.17,2.37,7.33,7.33,0,0,0,.07,1.44c0,.28.3.44.73.48a8.85,8.85,0,0,0,2-.15c.9-.14,2.23-.31,4-.52,1.2-.15,2.11-.22,2.72-.24a6.9,6.9,0,0,0,1.29-.1c.25-.06.37-.17.35-.33s-.06-.47-.12-1a8.72,8.72,0,0,0-1.15-3.42A5.61,5.61,0,0,0,629.38,90.15Z"/>
                        <path className="cls-2"
                              d="M666,82c.21-.21.48-.46.79-.75a5.43,5.43,0,0,0,.78-.85,12.05,12.05,0,0,1,3-2.61,10.09,10.09,0,0,1,3.6-1.37,9.62,9.62,0,0,1,3.78.11,7.94,7.94,0,0,1,3.54,1.92,7.71,7.71,0,0,1,1.5,3.34,56.94,56.94,0,0,1,1.24,6.27c.37,2.46.71,5.16,1,8.08s.65,5.9,1,8.89.62,5.89.9,8.66.6,5.22,1,7.3a20.92,20.92,0,0,1-4.29.94c-1.28.12-2.72.28-4.34.47-.27-1.4-.5-3-.72-4.79s-.43-3.64-.63-5.55-.44-3.82-.69-5.73-.52-3.69-.82-5.34c-.13-.74-.28-1.55-.43-2.44s-.34-1.75-.56-2.6a14.92,14.92,0,0,0-.76-2.26,3.66,3.66,0,0,0-1.06-1.46c0-.06-.1-.09-.15-.08a.17.17,0,0,1-.15-.08,5,5,0,0,0-1.7-.4,3.59,3.59,0,0,0-2.1.44A4.19,4.19,0,0,0,668,94a7.55,7.55,0,0,0-.52,3.86q.24,3.26.69,6.34t.92,6.17q.46,3.09.84,6.14t.45,6.14l.05.37a8.27,8.27,0,0,1-2,.8,12.79,12.79,0,0,1-1.77.26c-.6,0-1.28.07-2,.1a15.5,15.5,0,0,0-2.76.42c-.31-2.52-.68-5.56-1.13-9.1s-.93-7.34-1.44-11.41-1-8.27-1.55-12.62-1-8.58-1.53-12.71-1-8-1.37-11.7-.77-6.86-1-9.58c.56,0,1,0,1.4,0s.72,0,1-.08.67-.09,1-.17.83-.18,1.38-.3a6.92,6.92,0,0,0,.95-.26c.45-.15.9-.28,1.36-.4a6.15,6.15,0,0,1,1.24-.19c.38,0,.58.07.6.25s.2,1.12.36,2.45l.57,4.71q.33,2.73.7,5.83c.25,2.06.48,4,.69,5.77s.4,3.33.56,4.63S666,81.75,666,82Z"/>
                        <path className="cls-2"
                              d="M698.5,96.39l.2,1.66c.27,1.84.51,3.35.72,4.51a9.66,9.66,0,0,0,1.28,3.32,6.56,6.56,0,0,0,2.74,2.86,3.65,3.65,0,0,0,3.06.28,4.07,4.07,0,0,0,2.19-2.56,11.72,11.72,0,0,0,.19-5.79,10.75,10.75,0,0,0,1.88-.18c.78-.12,1.6-.27,2.44-.43a22.64,22.64,0,0,1,2.46-.34,6.56,6.56,0,0,1,2,.09c.1,1.61.19,3.2.28,4.75s.15,3,.17,4.34a30.62,30.62,0,0,1-.11,3.48,4.64,4.64,0,0,1-.56,2.09,7.06,7.06,0,0,1-2.67,3.5,11.56,11.56,0,0,1-4,1.61,28.16,28.16,0,0,1-4.55.54c-1.57.06-3,.18-4.29.33a8.39,8.39,0,0,1-3.81-1.23,14,14,0,0,1-3.68-4.2,34.49,34.49,0,0,1-2.75-6,47.23,47.23,0,0,1-1.9-7,68.71,68.71,0,0,1-1.05-7.56c-.14-2.3-.25-4.52-.35-6.67a33.85,33.85,0,0,1,.33-6.37A11.85,11.85,0,0,1,691,76.51a12.87,12.87,0,0,1,3.89-3.37A14.82,14.82,0,0,1,705,71.6a9.54,9.54,0,0,1,5.49,2.34,14.85,14.85,0,0,1,3.6,5,28,28,0,0,1,2.05,6.79,71.4,71.4,0,0,1,.92,7.67,10,10,0,0,1-1.8.59c-.88.24-1.93.49-3.14.76s-2.51.52-3.89.74-2.71.42-4,.58-2.42.26-3.44.32A13.47,13.47,0,0,1,698.5,96.39Zm6.68-15.34a4.12,4.12,0,0,0-1.19-.56,3.47,3.47,0,0,0-1.35-.08,6.93,6.93,0,0,0-2.65.79,4.15,4.15,0,0,0-1.51,1.49,5.71,5.71,0,0,0-.7,2.06,15.15,15.15,0,0,0-.18,2.36,7.45,7.45,0,0,0,.07,1.45c.06.27.3.43.73.48a9,9,0,0,0,2-.15c.89-.14,2.22-.31,4-.53,1.2-.14,2.11-.22,2.72-.23a5.94,5.94,0,0,0,1.29-.11c.25-.06.37-.16.35-.32s-.06-.48-.12-1a8.82,8.82,0,0,0-1.15-3.43A5.59,5.59,0,0,0,705.18,81.05Z"/>
                        <path className="cls-2"
                              d="M746.23,67.49c.09.36.17.73.23,1.09s.13.74.17,1.11l.11.93q1,10,2.43,19.63t2.14,19.58c.1.81.18,1.61.26,2.41s-.16,1.3-.7,1.49l-.84.1a18.82,18.82,0,0,1-2.27.14,16.89,16.89,0,0,0-2.27.13,7.36,7.36,0,0,0-1.48.32,7.93,7.93,0,0,1-1.48.31,1.08,1.08,0,0,1-.74-.66,4.36,4.36,0,0,1-.33-1.27,7.11,7.11,0,0,1-.06-1.68,8,8,0,0,0,0-1.59,15.69,15.69,0,0,1-3.75,4.3,9.39,9.39,0,0,1-4.77,2.16,5.69,5.69,0,0,1-4.92-1.94,21.67,21.67,0,0,1-3.9-5.81,44.44,44.44,0,0,1-2.81-7.83,59.48,59.48,0,0,1-1.55-8,37.94,37.94,0,0,1-.12-8.62,26,26,0,0,1,1.66-7,13.78,13.78,0,0,1,3.18-4.88,8.08,8.08,0,0,1,4.37-2.26c.84-.1,1.61-.18,2.34-.23a5.25,5.25,0,0,1,2,.22,4.48,4.48,0,0,1,1.75,1.11,7.61,7.61,0,0,1,1.48,2.3,12.21,12.21,0,0,1-.21-2.55,13.57,13.57,0,0,0-.08-2.2c0-.06.13-.12.43-.19a7.18,7.18,0,0,1,1-.12,10.78,10.78,0,0,0,1.12-.09l.76-.09c.79,0,1.76-.08,2.9-.16S744.73,67.54,746.23,67.49Zm-18,20.82a14.8,14.8,0,0,0-.06,2.49c.06.84.13,1.66.23,2.46a14.33,14.33,0,0,0,2.48,6.88,5.07,5.07,0,0,0,4.9,2.37,3.8,3.8,0,0,0,2.5-1.1,4.43,4.43,0,0,0,1-2.37,15.09,15.09,0,0,0,0-3.56c-.14-1.39-.32-3-.52-4.68-.26-2.15-.48-3.86-.66-5.12a11.22,11.22,0,0,0-.69-2.88,1.87,1.87,0,0,0-1.2-1.17,6.67,6.67,0,0,0-2.22,0A5.78,5.78,0,0,0,729.72,84,7.77,7.77,0,0,0,728.24,88.31Z"/>
                        <path className="cls-2"
                              d="M761.17,68.79q.26-.23,1.2-.9c.62-.45,1.24-.86,1.85-1.25a15.31,15.31,0,0,1,2.88-1.24,25,25,0,0,1,3.08-.79,22.76,22.76,0,0,1,2.45-.34c.68,0,1,0,1,0q.38,3.13.63,5.55c.17,1.61.33,3,.48,4.3s.29,2.42.42,3.47.26,2,.42,3l-.66-.2a4.8,4.8,0,0,0-.76-.24,5.77,5.77,0,0,1-.77-.23,14.93,14.93,0,0,0-1.76-.26c-.59-.06-.91-.08-1-.08a25.23,25.23,0,0,0-3.47.14,5.57,5.57,0,0,0-2.63,1,4.26,4.26,0,0,0-1.52,2.25,9.4,9.4,0,0,0-.08,4c.3,2.15.6,4.18.87,6.09s.53,3.83.76,5.77.49,3.91.75,5.91.53,4.14.81,6.42l0,.37a8.42,8.42,0,0,1-2.08.82,14.52,14.52,0,0,1-2,.28c-.7.06-1.46.1-2.27.14a15.75,15.75,0,0,0-2.87.43q-1.38-11.37-2.91-22.72t-2.73-23.59c.57,0,1,0,1.41,0s.72,0,1.05-.08.66-.09,1-.17.82-.17,1.37-.3a9.4,9.4,0,0,0,1-.22l1.5-.36a7.45,7.45,0,0,1,1.39-.22c.39,0,.6,0,.61.16.06.5.11.87.14,1.11s0,.45.07.6.05.33.09.51A6.92,6.92,0,0,1,761.17,68.79Z"/>
                        <path className="cls-2"
                              d="M787.2,45.12l1.49,12.39q.13,1.2.33,2.49c.13.87.25,1.76.36,2.68,1.43-.23,2.93-.51,4.51-.82l5.88-1.18a4.47,4.47,0,0,1,.82,2.11c.16,1,.28,2,.37,3.1s.16,2.24.23,3.39a17.13,17.13,0,0,0,.39,2.91l-10.76,1.29q.6,5,1.17,9.76t1.14,8.91a4.94,4.94,0,0,0,2.26,1.79,12.6,12.6,0,0,0,3,.82,15.11,15.11,0,0,0,3.15.13,20.93,20.93,0,0,0,2.74-.32c.2.85.4,1.79.62,2.83s.42,2.1.6,3.21.3,2.21.37,3.33a17.49,17.49,0,0,1-.09,3.11,40.41,40.41,0,0,1-5.77.64,25.22,25.22,0,0,1-5.75-.38,14.23,14.23,0,0,1-5-1.94,9.27,9.27,0,0,1-3.5-4.18,131,131,0,0,1-2.65-13.32q-.9-6.32-1.64-13.08c-.74.15-1.51.28-2.32.37l-2.67.33-1.15-11.31c.94-.05,1.81-.12,2.62-.22l2.32-.28c-.16-1.35-.36-2.82-.59-4.38s-.45-3.15-.65-4.76-.36-3.14-.51-4.62-.26-2.82-.31-4a7.37,7.37,0,0,1,2-.33c.72,0,1.48,0,2.28,0s1.6,0,2.39,0A9.94,9.94,0,0,0,787.2,45.12Z"/>
                        <path className="cls-2"
                              d="M802.27,41.62l.21,0c1.19-.27,2.56-.62,4.13-1.06a8.42,8.42,0,0,1,4.38-.15q1.29,11.85,2.7,24.48t3.25,24.42a34.07,34.07,0,0,1-4.34.85q-2.57.36-4.56.69H808q-.66-6.12-1.43-12.21T805,66.44c-.54-4.07-1-8.16-1.51-12.3S802.59,45.84,802.27,41.62Zm13.19,64.08-5.2.71c-.14,0-.26-.37-.35-1.17s-.19-1.78-.28-2.93-.17-2.34-.22-3.58-.14-2.3-.24-3.16v-.09A4.55,4.55,0,0,1,811,94.7a13.53,13.53,0,0,1,2.18-.27c.78,0,1.54,0,2.29,0a19.06,19.06,0,0,0,2.17-.12q.24,2.59.52,5.51c.18,1.95.39,3.82.6,5.61l-.49,0c-.55.07-1,.11-1.36.12s-.77.05-1.28.11Z"/>
                    </g>
                </symbol>
            </defs>
            <g id="Layer_1" data-name="Layer 1">
                <g width="1529.25" height="860.25" transform="translate(184.86 112.21)" xlinkHref="#game-frame-background">
                    <GameFrameBackground/>
                </g>
                <g>
                    {/*<use width="242.42" height="35.33" transform="translate(374.54 885.85)" xlinkHref="#game-progress-bar"/>*/}
                    <g width="209" height="209" transform="translate(183.87 764.53)" xlinkHref="#avatar-game-frame">
                        <AvatarGameFrame/>
                    </g>
                    {/*<use width="49.43" height="51.35" transform="translate(594.07 876.61)" xlinkHref="#game-heart"/>*/}
                </g>
                <g width="1539.81" height="871.64" transform="translate(179.58 106.52)" xlinkHref="#game-frame">
                    <GameFrame/>
                </g>
                <g id="Layer_2" data-name="Layer 2">
                </g>
            </g>
            <g id="Layer_2" data-name="Layer 2">
                <animated.g style={hugeHeartSpring}>
                    <g width="226.1" height="99.66" transform="translate(837.96 786.14)" xlinkHref="#continue-btn">
                        <GameBtn onClick={() => isGameCompleted ? completeGame() : completeLevel()}>
                            <ContinueButton/>
                        </GameBtn>
                    </g>
                    <g width="805.68" height="485.23" transform="translate(564.47 290.52)" xlinkHref="#huge-heart-achivement">
                        <HugeHeartAchievement/>
                    </g>
                    <use width="837.24" height="248.58" transform="translate(534.45 168.65)" xlinkHref="#Level_completed"/>
                </animated.g>
            </g>
        </svg>
    </SubscribingState>

}
