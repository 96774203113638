import {asState, ENTERING_STATE_ACTION, EXITED_STATE_ACTION, EXITING_STATE_ACTION, LEVEL_SELECTION_SCREEN_STATE, WELCOME_SCREEN_SCREEN_STATE} from "../constants";
import {HALF_SEC_FORWARD, makePipeFlow} from "../utils/classes/PipeCall";

export const welcomeScreenListeners = {
    [WELCOME_SCREEN_SCREEN_STATE]: {
        [ENTERING_STATE_ACTION]: makePipeFlow(HALF_SEC_FORWARD),
        [EXITING_STATE_ACTION]: makePipeFlow(HALF_SEC_FORWARD),
        [EXITED_STATE_ACTION]: makePipeFlow()
            .setArgs({state: asState(LEVEL_SELECTION_SCREEN_STATE, ENTERING_STATE_ACTION)})
            .condition('isChoosed', 'data.choosed', {choosed: 'configs.stateManager.avatar'})
            .conditionalCall('isChoosed', ({executor}) => executor.changeStateCall(), ({executor}) => executor.nextStateCall()),
    }
};
