import React from "react";
import '../../assets/scss/general-screen.scss';
import {AudioManager} from "../Managers/AudioManager";
import {InteractiveStateManager} from "../Managers/InteractiveStateManager";
import {ToolbarManager} from "../Managers/ToolbarManager";
import {SubscribedStateManager} from "../Managers/SubscribedStateManager";
import {Game} from "../games/Game";
import {SubscribingState} from "../../context/SubscribingState";
import {useDebuggerR} from "../../hooks/useDebuggerR";

export const GameFront = () => {
    useDebuggerR("init GameFront");

    return <SubscribedStateManager>
        {/*gamelisteners*/}
        <SubscribingState>
            {/*toolbar manager*/}
            <ToolbarManager>
                {/*interaction manager*/}
                <InteractiveStateManager>
                    {/*audio manager*/}
                    <AudioManager>
                        <Game/>
                    </AudioManager>
                </InteractiveStateManager>
            </ToolbarManager>
        </SubscribingState>
    </SubscribedStateManager>
}
