import React from "react";
import {animated, useSpring} from "react-spring";

export default function SoundSwitchButton({soundOn}) {


    const soundSpring = useSpring({
        from: {
            height: !soundOn ? 0 : 90,
        },
        height: soundOn ? 0 : 90,
    });

    return <svg xmlns="http://www.w3.org/2000/svg">
        <defs>
            <style>{".cls-1{fill:#1d76ce;}.cls-2{fill:#052b63;}.cls-3{fill:#fff;}.cls-4{fill:#00c0ed;}"}</style>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path className="cls-1" d="M0,5.39,22,85.8a1,1,0,0,0,1.23.65l70-21.76A1,1,0,0,0,94,63.58L84.68.82a1,1,0,0,0-1-.82L.94,4.12A1,1,0,0,0,0,5.39Z"/>
                <path className="cls-2" d="M69,22.1c-3.61-4.44-22.43-4.74-22.43-4.74s0,.08,0,.13a4.23,4.23,0,0,0-2.28.09,4.36,4.36,0,0,0-2.8,5.5c.92,2.85,4.28,11.67,7.27,19.44-.71-.07-1.43-.12-2.16-.12-9.27,0-16.78,5.87-16.78,13.12s7.51,13.12,16.78,13.12,16.78-5.87,16.78-13.12a11.2,11.2,0,0,0-3.15-7.62c-1.05-2.7-2.43-6.28-3.86-10C55.12,29.53,66.48,36.17,69,34.49S70.94,24.51,69,22.1Z"/>
                <path className="cls-3" d="M68.31,13.23C64.7,8.79,45.88,8.49,45.88,8.49v.13a4.23,4.23,0,0,0-2.28.09,4.37,4.37,0,0,0-2.81,5.5c.92,2.85,4.28,11.67,7.28,19.44-.71-.07-1.43-.12-2.16-.12-9.27,0-16.78,5.87-16.78,13.12s7.51,13.12,16.78,13.12S62.68,53.9,62.68,46.65A11.23,11.23,0,0,0,59.54,39c-1-2.69-2.44-6.28-3.86-10-1.24-8.39,10.12-1.75,12.63-3.43S70.26,15.64,68.31,13.23Z"/>
                <animated.rect className="cls-4" x="45.66" y="-4.01" width="4" height={soundSpring.height.to(x => x)} transform="translate(32.26 -20.06) rotate(35.28)"/>
            </g>
        </g>
    </svg>;
}
