import {createSlice} from '@reduxjs/toolkit'
import {readPlayerStateSave} from "./asyncThunk/savePlayerState";
import {scenesAvailable} from "../constants/assets";

export const generalStateSlice = createSlice({
    name: 'generalState',
    initialState: {
        finished: false,
        replaying: false,
        progress: new Array(scenesAvailable.length).fill(0),
        answers: new Array(scenesAvailable.length).fill(-1),
        backSoundOn: true,
        backSoundVolume: {
            act: 20,//.2,
            min: 10,//.1,
            max: 20,//.2,
        },
        sfxVolume: {
            act: 100,//.2,
            min: 50,//.1,
            max: 100,//.2,
        },
        canInteract: false,
    },
    reducers: {
        setInteractions: (state, {payload}) => {
            state.canInteract = payload;
        },
        setProgress: (state, {payload: {progress: newProgress, scene}}) => {

            const actProgress = [...state.progress];

            const toProgress = parseFloat(newProgress.toFixed(2));
            actProgress[scene] = toProgress > actProgress[scene] ? toProgress : actProgress[scene];

            state.progress = actProgress;

        },
        setAnswer: (state, {payload: {answer, scene}}) => {
            const answers = [...state.answers];

            answers[scene] = answer;

            state.answers = answers;
        },
        setBackSound: (state, action) => {
            state.backSoundOn = action.payload;
        },
        setAsFinished: state => {
            state.finished = true;
        },
        setReplaying: state => {
            state.replaying = true;
        }
    },
    extraReducers: builder => {
        builder.addCase(readPlayerStateSave.fulfilled, (state, action) => ({
            ...state,
            ...(action.payload?.state?.game ?? {})
        }))
    }
})

export const {setProgress, setAnswer, setBackSound, setInteractions, setAsFinished, setReplaying} = generalStateSlice.actions

export default generalStateSlice.reducer;
