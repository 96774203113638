import {AvatarBackground} from "./AvatarBackground";
import React from "react";

export const AvatarViewerBackground = () => {
    return <g>
        <g height="764.5" width="518.9" x="-259.4" y="-382.2">
            <AvatarBackground/>
        </g>
    </g>
};
