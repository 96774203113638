import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {logout, readPlayerInfo} from "./playerSlice";
import axios from "axios";


const CHOOSE_AVATAR = createAction('player/choose_avatar');

export const chooseAvatar = createAsyncThunk(CHOOSE_AVATAR.toString(), async ({
                                                                            face,
                                                                            hair,
                                                                            skin,
                                                                            accessory,
                                                                            hat,
                                                                            heart,
                                                                        }, thunkAPI) => {
    const response = await axios.post(`${process.env.REACT_APP_WEB_SERVICE_URL}/student/avatar`, {
        avatar: {
            face,
            hair,
            skin,
            accessory,
            hat,
            heart,
        }
    });
    return response.data;
})

const initialState = {
    face: Math.floor((Math.random() * 999) % 6),
    hair: Math.floor((Math.random() * 999) % 6),
    skin: Math.floor((Math.random() * 999) % 6),
    accessory: Math.floor((Math.random() * 999) % 6),
    hat: Math.floor((Math.random() * 999) % 6),
    heart: Math.floor((Math.random() * 999) % 6),
    choosed: false,
};
export const avatarSlice = createSlice({
    name: "avatar",
    initialState: initialState,
    reducers: {
        setPart: (state, {payload: {part, value}}) => {
            state[part] = value;
        },
        confirm: state => {
            state.choosed = true;
        },
        resetAvatar: () => initialState
    },
    extraReducers: builder => {
        builder.addCase(readPlayerInfo.fulfilled, (state, action) => {

            console.log(action.payload, Boolean(Number(action?.payload?.avatar?.choosed)))

            return ({
                ...initialState,
                ...action?.payload?.avatar ?? {},
                choosed: Boolean(Number(action?.payload?.avatar?.choosed))
            });
        });
        builder.addCase(logout.type, () => ({
            ...initialState,
        }))
    }
});


export const {setPart, confirm,resetAvatar} = avatarSlice.actions;


export default avatarSlice.reducer;
