import React from "react";
import '../../assets/scss/loading-screen.scss';
import {useSpring} from "react-spring";
import {LoadingScreenView} from "./LoadingScreen/View";
import {NotOnGameStateCmp} from "../../context/GameStateContext";
import {useSelector} from "react-redux";
import {ENTERING_STATE_ACTION, EXITING_STATE_ACTION, LOADING_SCREEN_SCREEN_STATE} from "../../constants";
import {SubscribingState} from "../../context/SubscribingState";


export const LoadingScreen = () => {
    const listeners = {
        [LOADING_SCREEN_SCREEN_STATE]: {
            [ENTERING_STATE_ACTION]: ({stateManager:{nextState}}) => {
                return loadingScreenOpacityApi.start({opacity: 1, onRest: () => nextState()});
            },
            [EXITING_STATE_ACTION]: ({stateManager:{nextState}}) => loadingScreenOpacityApi.start({opacity: 0,onRest: () => nextState() }),
        }
    };

    const [LoadingScreenOpacity, loadingScreenOpacityApi] = useSpring(() => ({opacity: 0}));
    const progress = useSelector(({loadingScreenState}) => loadingScreenState.progress);

    return <SubscribingState listeners={listeners}>
        <g>
            <NotOnGameStateCmp group="loading-screen" action="preload">
                <LoadingScreenView
                    LoadingScreenOpacity={LoadingScreenOpacity}
                    loadProgress={progress ?? 0}
                />
            </NotOnGameStateCmp>
        </g>
    </SubscribingState>
}

