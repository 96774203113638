import {
    ANSWER_SELECTION_SCREEN_STATE,
    AUDIO_ARPA_2,
    AUDIO_CONGRATS,
    AUDIO_DROP_PADLOCK,
    AUDIO_JUMP,
    AUDIO_OK,
    AUDIO_TRANSITION_2,
    AUDIO_WIN,
    AVATAR_CHANGE_STATE_ACTION,
    AVATAR_CHOOSED_STATE_ACTION,
    AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE,
    BACK_STATE_ACTION,
    COMPLETE_STATE_ACTION,
    CONFIRMED_STATE_ACTION,
    ENTERED_STATE_ACTION,
    ENTERING_STATE_ACTION,
    EXITING_STATE_ACTION,
    GAME_END_SCREEN_STATE,
    IDLE_STATE_ACTION,
    LEVEL_COMPLETE_STATE_ACTION,
    LEVEL_END_SCREEN_STATE,
    LEVEL_SCENE_SCREEN_STATE,
    LEVEL_SELECTION_SCREEN_STATE,
    LISTEN_STATE_ACTION,
    LOGIN_SCREEN_STATE,
    NEXT_SCENE_STATE_ACTION,
    PAUSE_STATE_ACTION,
    PLAY_SCENE_STATE_ACTION,
    PLAY_STATE_ACTION,
    PRELOAD_STATE_ACTION,
    PRESENTATION_STATE_ACTION,
    PREV_SCENE_STATE_ACTION,
    SELECTED_STATE_ACTION,
    SWITCHING_SOUND_STATE_ACTION,
    UNLOCKING_STATE_ACTION,
    WELCOME_SCREEN_SCREEN_STATE
} from "../constants";
import {soundManager} from "soundmanager2";
import {DIRECT_FORWARD, makePipeFlow, makeSinglePipeFlow} from "../utils/classes/PipeCall";

const toMinValue = ({stateManager: {backgroundAudio: {toMinVolume}}}) => toMinVolume();
const toMinValuePipe = ({configs: {stateManager: {backgroundAudio: {toMinVolume}}}}) => toMinVolume();
const toMaxValue = ({stateManager: {backgroundAudio: {toMaxVolume}}}) => toMaxVolume();
const toMinVolumePipe = ({configs: {stateManager: {backgroundAudio: {toMinVolume}}}}) => toMinVolume();
const toMaxValuePipe = ({configs: {stateManager: {backgroundAudio: {toMaxVolume}}}}) => toMaxVolume();

export const audioListeners = {
    "*": {
        [SWITCHING_SOUND_STATE_ACTION]: makeSinglePipeFlow(({configs: {stateManager: {backgroundAudio: {mute, unMute}, audioMixerState: {muted}, toPreviousAction}}}) => {
                muted ? unMute() : mute();
                toPreviousAction();
            }
        )
    },
    [LOGIN_SCREEN_STATE]: {
        [ENTERING_STATE_ACTION]: makeSinglePipeFlow(({configs: {stateManager: {backgroundAudio: {mute}}}}) => {
                mute();
            }
        )
    },
    [IDLE_STATE_ACTION]: {
        [ENTERING_STATE_ACTION]: makeSinglePipeFlow((all) => {
            const {configs: {stateManager: {nextState}}} = all;

            soundManager.onready(nextState);
        }),
    },
    [WELCOME_SCREEN_SCREEN_STATE]: {
        [ENTERING_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .playAudio(AUDIO_ARPA_2),
        [EXITING_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .playAudio(AUDIO_OK)
            .add(({configs: {stateManager: {backgroundAudio: {play}}}}) => play())
            .add(toMaxValuePipe)
    },
    [LEVEL_SELECTION_SCREEN_STATE]: {
        [PRELOAD_STATE_ACTION]: makeSinglePipeFlow(toMaxValuePipe, DIRECT_FORWARD),
        [COMPLETE_STATE_ACTION]: makeSinglePipeFlow(toMaxValuePipe, DIRECT_FORWARD),
        [NEXT_SCENE_STATE_ACTION]: ({stateManager: {backgroundAudio: {toMinVolume}, gameState: {sfxVolume}}, assetsManager: {get}}) => {
            get(AUDIO_TRANSITION_2).play().setVolume(sfxVolume.act);
            toMinVolume();
        },
        [PREV_SCENE_STATE_ACTION]: ({stateManager: {backgroundAudio: {toMinVolume}, gameState: {sfxVolume}}, assetsManager: {get}}) => {
            get(AUDIO_TRANSITION_2).play().setVolume(sfxVolume.act);
            toMinVolume();
        },
        [BACK_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .add(toMaxValuePipe),
        [UNLOCKING_STATE_ACTION]: ({stateManager: {backgroundAudio: {toMinVolume, toMaxVolume}}, assetsManager: {get}}) => {
            toMinVolume();
            setTimeout(() => {
                get(AUDIO_DROP_PADLOCK).play().on('onfinish', () => {
                    toMaxVolume();
                });

            })
        },
        [AVATAR_CHANGE_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .playAudio(AUDIO_JUMP),
        [PLAY_SCENE_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .playAudio(AUDIO_OK)
    },
    [LEVEL_SCENE_SCREEN_STATE]: {
        [PLAY_STATE_ACTION]: toMinValue,
        [PAUSE_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .add(toMaxValuePipe),
        [BACK_STATE_ACTION]: toMaxValue,
        [COMPLETE_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .add(toMaxValuePipe),
    },
    [ANSWER_SELECTION_SCREEN_STATE]: {
        [PRESENTATION_STATE_ACTION]: toMinValue,
        [IDLE_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .add(toMaxValuePipe),
        [LISTEN_STATE_ACTION]: toMinValue,
        [CONFIRMED_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .add(toMaxValuePipe)
            .playAudio(AUDIO_WIN),
        [SELECTED_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .playAudio(AUDIO_JUMP)
    },
    [LEVEL_END_SCREEN_STATE]: {
        [LEVEL_COMPLETE_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .playAudio(AUDIO_OK),
        // [GAME_COMPLETE_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
        //     .playAudio(AUDIO_CONGRATS),
    },
    [GAME_END_SCREEN_STATE]: {
        [ENTERED_STATE_ACTION]: makePipeFlow()
            .playAudio(AUDIO_CONGRATS)
    },
    [AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE]: {
        [AVATAR_CHOOSED_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .playAudio(AUDIO_OK)
    }
};
