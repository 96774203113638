import React from "react";
import {Skin1} from "./Skin/Skin1";
import {Skin2} from "./Skin/Skin2";
import {Skin3} from "./Skin/Skin3";
import {Skin4} from "./Skin/Skin4";
import Skin5 from "./Skin/Skin5";
import Skin6 from "./Skin/Skin6";

export const AvatarSkin = ({n = 0}) => {

    const els = [
        Skin6,
        Skin5,
        Skin4,
        Skin3,
        Skin2,
        Skin1,
    ]

    return <g>
        {(els[n] && React.createElement(els[n])) || null}
    </g>
}
