import '../../assets/scss/login.scss';
import {LoginGraphic} from "../partials/LoginGraphic";
import {useEffect} from "react";
import {LOGIN_SUCCESS_STATE_ACTION, TRY_LOGIN_STATE_ACTION} from "../../constants";
import {SplashLoader} from "../partials/SplashLoader";
import {animated, useSpring} from "react-spring";
import {useSelector} from "react-redux";
import {useGameActionSetter} from "../../hooks/useGameActionSetter";
import {LoginForm} from "../partials/LoginForm";
import {setAxiosAuth} from "../../store/playerSlice";

export const LoginScreen = () => {
    const {error: hasError, logged, submitting, token} = useSelector(({player}) => player);

    const tryLogin = useGameActionSetter(TRY_LOGIN_STATE_ACTION);
    const loginSuccess = useGameActionSetter(LOGIN_SUCCESS_STATE_ACTION);

    useEffect(() => {
        if (logged) {
            setTimeout(() => {
                opSpringApi.start({
                    to: {opacity: 0},
                    onRest: result => result && loginSuccess()

                })
            }, 1000)
        }
        else {
            setAxiosAuth();
        }
    }, [logged])

    useEffect(() => {
        if (token) {
            setAxiosAuth(token);
        }
    }, [token])

    const handleEnterClick = (nickname, classCode) => {
        if (nickname.length > 0 && classCode.length > 0) {
            tryLogin({
                nickname,
                classCode
            });
        }
    }

    const submittingLogin = submitting || logged || token;

    const [opSpring, opSpringApi] = useSpring(() => ({to: {opacity: 1}, immediate: true}));

    return <animated.g style={opSpring}>
        <g transform="translate(190 104)">
            <LoginGraphic/>
            <LoginForm handleEnterClick={handleEnterClick} hasError={hasError} submitting={submittingLogin}/>
        </g>
        {submittingLogin && <SplashLoader/>}
    </animated.g>

}
