import React from "react";

export default function ReplayButton() {
    return <svg data-name="replay-btn">
        <path className="replay-btn-16" d="M0,5.51,22.51,87.63a1,1,0,0,0,1.26.66L95.29,66.06a1,1,0,0,0,.7-1.13L86.48.84a1,1,0,0,0-1-.84L1,4.21A1,1,0,0,0,0,5.51Z"/>
        <g>
            <g id="bc4c7Q.tif">
                <path className="replay-btn-19"
                      d="M36.19,38.65H25.71c-1.71,0-2.87-.83-3-2.34A3,3,0,0,1,23,34.56C26.65,28.27,30.32,22,34,15.74a2.58,2.58,0,0,1,2.66-1.37,2.62,2.62,0,0,1,2.34,2c.3,1,.59,2,.88,3.05.33,1.18.86,1.43,2,.93a26,26,0,0,1,11.5-2.23,26.51,26.51,0,0,1,20.18,10,26.25,26.25,0,0,1,6,19.63A27.11,27.11,0,0,1,29.5,59.41,5.39,5.39,0,0,1,33.61,51a5.06,5.06,0,0,1,5,2.47,17.13,17.13,0,0,0,5.68,5.71A16.26,16.26,0,1,0,55.61,29.26a15.79,15.79,0,0,0-8.26.6c-1.08.37-1.22.95-.45,1.77s1.41,1.47,2.12,2.2a2.74,2.74,0,0,1-2,4.74H36.19Z"/>
            </g>
            <g id="bc4c7Q.tif-2" data-name="bc4c7Q.tif">
                <path className="replay-btn-2"
                      d="M36.19,32.65H25.71c-1.71,0-2.87-.83-3-2.34A3,3,0,0,1,23,28.56C26.65,22.27,30.32,16,34,9.74a2.58,2.58,0,0,1,2.66-1.37,2.62,2.62,0,0,1,2.34,2c.3,1,.59,2,.88,3,.33,1.18.86,1.43,2,.93a26,26,0,0,1,11.5-2.23,26.51,26.51,0,0,1,20.18,10,26.25,26.25,0,0,1,6,19.63A27.11,27.11,0,0,1,29.5,53.41,5.39,5.39,0,0,1,33.61,45a5.06,5.06,0,0,1,5,2.47,17.13,17.13,0,0,0,5.68,5.71A16.26,16.26,0,1,0,55.61,23.26a15.79,15.79,0,0,0-8.26.6c-1.08.37-1.22.95-.45,1.77s1.41,1.47,2.12,2.2a2.74,2.74,0,0,1-2,4.74H36.19Z"/>
            </g>
        </g>
    </svg>;
}
