import {ANSWER_SELECTION_SCREEN_STATE, IDLE_STATE_ACTION, LEVEL_END_SCREEN_STATE, LEVEL_SCENE_SCREEN_STATE, LEVEL_SELECTION_SCREEN_STATE, LOCKED_STATE_ACTION, PLAYING_STATE_ACTION, UNLOCKED_STATE_ACTION, WELCOME_SCREEN_SCREEN_STATE} from "../constants";

export const interactiveStateConfigs = {
    [WELCOME_SCREEN_SCREEN_STATE]: {
        [IDLE_STATE_ACTION]: true,
    },
    [LEVEL_SELECTION_SCREEN_STATE]: {
        [LOCKED_STATE_ACTION]: true,
        [UNLOCKED_STATE_ACTION]: true,
    },
    [ANSWER_SELECTION_SCREEN_STATE]: {
        [IDLE_STATE_ACTION]: true,
    },
    [LEVEL_SCENE_SCREEN_STATE]: {
        [IDLE_STATE_ACTION]: true,
        [PLAYING_STATE_ACTION]: true,
    },
    [LEVEL_END_SCREEN_STATE]: {
        [IDLE_STATE_ACTION]: true,
    }
};
