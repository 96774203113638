import React from "react";

export default () => {
    return <g style={{
        transform: 'matrix(1, 0, 0, 1, 668, 464)'
    }}>
        <style>{`
      .hat-2-1 {
        fill: #09070a;
      }

      .hat-2-2 {
        fill: #22282b;
      }

      .hat-2-3 {
        fill: #579c9e;
      }

      .hat-2-4 {
        fill: #579c9f;
      }

      .hat-2-5 {
        fill: #0c0a0d;
      }

      .hat-2-6 {
        fill: #f9f9f9;
      }
    `}</style>

        <g>
            <g opacity={1}>
                <path className="hat-2-1"
                      d="M691.08,359.85c-2.18-5.59-1.32-11.71-3-17.39-2.51-8.37-5-16.75-7.48-25.25-12.8,8-27,9.91-41.15,11.52q-16.89,1.92-33.65,4.67a50.28,50.28,0,0,1-10.42,1c-28.43-1.38-56.56,2.37-84.77,4.72-20.08,1.68-40.16,3.56-60.32,3.69-29.37.19-58.54,3.32-87.75,5.58-25.09,1.94-50.35.84-75.32,4.12-21.49,2.83-43.09,4.4-64.65,6.29a324,324,0,0,1-56.41-.1c-16.3-1.42-32.63-1.36-48.95-1.61-13.57-.21-26.38-3.18-38.16-10-.85,0-.68.61-.71,1-.1,1.78.37,4.06-2.12,4.36-2.07.25-2.48-1.83-3.14-3.34a63.3,63.3,0,0,1-5.41-22.75c-1.14-26.45-3.32-53.09,1.71-79.27C75.08,217.3,83,188,98.13,161.23a118,118,0,0,1,17.21-23.83c5-5.31,9.74-11,14.68-16.37,9.34-10.25,19.41-19.62,31.13-27.23,6.83-4.43,13-9.8,19.56-14.71,28-21.16,58.44-38.45,90.31-53,16.82-7.67,35.24-11,53-16.23,49.6-14.63,99-11.4,148.71-.68,26.66,5.74,52.05,15.27,77.73,24a41.07,41.07,0,0,1,12.94,7.45c19.85,16.47,40.4,32.14,56.89,52.32,28.7,35.11,49,74,55.67,119.37,3.18,21.59,7.69,43,9.1,64.8.67,10.37,5,17.3,12,24.29a217.78,217.78,0,0,1,43.71,62.28c7.53,16.14,10.6,32.94,7.27,50.57-1.63,8.62-5.81,15.5-14.78,18.58-2.63.91-4.89,2.83-7.38,4.18-4.59,2.5-9.39,4.3-14.77,3.87-5-.41-10-.45-14.29,2.93a3.27,3.27,0,0,1-4.57-.34c-1.17-1.19-1.71-3-.71-4.11,5.13-5.62,1.84-12.27,2.55-18.41-.87-9-.23-18-.4-27.13,2.69.27,2.58,2.12,3.29,3.31.85,3.11.55,6.39,1.34,9.51.52,3.53-.23,7.14.7,10.65.5,3.95,1.48,7.84,1.49,11.86,0,2.94,1.46,4.51,4.33,5.19,12,2.87,21.58-.92,29.2-10.29,8.73-10.74,10.33-22.76,6-35.65-6.79-20.31-15.42-39.61-30.13-55.7-4.8-5.26-8.82-11.25-13.39-16.74-1.8-2.16-4.32-5.44-6.61-4.42-2.59,1.16-.25,4.72.09,7.21,1.43,10.54,1.61,21.2,3.38,31.71.33,2,.63,3.93,1,5.9,0,2.63.88,5.51-1.66,7.6C690.87,362.84,692.28,360.91,691.08,359.85Z"/>
                <path className="hat-2-2" d="M691.08,359.85c1.14.59,1,1.87,2.1,2.83v-7c2.62.69,2.16,3,2.49,4.84,1.27,7.06,1.07,14.19,1,21.3a84.29,84.29,0,0,0,.74,10.89c.22,1.82.19,3.67-1.31,5.09-1.27-.53-.64-2.09-2.07-3.34V421c-1.31-2.3-1-4.85-1-7.32C692.66,395.71,693.76,377.71,691.08,359.85Z"/>
                <path className="hat-2-3"
                      d="M354.24,338.58c-7.16,0-14.34.26-21.49-.05a124.18,124.18,0,0,0-42.41,5.34c-27.94,8.6-56.82,8.52-85.62,9.32-32.21.9-64.14-4.06-96.29-4.35-4,0-8-.52-12-.71s-8-.61-10.73-4.23c-1.08-1.42-3-1.53-4.65-1.3-4.57.67-5.57-2-6-5.71-3.24-27.1-3.49-54.29,1.12-81.12,5.2-30.25,13.54-59.75,28-87.11a71.42,71.42,0,0,1,12.42-17.42c17-16.93,31.57-36.4,51.25-50.37C197.18,80.08,226,58.4,258.55,42.66c16.75-8.09,34.75-12.47,52.53-17.49,27.63-7.79,56.12-8.56,84.43-11.43,29.22-3,56.92,3.21,85,8.74a227.66,227.66,0,0,1,63.63,23c21.11,11.3,37.24,28.18,53.27,45.35,11,11.74,20.94,24.18,29.13,38,8.75,14.74,16.89,29.81,23.46,45.7,5.66,13.7,9.55,28,15.53,41.62,3.09,7,4,14.63,5,22.29,1.66,12.38,2.32,24.83,3.37,37.24.68,8.11,1.16,16.2,2.4,24.27.84,5.5-1.8,8.54-7.38,9.33-22.74,3.19-45,9.36-67.92,11.37-34.45,3-68.59,8.85-103.49,7.54-17.29-.64-34.78,2.83-52.11,5q-27,3.33-54.22,4.57c-12.3.54-24.64.1-37,.1Z"/>
                <path className="hat-2-4"
                      d="M696.1,397.84c-1.7-14,.21-28.23-2.92-42.12-2-6.45-2.26-13.23-2.91-19.86s-.81-13.58-2.57-20.16a8.79,8.79,0,0,1,.8-7.25,23,23,0,0,1,8.38,6.12c11.32,14.31,24.43,27.36,32.35,44.12,5.39,11.4,10.86,22.77,13.54,35.27a36.4,36.4,0,0,1-21.38,41.15c-6.06,2.54-12.13,1.49-18.15.49-2.42-.4-4.21-2.21-4.26-5.33-.06-4.14-.66-8.28-1-12.42-1-3.68.33-7.51-.72-11.19C695.47,403.9,696.49,400.78,696.1,397.84Z"/>
                <path className="hat-2-2" d="M697.23,406.66c1.46,3.65.72,7.45.72,11.19C696.31,414.21,697.25,410.41,697.23,406.66Z"/>
                <path className="hat-2-5"
                      d="M453.3,299.11c-2.51,4.86-6.35,5.36-10.37,5.55-35.4,1.69-70.8,3-106.24,3.45-18.47.23-36.9,2-55.39,1.79-1,0-2,.08-3,0-1.71-.17-4.51.61-4.56-1.69s2.88-1.4,4.46-1.69a48.57,48.57,0,0,1,7.45-.48c4.37-.16,6.52-3.13,5.55-7.5-3.34-15.09.14-28.27,11.22-39.25,4.61-4.55,9.89-8.44,13.63-13.86a5.58,5.58,0,0,1,2.3-1.86c12.88-5.32,25.14-12.07,39.42-13.78a149.56,149.56,0,0,1,30.36-.09,18.45,18.45,0,0,1,12.11,6.37c5.34,5.94,10.52,12,15.68,18.13A41.42,41.42,0,0,1,426,281.69c.07,17.25.37,17.43,17.47,17.42Z"/>
                <path className="hat-2-6" d="M366.85,237.55c3.66,0,7.33.17,11,0,7.7-.45,13.43,3,18.14,8.7,3.71,4.48,7.1,9.28,11.14,13.44,10.1,10.41,10.82,23.89,12.7,37,.5,3.49-2.56,2.57-4.13,2.69-15.93,1.18-31.88,2-47.8,3.25-22.9,1.76-45.91.59-68.77,2.87-3.41.35-4.37-.48-4.48-4.2-.35-12,.91-23.52,7.76-33.88,2.12-3.22,4.1-6.55,7.08-9,15-12.19,30.9-22.17,51.39-20.84,2,.12,4,0,6,0Z"/>
            </g>
        </g>

    </g>;
}
