import {LoadableAsset} from "../LoadableAsset";

export class SceneAsset extends LoadableAsset {
    _assetType = 'sceneLoadable';

    /**
     *
     * @param prop
     * @param {string=} assetsPath
     */
    constructor(prop, {assetsPath} = {assetsPath: ''}) {
        super(prop);
        this._assetsPath = assetsPath;
    }

    _assetsPath;

    get assetsPath() {
        return this._assetsPath;
    }

    loadAsset({resolve, reject}) {
        this.assetsProperties().then(r => {
            this._asset = r;
            return resolve(true);
        }).catch(reason => reject(reason));
    }
}
