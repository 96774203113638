import {useEffect, useState} from "react";
import {animated, config, useSpring} from "react-spring";
import {TextField} from "./TextField";
import {GameBtn} from "./GameBtn";
import {EnterBtn} from "./EnterBtn";
import {useGameActionSetter} from "../../hooks/useGameActionSetter";
import {LOGIN_ERROR_STATE_ACTION, LOGIN_SUCCESS_STATE_ACTION} from "../../constants";

const nicknameInit = "";
const classCodeInit = "";
export const LoginForm = ({handleEnterClick: onSubmit, submitting, hasError}) => {
    const [nickname, setNickName] = useState(nicknameInit);
    const [classCode, setClassCode] = useState(classCodeInit);
    const loginError = useGameActionSetter(LOGIN_ERROR_STATE_ACTION);

    const [{x}, spApi] = useSpring(() => ({
        to: {x: 1},
        config: config.slow
    }));

    useEffect(() => {
        if (hasError) {
            loginError();
            spApi.start({
                from: {x: 0},
                to: {x: 1}
            })
        }
    }, [hasError]);

    const handleEnterClick = () => onSubmit(nickname, classCode);

    return <g>
        <animated.g height="68" transform={x
            .to({
                range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                output: [20, 0, 20, 0, 20, 0, 20, 0]
            })
            .to(x => `translate(${997.9 + x} 282)`)} width="300">
            <TextField classes={hasError ? 'error' : ''} handleSubmit={handleEnterClick} disabled={submitting} placeholder="Nickname" value={nickname} onChange={(event) => setNickName(event.target.value)}/>
        </animated.g>
        <animated.g height="68" transform={x
            .to({
                range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                output: [20, 0, 20, 0, 20, 0, 20, 0]
            })
            .to(x => `translate(${997.9 + x} 374)`)} width="300">
            <TextField classes={hasError ? 'error' : ''} handleSubmit={handleEnterClick} disabled={submitting} placeholder="Class code" value={classCode} onChange={(event) => setClassCode(event.target.value)}/>
        </animated.g>
        <g height="99.66" transform="translate(1034.85 479.5)" width="226.1">
            <GameBtn disabled={submitting} onClick={handleEnterClick}>
                <EnterBtn/>
            </GameBtn>
        </g>
    </g>;
}
