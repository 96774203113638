
import answers_audio from './answers';
import miscellaneous_audio from './miscellaneous';
import questions_audio from './questions';
import scenes_audio from './scenes';
import sfx_audio from './sfx';

const audio = {
    scenes: scenes_audio,
    questions: questions_audio,
    miscellaneous: miscellaneous_audio,
    answers: answers_audio,
    sfx: sfx_audio,
};

export default audio;
