import {AUTH_SUCCESS_STATE_ACTION, ENTERED_STATE_ACTION, EXITED_STATE_ACTION, EXITING_STATE_ACTION, IDLE_STATE_ACTION, LOGIN_ERROR_STATE_ACTION, LOGIN_SCREEN_STATE, LOGIN_SUCCESS_STATE_ACTION, TRY_LOGIN_STATE_ACTION} from "../constants";
import {clearToken} from "../store/playerSlice";
import {DIRECT_FORWARD, makePipeFlow} from "../utils/classes/PipeCall";

export const loginScreenListeners = {
    [LOGIN_SCREEN_STATE]: {
        [ENTERED_STATE_ACTION]: makePipeFlow()
            .extractConfigs({player: "stateManager"})
            .setArgs(({data: {player}}) => ({
                    action:
                        player.token ? AUTH_SUCCESS_STATE_ACTION :
                            player.error ? LOGIN_ERROR_STATE_ACTION :
                                player.logged ? LOGIN_SUCCESS_STATE_ACTION :
                                    IDLE_STATE_ACTION
                }
            ))
            .delayChangeActionCall(1000),
        [AUTH_SUCCESS_STATE_ACTION]: makePipeFlow()
            .extractConfigs({
                token: "stateManager.player",
                changeAction: "stateManager",
                clearToken: "stateManager",
                readPlayerInfo: "stateManager",
            })
            .add(({data: {token, changeAction, readPlayerInfo, clearToken}}) => {
                    if (token) {
                        readPlayerInfo().then(value => {
                            changeAction(LOGIN_SUCCESS_STATE_ACTION);
                        }).catch(reason => {
                            changeAction(IDLE_STATE_ACTION);
                            clearToken();
                        })
                    }

                    return true;
                }
            ),
        [TRY_LOGIN_STATE_ACTION]: makePipeFlow()
            .extractConfigs({nickname: 'stateManager.options', classCode: 'stateManager.options'})
            .setArgs(({data}) => data)
            .callFn('configs.stateManager.loginPlayer', ({data}) => data),
        [LOGIN_SUCCESS_STATE_ACTION]: makePipeFlow()
            .callFn('configs.stateManager.readPlayerInfo')
            .add(({args, executor}) =>
                args.singleRes['configs.stateManager.readPlayerInfo'].then(() => {
                    const callS = executor.singleCall("configs.stateManager.readPlayerStateSave");
                    callS.args.singleRes["configs.stateManager.readPlayerStateSave"]
                        .then(() => {
                            callS.executor.nextStateCall();
                        });
                })
            ),
        [EXITING_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD),
        [EXITED_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD),
    }
};
