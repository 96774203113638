import {OnGameStateCmp} from "../../context/GameStateContext";
import {SplashLoader} from "./SplashLoader";
import React from "react";
import {CHECK_STATE_ACTION, ENTERED_STATE_ACTION, NEXT_SCENE_STATE_ACTION, PREV_SCENE_STATE_ACTION} from "../../constants";
import {useDebuggerR} from "../../hooks/useDebuggerR";

export const ScenePreloader = () => {

    useDebuggerR("init ScenePreloader", "init");

    return <OnGameStateCmp action={["preload", "idle", NEXT_SCENE_STATE_ACTION, PREV_SCENE_STATE_ACTION, ENTERED_STATE_ACTION, CHECK_STATE_ACTION]}>
        <mask id="myMask2">
            <polygon fill="white" points="982.15 530.62 18.8 588.93 18.8 0 1000.78 39.65 982.15 530.62"/>
        </mask>
        <g mask="url(#myMask2)">
            <g style={{
                transform: "translate(0px,-25px) scale(1.1)",
            }}>
                <svg width="1000.78" height="604.93">
                    <SplashLoader/>
                </svg>
            </g>
        </g>
    </OnGameStateCmp>;
};
