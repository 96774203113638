import {AssetsManager} from "../classes/AssetsManager";
import {SoundAsset} from "../classes/LoadableAsset/SoundAsset";
import soundAssets from '../assets/sounds';
import {map} from 'lodash';
import all_scenes, {assetPaths, finale_01, question_intro} from "../assets/scenes";
import {SceneAsset} from "../classes/LoadableAsset/SceneAsset";
import {ComponentAsset} from "../classes/LoadableAsset/ComponentAsset";
import {ImageAsset} from "../classes/LoadableAsset/ImageAsset";

export const assetManager = new AssetsManager();

window.assetsManager = assetManager;

/**
 * @typedef {Object} assetManagerHookReturn
 * @property {preloadFn} preload
 * @property {getAssetFn} get
 * @property {getAssetsListFn} getList
 */

/**
 *
 * @return {assetManagerHookReturn}
 */
export const useAssetManager = () => {
    return {
        get: assetManager.getLoadableAsset.bind(assetManager),
        preload: assetManager.preload.bind(assetManager),
        getList: assetManager.getAssetsList.bind(assetManager)
    }
}

export const useAssetManagerList = (keys, asAsset = false) => useAssetManager().getList(keys, asAsset);

export const useAssetManagerPreloader = () => useAssetManager().preload;

map(soundAssets, (group, groupKey) => map(group, (el, key) => assetManager.addAsset(new SoundAsset({
    id: `${groupKey}.${key}`,
    url: el,
    options: {
        // multiShot: false
    },
    stream: false,
    multiShot: false,
    autoLoad: false,
    autoPlay: false,
}), `${groupKey}.${key}`)));


map(all_scenes, (el, key) => {

    const sceneAsset = new SceneAsset(el, {assetsPath: assetPaths[key] ?? ''});

    assetManager.addAsset(sceneAsset, `scenes.${key}`);
})

assetManager.addAsset(new SceneAsset(finale_01), `finale.finale_01`);
assetManager.addAsset(new SceneAsset(question_intro), `questions.intro`);

assetManager.addAsset(new ImageAsset(() => import("../assets/previews/scene_01.png")), 'previews.scene_01');
assetManager.addAsset(new ImageAsset(() => import("../assets/previews/scene_02.png")), 'previews.scene_02');
assetManager.addAsset(new ImageAsset(() => import("../assets/previews/scene_03.png")), 'previews.scene_03');
assetManager.addAsset(new ImageAsset(() => import("../assets/previews/scene_04.png")), 'previews.scene_04');
assetManager.addAsset(new ImageAsset(() => import("../assets/previews/scene_05.png")), 'previews.scene_05');
assetManager.addAsset(new ImageAsset(() => import("../assets/previews/scene_06.png")), 'previews.scene_06');
assetManager.addAsset(new ImageAsset(() => import("../assets/previews/scene_07.png")), 'previews.scene_07');
assetManager.addAsset(new ImageAsset(() => import("../assets/previews/scene_08.png")), 'previews.scene_08');
assetManager.addAsset(new ImageAsset(() => import("../assets/previews/scene_09.png")), 'previews.scene_09');
assetManager.addAsset(new ImageAsset(() => import("../assets/previews/scene_10.png")), 'previews.scene_10');
assetManager.addAsset(new ImageAsset(() => import("../assets/previews/scene_11.png")), 'previews.scene_11');
assetManager.addAsset(new ImageAsset(() => import("../assets/previews/scene_12.png")), 'previews.scene_12');
assetManager.addAsset(new ImageAsset(() => import("../assets/previews/scene_13.png")), 'previews.scene_13');
assetManager.addAsset(new ImageAsset(() => import("../assets/previews/scene_14.png")), 'previews.scene_14');


assetManager.addAsset(new ComponentAsset(() => import("../components/partials/BackgroundRay")), 'components.BackgroundRay');
assetManager.addAsset(new ComponentAsset(() => import("../components/partials/BackgroundTerrain")), 'components.BackgroundTerrain');
assetManager.addAsset(new ComponentAsset(() => import("../components/partials/LoadingBar")), 'components.LoadingBar');
assetManager.addAsset(new ComponentAsset(() => import("../components/partials/PresentationSplashText")), 'components.PresentationSplashText');
assetManager.addAsset(new ComponentAsset(() => import("../components/partials/WelcomeFrame")), 'components.WelcomeFrame');
assetManager.addAsset(new ComponentAsset(() => import("../components/partials/PlayButton")), 'components.PlayButton');
assetManager.addAsset(new ComponentAsset(() => import("../components/partials/RollyRobot")), 'components.RollyRobot');

assetManager.addAsset(new ComponentAsset(() => import("../components/partials/GameFrameBackground")), 'components.GameFrameBackground');
assetManager.addAsset(new ComponentAsset(() => import("../components/partials/LearningSplashText")), 'components.LearningSplashText');
assetManager.addAsset(new ComponentAsset(() => import("../components/partials/LevelFrame")), 'components.LevelFrame');
assetManager.addAsset(new ComponentAsset(() => import("../components/partials/GameFrame")), 'components.GameFrame');
assetManager.addAsset(new ComponentAsset(() => import("../components/partials/LockSym")), 'components.LockSym');
assetManager.addAsset(new ComponentAsset(() => import("../components/partials/PrevLevelButton")), 'components.PrevLevelButton');
assetManager.addAsset(new ComponentAsset(() => import("../components/partials/NextLevelButton")), 'components.NextLevelButton');







