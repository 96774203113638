//region questions audio
import question_01_audio from './01.mp3';
import question_02_audio from './02.mp3';
import question_03_audio from './03.mp3';
import question_04_audio from './04.mp3';
import question_05_audio from './05.mp3';
import question_06_audio from './06.mp3';
import question_07_audio from './07.mp3';
import question_08_audio from './08.mp3';
import question_09_audio from './09.mp3';
import question_10_audio from './10.mp3';
import question_11_audio from './11.mp3';
import question_12_audio from './12.mp3';
import question_13_audio from './13.mp3';
import question_14_audio from './14.mp3';
//endregion

const all_audio = {
    audio_01: question_01_audio,
    audio_02: question_02_audio,
    audio_03: question_03_audio,
    audio_04: question_04_audio,
    audio_05: question_05_audio,
    audio_06: question_06_audio,
    audio_07: question_07_audio,
    audio_08: question_08_audio,
    audio_09: question_09_audio,
    audio_10: question_10_audio,
    audio_11: question_11_audio,
    audio_12: question_12_audio,
    audio_13: question_13_audio,
    audio_14: question_14_audio,
};
export default all_audio;
