import {BACK_STATE_ACTION, CHECK_STATE_ACTION, COMPLETE_STATE_ACTION, ENTERING_STATE_ACTION, LEVEL_SELECTION_SCREEN_STATE, LOCKED_STATE_ACTION, NEXT_SCENE_STATE_ACTION, PLAY_AGAIN_STATE_ACTION, PRELOAD_STATE_ACTION, PREV_SCENE_STATE_ACTION, UNLOCK_STATE_ACTION, UNLOCKED_STATE_ACTION} from "../constants";
import {halfSecondDelay} from "../utils/forwardState";
import {DIRECT_FORWARD, makePipeFlow} from "../utils/classes/PipeCall";

export const levelSelectionListeners = {
    [LEVEL_SELECTION_SCREEN_STATE]: {
        [ENTERING_STATE_ACTION]: makePipeFlow()
            .extractConfigs({choosed: 'stateManager.levelSelectionScreenState', choose: 'stateManager'})
            .callFn('data.choose', ({data: {choosed}}) => choosed)
            .toNextState(),
        [PRELOAD_STATE_ACTION]: makePipeFlow()
            .extractConfigs({choosed: 'stateManager.levelSelectionScreenState', available: 'stateManager.levelSelectionScreenState', audio: 'stateManager.levelSelectionScreenState', get: 'assetsManager',})
            .add(({data: {get, choosed: sceneChoosed, available: scenesAvailable, audio: scenesAudio, titles: scenesTitle}, configs: {writeD}, executor}) => {
                const audioChoosed = scenesAudio[sceneChoosed];
                sceneChoosed = scenesAvailable[sceneChoosed];

                writeD(get(sceneChoosed).isLoaded ? 'scena caricata' : 'scena NON caricata', LEVEL_SELECTION_SCREEN_STATE)

                executor.condition("isSceneLoaded", get(sceneChoosed).isLoaded && get(audioChoosed).isLoaded);
                executor.argsCall(() => ({
                    batchLoad: [sceneChoosed, audioChoosed],
                    finishDelay: halfSecondDelay
                }))
            })
            .conditionalCall("isSceneLoaded", ({executor,configs: {writeD}}) => {
                writeD("chiamo il prossimo stato", LEVEL_SELECTION_SCREEN_STATE)
                return executor.nextStateCall();
            }, ({executor, configs: {writeD}}) => {
                writeD("effettuo il preload della scena", LEVEL_SELECTION_SCREEN_STATE)
                executor.preloadCall();
            }),
        [CHECK_STATE_ACTION]: makePipeFlow()
            .extractConfigs({state: 'stateManager.levelSelectionScreenState'})
            .add(({data: {state: sceneState}, executor}) => {
                const sceneUnlocked = sceneState === 'unlocked';
                const sceneUnlockable = sceneState === 'unlockable';
                const sceneLocked = true;

                executor.condition("sceneLockState", () => [sceneUnlocked, sceneUnlockable, sceneLocked]);
                executor.argsCall(() => ({action: sceneUnlocked ? UNLOCKED_STATE_ACTION : sceneUnlockable ? UNLOCK_STATE_ACTION : LOCKED_STATE_ACTION}));
            })
            .conditionalCall(
                "sceneLockState",
                ({executor}) => executor.changeActionCall(),
                ({executor}) => executor.delayChangeActionCall(1000),
                ({executor}) => executor.changeActionCall()
            ),
        [UNLOCKED_STATE_ACTION]: makePipeFlow()
            .callFn('configs.stateManager.updatePlayerStateSave'),
        [COMPLETE_STATE_ACTION]: makePipeFlow()
            .extractConfigs({next: 'stateManager', nextState: 'stateManager', setUnlockable: 'stateManager', choosed: 'stateManager.levelSelectionScreenState', available: 'stateManager.levelSelectionScreenState', unlocked: 'stateManager.levelSelectionScreenState',})
            .condition("isBlocked", ({data: {choosed, available, unlocked}}) => (!unlocked.includes((choosed + 1) % available.length)))
            .conditionalCall("isBlocked", ({data: {setUnlockable, choosed}}) => setUnlockable(choosed + 1))
            .callFn('data.next')
            .toNextState(),
        [NEXT_SCENE_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .callFn('configs.stateManager.next'),
        [PREV_SCENE_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .callFn('configs.stateManager.prev'),
        [UNLOCK_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .callFn('configs.stateManager.tryUnlock'),
        [PLAY_AGAIN_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .extractConfigs({choose: 'stateManager'})
            .callFn('data.choose', () => 0),
        [BACK_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
    }
};
