import {useDispatch} from "react-redux";
import {sendEvent} from "../store/events";

export const useEventSender = () => {
    const dispatch = useDispatch();
    return {
        sendEvent: eventData => dispatch(sendEvent(eventData))
    }

}
