import {useSelector} from "react-redux";
import {useAssetManager} from "../../hooks/useAssetManager";
import {useEffect} from "react";
import {AUDIO_JINGLE} from "../../constants";
import {audioListeners} from "../../gameFlow/audioListeners";
import {SubscribingState} from "../../context/SubscribingState";


export const AudioManager = ({children}) => {

    const audioMixerState = useSelector(({audioMixerState}) => audioMixerState);

    const {
        muted,
        playing,
        paused,
        actVolume,
    } = audioMixerState;

    const {get: localGet} = useAssetManager();


    useEffect(() => {
        /** @type {SoundAsset&LoadableAsset} */
        const jingleAsset = localGet(AUDIO_JINGLE);

        const isPlaying = jingleAsset.isPlaying;
        playing && !isPlaying && jingleAsset.play({
            volume: actVolume,
            loops: 9999
        });

        muted ? jingleAsset.setVolume(0) : jingleAsset.setVolume(actVolume);

        paused && isPlaying ? jingleAsset.pause() : jingleAsset.resume();

    }, [audioMixerState])

    return <SubscribingState listeners={audioListeners}>
        {children}
    </SubscribingState>;

}
