import {ANSWER_SELECTION_SCREEN_STATE, AVATAR_CHOOSED_STATE_ACTION, AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE, CONFIRMED_STATE_ACTION, IDLE_STATE_ACTION, LEVEL_SELECTION_SCREEN_STATE, LISTEN_STATE_ACTION, LOGIN_SCREEN_STATE, LOGIN_SUCCESS_STATE_ACTION, PLAY_SCENE_STATE_ACTION, SELECTED_STATE_ACTION} from "./index";

export const eventsDataFlow = {
    [IDLE_STATE_ACTION]: {
        [IDLE_STATE_ACTION]: () => {
            return {
                success: true
            }
        }
    },
    [LOGIN_SCREEN_STATE]: {
        [LOGIN_SUCCESS_STATE_ACTION]: ({stateManager: {player: {classCode}}}) => {
            return {
                classCode
            }
        }
    },
    [LEVEL_SELECTION_SCREEN_STATE]: {
        [PLAY_SCENE_STATE_ACTION]: ({stateManager: {levelSelectionScreenState: {choosed: scene}}}) => ({
            scene
        })
    },
    [ANSWER_SELECTION_SCREEN_STATE]: {
        [CONFIRMED_STATE_ACTION]: ({stateManager: {answerSelectionScreenState: {choosed: answer}, levelSelectionScreenState: {choosed: scene}}}) => {
            return {
                answer,
                scene,
            }
        },
        [LISTEN_STATE_ACTION]: ({
                                    assetsManager: {
                                        get
                                    },
                                    stateManager: {
                                        changeAction,
                                        answerSelectionScreenState: {
                                            sounds,
                                            listening,
                                        },
                                        listen
                                    }
                                }) => {
            return {
                listening: sounds[listening],
                num: listening
            }
        },
        [SELECTED_STATE_ACTION]: ({stateManager: {options: {selected}, levelSelectionScreenState: {choosed: scene}}}) => {
            return {scene, selected}
        }

    },
    [AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE]: {
        [AVATAR_CHOOSED_STATE_ACTION]: ({stateManager: {avatar}}) => {
            return {avatar}
        }
    }
}
