import '../../assets/scss/level-scene-screen.scss';
import lottie from "lottie-web";
import React, {useEffect, useRef, useState} from "react";
import {animated, config, useSpring, useSprings} from "react-spring";
import {GameBtn} from "../partials/GameBtn";
import GameFrameBackground from "../partials/GameFrameBackground";
import GameFrame from "../partials/GameFrame";
import {useDispatch, useSelector} from "react-redux";
import GameProgressBar from "../partials/GameProgressBar";
import AvatarGameFrame from "../partials/AvatarGameFrame";
import GameHeart from "../partials/GameHeart";
import PlaySceneButton from "../partials/PlaySceneButton";

import {mean} from 'lodash';
import {ANSWER_SELECTION_SCREEN_STATE, COMPLETE_STATE_ACTION, LEVEL_SCENE_SCREEN_STATE, MOUNT_STATE_ACTION, PAUSE_STATE_ACTION, PLAY_STATE_ACTION, QUESTION_INTRO_SCREEN_STATE} from "../../constants";
import {playedFirstTime} from "../../store/levelSceneScreenStateSlice";
import {AnswerSelectionScreen} from "./AnswerSelectionScreen";
import {OnGameStateCmp} from "../../context/GameStateContext";
import {setProgress} from "../../store/generalStateSlice";
import {useGameActionSetter} from "../../hooks/useGameActionSetter";
import {SubscribingState} from "../../context/SubscribingState";
import {useDebuggerR} from "../../hooks/useDebuggerR";

export const LevelSceneDisplay = ({playing, sceneContainer, sceneRef, generalProgress, gameAction, isEnded, fullSpring}) => {
    useDebuggerR("LevelSceneDisplay");

    return <svg className="level-scene-screen" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1920 1080">
        <g id="Layer_1" data-name="Layer 1">
            <g width="1529.25" height="860.25" transform="translate(184.86 112.21)">
                <GameFrameBackground/>
            </g>
            <g style={(playing && {}) || {
                filter: `sepia(90%) hue-rotate(180deg) saturate(250%)`
            }} width="1539.81" height="871.64" transform="translate(179.58 106.52)">
                <animated.g width="1539.81" height="871.64">
                    <g ref={sceneContainer}/>
                </animated.g>
            </g>
            <g ref={sceneRef} width="1539.81" height="871.64" transform="translate(179.58 106.52)">
                <GameFrame/>
            </g>
            <GameBtn>
                <g>
                    <g width="242.42" height="35.33" transform="translate(374.54 885.85)">
                        <GameProgressBar progress={generalProgress} show={gameAction !== 'back'} full={playing && !isEnded} hPercent={1250}/>
                    </g>
                    <animated.g width="49.43" height="51.35" style={fullSpring}>
                        <GameHeart/>
                    </animated.g>
                    <g width="209" height="209" transform="translate(183.87 764.53)">
                        <AvatarGameFrame/>
                    </g>
                </g>
            </GameBtn>
        </g>
    </svg>
}

export const LevelSceneScreen = () => {
    useDebuggerR("LevelSceneScreen");
    const listeners = {
        [LEVEL_SCENE_SCREEN_STATE]: {
            [MOUNT_STATE_ACTION]: ({stateManager: {levelSelectionScreenState: {choosed, available, audio}}, assetsManager: {get}}) => {
                const sceneAsset = get(available[choosed]);
                setSceneAudio(get(audio[choosed]));
                const params = {
                    container: sceneContainer.current,
                    renderer: 'svg',
                    loop: false,
                    autoplay: false,
                    animationData: sceneAsset.asset,
                    assetsPath: sceneAsset.assetsPath ?? '',
                };

                const animationItem = lottie.loadAnimation(params);

                animationItem.addEventListener("complete", () => {
                    completeScene();
                    animationItem.removeEventListener("enterFrame");
                })

                sceneAudio && sceneAudio.pause();
                sceneAnimation && sceneAnimation.pause();
                sceneAnimation && sceneAnimation.destroy();
                sceneAnimation && sceneAnimation.hide();
                animationItem.hide();
                setSceneAnimation(animationItem);
            },
        }
    };


    const playScene = useGameActionSetter(PLAY_STATE_ACTION);
    const stopScene = useGameActionSetter(PAUSE_STATE_ACTION);
    const completeScene = useGameActionSetter(COMPLETE_STATE_ACTION);

    const [sceneAnimation, setSceneAnimation] = useState(null);
    const [sceneAudio, setSceneAudio] = useState(null);

    const {playing, toRestart, played, firstPlay, noAudio, recordProgress} = useSelector(({levelSceneScreenState}) => levelSceneScreenState);
    const {choosed} = useSelector(({levelSelectionScreenState}) => levelSelectionScreenState);

    const {action: gameAction, progress: generalScenesProgress} = useSelector(({generalState}) => generalState);

    const generalProgress = mean(generalScenesProgress);
    const dispatch = useDispatch();

    useEffect(() => {

        if (recordProgress) {
            sceneAnimation?.addEventListener("enterFrame", e => {
                const progress = e.currentTime / e.totalTime * 100;
                dispatch(setProgress({
                    scene: choosed,
                    progress: progress
                }));
            })
        } else {
            sceneAnimation?.removeEventListener("enterFrame");
        }

    }, [recordProgress, sceneAnimation])

    useEffect(() => {
        if (sceneAnimation) {
            if (toRestart) {
                sceneAudio.restart();
                sceneAnimation.goToAndPlay(0);
            } else if (playing) {
                if (firstPlay) {
                    sceneAudio.restart();
                    dispatch(playedFirstTime());
                } else {
                    sceneAudio.play();
                }

                sceneAnimation.play();
            } else {
                sceneAudio.pause();
                sceneAnimation.pause();
            }


            if (noAudio) {
                sceneAudio.setVolume(0);
            } else {
                sceneAudio.setVolume(100)
            }


        }
        return () => {
            if (sceneAudio && sceneAnimation) {
                sceneAudio.pause();
                sceneAnimation.pause();
            }
        }
    }, [playing, toRestart, firstPlay, noAudio]);


    const sceneContainer = useRef();
    const sceneRef = useRef();

    useEffect(() => {
        if (sceneAnimation?.renderer?.svgElement) {
            const bBox = sceneRef.current.getBBox();
            const width = bBox.width;
            const height = bBox.height;

            sceneAnimation.renderer.svgElement.setAttribute("width", width);
            sceneAnimation.renderer.svgElement.setAttribute("height", height);
            sceneAnimation.show()
        }
    }, [sceneAnimation])


    const playSwitchPos = useSpring({
        to: {
            transform: `translate(827.49px, ${playing ? 940 : 635}px)`
        },
        config: config.wobbly,
    });
    useSpring({
        to: {
            opacity: played ? 1 : 0,
        },
        immediate: !played

    });
    const btnBarPos = [
        [1653.9, 67.19],
        [281.13, 68.11],
        [160.66, 67.19],
        [399.28, 68.11],
        [515.81, 67.19],
    ];

    const isEnded = gameAction === 'back' || gameAction === 'complete';
    useSprings(
        btnBarPos.length,
        btnBarPos.map(item => {
            return {delay: isEnded ? 0 : 500, to: {transform: `translate(${item[0]}px,${isEnded ? -100 : item[1]}px)`}, from: {transform: `translate(${item[0]}px,${isEnded ? item[1] : -100}px)`}};
        })
    );
    const fullSpring = useSpring({
        to: {
            transform: `translate(${isEnded ? 325 : (playing && !isEnded) ? 1625 : 595}px, 876.61px)`
        },
        delay: (playing && !isEnded) ? 250 : 0,
        immediate: !playing
    });

    const sceneDisplayOptions = {playing, sceneContainer, sceneRef, generalProgress, gameAction, isEnded, fullSpring};

    return <SubscribingState listeners={listeners}>
        <svg>
            <OnGameStateCmp group={[LEVEL_SCENE_SCREEN_STATE, ANSWER_SELECTION_SCREEN_STATE, QUESTION_INTRO_SCREEN_STATE]}>
                <LevelSceneDisplay {...sceneDisplayOptions}/>
            </OnGameStateCmp>
            <OnGameStateCmp group={LEVEL_SCENE_SCREEN_STATE}>
                <svg className="level-scene-screen" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1920 1080">
                    <g id="Layer_1" data-name="Layer 1">
                        <animated.g onClick={() => ((playing && stopScene()) || playScene())} width="244" height="118.17" style={playSwitchPos}>
                            <GameBtn>
                                <PlaySceneButton playing={playing}/>
                            </GameBtn>
                        </animated.g>
                    </g>
                </svg>
            </OnGameStateCmp>
            <OnGameStateCmp group={ANSWER_SELECTION_SCREEN_STATE}>
                <AnswerSelectionScreen/>
            </OnGameStateCmp>
        </svg>
    </SubscribingState>

}
