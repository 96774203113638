import {createSlice} from "@reduxjs/toolkit";

export const audioMixerStateSlice = createSlice({
    name: 'audioMixerState',
    initialState: {
        muted: false,
        playing: false,
        paused: false,
        minVolume: 10,
        maxVolume: 20,
        actVolume: 20,
    },
    reducers: {
        mute: state => {
            state.muted = true;
        },
        unMute: state => {
            state.muted = false;
        },
        toMinVolume: state => {
            state.actVolume = state.minVolume;
        },
        toMaxVolume: state => {
            state.actVolume = state.maxVolume;
        },
        play: state => {
            state.playing = true;
        },
        pause: state => {
            state.paused = true;
        },
        resume: state => {
            state.paused = false;
        },
        stop: state => {
            state.playing = false;
        }
    }
});

export const {play,stop,pause,mute,resume,toMaxVolume,toMinVolume,unMute} = audioMixerStateSlice.actions


export default audioMixerStateSlice.reducer;
