import transition2_audio from './transition2.mp3';
import transition3_audio from './transition3.mp3';
import arpa2_audio from './arpa2.mp3';
import jump_audio from './jump.mp3';
import arpa3_audio from './arpa3.mp3';
import arpa1_audio from './arpa1.mp3';
import ok_audio from './ok.mp3';
import jump2_audio from './jump2.mp3';
import win_audio from './win.mp3';
import transition4_audio from './transition4.mp3';
import transition_audio from './transition.mp3';
import drop_padlock_audio from './drop-padlock.mp3';

const all_audio = {
    audio_transition2: transition2_audio,
    audio_transition3: transition3_audio,
    audio_arpa2: arpa2_audio,
    audio_jump: jump_audio,
    audio_arpa3: arpa3_audio,
    audio_arpa1: arpa1_audio,
    audio_ok: ok_audio,
    audio_jump2: jump2_audio,
    audio_win: win_audio,
    audio_transition4: transition4_audio,
    audio_transition: transition_audio,
    audio_drop_padlock: drop_padlock_audio
};
export default all_audio
