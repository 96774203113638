import {useState} from "react";
import {animated, Spring, to} from "react-spring";

export const GameBtn = ({
                            children, dropX = -5, dropY = 5, elevation = 1, disabled = false, onClick = () => {
    }
                        }) => {


    const [pressed, setPressed] = useState(false);
    const springTo = {translateX: !pressed ? 0 : dropX, translateY: !pressed ? 0 : dropY, dropShadowX: pressed ? 0 : dropX, dropShadowY: pressed ? 0 : dropY, elevation: pressed ? 0 : elevation,};

    return <g
        onMouseDown={(event) => {
            !disabled && setPressed(event.button === 0);
        }}
        onMouseUp={() => {
            !disabled && setPressed(false);
        }}
        onMouseLeave={() => !disabled && setPressed(false)}
        onTouchStart={() => !disabled && setPressed(true)}
        onTouchEnd={() => !disabled && setPressed(false)}
        onMouseEnter={event => !disabled && setPressed(event.buttons === 1)}
        onClick={() => !disabled && onClick()}
    >
        <Spring config={{duration: 30}} to={springTo}>{
            ({translateX, translateY, dropShadowX, dropShadowY, elevation}) => {
                const transformT = to([translateX, translateY], (trX, trY) => `translate(${trX}px, ${trY}px)`);
                const filter = to([dropShadowX, dropShadowY, elevation], (tx, ty, el) => `drop-shadow(black ${tx}px ${ty}px ${el}px)`);
                const transform = to([transformT], (translate) => `${translate}`);

                return <animated.g style={{
                    filter,
                    transform,
                    // transformOrigin: 'center'
                }
                }>
                    {children}
                </animated.g>
            }

        }</Spring>
    </g>
}
