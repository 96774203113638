import {SubState} from "./SubState";
import {SubscribingStateContext} from "./SubscribingStateContext";

export const SubscribingState = ({children, listeners}) => {
    return <SubscribingStateContext.Consumer>
        {
            value => <SubState listeners={listeners} sub={value}>
                {children}
            </SubState>
        }
    </SubscribingStateContext.Consumer>

}
