import React from "react";
import {ComponentAssets} from "../AssetsManager/Proxy";

export const GameBackground = () => {
    return <g>
        <g width="1920" height="1080">
            <ComponentAssets.BackgroundRay/>
        </g>
        <g width="1920" height="293.13" transform="translate(0 786.87)">
            <ComponentAssets.BackgroundTerrain/>
        </g>
    </g>
}
