import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {zipObject} from "lodash";
import {setToolbar} from "../store/toolbarStateSlice";
import {useGameState} from "./useGameState";

export const useToolbarConfiguration = (configs) => {
    const [listeners] = useState(configs);
    const stateManager = useGameState();
    const {action, group} = stateManager;
    const dispatch = useDispatch();


    useEffect(() => {
        const toolConfig = listeners?.[group]?.[action] ?? null;

        if (Array.isArray(toolConfig)) {


            const tools = zipObject(toolConfig, Array(toolConfig.length).fill(true));
            dispatch(setToolbar(tools))
        }

    }, [action, group, listeners])
}
