import {AvatarViewerBackground} from "./AvatarViewerBackground";
import {AvatarPlaceholder} from "./AvatarPlaceholder";
import {AvatarSkin} from "./AvatarSkin";
import {AvatarFace} from "./AvatarFace";
import {AvatarHair} from "./AvatarHair";
import {AvatarHat} from "./AvatarHat";
import {AvatarHeart} from "./AvatarHeart";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {GameBtn} from "./GameBtn";
import {AVATAR_CHOOSED_STATE_ACTION} from "../../constants";
import {confirm} from "../../store/avatarSlice";
import {useGameActionSetter} from "../../hooks/useGameActionSetter";
import {AvatarAccessory} from "./AvatarAccessory";


const UpPartSplashText = () => {
    return <g>
        <style>{`
      .tt1-3-1, .tt1-3-3 {
        fill: #052b63;
      }

      .tt1-3-2 {
        fill: #1d76ce;
      }

      .tt1-3-3, .tt1-3-6 {
        font-size: 57.91px;
        font-family: LondrinaSolid-Regular, Londrina Solid;
      }

      .tt1-3-4 {
        letter-spacing: -0.06em;
      }

      .tt1-3-5 {
        letter-spacing: -0.01em;
      }

      .tt1-3-6 {
        fill: #fff;
      }
    `}</style>
        <g>
            <path className="tt1-3-1" d="M4.22,41.64a4.67,4.67,0,0,1,4.44-4.9L523.48,6.63a4.67,4.67,0,0,1,4.9,4.44l5.52,111.72a4.67,4.67,0,0,1-4.69,4.91L13.58,141.36a4.68,4.68,0,0,1-4.65-4.45"/>
            <g>
                <path className="tt1-3-2" d="M525,121.08,9.37,134.73a4.68,4.68,0,0,1-4.66-4.44L0,35a4.67,4.67,0,0,1,4.44-4.9L519.27,0a4.67,4.67,0,0,1,4.9,4.44l5.52,111.72A4.68,4.68,0,0,1,525,121.08Z"/>
                <text className="tt1-3-3" transform="matrix(1, -0.02, 0.02, 1, 20.09, 109.62)">Customize <tspan className="tt1-3-4" x="244.02" y="0">Y</tspan>
                    <tspan x="265.5" y="0">ou</tspan>
                    <tspan className="tt1-3-5" x="317.5" y="0">r</tspan>
                    <tspan x="338.06" y="0" xmlSpace="preserve"> Profile</tspan>
                </text>
                <text className="tt1-3-6" transform="matrix(1, -0.02, 0.02, 1, 20.82, 102.75)">Customize <tspan className="tt1-3-4" x="244.02" y="0">Y</tspan>
                    <tspan x="265.5" y="0">ou</tspan>
                    <tspan className="tt1-3-5" x="317.5" y="0">r</tspan>
                    <tspan x="338.06" y="0" xmlSpace="preserve"> Profile</tspan>
                </text>
            </g>
        </g>

    </g>
}

const ConfirmAvatarBtn = () => {
    return <g>
        <style>{`
      .tt2-1 {
        fill: #00b86d;
      }

      .tt2-2 {
        fill: #15664a;
      }

      .tt2-3 {
        fill: #fff;
      }
    `}</style>
        <g>
            <path className="tt2-1" d="M331.19,98.32,7,105.63c-1.56,0-2.87-1.63-3-3.65L0,24.62c-.11-2.1,1.11-3.86,2.74-3.95L326.22,0c1.62-.08,3,1.55,3.14,3.64l4.73,90.73C334.2,96.54,332.88,98.35,331.19,98.32Z"/>
            <path className="tt2-2"
                  d="M37.78,82.27h0c-1.09,0-2.32.1-3.66.21a33.4,33.4,0,0,1-4.14.09H30c-.33-.71-.74-1.57-1.24-2.56s-1-2-1.63-3.11-1.24-2.14-1.92-3.19a28.11,28.11,0,0,0-2.05-2.77L23,70.8A21.56,21.56,0,0,0,23,73.46c0,1.05.08,2.13.16,3.24s.15,2.17.24,3.17.16,1.8.21,2.4a6.89,6.89,0,0,1-2.08.47c-.86.09-1.72.15-2.56.19s-1.61,0-2.28,0a7.09,7.09,0,0,0-1.25,0,3.56,3.56,0,0,0,.11-1.3c0-.86,0-2,0-3.41s-.08-3-.13-4.87-.11-3.72-.17-5.68-.15-3.9-.25-5.83-.18-3.7-.26-5.31-.15-3-.22-4.13-.16-1.92-.23-2.33c1.75-.29,3.67-.58,5.77-.85a30.53,30.53,0,0,1,6.58-.13,9.82,9.82,0,0,1,5,1.47,10.45,10.45,0,0,1,3.53,3.53,10.11,10.11,0,0,1,1.42,4.74A9.26,9.26,0,0,1,35.27,64,8.41,8.41,0,0,1,33,66.87a6.72,6.72,0,0,1-3.7,1.37H29v.05c.68,1.14,1.44,2.28,2.25,3.4s1.61,2.26,2.39,3.4,1.54,2.3,2.26,3.49A25.38,25.38,0,0,1,37.78,82.27ZM21.25,56.55c0,.4.07.86.1,1.38s.06,1,.07,1.57,0,1,.06,1.55a9.06,9.06,0,0,0,.15,1.27h.05c.72,0,1.51.07,2.36.08a12.24,12.24,0,0,0,2.39-.2,2.11,2.11,0,0,0,1.36-1,3.44,3.44,0,0,0,.55-1.61,4,4,0,0,0-.24-1.74,2.82,2.82,0,0,0-1-1.36,3.54,3.54,0,0,0-2.66-.8A9.93,9.93,0,0,0,21.25,56.55Z"/>
            <path className="tt2-2"
                  d="M59.47,75.45v.09c.08,1,.13,2,.13,3.12a13.43,13.43,0,0,1-.35,3q-4.8.08-9.75.37a82.1,82.1,0,0,1-9.53,0q-.1-4.09-.3-8.11c-.14-2.68-.25-5.35-.35-8s-.18-5.33-.21-8,0-5.35.07-8c1.22-.1,2.54-.18,4-.27l4.3-.23,4.18-.2c1.34-.07,2.53-.12,3.57-.14s1.86,0,2.49,0a3.36,3.36,0,0,1,.94.07,15.38,15.38,0,0,1,.21,3.33c-.05,1.13-.06,2.25,0,3.34v0a17.26,17.26,0,0,1-3,.48c-1,.07-2,.12-3.08.13l-3.1,0c-1,0-2.07.11-3.1.23h-.14l-.05,0a18.79,18.79,0,0,1,0,2.8,7.28,7.28,0,0,0,.23,2.7l0,0c.94,0,1.9.05,2.87,0s1.94,0,2.92,0v0a15.57,15.57,0,0,1,.22,3.19c0,1.07,0,2.19,0,3.34A6.69,6.69,0,0,1,51,69.2l-1.72.07c-.54,0-1,0-1.48.08a1.66,1.66,0,0,0-.88.26,14.44,14.44,0,0,0-.09,3.06q.12,1.69.24,2.82l0,0q3.1-.07,6.12,0T59.47,75.45Z"/>
            <path className="tt2-2"
                  d="M77.07,64.87l4,16.36v0a13.07,13.07,0,0,1-3.1.29,19.19,19.19,0,0,1-3.2-.31h0a17.41,17.41,0,0,1-.59-1.77c-.19-.69-.38-1.39-.57-2.11s-.39-1.4-.59-2a12.28,12.28,0,0,0-.66-1.68,8.1,8.1,0,0,0-.94-.07l-.59,0c-.17,0-.36,0-.56,0l-.83,0a9.29,9.29,0,0,0-.49,1.78c-.12.68-.25,1.38-.37,2.12s-.25,1.46-.39,2.16a7.44,7.44,0,0,1-.61,1.85l-.07,0a.08.08,0,0,1-.07,0,9,9,0,0,1-1.88,0c-1,0-2.57,0-4.7.07a.14.14,0,0,1-.1,0c.06-.22.15-.84.28-1.84s.32-2.32.56-3.92.53-3.46.87-5.55.74-4.33,1.21-6.69,1-4.83,1.55-7.38,1.18-5.1,1.87-7.63a4.94,4.94,0,0,1,.7-.06l1,0,1.57-.06c.64,0,1.48-.11,2.51-.23.11.4.27,1,.5,1.84s.47,1.76.73,2.81l.84,3.34.87,3.41c.28,1.09.54,2.09.78,3Zm-4.71,3.18c-.39-2.69-.71-4.78-1-6.28a22.45,22.45,0,0,0-.69-3c-.19-.53-.36-.64-.53-.34a6.37,6.37,0,0,0-.42,1.83c-.12.91-.24,2.06-.38,3.44s-.29,2.88-.47,4.48c.53,0,1.1,0,1.69,0Z"/>
            <path className="tt2-2"
                  d="M98.61,51.79a18.71,18.71,0,0,1,1.8,3.27,22.68,22.68,0,0,1,1.2,3.57A7.41,7.41,0,0,1,102,60.1c.06.49.12,1,.16,1.48,0,1.47,0,3,0,4.45a27,27,0,0,1-.38,4.41s0,0,0,0,0,0,0,0a23.68,23.68,0,0,1-2.42,5.33,56.81,56.81,0,0,1-3.29,4.79q-3.11.18-6.25.29l-6.11.18,0,0a77.16,77.16,0,0,1-.86-7.67q-.24-4-.35-8.06t-.14-8.26q0-4.16-.24-8.25a.24.24,0,0,0,.06-.09l.07-.1a18.2,18.2,0,0,1,2.74-.52c1-.1,1.92-.16,2.89-.17h2.89c1,0,1.89,0,2.8-.07a7.52,7.52,0,0,1,2.93,1.4A9.87,9.87,0,0,1,98.61,51.79ZM94.73,69a8.55,8.55,0,0,0,1.08-2.71,7,7,0,0,0-.11-3.2q-.15-.69-.36-1.47a7.41,7.41,0,0,0-.58-1.46,4.91,4.91,0,0,0-.91-1.23,3.39,3.39,0,0,0-1.33-.79c-.54,0-1.12-.07-1.75-.07a7.36,7.36,0,0,0-1.78.21h-.14v.18c.08.85.14,1.76.16,2.73s0,2,0,3,0,2.12,0,3.2.09,2.16.18,3.22a1.89,1.89,0,0,0,1.44.38c.61,0,1.2-.1,1.76-.18a.55.55,0,0,0,.42-.08,4.16,4.16,0,0,1,.42-.23,3.41,3.41,0,0,0,.81-.7A8.15,8.15,0,0,0,94.73,69Z"/>
            <path className="tt2-2"
                  d="M123,47.58v0A76.4,76.4,0,0,1,120.12,56c-1.12,2.73-2.28,5.45-3.5,8.16s-2.4,5.43-3.55,8.14-2.16,5.46-3,8.24a2.73,2.73,0,0,0-.57,0h-.61c-.65,0-1.38.06-2.16.13s-1.53.11-2.25.13h-.57a2.67,2.67,0,0,1-.56,0h-.1c.75-2.56,1.64-5.25,2.66-8s2.05-5.6,3.08-8.38v-.1a35.33,35.33,0,0,0-1.43-4.08q-.86-2-1.77-4c-.62-1.33-1.21-2.67-1.78-4a27.79,27.79,0,0,1-1.37-4,14.15,14.15,0,0,0,2-.2,15.77,15.77,0,0,1,2.06-.19,8.48,8.48,0,0,1,1.13,0,3.72,3.72,0,0,1,1.09.28c.18.68.43,1.49.75,2.42s.66,1.89,1,2.85.73,1.88,1.11,2.75.72,1.59,1,2.18a16.23,16.23,0,0,0,.91-2.21c.3-.9.61-1.84.91-2.83s.6-1.94.89-2.89.57-1.74.83-2.37a14,14,0,0,1,2.28-.32c1-.07,1.84-.12,2.65-.14h1A4.91,4.91,0,0,1,123,47.58Z"/>
            <path className="tt2-2"
                  d="M129,70.66l.05-1.79c.13-1,.3-2,.51-2.9a8.59,8.59,0,0,1,1.17-2.74,15.1,15.1,0,0,1,1.52-2.37,21.88,21.88,0,0,0,1.74-2.7,4.58,4.58,0,0,0,.64-2.47c-.05-.75-.65-1.31-1.79-1.69a2.19,2.19,0,0,0-1.67-.17,2.63,2.63,0,0,0-1,.83,13.91,13.91,0,0,0-.77,1.17,1.9,1.9,0,0,1-1,.87,18.64,18.64,0,0,1-2.89.13c-1,0-1.9-.06-2.84-.07v0a10.75,10.75,0,0,1,2.55-6.65,10.31,10.31,0,0,1,2.81-2.31,8.58,8.58,0,0,1,3.64-1.06,10.54,10.54,0,0,1,4.13.32,9.15,9.15,0,0,1,3.43,1.79,8.41,8.41,0,0,1,2.27,3,7.61,7.61,0,0,1,.61,3.84,8.31,8.31,0,0,1-.72,2.75,14.36,14.36,0,0,1-1.35,2.29,12.87,12.87,0,0,1-1.39,1.71A10.9,10.9,0,0,0,137.32,64a11.1,11.1,0,0,0-1.41,2.76,20.36,20.36,0,0,0-.74,3c-.06.26-.12.52-.17.81a1.66,1.66,0,0,0,0,.8v0a14.07,14.07,0,0,1-2.89.17,23.3,23.3,0,0,0-2.89.08h-.05a.67.67,0,0,1-.2-.49C129,71,129,70.82,129,70.66Zm5.74,9.21-5.31.1h-.14a9.35,9.35,0,0,1-.15-1.31c0-.54-.05-1.07,0-1.6V75.55c0-.47,0-.81,0-1a5.06,5.06,0,0,1,1.33-.32,17.16,17.16,0,0,1,1.76-.14,15.13,15.13,0,0,1,1.67,0,3.81,3.81,0,0,1,1.11.18s0,.29,0,.78,0,1-.05,1.67,0,1.24,0,1.83,0,1,0,1.23v.09Z"/>
            <path className="tt2-2"
                  d="M174.53,74.55c.09,1.13.14,2,.16,2.56a5.16,5.16,0,0,1-.09,1.27c-.07.27-.2.42-.38.44s-.46,0-.8,0a9.22,9.22,0,0,0-1.34,0c-.55,0-1.48.1-2.77.16s-3.09.13-5.36.19-5.31.11-9.1.14l0-.84c0-2.36.06-4.48.07-6.38s0-3.67,0-5.31-.06-3.24-.1-4.78-.1-3.1-.17-4.7-.15-3.29-.23-5.1-.16-3.79-.25-6a6.07,6.07,0,0,1,1.45-.34,17.18,17.18,0,0,1,2.11-.17,16.3,16.3,0,0,1,2.22.08,6.45,6.45,0,0,1,1.75.38q0,3.06.09,6.28c.07,2.14.15,4.3.24,6.46s.17,4.31.24,6.46.12,4.22.14,6.23v0a4.76,4.76,0,0,1,1.25-.11l2.14,0,2.61.05,2.64,0h2.21a10,10,0,0,0,1.34-.06c0,.35,0,.69,0,1s0,.63-.05,1A4.63,4.63,0,0,0,174.53,74.55Z"/>
            <path className="tt2-2"
                  d="M196.43,71.86V72c.09,1,.13,2,.13,3.13a12.8,12.8,0,0,1-.35,3c-3.19.06-6.44.18-9.75.38a81.87,81.87,0,0,1-9.52,0c-.07-2.72-.18-5.42-.31-8.1s-.25-5.35-.35-8-.17-5.32-.21-8,0-5.34.07-8.05c1.22-.09,2.54-.18,4-.26s2.86-.16,4.3-.23l4.18-.21c1.35-.06,2.54-.11,3.57-.14s1.87,0,2.5,0a4,4,0,0,1,.94.07,16.17,16.17,0,0,1,.2,3.34c-.05,1.13-.06,2.24,0,3.34v.05a18.07,18.07,0,0,1-3,.47c-1,.08-2,.12-3.08.13s-2.07,0-3.1.06-2.07.1-3.1.22h-.14l-.05,0a18.92,18.92,0,0,1,0,2.81,7.24,7.24,0,0,0,.24,2.69l0,0c.95,0,1.9,0,2.87,0s2,0,2.92,0v0a15.53,15.53,0,0,1,.23,3.19c0,1.07,0,2.18,0,3.34a6.8,6.8,0,0,1-1.6.25c-.59,0-1.16.06-1.71.07s-1,0-1.48.09a1.78,1.78,0,0,0-.89.25,14.6,14.6,0,0,0-.08,3.07c.07,1.12.15,2.06.24,2.81l0,0c2.07-.06,4.11,0,6.12,0S194.3,72,196.43,71.86Z"/>
            <path className="tt2-2"
                  d="M216.9,45c.09,1,.14,2.22.16,3.57a29.07,29.07,0,0,1-.16,3.72,9.56,9.56,0,0,0-2.22-.09l-3.52.24H211s0,.06,0,.07a.08.08,0,0,0,0,.07,4.23,4.23,0,0,0,0,.74c0,.32,0,.84,0,1.55s.05,1.63.1,2.77.11,2.62.19,4.42.16,4,.26,6.48.22,5.49.37,8.91v.09a14.18,14.18,0,0,1-1.86.22l-2,.07-2,0a12.85,12.85,0,0,0-1.83.19,15.91,15.91,0,0,1,0-1.69c0-1,0-2.5-.05-4.49s-.12-4.54-.24-7.64-.35-6.84-.65-11.23l-.05,0a3.77,3.77,0,0,0-1.42-.32c-.56,0-1.13,0-1.69,0l-1.56,0a3.41,3.41,0,0,1-1-.07,3.32,3.32,0,0,1-.19-.94q-.09-.75-.15-1.8c0-.71-.06-1.47-.08-2.28s0-1.6,0-2.36c0,0,0,0,0,0s0,0,0-.05l9.9-.33A91,91,0,0,1,216.9,45Z"/>
            <path className="tt2-2" d="M223.67,55.72h0a9.55,9.55,0,0,0-1,.12l-1.69.21c-.6.06-1.14.1-1.62.11s-.75-.06-.78-.22c.08-.34.2-.85.36-1.51s.32-1.4.5-2.23.37-1.66.57-2.53.39-1.67.57-2.41.34-1.38.47-1.92.2-.88.23-1c.85,0,1.57,0,2.16-.08l1.79-.07,1.36,0v0c-.33,1.42-.61,2.61-.82,3.57s-.41,1.85-.61,2.65-.4,1.61-.6,2.4-.47,1.73-.82,2.84Z"/>
            <path className="tt2-2"
                  d="M249.18,54.58h0a26.64,26.64,0,0,1-3.52.44,11.42,11.42,0,0,1-3.54-.3,2.2,2.2,0,0,0-.57-2.11,3.63,3.63,0,0,0-2-1,3.51,3.51,0,0,0-2.18.3,2.11,2.11,0,0,0-1.19,1.81,1.8,1.8,0,0,0-.06.64q0,.31.09.63a2.35,2.35,0,0,0,1.26,1.48,7.28,7.28,0,0,0,1.85.47A11.07,11.07,0,0,1,244,58.38a11.33,11.33,0,0,1,3.59,3.25,7.92,7.92,0,0,1,1.33,2.29,9.79,9.79,0,0,1,.56,2.55,10.82,10.82,0,0,1-.12,2.66,11.49,11.49,0,0,1-.75,2.59,7.92,7.92,0,0,1-1.44,2.53,8.16,8.16,0,0,1-2.1,1.75,9.6,9.6,0,0,1-2.56,1,11.15,11.15,0,0,1-2.84.33,13.75,13.75,0,0,1-4.13-.62,12.44,12.44,0,0,1-3.69-1.81A9,9,0,0,1,229.22,72a7.31,7.31,0,0,1-.9-4v-.29a18.82,18.82,0,0,1,3.62-.21c1.32,0,2.51,0,3.57,0a2.77,2.77,0,0,0,1.26,2.22,3.9,3.9,0,0,0,2.37.67,3.3,3.3,0,0,0,2.14-.88,2.46,2.46,0,0,0,.57-2.46,2.92,2.92,0,0,0-.89-1.79,5.75,5.75,0,0,0-1.7-1.06,9.75,9.75,0,0,0-2.11-.58L235,63.28a6.31,6.31,0,0,1-1.75-.57,7.49,7.49,0,0,1-1.48-1,9.59,9.59,0,0,1-2.92-4.49,10,10,0,0,1-.2-4.84,10.63,10.63,0,0,1,2-4.33,9.79,9.79,0,0,1,3.76-3,9.62,9.62,0,0,1,2.47-.7,15.75,15.75,0,0,1,2.75-.16,14.85,14.85,0,0,1,2.74.32,14.53,14.53,0,0,1,2.49.78,8.22,8.22,0,0,1,3.57,3.39A8.85,8.85,0,0,1,249.18,54.58Z"/>
            <path className="tt2-2"
                  d="M271.2,59.93a4.41,4.41,0,0,1,2-.5c.92-.07,1.9-.1,2.93-.1s2,0,2.9.09,1.5.08,1.81.07h.1l.05,0q.34,2.49.51,5.28a24.75,24.75,0,0,1-.24,5.35,5.92,5.92,0,0,1-1.36,3.7,7.86,7.86,0,0,1-3,2c-.77.24-1.58.46-2.43.65a24.2,24.2,0,0,1-2.57.45,12.94,12.94,0,0,1-2.56,0,8.83,8.83,0,0,1-2.4-.55,6.67,6.67,0,0,1-2.92-2.09A10.52,10.52,0,0,1,262.41,71c-.1-.34-.22-.82-.34-1.43s-.23-1.22-.31-1.85c-.12-1-.22-1.91-.31-2.72s-.16-1.62-.22-2.42-.13-1.64-.17-2.53S261,58.16,261,57s0-2,.06-3.09a30.71,30.71,0,0,1,.25-3.08,13.15,13.15,0,0,1,.74-3A5.27,5.27,0,0,1,264,45.35,6.1,6.1,0,0,1,266,44.12a16,16,0,0,1,2.43-.63,14.66,14.66,0,0,1,5,0,10.07,10.07,0,0,1,4.08,1.66,7.69,7.69,0,0,1,2.63,3.3,10.06,10.06,0,0,1,.65,4.94l0,.29q-.09.13-.42.15c-.22,0-.58,0-1.08.07s-.87,0-1.2.06l-1,0c-.32,0-.66,0-1,0l-1.22-.11c-.11-.31-.25-.69-.43-1.14a6.44,6.44,0,0,0-.7-1.26,5.32,5.32,0,0,0-.94-1,1.8,1.8,0,0,0-1.23-.41c-.54,0-1.05,0-1.56,0a1.82,1.82,0,0,0-1.3.6,1.9,1.9,0,0,0-.24.5,4.32,4.32,0,0,0-.24.78,18.32,18.32,0,0,0-.47,2.75c-.11,1.06-.19,2.2-.22,3.41s0,2.46,0,3.74.16,2.51.29,3.66a16.62,16.62,0,0,0,.43,2.25,3.34,3.34,0,0,0,1.23,1.9,3.1,3.1,0,0,0,1.09.39,5,5,0,0,0,1.48-.08,9.25,9.25,0,0,0,1.43-.42,2.58,2.58,0,0,0,.87-.66,2.51,2.51,0,0,0,.54-1.07c0-.12.06-.34.1-.64a9,9,0,0,0,.07-.92,7.47,7.47,0,0,0,0-.84c0-.25-.08-.39-.18-.42a3.33,3.33,0,0,0-.78,0l-1,.07c-.36,0-.69,0-1,0a2.28,2.28,0,0,1-.68-.1,12.71,12.71,0,0,1-.15-1.32,10.17,10.17,0,0,1,0-1.1c0-.36,0-.73,0-1.11a12.87,12.87,0,0,0-.13-1.41v0a0,0,0,0,1,.05-.05Z"/>
            <path className="tt2-2"
                  d="M302.63,47.63a7,7,0,0,1,.54,1.42,14.15,14.15,0,0,1,.29,1.47c.09.78.14,1.56.18,2.33s.05,1.54,0,2.32c-.05,1.67,0,3.28,0,4.85s.07,3.17,0,4.8l-.1,1.55a17.49,17.49,0,0,1-.44,3.33A10.44,10.44,0,0,1,302,72.77a5.81,5.81,0,0,1-2,1.86,14.68,14.68,0,0,1-5.37,1.67,18.66,18.66,0,0,1-2.7.07,18.91,18.91,0,0,1-1.91-.21,6.77,6.77,0,0,1-1.75-.49,6,6,0,0,1-1.51-.93,4.47,4.47,0,0,1-1.12-1.5,7.59,7.59,0,0,1-.94-2.49,24.27,24.27,0,0,1-.33-2.7c-.16-2.44-.32-4.89-.5-7.34a70.38,70.38,0,0,1-.12-7.41,21.48,21.48,0,0,1,.43-4.27A8.59,8.59,0,0,1,286,45.19a6.08,6.08,0,0,1,1.06-.87,13.3,13.3,0,0,1,3.33-1.19,14.78,14.78,0,0,1,3.62-.36,14.05,14.05,0,0,1,3.54.55,10,10,0,0,1,3.12,1.49A6.69,6.69,0,0,1,302.63,47.63Zm-4.73,11.6L297.73,56s0-.36-.1-1-.12-1.18-.16-1.71a5.55,5.55,0,0,0-.79-2.38,4,4,0,0,0-1.76-1.55,3,3,0,0,0-1.93-.09,4.53,4.53,0,0,0-1.72.84,6.18,6.18,0,0,0-2.13,3.31,21.66,21.66,0,0,0-.34,3.77c0,1.32,0,2.63.12,4,.19,1.09.33,2.21.42,3.35a13.68,13.68,0,0,0,.65,3.25,3.37,3.37,0,0,0,1.4,1.42,4.41,4.41,0,0,0,1.94.54,5.18,5.18,0,0,0,2.07-.34,4,4,0,0,0,1.7-1.19,7.22,7.22,0,0,0,.59-.94,2.14,2.14,0,0,0,.22-.78,6.92,6.92,0,0,0,0-1,14.93,14.93,0,0,1,0-1.58c0-.62,0-1.17,0-1.64v-.8Z"/>
            <path className="tt2-2"
                  d="M306.92,42.34h.14c.81-.09,1.76-.21,2.84-.36a7.53,7.53,0,0,1,2.94.11q.06,6,.16,12.44T313.54,67a27.25,27.25,0,0,1-3,.24l-3.1.15h-.05q0-3.1-.14-6.21t-.23-6.2q-.13-3.11-.19-6.26T306.92,42.34Zm4.53,32.81-3.53.14c-.09,0-.15-.2-.16-.6s0-.9,0-1.49.05-1.18.1-1.81.06-1.16.05-1.6v-.05a4,4,0,0,1,1.26-.31,10.25,10.25,0,0,1,1.48,0c.52,0,1,0,1.53.08s1,0,1.46,0c0,.88,0,1.81,0,2.8s0,1.94,0,2.84h-1.25c-.23,0-.53,0-.87,0Z"/>
            <path className="tt2-3"
                  d="M38.36,76.77h0c-1.1,0-2.32.1-3.67.21a35.37,35.37,0,0,1-4.14.09h0c-.34-.72-.75-1.57-1.25-2.56s-1-2-1.63-3.11-1.23-2.15-1.92-3.19a27,27,0,0,0-2.05-2.77l-.1-.14A23.74,23.74,0,0,0,23.54,68c0,1.05.08,2.13.16,3.24s.16,2.17.25,3.17.15,1.8.2,2.39a6.58,6.58,0,0,1-2.08.48c-.86.09-1.71.15-2.56.19s-1.61,0-2.28,0a9,9,0,0,0-1.25,0c.07,0,.1-.43.11-1.29s0-2,0-3.41-.08-3-.13-4.87-.11-3.73-.17-5.69-.15-3.9-.25-5.82-.18-3.7-.26-5.31-.14-3-.22-4.14-.15-1.91-.23-2.32c1.75-.3,3.67-.58,5.77-.86a31.22,31.22,0,0,1,6.58-.12,9.69,9.69,0,0,1,5,1.47,10.42,10.42,0,0,1,3.52,3.53,10,10,0,0,1,1.42,4.73,9.29,9.29,0,0,1-1.25,5.1,8.65,8.65,0,0,1-2.3,2.88,6.78,6.78,0,0,1-3.71,1.37h-.23v0c.69,1.14,1.44,2.27,2.25,3.4s1.61,2.25,2.4,3.39,1.53,2.31,2.25,3.5A25.38,25.38,0,0,1,38.36,76.77ZM21.83,51c0,.41.08.87.11,1.39s0,1,.06,1.57,0,1,.07,1.55a6.92,6.92,0,0,0,.15,1.27h0c.73,0,1.51.07,2.36.08A11.5,11.5,0,0,0,27,56.69a2.1,2.1,0,0,0,1.36-.95,3.44,3.44,0,0,0,.55-1.61,4.19,4.19,0,0,0-.23-1.74,2.86,2.86,0,0,0-1-1.36,3.49,3.49,0,0,0-2.65-.8A10,10,0,0,0,21.83,51Z"/>
            <path className="tt2-3"
                  d="M60.06,69.94V70c.09,1,.13,2,.13,3.12a13.5,13.5,0,0,1-.35,3c-3.19.05-6.45.17-9.75.37a84.38,84.38,0,0,1-9.53,0q-.11-4.09-.3-8.11t-.35-8c-.1-2.67-.17-5.33-.21-8s0-5.34.07-8c1.22-.1,2.54-.19,4-.27S46.6,44,48,44l4.18-.21c1.35-.06,2.54-.11,3.57-.14s1.87,0,2.49,0a3.84,3.84,0,0,1,.95.06,16.17,16.17,0,0,1,.2,3.34c0,1.13-.06,2.24,0,3.34v0a19.19,19.19,0,0,1-3,.48c-1,.07-2,.11-3.08.12l-3.1.06c-1,0-2.07.1-3.1.22H47l-.05,0a18.79,18.79,0,0,1,0,2.8,7.28,7.28,0,0,0,.23,2.7l0,0c.94,0,1.9.05,2.87,0s1.95,0,2.92,0v0a15.57,15.57,0,0,1,.22,3.19c0,1.07,0,2.18.05,3.34a7.33,7.33,0,0,1-1.6.26l-1.71.06c-.55,0-1,.05-1.48.09a1.67,1.67,0,0,0-.89.26,14.48,14.48,0,0,0-.08,3.06c.07,1.12.15,2.06.23,2.81l0,0q3.1-.07,6.12,0T60.06,69.94Z"/>
            <path className="tt2-3"
                  d="M77.66,59.37l4,16.36v0a13,13,0,0,1-3.09.29,18.19,18.19,0,0,1-3.21-.32h0A16,16,0,0,1,74.68,74c-.2-.68-.39-1.38-.58-2.1s-.39-1.4-.59-2a11.79,11.79,0,0,0-.66-1.68,6.33,6.33,0,0,0-.94-.07h-.59l-.56,0a8,8,0,0,1-.82.05,9.26,9.26,0,0,0-.5,1.78c-.12.67-.24,1.38-.37,2.12s-.25,1.46-.39,2.15a7.31,7.31,0,0,1-.61,1.85s0,0-.07,0,0,0-.07,0a8.26,8.26,0,0,1-1.88.05c-1,0-2.57,0-4.7.08a.16.16,0,0,1-.1,0c.06-.22.16-.84.29-1.85s.31-2.31.55-3.92S62.62,67,63,64.9s.74-4.32,1.21-6.69,1-4.82,1.55-7.38,1.18-5.09,1.87-7.62a3.88,3.88,0,0,1,.7-.06l1,0c.41,0,.93,0,1.57-.07s1.48-.1,2.52-.22c.1.4.27,1,.49,1.84s.47,1.76.73,2.8.55,2.16.84,3.35l.87,3.41c.28,1.09.54,2.08.78,3S77.52,58.84,77.66,59.37Zm-4.72,3.18q-.57-4-1-6.28a22.45,22.45,0,0,0-.69-3c-.18-.53-.36-.64-.52-.34a5.87,5.87,0,0,0-.43,1.82c-.11.92-.24,2.06-.38,3.45s-.29,2.88-.47,4.48c.54,0,1.1,0,1.7,0S72.35,62.56,72.94,62.55Z"/>
            <path className="tt2-3"
                  d="M99.19,46.29a18.71,18.71,0,0,1,1.8,3.27,22.68,22.68,0,0,1,1.2,3.57,7.29,7.29,0,0,1,.35,1.47c.06.48.11,1,.15,1.48,0,1.47,0,3,0,4.44a27,27,0,0,1-.37,4.41s0,0,0,0,0,0,0,0a23.44,23.44,0,0,1-2.42,5.33,56.81,56.81,0,0,1-3.29,4.79q-3.11.18-6.25.28c-2.1.07-4.13.14-6.11.19l-.05,0A73.82,73.82,0,0,1,83.25,68q-.25-4-.36-8.06t-.14-8.26q0-4.16-.24-8.25l.07-.09a.21.21,0,0,1,.06-.1,18.44,18.44,0,0,1,2.74-.52c1-.1,1.92-.16,2.89-.17h2.9c1,0,1.88,0,2.79-.08a7.69,7.69,0,0,1,2.93,1.41A9.51,9.51,0,0,1,99.19,46.29ZM95.31,63.47a8.55,8.55,0,0,0,1.08-2.71,7.13,7.13,0,0,0-.1-3.2c-.11-.47-.23-1-.37-1.47a7.14,7.14,0,0,0-.58-1.47,5.28,5.28,0,0,0-.9-1.22,3.43,3.43,0,0,0-1.34-.79c-.54,0-1.12-.07-1.74-.07a7.44,7.44,0,0,0-1.79.21h-.14v.19c.08.85.14,1.76.16,2.73s0,2,0,3,0,2.12,0,3.2.09,2.15.18,3.22a1.92,1.92,0,0,0,1.45.38c.61,0,1.19-.11,1.75-.18a.59.59,0,0,0,.43-.09,3.14,3.14,0,0,1,.41-.22,3.65,3.65,0,0,0,.81-.7A6.89,6.89,0,0,0,95.31,63.47Z"/>
            <path className="tt2-3"
                  d="M123.59,42.07v0a76.35,76.35,0,0,1-2.88,8.36q-1.68,4.08-3.51,8.16t-3.55,8.14q-1.73,4.06-3,8.24a2.63,2.63,0,0,0-.56,0h-.61c-.66,0-1.38.06-2.16.13s-1.54.11-2.26.13h-.56a2.73,2.73,0,0,1-.57,0h-.09q1.11-3.84,2.66-8t3.07-8.39v-.09a33.54,33.54,0,0,0-1.43-4.08q-.85-2-1.77-4c-.62-1.33-1.21-2.67-1.78-4a29.57,29.57,0,0,1-1.37-4,14,14,0,0,0,2-.2,16.1,16.1,0,0,1,2.06-.19,8.69,8.69,0,0,1,1.14,0,3.74,3.74,0,0,1,1.08.28c.18.68.43,1.49.75,2.42s.66,1.88,1,2.85.72,1.88,1.1,2.74.73,1.6,1,2.19a15,15,0,0,0,.91-2.21c.31-.91.61-1.85.91-2.83s.6-1.94.89-2.89a23.75,23.75,0,0,1,.84-2.37,13.8,13.8,0,0,1,2.27-.32c.95-.07,1.84-.12,2.65-.14h1C123.15,42,123.4,42.05,123.59,42.07Z"/>
            <path className="tt2-3"
                  d="M129.56,65.16l.05-1.79c.13-1,.3-2,.51-2.91a8.54,8.54,0,0,1,1.17-2.73,15.87,15.87,0,0,1,1.52-2.37,21.88,21.88,0,0,0,1.74-2.7,4.6,4.6,0,0,0,.64-2.47c-.05-.75-.65-1.31-1.78-1.69a2.17,2.17,0,0,0-1.68-.17,2.54,2.54,0,0,0-1,.83,12.45,12.45,0,0,0-.77,1.17,2,2,0,0,1-1,.87,17.57,17.57,0,0,1-2.89.12c-1,0-1.91-.06-2.85-.06v0a10.75,10.75,0,0,1,2.55-6.65,10.31,10.31,0,0,1,2.81-2.31,8.62,8.62,0,0,1,3.64-1.06,10.72,10.72,0,0,1,4.13.31,9.31,9.31,0,0,1,3.43,1.8,8.35,8.35,0,0,1,2.27,3,7.63,7.63,0,0,1,.62,3.85,8.51,8.51,0,0,1-.73,2.74,14.46,14.46,0,0,1-1.35,2.3,12.1,12.1,0,0,1-1.39,1.7,12.3,12.3,0,0,0-1.35,1.62,11.28,11.28,0,0,0-1.41,2.76,21.68,21.68,0,0,0-.74,3c-.06.26-.11.52-.17.81a1.66,1.66,0,0,0,0,.8v0a14.81,14.81,0,0,1-2.89.17,21.56,21.56,0,0,0-2.89.08h0a.69.69,0,0,1-.21-.49C129.57,65.5,129.56,65.32,129.56,65.16Zm5.75,9.21-5.32.09h-.14a9.52,9.52,0,0,1-.15-1.32c0-.53,0-1.06,0-1.59s0-1,0-1.51,0-.82,0-1A5.69,5.69,0,0,1,131,68.7a14.74,14.74,0,0,1,1.76-.14c.6,0,1.16,0,1.68,0a4.26,4.26,0,0,1,1.11.19s0,.29,0,.77,0,1,0,1.68,0,1.24-.05,1.83,0,1,0,1.23v.09Z"/>
            <path className="tt2-3"
                  d="M175.11,69.05c.09,1.12.15,2,.16,2.56a4.8,4.8,0,0,1-.08,1.27c-.08.27-.21.41-.39.43a7.17,7.17,0,0,1-.8,0,10.66,10.66,0,0,0-1.34,0c-.55,0-1.47.11-2.77.17s-3.09.13-5.36.19-5.31.11-9.1.14l0-.84c0-2.36.05-4.48.07-6.38s0-3.67,0-5.32-.06-3.23-.1-4.77-.1-3.1-.17-4.7-.15-3.3-.23-5.1-.16-3.8-.25-6a6.2,6.2,0,0,1,1.45-.34,17.11,17.11,0,0,1,2.12-.18,16.14,16.14,0,0,1,2.21.09,6.8,6.8,0,0,1,1.75.37c0,2,0,4.14.09,6.28s.16,4.3.24,6.47.17,4.31.24,6.46.12,4.22.14,6.23v0a5.48,5.48,0,0,1,1.25-.1l2.14,0c.81,0,1.69,0,2.61.05s1.81,0,2.64,0l2.21,0a10,10,0,0,0,1.34-.06c0,.34,0,.69,0,1s0,.64-.05,1A5.67,5.67,0,0,0,175.11,69.05Z"/>
            <path className="tt2-3"
                  d="M197,66.36v.09c.09,1,.13,2,.13,3.13a13.42,13.42,0,0,1-.34,3c-3.2,0-6.45.18-9.76.37a81.92,81.92,0,0,1-9.52,0c-.07-2.73-.18-5.43-.31-8.11s-.25-5.34-.35-8-.17-5.33-.21-8,0-5.35.07-8.05l4-.27,4.3-.23,4.18-.2c1.35-.07,2.54-.11,3.57-.14s1.87,0,2.5,0a3.93,3.93,0,0,1,.94.07,16.13,16.13,0,0,1,.21,3.34c0,1.13-.06,2.24,0,3.34v0a17.37,17.37,0,0,1-3,.48c-1,.08-2,.12-3.07.13s-2.07,0-3.11.06-2.06.1-3.1.22h-.14l0,.05a18.82,18.82,0,0,1-.05,2.8,7.49,7.49,0,0,0,.24,2.7l.05,0c.94,0,1.89,0,2.87,0s1.94,0,2.91,0v0a15.68,15.68,0,0,1,.23,3.2c0,1.06,0,2.18,0,3.34a7.36,7.36,0,0,1-1.59.25l-1.72.07c-.55,0-1,0-1.48.08a1.8,1.8,0,0,0-.89.26,14.52,14.52,0,0,0-.08,3.06c.08,1.13.15,2.07.24,2.82l.05,0q3.09-.09,6.11,0T197,66.36Z"/>
            <path className="tt2-3"
                  d="M217.48,39.52c.09,1,.14,2.23.16,3.57a29.23,29.23,0,0,1-.16,3.73,9.5,9.5,0,0,0-2.21-.09l-3.53.24h-.14a.08.08,0,0,1,0,.07.14.14,0,0,0,0,.07,5.49,5.49,0,0,0-.05.73c0,.33,0,.85,0,1.55s.05,1.63.09,2.78.11,2.61.19,4.41.16,4,.26,6.49.22,5.49.38,8.91v.09a12.57,12.57,0,0,1-1.85.21c-.68,0-1.35.06-2,.08l-2,0a11.3,11.3,0,0,0-1.83.19,12.26,12.26,0,0,1-.05-1.69c0-1,0-2.5,0-4.49s-.12-4.54-.25-7.64-.34-6.85-.65-11.23l0,0a3.77,3.77,0,0,0-1.42-.32c-.56,0-1.13,0-1.69,0s-1.08,0-1.55,0a3.53,3.53,0,0,1-1-.07,3.32,3.32,0,0,1-.19-.94c-.06-.5-.11-1.1-.14-1.81s-.07-1.46-.09-2.27,0-1.6,0-2.36c0,0,0,0,0,0s0,0,0-.05l9.89-.33A91,91,0,0,1,217.48,39.52Z"/>
            <path className="tt2-3" d="M224.25,50.21h-.05l-1,.13-1.69.2c-.59.07-1.13.1-1.62.12s-.75-.06-.78-.22c.08-.34.2-.85.36-1.51s.32-1.41.5-2.23.38-1.66.57-2.53.39-1.67.57-2.41.35-1.38.47-1.92.21-.88.23-1c.85,0,1.57,0,2.17-.08s1.19-.06,1.78-.07l1.37,0v0c-.34,1.42-.62,2.61-.83,3.57s-.41,1.85-.61,2.65-.4,1.61-.6,2.39-.47,1.74-.82,2.85Z"/>
            <path className="tt2-3"
                  d="M249.77,49.08h-.05a28.18,28.18,0,0,1-3.52.44,11,11,0,0,1-3.53-.31,2.21,2.21,0,0,0-.58-2.1,3.61,3.61,0,0,0-2-1,3.48,3.48,0,0,0-2.18.29,2.13,2.13,0,0,0-1.2,1.82,1.78,1.78,0,0,0-.06.64q0,.3.09.63a2.3,2.3,0,0,0,1.26,1.47,6.53,6.53,0,0,0,1.85.47,11.45,11.45,0,0,1,8.26,4.73,7.57,7.57,0,0,1,1.33,2.29,9.23,9.23,0,0,1,.56,2.55,11.34,11.34,0,0,1-.11,2.66,11.52,11.52,0,0,1-.76,2.59,7.92,7.92,0,0,1-1.44,2.53,8.5,8.5,0,0,1-2.09,1.75,9.81,9.81,0,0,1-2.57,1,11.54,11.54,0,0,1-2.83.33,14.14,14.14,0,0,1-4.14-.62,12.44,12.44,0,0,1-3.69-1.81,9.15,9.15,0,0,1-2.62-2.92,7.51,7.51,0,0,1-.9-4v-.28a18.05,18.05,0,0,1,3.62-.21c1.32,0,2.51,0,3.57,0a2.77,2.77,0,0,0,1.26,2.22,4,4,0,0,0,2.37.67,3.28,3.28,0,0,0,2.14-.88,2.46,2.46,0,0,0,.57-2.46,2.92,2.92,0,0,0-.89-1.79,5.56,5.56,0,0,0-1.7-1.06,9.21,9.21,0,0,0-2.11-.58l-2.12-.35a6,6,0,0,1-1.76-.56,8.31,8.31,0,0,1-1.48-1,9.59,9.59,0,0,1-2.92-4.49,10.09,10.09,0,0,1-.2-4.84,10.57,10.57,0,0,1,2-4.33,9.7,9.7,0,0,1,3.76-3,9.34,9.34,0,0,1,2.47-.7,16.86,16.86,0,0,1,2.75-.17,14.76,14.76,0,0,1,2.74.33,14.53,14.53,0,0,1,2.49.78,8.22,8.22,0,0,1,3.57,3.39A8.9,8.9,0,0,1,249.77,49.08Z"/>
            <path className="tt2-3"
                  d="M271.78,54.43a4.45,4.45,0,0,1,2-.5c.92-.07,1.9-.11,2.94-.1s2,0,2.89.09,1.5.08,1.82.07h.09l.05.05q.35,2.49.51,5.28a25.48,25.48,0,0,1-.23,5.35,6,6,0,0,1-1.37,3.7,7.82,7.82,0,0,1-3,2c-.78.24-1.59.45-2.43.65a24.37,24.37,0,0,1-2.58.44,13,13,0,0,1-2.56.05,9,9,0,0,1-2.39-.55,6.65,6.65,0,0,1-2.93-2.09A10.63,10.63,0,0,1,263,65.53c-.1-.35-.22-.82-.34-1.43s-.23-1.22-.31-1.85c-.12-1-.22-1.91-.3-2.72s-.16-1.62-.23-2.42-.12-1.64-.16-2.54-.06-1.91-.06-3.08c0-1,0-2,.06-3.08s.12-2.08.25-3.09a13.69,13.69,0,0,1,.74-3,5.37,5.37,0,0,1,1.89-2.45,6.38,6.38,0,0,1,2.09-1.23,14.41,14.41,0,0,1,2.43-.62A14.61,14.61,0,0,1,274,38a10.12,10.12,0,0,1,4.09,1.66A7.65,7.65,0,0,1,280.7,43a10.08,10.08,0,0,1,.65,5l0,.28q-.09.15-.42.15l-1.08.08q-.71,0-1.2.06c-.33,0-.65,0-1,0a8.77,8.77,0,0,1-1,0c-.34,0-.75,0-1.22-.11-.1-.31-.25-.69-.43-1.14a6.88,6.88,0,0,0-.69-1.25,5.37,5.37,0,0,0-.95-1,1.75,1.75,0,0,0-1.23-.41c-.53,0-1,0-1.55,0a1.79,1.79,0,0,0-1.31.6,1.74,1.74,0,0,0-.24.5,6.86,6.86,0,0,0-.24.78,18.17,18.17,0,0,0-.47,2.74q-.16,1.61-.21,3.42c0,1.21,0,2.45.05,3.74s.16,2.5.28,3.66a16.74,16.74,0,0,0,.44,2.25,3.22,3.22,0,0,0,1.22,1.89,3.1,3.1,0,0,0,1.09.4,4.67,4.67,0,0,0,1.48-.09,8.27,8.27,0,0,0,1.43-.41,2.86,2.86,0,0,0,.87-.66,2.41,2.41,0,0,0,.54-1.07c0-.13.06-.34.1-.64a9,9,0,0,0,.07-.92,7.47,7.47,0,0,0,0-.84c0-.26-.08-.39-.18-.42a3.75,3.75,0,0,0-.77,0l-1,.08c-.36,0-.69,0-1,0a2,2,0,0,1-.69-.1c-.07-.5-.12-.93-.15-1.31a10.24,10.24,0,0,1,0-1.1c0-.37,0-.73,0-1.11a12.79,12.79,0,0,0-.14-1.41v0s0,0,.05,0Z"/>
            <path className="tt2-3"
                  d="M303.22,42.12a7.72,7.72,0,0,1,.53,1.43A14.15,14.15,0,0,1,304,45c.09.78.15,1.56.18,2.32s0,1.55,0,2.33c-.05,1.67,0,3.28,0,4.85s.07,3.16,0,4.8l-.1,1.55a18.3,18.3,0,0,1-.43,3.33,11,11,0,0,1-1.19,3.07,6,6,0,0,1-2.05,1.86,12.79,12.79,0,0,1-2.72,1.13,13.45,13.45,0,0,1-2.64.54,18.84,18.84,0,0,1-2.71.07c-.66-.05-1.29-.11-1.91-.21a7.32,7.32,0,0,1-1.75-.49,6,6,0,0,1-1.51-.93,4.47,4.47,0,0,1-1.12-1.5,7.59,7.59,0,0,1-.94-2.49,22.1,22.1,0,0,1-.32-2.7q-.24-3.66-.5-7.35a67.55,67.55,0,0,1-.13-7.4,21.4,21.4,0,0,1,.43-4.27,8.41,8.41,0,0,1,1.86-3.84,5.65,5.65,0,0,1,1.05-.87,14,14,0,0,1,3.34-1.2,15.19,15.19,0,0,1,3.61-.35,14,14,0,0,1,3.54.54,9.81,9.81,0,0,1,3.12,1.5A6.83,6.83,0,0,1,303.22,42.12Zm-4.73,11.61-.18-3.2s0-.35-.1-1-.12-1.18-.16-1.71a5.51,5.51,0,0,0-.79-2.38,3.88,3.88,0,0,0-1.76-1.55,3,3,0,0,0-1.93-.09,4.61,4.61,0,0,0-1.72.84,6.18,6.18,0,0,0-2.12,3.3,21.85,21.85,0,0,0-.35,3.78c0,1.31,0,2.63.12,3.94.19,1.1.33,2.21.42,3.36a13.78,13.78,0,0,0,.65,3.25,3.42,3.42,0,0,0,1.4,1.42,4.45,4.45,0,0,0,1.95.54,5.13,5.13,0,0,0,2.06-.34,4,4,0,0,0,1.71-1.19,8.16,8.16,0,0,0,.58-.94,2.26,2.26,0,0,0,.22-.78,8.45,8.45,0,0,0,0-1,11,11,0,0,1,0-1.58c0-.62,0-1.17,0-1.65s0-.73,0-.8Z"/>
            <path className="tt2-3"
                  d="M307.5,36.84h.14c.81-.08,1.76-.2,2.84-.35a7.32,7.32,0,0,1,2.94.11q.06,6,.16,12.44t.54,12.43c-.81.11-1.8.19-3,.24l-3.1.15H308q0-3.1-.14-6.2c-.07-2.07-.14-4.14-.23-6.21s-.15-4.15-.19-6.25S307.43,39,307.5,36.84ZM312,69.65l-3.53.14c-.09,0-.14-.2-.15-.61s0-.9,0-1.48,0-1.18.1-1.81.06-1.16.05-1.6v-.05a4,4,0,0,1,1.26-.32,12,12,0,0,1,1.48,0c.52,0,1,0,1.53.07a14.55,14.55,0,0,0,1.46,0c0,.87,0,1.81,0,2.8s0,1.93,0,2.84H313q-.35,0-.87,0Z"/>
        </g>

    </g>
}

export const AvatarViewer = () => {

    const letsGo = useGameActionSetter(AVATAR_CHOOSED_STATE_ACTION);

    const config = useSelector(({avatar}) => avatar);

    const dispatch = useDispatch();

    const handleConfirm = () => {

        dispatch(confirm());

        return letsGo();
    };
    return <g>
        <g height="764.5" transform="matrix(1 0 0 -1 504.6204 545.3611)" width="518.9" x="-259.4" y="-382.2">
            <AvatarViewerBackground/>
        </g>
        <g transform="matrix(1, 0, 0, 1, 244.62, 76.361)">
            <UpPartSplashText/>
        </g>
        <g>

            <g transform="matrix(0.5 0 0 0.5 0 0)">
                <AvatarSkin n={config['skin']}/>
            </g>
            <g height="1127.5" transform="matrix(0.5 0 0 -0.5 525.2047 567.4869)" width="762.6" x="-381.3" y="-563.7">
                <AvatarPlaceholder/>
            </g>
            <g transform="scale(.5)">
                <AvatarFace n={config['face']}/>
            </g>
            <g style={{transform: `scale(.5)`}}>
                <AvatarHair n={config['hair']}/>
            </g>
            <g transform="scale(.5)">
                <AvatarAccessory n={config['accessory']}/>
            </g>


            <g transform="scale(.5)">
                <AvatarHat n={config['hat']}/>
            </g>

            <g height="24" transform="matrix(1 0 0 -1 530.9503 663.2717)" width="21.4" x="-10.7" y="-12">
                <AvatarHeart n={config['heart']}/>
            </g>
        </g>
        <g transform="matrix(1, 0, 0, 1, 354.62, 846.361)">
            <GameBtn dropX={-8} dropY={8} elevation={0} onClick={handleConfirm}>
                <ConfirmAvatarBtn/>
            </GameBtn>
        </g>
    </g>;
};
