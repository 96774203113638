import {useDispatch} from "react-redux";
import {useGameState} from "./useGameState";

export const useGameStateSetter = (state) => {
    const setter = useGameState().actions.general.changeState;

    const dispatch = useDispatch();

    return () => dispatch(setter(state));
}
