import {BACK_STATE_ACTION, COMPLETE_STATE_ACTION, LEVEL_SCENE_SCREEN_STATE, MOUNT_STATE_ACTION, PAUSE_STATE_ACTION, PLAY_STATE_ACTION, PLAYING_STATE_ACTION, RESTART_STATE_ACTION, WANT_EXIT_SCENE_STATE_ACTION} from "../constants";
import {DIRECT_FORWARD, makePipeFlow} from "../utils/classes/PipeCall";

export const levelSceneListeners = {
    [LEVEL_SCENE_SCREEN_STATE]: {
        [MOUNT_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD).add(({configs: {stateManager: {recordProgress}}}) => recordProgress(true)),
        [PLAY_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD).add(({configs: {stateManager: {play}}}) => play()),
        [PLAYING_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD).add(({configs: {stateManager: {play}}}) => play()),
        [WANT_EXIT_SCENE_STATE_ACTION]: makePipeFlow().add(({configs: {stateManager: {pause}}}) => pause()),
        [PAUSE_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD).add(({configs: {stateManager: {pause}}}) => pause()),
        [RESTART_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD).add(({configs: {stateManager: {restart}}}) => restart()),
        [BACK_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD).add(({configs: {stateManager: {unload},}}) => unload()),
        [COMPLETE_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
            .extractConfigs({setProgress: 'stateManager', choosed: 'stateManager.levelSelectionScreenState'})
            .callFn('configs.stateManager.recordProgress', () => false)
            .add(({data: {setProgress, choosed}}) => setProgress({scene: choosed, progress: 100}))
            .callFn('configs.stateManager.unload'),
    }
};
