import {createSlice} from "@reduxjs/toolkit";
import {uniq} from 'lodash';
import {setAsFinished} from "./generalStateSlice";
import {readPlayerStateSave} from "./asyncThunk/savePlayerState";
import {previewsAvailable, scenesAudio, scenesAvailable, scenesTitle} from "../constants/assets";

export const levelSelectionStateSlice = createSlice({
    name: 'levelSelectionState',
    initialState: {
        available: scenesAvailable,
        previews: previewsAvailable,
        audio: scenesAudio,
        titles: scenesTitle,
        unlocked: [],
        unlockable: [0],
        completed: [],
        choosed: 0,
        playable: 0,
        state: 'locked',
        allFinished: false,
    },
    extraReducers: builder => {
        builder.addCase(setAsFinished.type, state => {
            state.unlocked = state.available.map((el, pos) => pos);
            state.allFinished = true;
        });
        builder.addCase(readPlayerStateSave.fulfilled, (state, action) => ({
            ...state,
            ...(action.payload?.state?.levels ?? {})
        }))
    },
    reducers: {
        choose: (actState, action) => {
            const {available, unlocked, unlockable} = actState;
            const choosed = actState.choosed = action.payload % available.length;
            actState.state = unlocked.includes(choosed) ? 'unlocked' :
                unlockable.includes(choosed) ? 'unlockable' :
                    'locked';
        },
        next: state => {
            const {unlocked, unlockable} = state;
            const choosed = state.choosed = (state.choosed + 1) % state.available.length;
            state.state = unlocked.includes(choosed) ? 'unlocked' :
                unlockable.includes(choosed) ? 'unlockable' :
                    'locked';
        },
        prev: state => {
            const {unlocked, unlockable} = state;
            const choosed = state.choosed = (state.choosed - 1 + state.available.length) % state.available.length
            state.state = unlocked.includes(choosed) ? 'unlocked' :
                unlockable.includes(choosed) ? 'unlockable' :
                    'locked';
        },
        tryUnlock: (state) => {
            if (state.state === 'unlockable') {
                state.state = 'unlocking';
            }
        },
        setUnlockable: (state, action) => {
            state.unlockable = [...state.unlockable, action.payload];
        },
        setUnlocked: state => {
            state.state = 'unlocked';

            const unlockable = [...state.unlockable];
            const pos = unlockable.indexOf(state.choosed);
            if (pos >= 0) {
                unlockable.splice(pos, 1);
                state.unlockable = unlockable;
            }

            state.unlocked = uniq([
                    ...state.unlocked,
                    state.choosed
                ]
            );
        },
        setCompleted: state => {
            state.state = 'completed';

            if (!state.allFinished) {
                const unlocked = [...state.unlocked];
                const pos = unlocked.indexOf(state.choosed);
                if (pos >= 0) {
                    unlocked.splice(pos, 1);
                    state.unlocked = unlocked;
                }

                state.completed = uniq([
                        ...state.completed,
                        state.choosed
                    ]
                );
            }
        },
    }
});

export const {next, prev, choose, setCompleted, setUnlocked, tryUnlock, setUnlockable} = levelSelectionStateSlice.actions;

export default levelSelectionStateSlice.reducer;
