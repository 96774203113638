import {GameBtn} from "./GameBtn";

export default function RepeatSoundButton({listening=false}) {

    const scaleL = listening ? 1.5 : 1

    return <GameBtn dropX={0}>
        <g data-name="repeat-snd-btn" transform={`translate(${listening ? -10 : 0} 0)`}>
            <g transform={`scale(${scaleL})`} style={{
                // transformOrigin: 'top'
            }}>
                <path className="cls-19" d="M0,3.42,14,54.4a.63.63,0,0,0,.78.42L59.16,41a.62.62,0,0,0,.43-.7L53.69.52A.63.63,0,0,0,53.05,0L.59,2.61A.63.63,0,0,0,0,3.42Z"/>
                <g>
                    <path className="cls-5" d="M46.86,27.34a6.55,6.55,0,0,0-5.38-6.44V14.22C40.9,10,37.65,8.8,33.75,11.68l-8.87,6.53H19.42a7,7,0,0,0-7,7v5.06a7,7,0,0,0,7,7h5.46l8.87,6.53c3.9,2.88,7.15,1.68,7.73-2.54V33.77A6.53,6.53,0,0,0,46.86,27.34Z"/>
                    <path className="cls-5" d="M47.51,23.24a6.55,6.55,0,0,0-5.38-6.44V10.12C41.55,5.9,38.3,4.7,34.39,7.58l-8.86,6.53H20.07a7,7,0,0,0-7,7v5.06a7,7,0,0,0,7,7h5.46l8.86,6.53c3.91,2.88,7.16,1.68,7.74-2.55V29.67A6.54,6.54,0,0,0,47.51,23.24Z"/>
                    <g>
                        <path className="cls-4" d="M42.13,16.8V10.12C41.55,5.9,38.3,4.7,34.39,7.58l-8.86,6.53h-.35v19h.35l8.86,6.53c3.91,2.88,7.16,1.68,7.74-2.55V29.67h0V16.81Z"/>
                        <path className="cls-4" d="M47.51,23.24a6.54,6.54,0,0,0-4.22-6.11V29.34A6.52,6.52,0,0,0,47.51,23.24Z"/>
                        <path className="cls-4" d="M20.07,14.11a7,7,0,0,0-7,7v5.06a7,7,0,0,0,7,7h4v-19Z"/>
                    </g>
                </g>
            </g>
        </g>
    </GameBtn>;
}
