import {ANSWER_SELECTION_SCREEN_STATE, AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE, ENTERED_STATE_ACTION, ENTERING_STATE_ACTION, GAME_END_SCREEN_STATE, IDLE_STATE_ACTION, LEVEL_END_SCREEN_STATE, LEVEL_SCENE_SCREEN_STATE, LEVEL_SELECTION_SCREEN_STATE, LOGIN_SCREEN_STATE, QUESTION_INTRO_SCREEN_STATE, WELCOME_SCREEN_SCREEN_STATE} from "../constants";

export const toolbarConfigs = {
    [WELCOME_SCREEN_SCREEN_STATE]: {
        [ENTERING_STATE_ACTION]: [],
    },
    [QUESTION_INTRO_SCREEN_STATE]: {
        [ENTERING_STATE_ACTION]: [],
    },
    [LEVEL_SELECTION_SCREEN_STATE]: {
        [ENTERING_STATE_ACTION]: ['sound', 'logout', "full"],
        [IDLE_STATE_ACTION]: ['sound', 'logout', "full"],
    },
    [LEVEL_SCENE_SCREEN_STATE]: {
        [ENTERED_STATE_ACTION]: ["close", "replay", "full", "sound",],
        [IDLE_STATE_ACTION]: ["close", "replay", "full", "sound",],
    },
    [ANSWER_SELECTION_SCREEN_STATE]: {
        [ENTERING_STATE_ACTION]: ['sound', "full",],
        [IDLE_STATE_ACTION]: ['sound', "full",],
    },
    [LEVEL_END_SCREEN_STATE]: {
        [ENTERING_STATE_ACTION]: ['sound', "full"],
        [IDLE_STATE_ACTION]: ['sound', "full"],
    },
    [GAME_END_SCREEN_STATE]: {
        [ENTERING_STATE_ACTION]: [],
    },
    [AVATAR_CUSTOMIZER_SCREEN_SCREEN_STATE]: {
        [ENTERED_STATE_ACTION]: [],
    },
    [LOGIN_SCREEN_STATE]: {
        // [ENTERING_STATE_ACTION]: ["full"]
    }
};
