export function EnterBtn() {
    return <g>
        <path className="login-1"
              d="M.05,6.21,25.39,98.86a1.14,1.14,0,0,0,1.42.75L225.3,74.53a1.15,1.15,0,0,0,.79-1.28L215.37.94A1.15,1.15,0,0,0,214.19,0L1.08,4.75A1.13,1.13,0,0,0,.05,6.21Z"/>
        <g>
            <g>
                <path className="login-2"
                      d="M78.76,61.06v.13c.09,1.4.11,2.86.06,4.37a18.31,18.31,0,0,1-.59,4.24q-6.7-.06-13.64.17a116.42,116.42,0,0,1-13.32-.3c0-3.81,0-7.6-.13-11.34s-.16-7.49-.2-11.22,0-7.45,0-11.18.18-7.47.4-11.24c1.71-.09,3.56-.16,5.55-.23s4-.12,6-.16l5.85-.14c1.89,0,3.55-.06,5-.06s2.61,0,3.49.06a4.77,4.77,0,0,1,1.31.14A22.62,22.62,0,0,1,78.73,29c-.11,1.58-.17,3.14-.17,4.67v.07a25.39,25.39,0,0,1-4.24.56c-1.43.06-2.86.09-4.31.06s-2.89,0-4.34,0-2.89.07-4.34.2h-.19l-.07.06a27.41,27.41,0,0,1-.16,3.92,10.09,10.09,0,0,0,.23,3.78l.06.06c1.32.09,2.65.14,4,.14s2.72,0,4.08.06v.07a22.32,22.32,0,0,1,.2,4.47c-.09,1.49-.11,3.05-.07,4.67a9.88,9.88,0,0,1-2.23.29c-.84,0-1.64,0-2.41,0s-1.45,0-2.07.06a2.52,2.52,0,0,0-1.25.33,20.67,20.67,0,0,0-.23,4.28c.07,1.57.14,2.89.23,3.94l.07.07c2.89,0,5.74.07,8.55.23S75.78,61.14,78.76,61.06Z"/>
                <path className="login-2"
                      d="M91.05,40.67c.22-.13.5-.29.83-.46a5.31,5.31,0,0,0,.82-.53,12.5,12.5,0,0,1,3.09-1.58,12,12,0,0,1,3.52-.65,11.9,11.9,0,0,1,3.52.39,7.86,7.86,0,0,1,3.09,1.65,4.67,4.67,0,0,1,1,2.46,30.85,30.85,0,0,1,.46,4.51c.06,1.75.08,3.67.06,5.75s0,4.19-.1,6.31-.09,4.18-.13,6.15,0,3.71.07,5.2a26.19,26.19,0,0,1-4.11.29q-1.81,0-4.11,0C99,69.12,99,68,99,66.71s0-2.59,0-3.94,0-2.72,0-4.08-.08-2.63-.17-3.82c0-.52-.08-1.1-.13-1.74a16.47,16.47,0,0,0-.23-1.87A8,8,0,0,0,98,49.61a2.57,2.57,0,0,0-.82-1.11.17.17,0,0,0-.13-.07.16.16,0,0,1-.13-.07,5.87,5.87,0,0,0-1.55-.42,4.36,4.36,0,0,0-2,.13,3.82,3.82,0,0,0-1.78,1.18,4.4,4.4,0,0,0-.92,2.66c-.09,1.54-.11,3-.07,4.51s.1,2.94.17,4.41.1,2.92.1,4.37a35.24,35.24,0,0,1-.27,4.34v.26a10.16,10.16,0,0,1-1.94.4,15.37,15.37,0,0,1-1.67,0l-1.91-.1a19,19,0,0,0-2.63.07q0-8.1-.16-16.18t.09-16.77l1.32.1,1,0,1,0,1.32-.1a6.72,6.72,0,0,0,.92-.1c.44-.06.87-.12,1.31-.16a7,7,0,0,1,1.19,0c.35,0,.52.1.52.23A11.82,11.82,0,0,1,91,39,15.52,15.52,0,0,0,91.05,40.67Z"/>
                <path className="login-2"
                      d="M123.67,25v8.81c0,.57,0,1.16,0,1.78s0,1.25,0,1.9c1.35,0,2.79-.11,4.3-.19l5.63-.33a2.54,2.54,0,0,1,.52,1.54,16.28,16.28,0,0,1,0,2.21c0,.78-.1,1.58-.16,2.4a8.71,8.71,0,0,0,0,2.07H123.87v6.93q0,3.39.06,6.35a4.4,4.4,0,0,0,1.91,1.45,14.43,14.43,0,0,0,2.7.82,17.92,17.92,0,0,0,2.92.36c1,0,1.85,0,2.6,0,.09.61.18,1.29.26,2s.16,1.51.2,2.3a18.75,18.75,0,0,1,0,2.37,9.48,9.48,0,0,1-.43,2.17,52.53,52.53,0,0,1-5.46,0,31.26,31.26,0,0,1-5.32-.76,15,15,0,0,1-4.44-1.78,6.74,6.74,0,0,1-2.8-3.22,68.45,68.45,0,0,1-1-9.57q-.14-4.5-.07-9.3c-.7,0-1.44.06-2.2.06h-2.53l.19-8c.88,0,1.7.07,2.47.07h2.2c0-1,0-2-.06-3.13s-.07-2.24-.07-3.38,0-2.24,0-3.29.08-2,.17-2.83a8.15,8.15,0,0,1,1.87-.06c.68,0,1.39.09,2.14.16s1.49.12,2.24.16A11.9,11.9,0,0,0,123.67,25Z"/>
                <path className="login-2"
                      d="M145.24,53.49v1.19c0,1.31.1,2.39.17,3.22a5.29,5.29,0,0,0,.82,2.43,5,5,0,0,0,2.23,2.24,4.44,4.44,0,0,0,2.83.46,3.71,3.71,0,0,0,2.34-1.61,6.56,6.56,0,0,0,.82-4.05,13.36,13.36,0,0,0,1.77,0l2.34-.1c.81-.05,1.58-.06,2.33,0a8.57,8.57,0,0,1,1.84.23c-.09,1.14-.17,2.26-.26,3.36s-.2,2.11-.33,3.06a17,17,0,0,1-.49,2.43,2.88,2.88,0,0,1-.76,1.41A6.06,6.06,0,0,1,158,70a13.64,13.64,0,0,1-3.88.79,38.73,38.73,0,0,1-4.31,0c-1.47-.09-2.82-.13-4-.13a9.3,9.3,0,0,1-3.42-1.19,10.3,10.3,0,0,1-3-3.25,19.92,19.92,0,0,1-1.91-4.41,27.15,27.15,0,0,1-1-5.09,37.47,37.47,0,0,1-.13-5.4c.13-1.62.28-3.19.43-4.7a18.1,18.1,0,0,1,1-4.44,8.37,8.37,0,0,1,2.63-3.25,12.76,12.76,0,0,1,4-2,18.16,18.16,0,0,1,9.6-.23,10,10,0,0,1,4.87,2.1,10.27,10.27,0,0,1,2.79,3.82,15.16,15.16,0,0,1,1.15,4.93,35.54,35.54,0,0,1,0,5.46,13.38,13.38,0,0,1-1.74.26c-.85.09-1.86.18-3,.26s-2.4.16-3.72.2-2.58.07-3.78.07-2.29,0-3.25-.07A14.51,14.51,0,0,1,145.24,53.49Zm8-10.19a4.5,4.5,0,0,0-1-.49,4.25,4.25,0,0,0-1.25-.17,8.27,8.27,0,0,0-2.57.33,4.05,4.05,0,0,0-1.58.92,3.87,3.87,0,0,0-.88,1.38,7.7,7.7,0,0,0-.43,1.65,4.09,4.09,0,0,0-.1,1c0,.19.23.32.62.39a10.68,10.68,0,0,0,1.88.07c.85,0,2.11,0,3.78,0,1.14,0,2,0,2.56.07a9.18,9.18,0,0,0,1.22,0c.24,0,.36-.09.36-.2v-.69a4.85,4.85,0,0,0-.69-2.49A4.39,4.39,0,0,0,153.2,43.3Z"/>
                <path className="login-2"
                      d="M175.55,39.42c.18-.09.59-.26,1.22-.53s1.26-.5,1.88-.72a18,18,0,0,1,2.82-.62,28,28,0,0,1,3-.3q1.38-.06,2.34,0c.63,0,.95.05.95.1,0,1.49,0,2.8,0,3.94s0,2.16,0,3.06v2.47c0,.74,0,1.44.07,2.1l-.59-.2a7,7,0,0,0-.69-.23,5.08,5.08,0,0,1-.69-.23c-.53-.13-1.07-.24-1.61-.33l-.89-.13a30.06,30.06,0,0,0-3.26-.2,6.65,6.65,0,0,0-2.56.46,3.55,3.55,0,0,0-1.68,1.45,5.17,5.17,0,0,0-.52,2.83c0,1.53.08,3,.13,4.34s.06,2.73.06,4.11,0,2.78,0,4.21,0,3,0,4.57v.26a10.29,10.29,0,0,1-2,.4c-.61,0-1.25,0-1.91,0s-1.37-.06-2.13-.1a20.15,20.15,0,0,0-2.73.07q0-8.1-.17-16.18t.1-16.77l1.32.1,1,0,1,0,1.31-.1c.18,0,.5,0,1-.07l1.44-.13a10.17,10.17,0,0,1,1.32,0c.37,0,.56.08.56.16V38.4a2.38,2.38,0,0,0,0,.36A5,5,0,0,1,175.55,39.42Z"/>
            </g>
            <g>
                <path className="login-3"
                      d="M78.76,53.27v.13c.09,1.4.11,2.86.06,4.37A18.39,18.39,0,0,1,78.23,62q-6.7-.08-13.64.16a116.42,116.42,0,0,1-13.32-.3q0-5.72-.13-11.34t-.2-11.21c0-3.73,0-7.45,0-11.18s.18-7.48.4-11.25c1.71-.08,3.56-.16,5.55-.23s4-.12,6-.16l5.85-.13c1.89-.05,3.55-.07,5-.07s2.61,0,3.49.07a5.11,5.11,0,0,1,1.31.13,22.69,22.69,0,0,1,.17,4.67c-.11,1.58-.17,3.13-.17,4.67v.06a24.39,24.39,0,0,1-4.24.56c-1.43.07-2.86.09-4.31.07s-2.89,0-4.34,0-2.89.06-4.34.19h-.19l-.07.07a27.33,27.33,0,0,1-.16,3.91,10.07,10.07,0,0,0,.23,3.78l.06.07c1.32.09,2.65.13,4,.13s2.72,0,4.08.07v.06a22.32,22.32,0,0,1,.2,4.47c-.09,1.49-.11,3.05-.07,4.67a9.32,9.32,0,0,1-2.23.3l-2.41,0c-.76,0-1.45,0-2.07.07a2.44,2.44,0,0,0-1.25.32,20.71,20.71,0,0,0-.23,4.28c.07,1.58.14,2.89.23,3.94l.07.07q4.34,0,8.55.23C72.88,53.32,75.78,53.36,78.76,53.27Z"/>
                <path className="login-3"
                      d="M91.05,32.88l.83-.46a5.32,5.32,0,0,0,.82-.52,11.85,11.85,0,0,1,3.09-1.58,12,12,0,0,1,3.52-.66,11.9,11.9,0,0,1,3.52.4,7.71,7.71,0,0,1,3.09,1.64,4.71,4.71,0,0,1,1,2.47,30.85,30.85,0,0,1,.46,4.5c.06,1.75.08,3.67.06,5.75s0,4.19-.1,6.32-.09,4.17-.13,6.15,0,3.7.07,5.19a26.31,26.31,0,0,1-4.11.3c-1.21,0-2.58,0-4.11,0-.09-1-.13-2.14-.13-3.42s0-2.58,0-3.94,0-2.72,0-4.08-.08-2.63-.17-3.81c0-.53-.08-1.11-.13-1.74a16.36,16.36,0,0,0-.23-1.88A8,8,0,0,0,98,41.83a2.54,2.54,0,0,0-.82-1.12.21.21,0,0,0-.13-.07.18.18,0,0,1-.13-.06,5.46,5.46,0,0,0-1.55-.43,4.36,4.36,0,0,0-2,.13,3.85,3.85,0,0,0-1.78,1.19,4.4,4.4,0,0,0-.92,2.66c-.09,1.53-.11,3-.07,4.5s.1,2.94.17,4.41.1,2.93.1,4.37a35,35,0,0,1-.27,4.34V62a10.06,10.06,0,0,1-1.94.39,15.37,15.37,0,0,1-1.67,0c-.57,0-1.21,0-1.91-.1a19,19,0,0,0-2.63.07q0-8.08-.16-16.18t.09-16.77l1.32.1c.35,0,.68,0,1,0s.63,0,1,0l1.32-.1a8.11,8.11,0,0,0,.92-.09c.44-.07.87-.12,1.31-.17a8.17,8.17,0,0,1,1.19,0c.35,0,.52.1.52.23a11.7,11.7,0,0,1,.1,1.84A15.52,15.52,0,0,0,91.05,32.88Z"/>
                <path className="login-3"
                      d="M123.67,17.23V26c0,.57,0,1.17,0,1.78s0,1.25,0,1.91c1.35-.05,2.79-.11,4.3-.2l5.63-.33a2.57,2.57,0,0,1,.52,1.55,16.13,16.13,0,0,1,0,2.2c0,.79-.1,1.59-.16,2.4a8.71,8.71,0,0,0,0,2.07H123.87v6.94q0,3.39.06,6.34a4.33,4.33,0,0,0,1.91,1.45,13.73,13.73,0,0,0,2.7.82,19.11,19.11,0,0,0,2.92.37c1,0,1.85,0,2.6,0,.09.61.18,1.29.26,2s.16,1.52.2,2.31a18.61,18.61,0,0,1,0,2.36,9.38,9.38,0,0,1-.43,2.17,49.9,49.9,0,0,1-5.46,0,30.3,30.3,0,0,1-5.32-.76,15,15,0,0,1-4.44-1.77,6.74,6.74,0,0,1-2.8-3.22,68.75,68.75,0,0,1-1-9.57q-.14-4.52-.07-9.31c-.7.05-1.44.07-2.2.07h-2.53l.19-8c.88,0,1.7.06,2.47.06h2.2q0-1.44-.06-3.12c0-1.12-.07-2.25-.07-3.39s0-2.23,0-3.29.08-2,.17-2.82a8.72,8.72,0,0,1,1.87-.07c.68,0,1.39.1,2.14.16s1.49.12,2.24.17A12.82,12.82,0,0,0,123.67,17.23Z"/>
                <path className="login-3"
                      d="M145.24,45.71v1.18c0,1.32.1,2.39.17,3.22a5.29,5.29,0,0,0,.82,2.44,5,5,0,0,0,2.23,2.23,4.37,4.37,0,0,0,2.83.46,3.67,3.67,0,0,0,2.34-1.61,6.54,6.54,0,0,0,.82-4,15.75,15.75,0,0,0,1.77,0c.75,0,1.52,0,2.34-.1s1.58-.05,2.33,0a8,8,0,0,1,1.84.23c-.09,1.14-.17,2.26-.26,3.35s-.2,2.12-.33,3.06a17,17,0,0,1-.49,2.43,3,3,0,0,1-.76,1.42A6.1,6.1,0,0,1,158,62.21a13.64,13.64,0,0,1-3.88.79,34.43,34.43,0,0,1-4.31,0c-1.47-.09-2.82-.13-4-.13a9.47,9.47,0,0,1-3.42-1.18,10.34,10.34,0,0,1-3-3.26,19.84,19.84,0,0,1-1.91-4.4,27.1,27.1,0,0,1-1-5.1,37.36,37.36,0,0,1-.13-5.39c.13-1.63.28-3.19.43-4.7a17.89,17.89,0,0,1,1-4.44,8.33,8.33,0,0,1,2.63-3.26,12.76,12.76,0,0,1,4-2,17.41,17.41,0,0,1,4.74-.75,17.94,17.94,0,0,1,4.86.52A9.87,9.87,0,0,1,158.92,31a10.24,10.24,0,0,1,2.79,3.81,15.22,15.22,0,0,1,1.15,4.93,35.54,35.54,0,0,1,0,5.46,10.54,10.54,0,0,1-1.74.26c-.85.09-1.86.18-3,.27s-2.4.15-3.72.19-2.58.07-3.78.07-2.29,0-3.25-.07A16.55,16.55,0,0,1,145.24,45.71Zm8-10.2a4.5,4.5,0,0,0-1-.49,4.26,4.26,0,0,0-1.25-.16,8.27,8.27,0,0,0-2.57.33,3.82,3.82,0,0,0-1.58.92,3.71,3.71,0,0,0-.88,1.38,7.47,7.47,0,0,0-.43,1.64,4.09,4.09,0,0,0-.1,1c0,.2.23.33.62.39a10.68,10.68,0,0,0,1.88.07q1.28,0,3.78,0c1.14,0,2,0,2.56.06a8.06,8.06,0,0,0,1.22,0c.24,0,.36-.09.36-.2v-.69a4.88,4.88,0,0,0-.69-2.5A4.45,4.45,0,0,0,153.2,35.51Z"/>
                <path className="login-3"
                      d="M175.55,31.63l1.22-.52c.64-.26,1.26-.51,1.88-.73a18,18,0,0,1,2.82-.62c1.06-.15,2-.25,3-.3s1.7-.05,2.34,0a2.72,2.72,0,0,1,.95.1c0,1.49,0,2.81,0,3.95s0,2.15,0,3.05V39c0,.74,0,1.45.07,2.1l-.59-.19a5.08,5.08,0,0,0-.69-.23c-.24-.07-.47-.15-.69-.23-.53-.14-1.07-.24-1.61-.33l-.89-.13a27.72,27.72,0,0,0-3.26-.2,6.47,6.47,0,0,0-2.56.46,3.55,3.55,0,0,0-1.68,1.45,5.14,5.14,0,0,0-.52,2.82c0,1.54.08,3,.13,4.34s.06,2.73.06,4.11,0,2.79,0,4.21,0,3,0,4.57V62a10.21,10.21,0,0,1-2,.39c-.61,0-1.25.05-1.91,0s-1.37,0-2.13-.1a20.15,20.15,0,0,0-2.73.07q0-8.08-.17-16.18t.1-16.77l1.32.1c.35,0,.68,0,1,0s.64,0,1,0,.79,0,1.31-.1q.27,0,1-.06l1.44-.13a8.8,8.8,0,0,1,1.32,0c.37,0,.56.08.56.17v1.22a2.49,2.49,0,0,0,0,.36A4.89,4.89,0,0,1,175.55,31.63Z"/>
            </g>
        </g>
    </g>;
}
