import {animated} from "react-spring";
import {ComponentAssets} from "../../AssetsManager/Proxy";
import React from "react";

export const LoadingScreenView = ({LoadingScreenOpacity, loadProgress,}) => {
    return <animated.svg style={LoadingScreenOpacity} className="loading-screen" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1920 1080">
        <g id="loading-bar" width="522.6" height="36.88" transform="translate(695.49 521.56)">
            <ComponentAssets.LoadingBar loaded={loadProgress}/>
        </g>
        <g id="big-splash-text" width="770.57" height="349.81" transform="translate(660 218) scale(0.76)">
            <ComponentAssets.PresentationSplashText/>
        </g>
        <g id="big-rolly" width="579.7" height="645.41" transform="translate(828.06 625.93) scale(0.53)">
            <ComponentAssets.RollyRobot/>
        </g>
    </animated.svg>;
};
