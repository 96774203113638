import {animated, SpringValue} from "react-spring";
import React, {useState} from "react";
import {useAssetManager} from "../../hooks/useAssetManager";
import {AUDIO_TRANSITION_2, AUDIO_TRANSITION_3, AUDIO_TRANSITION_4} from "../../constants";
import {useSelector} from "react-redux";
import {ComponentAssets} from "../AssetsManager/Proxy";

export function LevelSelectionSplashText() {

    const springCalc = new SpringValue("translate(559.71px, 152.3px) rotate(0deg)");

    const [toSpring] = useState(new SpringValue("translate(559.71px, 152.3px) rotate(0deg)"));

    const transitions = useAssetManager().getList({
        't1': AUDIO_TRANSITION_2,
        't2': AUDIO_TRANSITION_3,
        't3': AUDIO_TRANSITION_4,
    });

    const [clicks, setClicks] = useState(1);

    const {sfxVolume} = useSelector(({generalState}) => generalState)

    return <animated.g onClick={() => {
        springCalc.start("translate(559.71px, 90px) rotate(9deg)");
        setTimeout(() => {
            springCalc.stop();

            toSpring.start(springCalc.get());

            if (clicks < 3) {
                setClicks(clicks + 1)
            }
            transitions[`t${clicks}`].play().setVolume(sfxVolume.act);

        }, 100);
    }} width="786" height="277.55" style={{
        transform: toSpring
    }}>
        <ComponentAssets.LearningSplashText/>
    </animated.g>;
}
