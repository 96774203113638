import {useDispatch} from "react-redux";
import {useGameState} from "./useGameState";
import {transform} from 'lodash';

export const useGameActionSetter = (action, options = {}) => {
    const setter = useGameState().actions.general.changeAction;

    const dispatch = useDispatch();

    return (localOptions = {}) => dispatch(setter({
        action, options: {
            ...options,
            ...localOptions
        }
    }));
};

export const useGameActionSetterCreator = (configs) => {
    const setter = useGameState().actions.general.changeAction;

    const dispatch = useDispatch();

    return transform(configs, (a, config, k) => {

        if (typeof config === "string") {
            a[k] = (localOptions = {}) => dispatch(setter({
                action: config,
                options: localOptions
            }));
        } else {
            const {action, options = {}} = config;
            a[k] = (localOptions = {}) => dispatch(setter({
                action, options: {
                    ...options,
                    ...localOptions
                }
            }));
        }

    })
}
