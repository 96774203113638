import {WantLeaveDialogBase} from "./WantLeaveDialogBase";
import {useDispatch} from "react-redux";
import {toPreviousAction} from "../../store/gameStateFlowSlice";
import {useGameActionSetter} from "../../hooks/useGameActionSetter";
import {BACK_STATE_ACTION} from "../../constants";

export const WantExitSceneDialog = () => {

    const dispatch = useDispatch();

    const dontWant = () => dispatch(toPreviousAction());

    const back = useGameActionSetter(BACK_STATE_ACTION);

    return <WantLeaveDialogBase
        handleNotLeave={() => dontWant()}
        handleLeave={() => back()}
    />
}
