import {createAction, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const SAVE_STATE = createAction('player/save');
export const READ_STATE = createAction('player/read');
export const UPDATE_STATE = createAction('player/update');

const collectInfo = ({levelSelectionScreenState, generalState, player}) => {

    return {
        levels: {
            unlocked: levelSelectionScreenState.unlocked,
            locked: levelSelectionScreenState.locked,
            unlockable: levelSelectionScreenState.unlockable,
            completed: levelSelectionScreenState.completed,
            allFinished: levelSelectionScreenState.allFinished,
            choosed: levelSelectionScreenState.choosed,
        },
        game: {
            finished: generalState.finished,
            replaying: generalState.replaying,
            progress: generalState.progress,
            answers: generalState.answers,
        },
        player: {
            class_code: player.classCode
        }
    }
};

export const initPlayerStateSave = createAsyncThunk(SAVE_STATE.toString(), async (arg, thunkAPI) => {

    const state = thunkAPI.getState();

    const info = collectInfo(state);

    return (await axios.post(`${process.env.REACT_APP_WEB_SERVICE_URL}/gameState`, info).then(response => {
        return response;
    })).data;
});

export const updatePlayerStateSave = createAsyncThunk(UPDATE_STATE.toString(), async (arg, thunkAPI) => {

    const state = thunkAPI.getState();

    const info = collectInfo(state);

    return (await axios.put(`${process.env.REACT_APP_WEB_SERVICE_URL}/gameState/${state.player.stateId}`, info).then(response => {
        return response;
    })).data;
});

export const readPlayerStateSave = createAsyncThunk(READ_STATE.toString(), async (arg, thunkAPI) => {

    const state = thunkAPI.getState();

    const info = collectInfo(state);


    const found = state.player?.stateId ?? false;

    if (!found) {
        return thunkAPI.dispatch(initPlayerStateSave());
    }


    return (await axios.get(`${process.env.REACT_APP_WEB_SERVICE_URL}/gameState/${state.player.stateId}`, info).then(response => {
        return response;
    })).data;
});
