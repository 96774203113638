export const listenerRepository = (function () {
    let allListeners = [];

    const clearListener = index => {
        return allListeners.splice(index, 1);
    };
    const listenersOnly = el => ({...el.listeners});

    return {
        add: listeners => {
            const item = {
                listeners,
                clear: ((index) => () => clearListener(index))(allListeners.length)
            };
            allListeners.push(item)

            return item;
        },
        get: () => allListeners.map(listenersOnly),
        getGroup: group => allListeners.map(listenersOnly).map(g => (g?.[group])).filter(el => typeof el !== 'undefined'),
        getActionGroup: (group, action) => allListeners.map(listenersOnly).map(g => (g?.[group]?.[action])).filter(el => typeof el !== 'undefined'),
        clear: clearListener
    }
})();

export const getListenerRepo = () => listenerRepository;
