import {Col, Container, Row} from "react-bootstrap";
import {FontPreloader} from "./FontPreloader";
import React from "react";
import {DebuggerR} from "../debugger/DebuggerR";


export const AppWrapper = ({children, ...props}) =>
    <Container className="main-wrapper" {...props}>
        <FontPreloader/>
        <Row>
            {/*<DebuggerR/>*/}
            <Col>
                {children}
            </Col>
        </Row>
    </Container>
