import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const sendEvent = createAsyncThunk("player/event", async ({event, classCode}, thunkAPI) => {

    const response = await axios.post(`${process.env.REACT_APP_WEB_SERVICE_URL}/game_events`, {
        event,
        classCode
    })

    return response.data;

})
