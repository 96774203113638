import {animated, useSpring} from "react-spring";
import {GameBtn} from "./GameBtn";
import AnswerFrame from "./AnswerFrame";
import AnswerChoosedCheck from "./AnswerChoosedCheck";

export default function AnswerButton({choosed = false, text, ...props}) {

    const choosedSpring = useSpring({
        to: {
            opacity: choosed ? 1 : 0
        }
    });

    return <g {...props}>
        <GameBtn dropX={0}>
            <g>
                <g width="397.55" height="267.82">
                    <AnswerFrame/>
                </g>
                <foreignObject transform="translate(20 20)" width="360" height="227.82">
                    <div className="cls-23" xmlns="http://www.w3.org/1999/xhtml">{text}</div>
                </foreignObject>

            </g>
        </GameBtn>
        {
            choosed && <animated.g style={choosedSpring} width="72.52" height="61.95" transform="translate(167.52 266)">
                <AnswerChoosedCheck/>
            </animated.g>
        }

    </g>
}
