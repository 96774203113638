import React from "react";

export default function FullScreenButton() {
    return <svg data-name="full-screen-btn">
        <path className="full-btn-16" d="M0,5.39,22,85.8a1,1,0,0,0,1.23.65l70-21.76A1,1,0,0,0,94,63.58L84.68.82a1,1,0,0,0-1-.82L.94,4.12A1,1,0,0,0,0,5.39Z"/>
        <g>
            <path className="full-btn-19" d="M25.5,20.92v15H33V28.38a4.67,4.67,0,0,1,4.68-4.68h8.49V16.24h-16A4.67,4.67,0,0,0,25.5,20.92Z"/>
            <path className="full-btn-19" d="M33,55.6V48.07H25.5v15a4.67,4.67,0,0,0,4.68,4.68h16V60.28H37.64A4.67,4.67,0,0,1,33,55.6Z"/>
            <path className="full-btn-19" d="M72.81,16.24H56.87V23.7h8.48A4.67,4.67,0,0,1,70,28.38v7.53H77.5v-15A4.68,4.68,0,0,0,72.81,16.24Z"/>
            <path className="full-btn-19" d="M70,55.6a4.67,4.67,0,0,1-4.68,4.68H56.87v7.46H72.81a4.68,4.68,0,0,0,4.69-4.68v-15H70Z"/>
        </g>
        <g>
            <path className="full-btn-2" d="M25.5,13.92v15H33V21.38a4.67,4.67,0,0,1,4.68-4.68h8.49V9.24h-16A4.67,4.67,0,0,0,25.5,13.92Z"/>
            <path className="full-btn-2" d="M33,48.6V41.07H25.5v15a4.67,4.67,0,0,0,4.68,4.68h16V53.28H37.64A4.67,4.67,0,0,1,33,48.6Z"/>
            <path className="full-btn-2" d="M72.81,9.24H56.87V16.7h8.48A4.67,4.67,0,0,1,70,21.38v7.53H77.5v-15A4.68,4.68,0,0,0,72.81,9.24Z"/>
            <path className="full-btn-2" d="M70,48.6a4.67,4.67,0,0,1-4.68,4.68H56.87v7.46H72.81a4.68,4.68,0,0,0,4.69-4.68v-15H70Z"/>
        </g>
    </svg>;
}
