import {GameBtn} from "./GameBtn";

export const WantLeaveDialogBase = ({handleNotLeave, handleLeave}) => {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
        <rect width={1920} height={1080} fill="rgba(29,118,206,.5)"/>
        <svg x={750} y={381} viewBox="0 0 1920 1080">
            <g id="Livello_2" data-name="Livello 2">
                <g id="Layer_1" data-name="Layer 1">
                    <g>
                        <g>
                            <polygon className="want-leave-1" points="403.88 247.58 0 275.56 0.86 7.32 412.41 23.93 403.88 247.58"/>
                            <polygon className="want-leave-2" points="411.78 240.27 7.9 268.24 8.77 0 420.31 16.62 411.78 240.27"/>
                        </g>
                        <text className="want-leave-3" transform="translate(68.19 71.79)">Are
                            <tspan className="want-leave-4" x="74.76" y="0"/>
                            <tspan className="want-leave-5" x="85.68" y="0">y</tspan>
                            <tspan x="111.9" y="0">ou sure</tspan>
                            <tspan className="want-leave-6" x="4.68" y="60">y</tspan>
                            <tspan x="30.9" y="60">ou</tspan>
                            <tspan className="want-leave-7" x="84.78" y="60"/>
                            <tspan className="want-leave-8" x="95.76" y="60">w</tspan>
                            <tspan className="want-leave-9" x="135.78" y="60">ant</tspan>
                            <tspan className="want-leave-10" x="214.56" y="60"/>
                            <tspan x="225.72" y="60">to</tspan>
                            <tspan x="67.53" y="120">lea</tspan>
                            <tspan className="want-leave-11" x="135.09" y="120">ve</tspan>
                            <tspan x="185.91" y="120">?</tspan>
                        </text>
                        <GameBtn onClick={handleLeave}>
                            <path className="want-leave-12" d="M92.54,235.43,115,317.55a1,1,0,0,0,1.26.66L187.79,296a1,1,0,0,0,.69-1.13l-9.5-64.1a1,1,0,0,0-1-.83l-84.49,4.21A1,1,0,0,0,92.54,235.43Z"/>
                            <text className="want-leave-13" transform="translate(112.16 289.76)">Y
                                <tspan className="want-leave-14" x="17.76" y="0">es</tspan>
                            </text>
                        </GameBtn>
                        <GameBtn onClick={handleNotLeave}>
                            <path className="want-leave-12" d="M235.58,219.39l22.47,82.12a1,1,0,0,0,1.26.66l71.52-22.23a1,1,0,0,0,.7-1.13L322,214.72a1,1,0,0,0-1-.84l-84.48,4.21A1,1,0,0,0,235.58,219.39Z"/>
                            <text className="want-leave-15" transform="translate(260.84 273.72)">No</text>
                        </GameBtn>
                    </g>
                </g>
            </g>
        </svg>
    </svg>
}
