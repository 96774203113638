import {ANSWER_SELECTION_SCREEN_STATE, BACK_STATE_ACTION, CONFIRMED_STATE_ACTION, ENTERING_STATE_ACTION, IDLE_STATE_ACTION, LISTEN_STATE_ACTION, LISTENING_STATE_ACTION, NEXT_PRESENTATION_STATE_ACTION, PRELOAD_STATE_ACTION, PRESENTATION_STATE_ACTION} from "../constants";
import {DIRECT_FORWARD, makePipeFlow, ONE_SEC_FORWARD} from "../utils/classes/PipeCall";

export const answerSelectionListeners = {
        [ANSWER_SELECTION_SCREEN_STATE]: {
            [BACK_STATE_ACTION]: makePipeFlow(ONE_SEC_FORWARD),
            [ENTERING_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
                .extractConfigs({choosed: 'stateManager.levelSelectionScreenState', initSelection: 'stateManager'})
                .add(({data: {choosed, initSelection}}) => initSelection(choosed)),
            [PRELOAD_STATE_ACTION]: makePipeFlow()
                .extractConfigs({sounds: 'stateManager.answerSelectionScreenState'})
                .setArgs(({data: {sounds}}) => ({batchLoad: [...sounds]}))
                .preload(),
            [PRESENTATION_STATE_ACTION]: makePipeFlow()
                .extractConfigs({get: 'assetsManager', changeAction: 'stateManager', listened: 'stateManager.answerSelectionScreenState', sounds: 'stateManager.answerSelectionScreenState', setListened: 'stateManager', restart: 'stateManager', muteScene: 'stateManager',})
                .callFn('data.muteScene')
                .callFn('data.restart')
                .add(({data: {listened}, executor}) => {

                    let toListen = null;

                    listened.map((el, id) => {
                        if (!el && typeof toListen !== "number") {
                            toListen = id;
                        }
                        return el;
                    });
                    const conditionsMet = typeof toListen === "number";


                    executor.condition("toListen", () => conditionsMet);
                    executor.argsCall(() => ({
                            toListen,
                            action: IDLE_STATE_ACTION
                        }
                    ));
                })
                .conditionalCall("toListen",
                    ({data: {get, changeAction, sounds, setListened,}, args: {toListen}}) => {
                        setListened(toListen);
                        get(sounds[toListen]).play().on('onfinish', () => changeAction(NEXT_PRESENTATION_STATE_ACTION));
                    },
                    ({executor}) => executor.changeActionCall()),
            [LISTEN_STATE_ACTION]: makePipeFlow()
                .add(({
                          configs: {
                              writeD,
                              assetsManager: {
                                  get
                              },
                              stateManager: {
                                  changeAction,
                                  answerSelectionScreenState: {
                                      sounds,
                                      listening,
                                  },
                                  listen
                              }
                          }
                      }) => {
                    if (typeof listening === 'number') {

                        sounds.map(el => get(el).stop());

                        changeAction(LISTENING_STATE_ACTION)
                        writeD("inizio ascolto", ANSWER_SELECTION_SCREEN_STATE);
                        get(sounds[listening]).play()
                            .on('onfinish',
                                () => {
                                    writeD("fine ascolto", ANSWER_SELECTION_SCREEN_STATE);
                                    listen(null)
                                    changeAction(IDLE_STATE_ACTION);
                                }
                            );
                    } else {
                        changeAction(IDLE_STATE_ACTION)
                    }
                }),
            [CONFIRMED_STATE_ACTION]: makePipeFlow(DIRECT_FORWARD)
                .callFn('configs.stateManager.unload')
                .add(({configs: {stateManager: {setAnswer, answerSelectionScreenState: {choosed: answer}, levelSelectionScreenState: {choosed: scene}}}}) => {
                    setAnswer({answer, scene});
                }),
        }
    }
;
