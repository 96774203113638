import {useDispatch, useSelector} from "react-redux";
import {writeDebug} from "../store/debuggerSlice";
import {useEffect} from "react";
import {keys} from 'lodash';

export const useDebuggerR = (init = "", type = "init", out = `${init} (out)`) => {
    const dispatch = useDispatch();

    const debugs = useSelector(state => state.debuggerR);

    const tabs = keys(debugs ?? {});

    const writeD = (text, type = 'general') => dispatch(writeDebug({text, type}));

    useEffect(() => {
        if (init !== '') {
            writeD(init, type);
            return () => writeD(out, type);
        }
    }, [init])

    return {
        debugs,
        tabs,
        writeD
    }
}
